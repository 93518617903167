import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHome: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="home">
			<path d="M4 21.75a.75.75 0 01-.743-.648L3.25 21V10.04l-.82.574a.75.75 0 01-.974-.098l-.07-.086a.75.75 0 01.098-.974l.086-.07 10-7a.75.75 0 01.76-.06l.1.06 10 7a.75.75 0 01-.765 1.285l-.095-.057-.82-.573V21a.75.75 0 01-.648.743L20 21.75H4zm8-17.835L4.75 8.99v11.26h3.5V15a2.75 2.75 0 012.582-2.745L11 12.25h2a2.75 2.75 0 012.745 2.582l.005.168v5.25h3.5V8.991L12 3.915zm1 9.835h-2a1.25 1.25 0 00-1.244 1.122L9.75 15v5.25h4.5V15a1.25 1.25 0 00-1.122-1.244L13 13.75z" />
		</Icon>
	);
};
