import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandButton: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-button">
			<path d="M17 2.25a2.75 2.75 0 012.745 2.582L19.75 5v2a2.75 2.75 0 01-2.582 2.745L17 9.75h-3.778v1.515l4.302.667a2.75 2.75 0 012.316 2.982l-.021.166-.032.166-1.189 5.35a2.75 2.75 0 01-2.51 2.148l-.174.006h-4.633a2.752 2.752 0 01-1.675-.569l-.155-.128-4.825-4.303a2.31 2.31 0 01-.19-3.251l.12-.129.143-.128a2.309 2.309 0 012.35-.344l.166.077 1.553.796V9.75H5a2.75 2.75 0 01-2.745-2.582L2.25 7V5a2.75 2.75 0 012.582-2.745L5 2.25h12zm-6.018 4.5l-.085.007a.727.727 0 00-.62.612l-.008.108V16a.75.75 0 01-.998.708l-.094-.04-2.646-1.359a.815.815 0 00-.765.009l-.088.06-.088.078c-.3.301-.312.777-.046 1.092l.08.082 4.825 4.303c.196.175.442.283.701.31l.131.007h4.633c.544 0 1.02-.35 1.187-.859l.033-.12 1.189-5.35a1.25 1.25 0 00-.905-1.481l-.124-.026-4.937-.765a.75.75 0 01-.627-.632l-.008-.109V7.477a.73.73 0 00-.53-.705l-.09-.016-.12-.006zm6.018-3H5a1.25 1.25 0 00-1.244 1.122L3.75 5v2c0 .648.491 1.18 1.122 1.244L5 8.25h3.768l.001-.773c0-1.123.83-2.052 1.911-2.205l.156-.016.159-.006.214.01a2.227 2.227 0 012.008 2.061l.005.156v.773H17a1.25 1.25 0 001.244-1.122L18.25 7V5a1.25 1.25 0 00-1.122-1.244L17 3.75z" />
		</Icon>
	);
};
