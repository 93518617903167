import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCameraRotate: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="camera-rotate">
			<path d="M3.71 13.553c.249.33.184.801-.146 1.051-.556.42-.814.823-.814 1.174 0 .834 1.206 1.686 3.129 2.291l.456.135.237.064.492.121c.503.116 1.039.215 1.602.296l.572.074c.287.034.58.062.88.086l.019.02a.75.75 0 01-.073-.977l.073-.084a.75.75 0 01.976-.073l.084.073 1.333 1.333.062.071.035.048.05.09.04.103c.022.068.033.142.033.218l-.002.057-.013.09-.008.038-.035.106-.034.069-.055.086-.034.042-.039.042-1.333 1.333a.75.75 0 01-1.133-.976l.073-.084.11-.11c-5.083-.332-8.997-2.105-8.997-4.582 0-.892.512-1.692 1.408-2.37a.75.75 0 011.051.145zm17.632-.145c.896.678 1.408 1.478 1.408 2.37 0 2.122-2.814 3.708-6.912 4.349a.75.75 0 11-.232-1.482c3.427-.536 5.644-1.786 5.644-2.867 0-.351-.258-.753-.814-1.174a.75.75 0 01.906-1.196zM13.368 3.25a1.86 1.86 0 011.505.766l.09.135.794 1.321h1.105a2.14 2.14 0 012.116 1.83l.017.156.005.153v5a2.14 2.14 0 01-1.986 2.134l-.153.005H7.139a2.14 2.14 0 01-2.134-1.986L5 12.61v-5a2.14 2.14 0 011.986-2.134l.153-.005h1.097l.76-1.299a1.86 1.86 0 011.28-.894l.162-.022.164-.007h2.766zm0 1.5h-2.766a.36.36 0 00-.266.118l-.045.061L9.315 6.6a.75.75 0 01-.535.363l-.113.009H7.139a.64.64 0 00-.632.545L6.5 7.61v5c0 .32.237.586.545.632l.094.007h9.722a.64.64 0 00.632-.545l.007-.094v-5a.64.64 0 00-.544-.632l-.094-.007h-1.529a.75.75 0 01-.578-.272l-.065-.091-1.013-1.684a.36.36 0 00-.309-.175zm.445 2.775l.134.126a2.696 2.696 0 01.126 3.676l-.126.134a2.695 2.695 0 11-.134-3.936zM11.197 8.71a1.195 1.195 0 101.777.097l-.092-.101-.093-.083a1.194 1.194 0 00-1.592.087z" />
		</Icon>
	);
};
