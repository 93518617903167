import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandArrow: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-arrow">
			<path d="M12.036 6.25l.18.01a2.251 2.251 0 012.029 2.083l.005.157v3.862l3.594.587a2.75 2.75 0 012.307 2.712l-.006.174-.016.169-.542 4.338a2.751 2.751 0 01-2.557 2.403l-.172.005H11.57a2.75 2.75 0 01-2.182-1.076l-.106-.149-2.699-4.049a2.307 2.307 0 01.187-2.796l.128-.137.147-.132a2.305 2.305 0 012.498-.268l.156.087.051.033V8.5c0-1.136.843-2.077 1.938-2.229l.158-.016.19-.004zm-.043 1.5l-.095.007a.751.751 0 00-.641.641l-.007.102V16a.75.75 0 01-1.203.598l-.077-.068-.901-.9a.809.809 0 00-.956-.144l-.091.063-.09.08a.804.804 0 00-.157.916l.056.099 2.7 4.05c.205.308.537.508.901.548l.138.008h5.288a1.25 1.25 0 001.22-.972l.02-.123.543-4.337a1.25 1.25 0 00-.917-1.363l-.122-.026-4.223-.689a.75.75 0 01-.621-.632L12.75 13V8.5a.754.754 0 00-.548-.728l-.093-.016-.116-.006zM12 1.25c3.013 0 5.727 1.62 7.25 4.155V4.5a.75.75 0 01.648-.743L20 3.75a.75.75 0 01.743.648l.007.102V7a.75.75 0 01-.648.743L20 7.75h-2.648a.75.75 0 01-.102-1.493l.102-.007h.656C16.757 4.113 14.5 2.75 12 2.75c-2.881 0-5.436 1.809-6.505 4.525a.75.75 0 01-1.396-.55C5.39 3.445 8.492 1.25 12 1.25z" />
		</Icon>
	);
};
