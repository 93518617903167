import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDocumentEnergy: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="document-energy">
			<path d="M19 2.25a2.75 2.75 0 012.745 2.582L21.75 5v5a.75.75 0 01-1.493.102L20.25 10V5a1.25 1.25 0 00-1.122-1.244L19 3.75H5a1.25 1.25 0 00-1.244 1.122L3.75 5v14c0 .647.492 1.18 1.122 1.244L5 20.25h7a.75.75 0 01.102 1.493L12 21.75H5a2.75 2.75 0 01-2.745-2.582L2.25 19V5a2.75 2.75 0 012.582-2.745L5 2.25h14zm-2.939 8.545a1.25 1.25 0 012.282.706l-.001 2.494h2.157c.418 0 .804.208 1.035.549l.07.118c.217.41.189.906-.073 1.289l-3.592 5.254a1.25 1.25 0 01-2.282-.706v-2.495h-2.156a1.25 1.25 0 01-1.035-.548l-.07-.118a1.25 1.25 0 01.073-1.289zm.781 1.515l-2.868 4.194h2.433a.75.75 0 01.743.649l.007.102v2.434l2.868-4.194h-2.432a.75.75 0 01-.743-.648l-.007-.102-.001-2.435zM9 15.25a.75.75 0 01.102 1.493L9 16.75H7a.75.75 0 01-.102-1.493L7 15.25h2zm2-4a.75.75 0 01.102 1.493L11 12.75H7a.75.75 0 01-.102-1.493L7 11.25h4zm4-4a.75.75 0 01.102 1.493L15 8.75H7a.75.75 0 01-.102-1.493L7 7.25h8z" />
		</Icon>
	);
};
