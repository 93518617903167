import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCloseCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="close-circle">
			<path d="M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm0 1.5l-.244.004c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.994-8.002L12 3.75zM9.616 8.567l.084.073 2.3 2.299 2.3-2.3a.75.75 0 011.133.977l-.073.084-2.299 2.3 2.3 2.3a.75.75 0 01-.977 1.133l-.084-.073-2.3-2.299-2.3 2.3a.75.75 0 01-1.133-.977l.073-.084 2.299-2.3-2.3-2.3a.75.75 0 01.977-1.133z" />
		</Icon>
	);
};
