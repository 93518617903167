import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconQuotationMark: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="quotation-mark">
            <g id="Group_126" transform="matrix(0.5307827,0,0,0.5307827,0,4.7459224)">
                <path
                    id="Path_3"
                    d="M 0,16.8 A 10.534,10.534 0 1 0 11.864,6.352 V 0.493 A 0.491,0.491 0 0 0 11.2,0.032 17.27,17.27 0 0 0 0,16.739 v 0 c 0,0.021 0,0.04 0,0.06"
                    fill="#665f5f"
                />
            </g>
            <g id="g171" transform="matrix(0.5307827,0,0,0.5307827,12.818001,4.7459224)">
                <path
                    id="path169"
                    d="M 0,16.8 A 10.534,10.534 0 1 0 11.864,6.352 V 0.493 A 0.491,0.491 0 0 0 11.2,0.032 17.27,17.27 0 0 0 0,16.739 v 0 c 0,0.021 0,0.04 0,0.06"
                    fill="#665f5f"
                />
            </g>
        </Icon>
    );
};
