import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconBank: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="bank">
			<path d="M11.75 2.293a.75.75 0 01.5 0l9.5 3.36c.3.107.5.39.5.708V9a.75.75 0 01-.75.75h-.75v7.5H21c.16 0 .313.05.44.143l.09.077 1 1c.141.14.22.331.22.53v2a.75.75 0 01-.75.75H2a.75.75 0 01-.75-.75v-2a.75.75 0 01.22-.53l1-1a.75.75 0 01.53-.22h.25v-7.5H2.5a.75.75 0 01-.743-.648L1.75 9V6.361c0-.318.2-.601.5-.707zm8.939 16.457H3.31l-.56.56v.94h18.5v-.939l-.561-.561zm-13.439-9h-2.5v7.5h2.5v-7.5zm4 0h-2.5v7.5h2.5v-7.5zm4 0h-2.5v7.5h2.5v-7.5zm4 0h-2.5v7.5h2.5v-7.5zM12 3.796L3.25 6.891V8.25h17.5V6.891L12 3.796zm0 1.729l.11.007a.875.875 0 11-.22 0l.11-.007z" />
		</Icon>
	);
};
