import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewsletterSubscribeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  origin: Types.MarketingConsentOrigin;
  recaptcha: Types.Scalars['String']['input'];
}>;


export type NewsletterSubscribeMutation = { __typename?: 'Mutation', newsletterSubscribe?: boolean | null };


export const NewsletterSubscribeDocument = gql`
    mutation NewsletterSubscribe($email: String!, $origin: MarketingConsentOrigin!, $recaptcha: String!) {
  newsletterSubscribe(email: $email, origin: $origin, recaptcha: $recaptcha)
}
    `;
export type NewsletterSubscribeMutationFn = Apollo.MutationFunction<NewsletterSubscribeMutation, NewsletterSubscribeMutationVariables>;

/**
 * __useNewsletterSubscribeMutation__
 *
 * To run a mutation, you first call `useNewsletterSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterSubscribeMutation, { data, loading, error }] = useNewsletterSubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      origin: // value for 'origin'
 *      recaptcha: // value for 'recaptcha'
 *   },
 * });
 */
export function useNewsletterSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<NewsletterSubscribeMutation, NewsletterSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewsletterSubscribeMutation, NewsletterSubscribeMutationVariables>(NewsletterSubscribeDocument, options);
      }
export type NewsletterSubscribeMutationHookResult = ReturnType<typeof useNewsletterSubscribeMutation>;
export type NewsletterSubscribeMutationResult = Apollo.MutationResult<NewsletterSubscribeMutation>;
export type NewsletterSubscribeMutationOptions = Apollo.BaseMutationOptions<NewsletterSubscribeMutation, NewsletterSubscribeMutationVariables>;