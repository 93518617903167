import {ComponentProps, ReactNode} from 'react';
import {RecaptchaClaim as RCOrig} from '@components/RecaptchaClaim';
import {FormattedMessage} from 'react-intl';

export const RecaptchaClaim = (props: ComponentProps<typeof RCOrig>) => (
    <RCOrig {...props}>
        <FormattedMessage
            defaultMessage={
                'Tato stránka je chráněna technologií reCAPTCHA a platí <privacy>Zásady ochrany osobních údajů</privacy> a <terms>Smluvní podmínky</terms> společnosti Google'
            }
            values={{
                privacy: (chunks: ReactNode) => (
                    <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                        {chunks}
                    </a>
                ),
                terms: (chunks: ReactNode) => (
                    <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                        {chunks}
                    </a>
                ),
            }}
        />
    </RCOrig>
);
