import classNames from 'classnames';
import {forwardRef, PropsWithChildren} from 'react';
import {ContentBox} from '../ContentBox';
import styles from './CalendarSelectionGroup.module.scss';
import {CalendarSelectionGroupSeparator} from './CalendarSelectionGroupSeparator';

export const CalendarSelectionGroup = forwardRef<HTMLDivElement, PropsWithChildren>(({children}, ref) => {
    return (
        <ContentBox ref={ref} className={classNames(styles.calendarSelectionGroup, 'p-0 d-flex')} rounded="sm">
            {children}
        </ContentBox>
    );
});

export default Object.assign(CalendarSelectionGroup, {
    Separator: CalendarSelectionGroupSeparator,
});
