import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-right">
			<path d="M19.7 12.27l-.023.054-.041.074-.042.06-.064.072-5 5a.75.75 0 01-1.133-.976l.073-.084 3.718-3.72H5a.75.75 0 01-.102-1.493L5 11.25h12.189l-3.72-3.72a.75.75 0 01.977-1.133l.084.073 5 5 .052.057.056.078.053.103.033.096.019.09.007.106-.002.057-.017.111-.031.103z" />
		</Icon>
	);
};
