import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconLogoSymbol: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="logo-symbol">
			<path
				fill="#009c70"
				d="M17.792 5.39C15.436 1.915 10.607.973 7.014 3.211 3.42 5.45 2.419 10.043 4.717 13.518c0 0 3.71 6.303 15.019 8.482 2.533-10.779-1.944-16.61-1.944-16.61"
			/>
			<path
				fill="#fff"
				d="M13.155 10.22a2.88 2.88 0 00.723-1.9 2.905 2.905 0 10-2.904 2.904 2.9 2.9 0 00.525-.053l.168.168-.236.883 1.06.236-.294 1.06 1.119.295-.236 1.001.943.236-.177.589 1.885.47.353-1.295-2.886-4.535-.043-.06"
			/>
			<path
				fill="#009c70"
				d="M10.782 6.817c.651 0 1.178.501 1.178 1.119s-.527 1.12-1.178 1.12c-.65 0-1.178-.502-1.178-1.12 0-.618.527-1.119 1.178-1.119"
			/>
		</Icon>
	);
};
