import {FormattedMessage} from 'react-intl';
import {ResultOrder} from '.cache/__types__';

export const translations = {
    [ResultOrder.PriceAsc]: <FormattedMessage key={ResultOrder.PriceAsc} defaultMessage="Nejlevnější" />,
    [ResultOrder.PriceDesc]: <FormattedMessage key={ResultOrder.PriceDesc} defaultMessage="Nejdražší" />,
    [ResultOrder.SurfaceAsc]: <FormattedMessage key={ResultOrder.SurfaceAsc} defaultMessage="Nejmenší" />,
    [ResultOrder.SurfaceDesc]: <FormattedMessage key={ResultOrder.SurfaceDesc} defaultMessage="Největší" />,
    [ResultOrder.TimeorderDesc]: <FormattedMessage key={ResultOrder.TimeorderDesc} defaultMessage="Nejnovější" />,
    [ResultOrder.DiscountDesc]: <FormattedMessage key={ResultOrder.DiscountDesc} defaultMessage="Nejvyšší sleva" />,
};

export const listDefaultType = ResultOrder.TimeorderDesc;
