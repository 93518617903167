import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconOneCircle: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="one-circle">
            <path
                d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM12 17H14V7H10V9H12V17Z"
                fill={'currentColor'}
            />
        </Icon>
    );
};
