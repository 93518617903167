import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCheckCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="check-circle">
			<path d="M12 2.25a9.75 9.75 0 110 19.5 9.75 9.75 0 010-19.5zm0 1.5a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zm3.997 5.315a.75.75 0 01.073.977l-.072.084-4.73 4.737-.01.022c-.217.35-.705.543-1.107.218l-.096-.088-2.145-2.146a.75.75 0 01-.073-.976l.073-.084a.75.75 0 01.976-.073l.084.073 1.616 1.615 4.35-4.358a.75.75 0 011.06 0z" />
		</Icon>
	);
};
