import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronUp: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-up">
			<path d="M11.47 9.47a.75.75 0 01.976-.073l.084.073 4 4a.75.75 0 01-.976 1.133l-.084-.073L12 11.061l-3.47 3.47a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084 4-4z" />
		</Icon>
	);
};
