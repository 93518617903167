import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPlayCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="play-circle">
			<path d="M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm.004 1.499l-.248.005c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.99-8.003l-.252-.004zM9.059 9.704a1.5 1.5 0 012.148-1.354l.116.063 3.881 2.295a1.5 1.5 0 01.123 2.502l-.122.08-3.882 2.296a1.5 1.5 0 01-2.258-1.159l-.006-.132V9.704zm1.5 0v4.591l3.883-2.296-3.883-2.296z" />
		</Icon>
	);
};
