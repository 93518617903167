import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHeartFilled: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="heart-filled">
			<path fill={"#e63d2e"} d="M15.399 3.257a5.371 5.371 0 00-3.222 1.23L12 4.64l-.177-.152c-.94-.774-2.117-1.237-3.519-1.237-3.415 0-6.054 3.101-6.054 6.505 0 2.848 1.556 5.569 4.08 7.854 1.985 1.798 4.44 3.141 5.67 3.141 1.23 0 3.685-1.343 5.67-3.141 2.524-2.285 4.08-5.006 4.08-7.854 0-3.404-2.639-6.505-6.054-6.505l-.297.007zm.297 1.493c2.528 0 4.554 2.38 4.554 5.005 0 2.358-1.348 4.715-3.587 6.742-1.734 1.57-3.895 2.753-4.663 2.753-.768 0-2.929-1.182-4.663-2.753C5.098 14.47 3.75 12.113 3.75 9.755c0-2.624 2.026-5.005 4.554-5.005 1.328 0 2.36.552 3.126 1.448.3.35.84.35 1.14 0 .766-.896 1.798-1.448 3.126-1.448z" />
		</Icon>
	);
};
