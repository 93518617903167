import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDotsHorizontal: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="dots-horizontal">
			<path d="M7.25 11.997l-.006.128A1.252 1.252 0 016 13.25a1.25 1.25 0 111.243-1.381l.007.128zm6 0l-.006.128A1.252 1.252 0 0112 13.25a1.25 1.25 0 111.243-1.381l.007.128zm6 0l-.006.128A1.252 1.252 0 0118 13.25a1.25 1.25 0 111.243-1.381l.007.128z" />
		</Icon>
	);
};
