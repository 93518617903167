import {ApiActionButton, ApiActionButtonProps} from '@liveComponents/ApiActionButton';
import {useFormikContext} from 'formik';
import {FocusError} from '@liveComponents/Field/FocusError';

export type SubmitButtonProps = ApiActionButtonProps & {
    submitting?: never;
    type?: never;
    preventErrorFocus?: boolean;
};

export const SubmitButton = ({children, preventErrorFocus, ...rest}: SubmitButtonProps) => {
    const {isSubmitting} = useFormikContext();
    return (
        <ApiActionButton type="submit" {...rest} submitting={isSubmitting}>
            {!preventErrorFocus && <FocusError />}
            {children}
        </ApiActionButton>
    );
};
