import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SupportedPhonePrefixesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SupportedPhonePrefixesQuery = { __typename?: 'Query', supportedPhonePrefixes: Array<number> };


export const SupportedPhonePrefixesDocument = gql`
    query SupportedPhonePrefixes {
  supportedPhonePrefixes
}
    `;

/**
 * __useSupportedPhonePrefixesQuery__
 *
 * To run a query within a React component, call `useSupportedPhonePrefixesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedPhonePrefixesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedPhonePrefixesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportedPhonePrefixesQuery(baseOptions?: Apollo.QueryHookOptions<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>(SupportedPhonePrefixesDocument, options);
      }
export function useSupportedPhonePrefixesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>(SupportedPhonePrefixesDocument, options);
        }
export function useSupportedPhonePrefixesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>(SupportedPhonePrefixesDocument, options);
        }
export type SupportedPhonePrefixesQueryHookResult = ReturnType<typeof useSupportedPhonePrefixesQuery>;
export type SupportedPhonePrefixesLazyQueryHookResult = ReturnType<typeof useSupportedPhonePrefixesLazyQuery>;
export type SupportedPhonePrefixesSuspenseQueryHookResult = ReturnType<typeof useSupportedPhonePrefixesSuspenseQuery>;
export type SupportedPhonePrefixesQueryResult = Apollo.QueryResult<SupportedPhonePrefixesQuery, SupportedPhonePrefixesQueryVariables>;