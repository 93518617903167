export type State = {
    showMenuBar: boolean;
    showUserBar: boolean;
};

export type Action = ToggleAction | Reset;

export enum ActionTypes {
    toggle,
    reset,
}

export enum Toggles {
    MenuBar = 'showMenuBar',
    UserBar = 'showUserBar',
}

type ToggleAction = {
    type: ActionTypes.toggle;
    attr: Toggles;
};

type Reset = {
    type: ActionTypes.reset;
};
