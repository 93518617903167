import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconLock: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="lock">
			<path d="M12 2.25a4.75 4.75 0 014.745 4.533L16.75 7v2.25H17a2.75 2.75 0 012.745 2.582l.005.168v7a2.75 2.75 0 01-2.582 2.745L17 21.75H7a2.75 2.75 0 01-2.745-2.582L4.25 19v-7a2.75 2.75 0 012.582-2.745L7 9.25h.25V7A4.75 4.75 0 0112 2.25zm5 8.5H7a1.25 1.25 0 00-1.244 1.122L5.75 12v7c0 .648.491 1.18 1.122 1.244L7 20.25h10a1.25 1.25 0 001.244-1.122L18.25 19v-7a1.25 1.25 0 00-1.122-1.244L17 10.75zm-5-7a3.25 3.25 0 00-3.245 3.066L8.75 7v2.25h6.5V7A3.25 3.25 0 0012 3.75z" />
		</Icon>
	);
};
