import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './SocialsMenu.module.scss';

type SocialsMenuLinkProps = {
	variant?: 'facebook' | 'instagram' | 'twitter';
}

export const SocialsMenuLink: FC<React.PropsWithChildren<SocialsMenuLinkProps>> = ({ variant, children }) => {
	return (
		<span
			className={classNames(styles.socialsMenuLink, {
				[styles[`socialsMenuLink--${variant}`]]: variant,
			})}
		>
			{children}
		</span>
	);
};
