import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronBigDown: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-big-down">
			<path d="M2.47 6.97a.75.75 0 01.976-.073l.084.073L12 15.439l8.47-8.47a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084-9 9a.75.75 0 01-.976.073l-.084-.073-9-9a.75.75 0 010-1.06z" />
		</Icon>
	);
};
