import {FormattedMessage} from 'react-intl';
import {UserGroupKeyType} from '.cache/__types__';

export const translations = {
    [UserGroupKeyType.Developer]: <FormattedMessage defaultMessage="Developer" />,
    [UserGroupKeyType.ObceAMesta]: <FormattedMessage defaultMessage="Obce a města" />,
    [UserGroupKeyType.Cirkev]: <FormattedMessage defaultMessage="Církev" />,
    [UserGroupKeyType.BytoveDruzstvo]: <FormattedMessage defaultMessage="Bytové družstvo" />,
    [UserGroupKeyType.SpolecenstviClastnikuJednotek]: <FormattedMessage defaultMessage="Společenství vlastníků" />,
    [UserGroupKeyType.SpravaNemovitosti]: <FormattedMessage defaultMessage="Správa nemovitostí" />,
    [UserGroupKeyType.NeziskovaOrganizace]: <FormattedMessage defaultMessage="Nezisková organizace" />,
    [UserGroupKeyType.StavebniSpolecnost]: <FormattedMessage defaultMessage="Stavební společnost" />,
    [UserGroupKeyType.Ostatni]: <FormattedMessage defaultMessage="Ostatní" />,
    [UserGroupKeyType.Undefined]: <FormattedMessage defaultMessage="Nespecifikováno" />,
};
