import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconCalendarViewDay: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="calendar-view-day">
            <path d="M3 16.5h18v2H3v-2Zm0-7h18v5H3v-5Zm0-4h18v2H3v-2Z" />
        </Icon>
    );
};
