const path = require('path');

const sassImports = [path.resolve(__dirname, '../styles/common/index.scss').split(path.sep).join('/')];

const basePath = {
	assets: '../public/',
};

const config = {
	mediaQueries: {
		breakpoints: {
			all: '0px',
			xxs: '360px',
			sm: '480px',
			md: '768px',
			lg: '1024px',
			xl: '1200px',
			xxl: '1400px',
			xxxl: '1600px',
			full: '1920px',
		},
		rules: {
			webkit: '(-webkit-min-device-pixel-ratio: 0)',
			retina: '(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
		},
	},
	assets: {
		fonts: `${basePath.assets}fonts/`,
		images: `${basePath.assets}img/`,
	},
};

const { breakpoints, rules } = config.mediaQueries;

const breakpointsVars = Object.keys(breakpoints).reduce((acc, key) => {
	const [, number, , unit] = breakpoints[key].match(/(\d+)(\s*)(\S+)/);

	return {
		...acc,
		[`${key}Up`]: `min-width: ${number}${unit}`,
		[`${key}Down`]: `max-width: ${number - 0.02}${unit}`,
	};
}, {});

const sassVariables = {
	...breakpointsVars,
	...rules,
	breakpoints,
	paths: config.assets,
};

module.exports = {
	sassVariables,
	sassImports,
	mediaQueries: breakpointsVars,
};
