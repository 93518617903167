import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconCheckPlus: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="check-plus">
            <g fill="none">
                <path d="M24 24H0V0h24Z" />
                <path
                    stroke="#323232"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M13.794 7.006h0a5.5 5.5 0 1 0-6.788 6.788"
                />
                <circle cx={15.5} cy={15.5} r={5.5} stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
                <path
                    stroke="#323232"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15.5 13.73v3.54M13.73 15.5h3.54M10.333 7.583l-2.29 2.292L6.667 8.5"
                />
            </g>
        </Icon>
    );
};
