import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEnvelope: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="envelope">
			<path d="M19.218 4.25a2.532 2.532 0 012.532 2.532l-.005.142.005.076v10a2.75 2.75 0 01-2.582 2.745L19 19.75H5a2.75 2.75 0 01-2.745-2.582L2.25 17V7l.005-.081-.005-.137a2.532 2.532 0 012.366-2.527l.166-.005zm-9.38 8.975l-5.014 5.013.048.006.128.006h14c.06 0 .119-.004.176-.012l-5.009-5.009a4.753 4.753 0 01-4.33-.004zM3.75 9.142V17c0 .06.004.119.012.176l4.785-4.785L3.75 9.142zm16.5.006l-4.792 3.249 4.78 4.779.006-.048.006-.128V9.148zM19.218 5.75H4.782a1.032 1.032 0 00-.58 1.886l5.848 3.961c.06.03.119.067.171.114a3.244 3.244 0 003.61-.022l-.032.02.046-.038a.745.745 0 01.104-.063l5.849-3.964c.282-.19.452-.51.452-.852l-.006-.122a1.032 1.032 0 00-1.026-.92z" />
		</Icon>
	);
};
