import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconClose: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="close">
			<path d="M8.446 7.397l.084.073L12 10.939l3.47-3.47a.75.75 0 011.133.977l-.073.084L13.061 12l3.47 3.47a.75.75 0 01-.977 1.133l-.084-.073L12 13.061l-3.47 3.47a.75.75 0 01-1.133-.977l.073-.084L10.939 12l-3.47-3.47a.75.75 0 01.875-1.197l.102.064z" />
		</Icon>
	);
};
