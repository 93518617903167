import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconElectricSocket: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="electric-socket">
			<path d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zm0 1.5a8.25 8.25 0 00-.75 16.466V16.75a3.75 3.75 0 01-3.745-3.55L7.5 13v-1.5a1.75 1.75 0 011.606-1.744l.144-.006V8a.75.75 0 011.493-.102L10.75 8v1.75h2.5V8a.75.75 0 011.493-.102L14.75 8v1.75a1.75 1.75 0 011.744 1.606l.006.144V13a3.75 3.75 0 01-3.55 3.745l-.2.005v3.466A8.251 8.251 0 0012 3.75zm2.75 7.5h-5.5a.25.25 0 00-.243.193L9 11.5V13a2.25 2.25 0 002.096 2.245l.154.005h1.5a2.25 2.25 0 002.245-2.096L15 13v-1.5a.25.25 0 00-.193-.243l-.057-.007z" />
		</Icon>
	);
};
