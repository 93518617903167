import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconRotateLeft: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="rotate-left">
			<path d="M4 9.75a.75.75 0 01-.743-.648L3.25 9V5a.75.75 0 011.493-.102L4.75 5l-.002 2.108A8.74 8.74 0 0112 3.25 8.75 8.75 0 0120.75 12a.75.75 0 11-1.5 0A7.25 7.25 0 0012 4.75a7.242 7.242 0 00-6.201 3.5H8a.75.75 0 01.743.648L8.75 9a.75.75 0 01-.648.743L8 9.75H4zm.29 4.495a.75.75 0 01.973.421 7.247 7.247 0 0013.092.826.75.75 0 011.314.724 8.747 8.747 0 01-15.8-.998.75.75 0 01.421-.973z" />
		</Icon>
	);
};
