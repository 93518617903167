import {Dispatch} from 'react';
import {State, ActionTypes, Action, Toggles} from './types';

export const initialState: State = {
    [Toggles.MenuBar]: false,
    [Toggles.UserBar]: false,
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.toggle: {
            return {
                ...initialState,
                [action.attr]: !state[action.attr],
            };
        }

        case ActionTypes.reset: {
            return {
                ...initialState,
            };
        }

        default:
            // or just 'return state;'
            throw new Error('Undefined dispatch type');
    }
};

export const onClickToggle = (dispatch: Dispatch<Action>) => (attr: Toggles) => () => {
    dispatch({type: ActionTypes.toggle, attr});
};

export const onReset = (dispatch: Dispatch<Action>) => () => {
    dispatch({type: ActionTypes.reset});
};
