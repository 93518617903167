import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconMountain: FC<IconProps> = ({...props}) => {
    return (
        <Icon {...props} id="mountain">
            <g id="Layer_2" data-name="Layer 2"
               strokeLinecap={"round"}
               strokeLinejoin={"round"}
               strokeWidth={1}
               fill={"none"}
            >
                <path d="M22,21,15.38333,8.53125a1,1,0,0,0-1.76666,0L7,21" fill="none" stroke="#323232"/>
                <line x1="18.08027" y1="13.61364" x2="16.29012" y2="14.61364" fill="none" stroke="#323232"/>
                <line x1="14.49997" y1="13.61364" x2="16.29012" y2="14.61364" fill="none" stroke="#323232"/>
                <line x1="12.70982" y1="14.61364" x2="14.49997" y2="13.61364" fill="none" stroke="#323232"/>
                <line x1="10.91967" y1="13.61364" x2="12.70982" y2="14.61364" fill="none" stroke="#323232"/>
                <line x1="23" y1="21" x2="1" y2="21" fill="none" stroke="#323232"/>
                <path
                    d="M8.43506,4.51868A1.74534,1.74534,0,0,1,8.25,8H4.5a1.5,1.5,0,0,1,0-3,1.99655,1.99655,0,0,1,3.93506-.48132Z"
                    fill="none" stroke="#323232"/>
                <path d="M9.95966,15.42273,8.378,12.52136a.99993.99993,0,0,0-1.756,0L2,21" fill="none" stroke="#323232"/>
                <rect width="24" height="24" fill="none"/>
            </g>
        </Icon>
    );
};
