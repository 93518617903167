import {Locale} from '.cache/__types__';
import {LocaleEnum} from '../general';

export const getGraphQlLocale = (locale: string | undefined) => {
    switch (locale) {
        case 'cs':
            return Locale.Cs;
        case 'sk':
            return Locale.Sk;
        case 'en':
            return Locale.En;
    }

    return Locale.Cs;
};

export const fromGraphQLLocale = (locale: Locale) => {
    switch (locale) {
        case Locale.Cs:
            return LocaleEnum.cs;
        case Locale.En:
            return LocaleEnum.en;
        case Locale.Sk:
            return LocaleEnum.sk;
    }

    return LocaleEnum.cs;
};
