import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconGroup: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="group">
            <path d="M16 11a2.99 2.99 0 0 0 2.99-3A3 3 0 1 0 16 11Zm-8 0a2.99 2.99 0 0 0 2.99-3A3 3 0 1 0 8 11Zm.17 2H8c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.27-4.45-3.44-6.83-3.5ZM16 13c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5Z" />
        </Icon>
    );
};
