import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconWalletMoney: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="wallet-money">
            <g strokeLinecap={'round'} stroke={'currentColor'} strokeWidth={'1.5'} fill={'none'} strokeLinejoin={'round'}>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21.75 12h-3.581c-.784 0-1.419.635-1.419 1.419v1.162c0 .784.635 1.419 1.419 1.419h3.581"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19.75 21h-14a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2Z"
                    clipRule="evenodd"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="m14.75 7-3.293-3.293a.999.999 0 0 0-1.414 0L6.75 7"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="m12.75 5 1.293-1.293a.999.999 0 0 1 1.414 0L18.75 7"
                />
            </g>
        </Icon>
    );
};
