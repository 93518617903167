import { InputGroup as InputGroupComponent } from 'react-bootstrap';
import InputGroupDefaultProps from 'react-bootstrap/InputGroup';
import { InputGroupRequired, InputGroupRequiredProps } from './InputGroupRequired';
import { InputGroupShowPassword, InputGroupShowPasswordProps } from './InputGroupShowPassword';

type InputGroupProps = typeof InputGroupDefaultProps & {
	Required: InputGroupRequiredProps;
	ShowPassword: InputGroupShowPasswordProps;
};

export default Object.assign(InputGroupComponent as InputGroupProps, {
	Required: InputGroupRequired,
	ShowPassword: InputGroupShowPassword,
});
