import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandUp: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-up">
			<path d="M9.5 1.25a2.25 2.25 0 012.245 2.096l.005.154-.001 2.878a2.25 2.25 0 012.758 1.103 2.25 2.25 0 013 1 2.25 2.25 0 013.238 1.865l.005.154V16a6.75 6.75 0 01-6.513 6.746L14 22.75h-1.789a6.75 6.75 0 01-5.462-2.784l-.154-.222-3.526-5.288a2.275 2.275 0 01.285-2.87 2.33 2.33 0 013.165-.119l.127.119.604.603V3.5a2.25 2.25 0 012.096-2.245L9.5 1.25zm0 1.5a.75.75 0 00-.743.648L8.75 3.5V14a.75.75 0 01-1.203.598l-.077-.068-1.884-1.884a.829.829 0 00-1.172 0 .775.775 0 00-.156.872l.059.106 3.526 5.288a5.25 5.25 0 004.118 2.332l.25.006H14a5.25 5.25 0 005.246-5.034L19.25 16v-5.5a.75.75 0 00-1.493-.102l-.007.102V13a.75.75 0 01-1.493.102L16.25 13V9.5a.75.75 0 00-1.493-.102l-.007.102V12a.75.75 0 01-1.493.102L13.25 12V8.5a.75.75 0 00-1.493-.102l-.007.102V12a.75.75 0 01-1.493.102L10.25 12V3.5a.75.75 0 00-.75-.75z" />
		</Icon>
	);
};
