import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCopy: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="copy">
            <path d="M11.25,10.5H12a3,3,0,0,1,0,6H9a2.9899,2.9899,0,0,1-2-5.22144" fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <path d="M12.75,13.5H12a3,3,0,0,1,0-6h3a2.9899,2.9899,0,0,1,2,5.22144" fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            <rect x="3" y="2.97211" width="18" height="18" rx="5" strokeWidth="1.5" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
		</Icon>
	);
};
