import {Typography} from '@components/Typography';
import {Icon} from '@components/Icon';
import {Button} from '@components/Button';
import {FormattedMessage} from 'react-intl';

export type PasswordResetEmailRegisterProps = {
    onClose?: () => void;
};

export const PasswordResetEmailRegister = ({onClose}: PasswordResetEmailRegisterProps) => (
    <>
        <Typography as="p" textAlign="center" color="grey-dark" className="mb-8">
            <Icon.Envelope size="xl" />
        </Typography>
        <Typography as="h2" variant="h4" className="mb-4" textAlign="center">
            <FormattedMessage defaultMessage="Zapomenuté heslo" />
        </Typography>

        <Typography as="p" className="mb-8" textAlign="center">
            <FormattedMessage defaultMessage="Je nám líto, ale váš účet neexistuje." />
        </Typography>

        <Button className="w-100" onClick={onClose}>
            <FormattedMessage defaultMessage="Zpět na registraci" />
        </Button>
    </>
);
