import React, {FC} from 'react';
import {Typography, TypographyProps} from '../../Typography';

export type FormRangeSliderLabelProps = TypographyProps;

export const FormRangeSliderLabel: FC<React.PropsWithChildren<FormRangeSliderLabelProps>> = ({children, ...rest}) => {
    return (
        <Typography variant="perex" {...rest}>
            {children}
        </Typography>
    );
};
