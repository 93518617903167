import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconLink: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="link">
            <path d="M11 7v1.9H7a3.1 3.1 0 0 0 0 6.2h4V17H7A5 5 0 0 1 7 7h4Zm6 0a5 5 0 0 1 0 10h-4v-1.9h4a3.1 3.1 0 0 0 0-6.2h-4V7h4Zm-1 4v2H8v-2h8Z" />
        </Icon>
    );
};
