import classNames from 'classnames';
import React, {FC, PropsWithChildren, useEffect, useRef} from 'react';
import ReactSlider, {ReactSliderProps} from 'react-slider';
import useResizeObserver from 'use-resize-observer';
import styles from './RangeSlider.module.scss';

export type FormRangeSliderProps = Omit<ReactSliderProps<number | number[]>, 'children'> & {onGrey?: boolean};

export const RangeSlider: FC<PropsWithChildren<FormRangeSliderProps>> = ({children, onGrey, ...props}) => {
    const sliderRef = useRef<ReactSlider & {slider?: HTMLDivElement}>(null);
    const {ref} = useResizeObserver<HTMLDivElement>({
        ref: sliderRef.current?.slider,
    });

    useEffect(() => {
        sliderRef.current?.resize();
    }, [ref]);

    return (
        <>
            <ReactSlider
                {...props}
                ref={sliderRef as any}
                className={classNames(styles.rangeSlider, onGrey && styles.onGrey)}
                thumbClassName={styles.rangeSliderThumb}
                thumbActiveClassName={styles.rangeSliderThumbActive}
                renderTrack={(props, state) => (
                    <div
                        {...props}
                        className={classNames(styles.rangeSliderTrack, {
                            [styles[`rangeSliderTrack--active`]]: typeof state.value === 'number' ? state.index === 0 : state.index === 1,
                        })}
                    />
                )}
            />

            {children && <div className={styles.rangeSliderLabels}>{children}</div>}
        </>
    );
};
