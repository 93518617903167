import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import styles from './LoginSidebar.module.scss';

export const LoginSidebar: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.loginSidebar, 'loginSidebar', 'px-md-10 py-md-8 h-100')} rounded={false}>
			{children}
		</ContentBox>
	);
};
