import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconBroke: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="broke">
            <g strokeLinecap={'round'} stroke={'currentColor'} strokeWidth={'1.5'} fill={'none'} strokeLinejoin={'round'}>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="m11.17 16.646-1.97 1.97a3.484 3.484 0 0 1-4.929 0v0a3.484 3.484 0 0 1 0-4.927l1.971-1.971M14.978 8.762h2.788a3.485 3.485 0 0 1 3.485 3.485v0a3.485 3.485 0 0 1-3.485 3.485h-2.788M8.66 4.36l.5 1.48M12.69 4.49l-.78 1.58M5.64 6.96l1.47.73"
                />
            </g>
        </Icon>
    );
};
