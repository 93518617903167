import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconDocumentHouseCheck: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="document-house-checked">
            <path d="M15.75 0a2.75 2.75 0 0 1 2.745 2.582l.005.168v4a.75.75 0 0 1-1.493.102L17 6.75v-4a1.25 1.25 0 0 0-1.122-1.244L15.75 1.5h-13a1.25 1.25 0 0 0-1.244 1.122L1.5 2.75v14c0 .647.492 1.18 1.122 1.244L2.75 18h3a.75.75 0 0 1 .102 1.493l-.102.007h-3a2.75 2.75 0 0 1-2.745-2.582L0 16.75v-14A2.75 2.75 0 0 1 2.582.005L2.75 0Zm-.864 8.174L15 8.25l5.2 3.9a.75.75 0 0 1-.7 1.307v4.793a1.25 1.25 0 0 1-1.122 1.244l-.128.006h-8a1.25 1.25 0 0 1-1.244-1.122L9 18.25v-4.793a.75.75 0 0 1-.777-.173L8.15 13.2a.75.75 0 0 1 .066-.977l.084-.073 5.2-3.9a1.25 1.25 0 0 1 1.386-.076Zm-.636 1.389-3.75 2.812V18h1.45v-2.75c0-.647.492-1.18 1.122-1.244L13.2 14h2c.647 0 1.18.492 1.244 1.122l.006.128V18H18v-5.625zm.7 5.937h-1.5V18h1.5zM5.75 12a.75.75 0 0 1 .102 1.493l-.102.007h-1a.75.75 0 0 1-.102-1.493L4.75 12Zm2-4a.75.75 0 0 1 .102 1.493L7.75 9.5h-3a.75.75 0 0 1-.102-1.493L4.75 8Zm6-4a.75.75 0 0 1 .102 1.493l-.102.007h-9a.75.75 0 0 1-.102-1.493L4.75 4Z" />
            <path
                stroke="#1f9970"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m18.165 23.42-3-3M23.436 15.275l-5 8"
            />
        </Icon>
    );
};
