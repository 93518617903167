import {Footer} from '@components/Footer';
import {SocialsMenu} from '@components/SocialsMenu';
import {Accordion} from '@components/Accordion';
import {ContactBox} from '@liveComponents/ContactBox/ContactBox';
import {Row} from '@components/Row';
import {Col} from '@components/Col';
import {Stack} from '@components/Stack';
import {VisuallyHidden} from '@components/VisuallyHidden';
import {Logo} from '@components/Logo';
import {Icon} from '@components/Icon';
import {Nav} from '@components/Nav';
import {FormattedMessage} from 'react-intl';
import {getLocalizedRoute, routes} from '@utils/routes';
import Link from 'next/link';
import {LanguageSwitcher} from './LanguageSwitcher';
import {useLocale} from '@utils/locale';
import {LocaleEnum} from 'src/types/general';
import {getCookiesUriForLocale, getTermsUriForLocale} from '@constants/Page';
import {Logos} from './Logos';
import {DateTime} from 'luxon';
import {ContactBoxComfort} from '@liveComponents/ContactBox/ContactBoxComfort';

type PageFooterProps = {
    contactBoxVariant?: 'default' | 'comfort';
    withChatBot?: boolean;
};

export const PageFooter = ({contactBoxVariant = 'default', withChatBot}: PageFooterProps) => {
    const locale = useLocale();
    const currentYear = DateTime.now().year;

    return (
        <Footer>
            <Row className="justify-content-between flex-xxl-nowrap">
                <Col lg="auto" md={8}>
                    <Footer.SideContent>
                        <Stack>
                            <Footer.Logo>
                                <Link href={getLocalizedRoute(routes.homepage, locale)}>
                                    <VisuallyHidden>Bezrealitky</VisuallyHidden>
                                    <Logo width={165} />
                                </Link>
                            </Footer.Logo>

                            <Footer.Claim>
                                <p>
                                    <FormattedMessage defaultMessage="Zajišťujeme prodej a pronájem nemovitostí bez provize a s kompletním servisem." />
                                </p>
                            </Footer.Claim>

                            <Footer.Socials>
                                <SocialsMenu>
                                    <SocialsMenu.Link variant="facebook">
                                        <a
                                            href={
                                                locale === LocaleEnum.sk
                                                    ? 'https://www.facebook.com/bezrealitky.sk'
                                                    : 'https://www.facebook.com/bezrealitky'
                                            }
                                        >
                                            <Icon.Facebook size="lg" />

                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Bezrealitky na Facebooku" />
                                            </VisuallyHidden>
                                        </a>
                                    </SocialsMenu.Link>

                                    <SocialsMenu.Link variant="instagram">
                                        <a
                                            href={
                                                locale === LocaleEnum.sk
                                                    ? 'https://www.instagram.com/bezrealitky.sk/'
                                                    : 'https://www.instagram.com/bezrealitky.cz/'
                                            }
                                        >
                                            <Icon.Instagram size="lg" />

                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Bezrealitky na Instagramu" />
                                            </VisuallyHidden>
                                        </a>
                                    </SocialsMenu.Link>
                                </SocialsMenu>
                            </Footer.Socials>
                        </Stack>
                    </Footer.SideContent>
                </Col>

                <Col
                    md={{
                        order: 'last',
                    }}
                    xxl={{
                        order: 0,
                        span: 'auto',
                    }}
                >
                    <Footer.Nav>
                        <Row>
                            <Col md xs="12">
                                <Accordion>
                                    <Footer.NavTitle>
                                        <Accordion.Header as="span">
                                            <FormattedMessage defaultMessage="Zájemci" />
                                        </Accordion.Header>
                                    </Footer.NavTitle>

                                    <Accordion.Body>
                                        <Nav as="ul" className="flex-column">
                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.list, locale, {list: ''})} passHref legacyBehavior>
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Nemovitosti" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            <Nav.Item as="li">
                                                <Link
                                                    href={getLocalizedRoute(routes.serviceCentreIntroCustomer, locale)}
                                                    passHref
                                                    legacyBehavior
                                                >
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Tipy pro zájemce" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.serviceCentrePremium, locale)} passHref legacyBehavior>
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Premium profil" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            {locale === LocaleEnum.cs && (
                                                <Nav.Item as="li">
                                                    <Link
                                                        href={getLocalizedRoute(routes.serviceCentreMortgage, locale, {reload: true})}
                                                        passHref
                                                        legacyBehavior
                                                    >
                                                        <Nav.Link>
                                                            <FormattedMessage defaultMessage="Hypotéka" />
                                                        </Nav.Link>
                                                    </Link>
                                                </Nav.Item>
                                            )}

                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.serviceCentreEnergy, locale)} passHref legacyBehavior>
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Převod energií" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            <Nav.Item as="li">
                                                <Link
                                                    href={getLocalizedRoute(routes.serviceCentreWatchdog, locale)}
                                                    passHref
                                                    legacyBehavior
                                                >
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Hlídací pes" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion>
                            </Col>

                            <Col md xs="12">
                                <Accordion>
                                    <Footer.NavTitle>
                                        <Accordion.Header as="span">
                                            <FormattedMessage defaultMessage="Majitelé" />
                                        </Accordion.Header>
                                    </Footer.NavTitle>

                                    <Accordion.Body>
                                        <Nav as="ul" className="flex-column">
                                            <Nav.Item as="li">
                                                <Link
                                                    href={getLocalizedRoute(routes.serviceCentreIntroOwner, locale)}
                                                    passHref
                                                    legacyBehavior
                                                >
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Nabídnout nemovitost" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.serviceCentreSale, locale)} passHref legacyBehavior>
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Služby" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>

                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.priceListRent, locale)} passHref legacyBehavior>
                                                    <Nav.Link>
                                                        <FormattedMessage defaultMessage="Ceník inzerce" />
                                                    </Nav.Link>
                                                </Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Accordion.Body>
                                </Accordion>
                            </Col>

                            <Col md xs="12">
                                <Footer.NavTitle>
                                    <FormattedMessage defaultMessage="Další informace" />
                                </Footer.NavTitle>

                                <Nav as="ul" className="flex-column">
                                    <Nav.Item as="li">
                                        <Link href={getLocalizedRoute(routes.aboutUs, locale)} passHref legacyBehavior>
                                            <Nav.Link>
                                                <FormattedMessage defaultMessage="O nás" />
                                            </Nav.Link>
                                        </Link>
                                    </Nav.Item>

                                    {LocaleEnum.en !== locale && (
                                        <Nav.Item as="li">
                                            <Nav.Link
                                                href={
                                                    locale === LocaleEnum.sk
                                                        ? 'https://www.bezrealitky.sk/blog/'
                                                        : getLocalizedRoute(routes.blog, locale)
                                                }
                                            >
                                                <FormattedMessage defaultMessage="Blog Bezrealitky" />
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>
                            </Col>
                        </Row>
                    </Footer.Nav>
                </Col>

                <Col lg="auto" md={8}>
                    <Footer.SideContent>
                        <div
                            style={{
                                maxWidth: '26rem',
                            }}
                        >
                            {contactBoxVariant === 'default' && <ContactBox withChatBot={withChatBot} />}

                            {contactBoxVariant === 'comfort' && <ContactBoxComfort withChatBot={withChatBot} />}
                        </div>
                    </Footer.SideContent>
                </Col>
            </Row>

            <Footer.Bottom>
                <Logos />

                <Footer.Copyright>
                    <Footer.CopyrightItem variant="year">© {currentYear} Bezrealitky</Footer.CopyrightItem>
                    <Footer.CopyrightItem variant="copy">
                        <FormattedMessage defaultMessage="Všechna práva vyhrazena" />
                    </Footer.CopyrightItem>

                    <Footer.CopyrightItem variant="link">
                        <Link
                            href={getLocalizedRoute(routes.page, locale, {
                                uri: getCookiesUriForLocale(locale),
                            })}
                            passHref
                        >
                            <FormattedMessage defaultMessage="Soukromí" />
                        </Link>
                    </Footer.CopyrightItem>

                    <Footer.CopyrightItem variant="link">
                        <Link
                            href={getLocalizedRoute(routes.page, locale, {
                                uri: getTermsUriForLocale(locale),
                            })}
                            passHref
                        >
                            <FormattedMessage defaultMessage="Podmínky" />
                        </Link>
                    </Footer.CopyrightItem>

                    <Footer.CopyrightItem>
                        <a href="https://www.id-sign.com/tvorba-webovych-stranek" target="_blank">
                            <FormattedMessage defaultMessage="Stránky vytvářeny v iD-SIGN" />
                        </a>
                    </Footer.CopyrightItem>

                    <Footer.CopyrightItem variant="lang-switcher">
                        <LanguageSwitcher />
                    </Footer.CopyrightItem>
                </Footer.Copyright>
            </Footer.Bottom>
        </Footer>
    );
};
