import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconUserCircle: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="user-circle">
            <path d="M12 2.25a9.75 9.75 0 1 1 0 19.5 9.75 9.75 0 0 1 0-19.5Zm0 1.5a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5Zm1.85 8.67c1.7 0 3.07 1.38 3.07 3.07v.46c0 .72-.58 1.3-1.3 1.3H8.39a1.3 1.3 0 0 1-1.3-1.3v-.46c0-1.7 1.37-3.07 3.07-3.07h3.69Zm0 1.5h-3.69c-.87 0-1.57.7-1.57 1.57v.26h6.83v-.25c0-.83-.63-1.5-1.43-1.58h-.15ZM12 5.75a3 3 0 1 1 .01 6 3 3 0 0 1-.01-6Zm0 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
        </Icon>
    );
};
