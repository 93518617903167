import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDocument: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="document">
			<path d="M15.172 2.25c.663 0 1.301.24 1.8.67l.144.136 2.828 2.828c.47.468.751 1.09.799 1.746l.007.198V19a2.75 2.75 0 01-2.582 2.745L18 21.75H6a2.75 2.75 0 01-2.745-2.582L3.25 19V5a2.75 2.75 0 012.582-2.745L6 2.25h9.172zm-.922 1.5H6a1.25 1.25 0 00-1.244 1.122L4.75 5v14c0 .648.491 1.18 1.122 1.244L6 20.25h12a1.25 1.25 0 001.244-1.122L19.25 19V8.75H16a1.75 1.75 0 01-1.744-1.607L14.25 7V3.75zM16 16.25a.75.75 0 01.102 1.493L16 17.75H8a.75.75 0 01-.102-1.493L8 16.25h8zm0-4a.75.75 0 01.102 1.493L16 13.75H8a.75.75 0 01-.102-1.493L8 12.25h8zm-5-4a.75.75 0 01.102 1.493L11 9.75H8a.75.75 0 01-.102-1.493L8 8.25h3zm4.751-4.357L15.75 7a.25.25 0 00.193.243L16 7.25h3.108a1.251 1.251 0 00-.139-.211l-.085-.095-2.828-2.828a1.25 1.25 0 00-.196-.16l-.109-.063z" />
		</Icon>
	);
};
