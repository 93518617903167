import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronDown: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-down">
			<path d="M7.47 9.47a.75.75 0 01.976-.073l.084.073L12 12.939l3.47-3.47a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084-4 4a.75.75 0 01-.976.073l-.084-.073-4-4a.75.75 0 010-1.06z" />
		</Icon>
	);
};
