import classNames from 'classnames';
import React, { FC } from 'react';
import { ToggleButton as ToggleButtonComponent, ToggleButtonProps as ToggleButtonComponentProps } from 'react-bootstrap';
import styles from './ToggleButton.module.scss';

export type ToggleButtonProps = ToggleButtonComponentProps & {
	className?: string;
};

export const ToggleButton: FC<React.PropsWithChildren<ToggleButtonProps>> = ({ className, children, ...props }) => {
	return (
		<ToggleButtonComponent
			{...props}
			variant="outline-secondary"
			className={classNames(styles.toggleButton, 'd-flex align-items-center justify-content-center w-100 h-100', className)}
		>
			{children}
		</ToggleButtonComponent>
	);
};
