import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconElevator: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="elevator">
			<path d="M21.5 2.25a.75.75 0 01.251 1.457L21.75 20.25H22a.75.75 0 01.102 1.493L22 21.75H2a.75.75 0 01-.102-1.493L2 20.25h.25V3.707a.75.75 0 01.148-1.45L2.5 2.25h19zm-10.25 1.5h-7.5v16.5h7.5V3.75zm9 0h-7.5v16.5h7.5V3.75zM7.543 9.25a.75.75 0 01.743.648l.007.102-.001 2.189.178-.176a.75.75 0 01.976-.073l.084.073a.75.75 0 01.073.976l-.073.084-1.457 1.457a.753.753 0 01-.119.097l-.09.05-.103.04a.747.747 0 01-.218.033l-.057-.002-.09-.013-.038-.008-.106-.035-.069-.034-.086-.055a.785.785 0 01-.084-.073L5.47 12.987a.75.75 0 01.976-1.133l.084.073.262.261L6.793 10a.75.75 0 01.75-.75zm8.92 0l.096.007.083.016.106.035.069.034.098.064.072.064 1.543 1.543a.75.75 0 01-.976 1.133l-.084-.073-.263-.262V14a.75.75 0 01-1.493.102L15.707 14v-2.19l-.177.177a.75.75 0 01-.976.073l-.084-.073a.75.75 0 01-.073-.976l.073-.084 1.457-1.457a.753.753 0 01.119-.097l.09-.05.103-.04a.74.74 0 01.143-.03l.08-.003z" />
		</Icon>
	);
};
