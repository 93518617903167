import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconSelectAll: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="select-all">
            <path d="m17.98 13.7 5.31 5.32c.98.98.29 2.66-1.1 2.66h-2.58l-1.64 1.62c-.95.94-2.54.32-2.64-.97V14.8a1.56 1.56 0 0 1 2.65-1.1Zm-1.16 1.1v7.4c0 .04.06.07.1.03l1.85-1.83a.75.75 0 0 1 .53-.22h2.9c.04 0 .07-.06.03-.1l-5.31-5.31a.06.06 0 0 0-.1.04Zm.66-13.55c1.39 0 2.52 1.13 2.52 2.52v6.86a.75.75 0 1 1-1.5 0V3.76c0-.56-.46-1.02-1.02-1.02H3.78c-.57 0-1.03.46-1.03 1.02v13.7c0 .57.46 1.03 1.02 1.03H12a.75.75 0 1 1 0 1.5H3.77a2.52 2.52 0 0 1-2.52-2.52V3.78c0-1.4 1.13-2.53 2.52-2.53h13.7ZM6.92 14.42A1 1 0 0 1 7 15.74l-.08.1a1 1 0 1 1 0-1.42Zm5.08-.04a.75.75 0 1 1 0 1.5h-1.65a.75.75 0 1 1 0-1.5H12ZM6.83 9.9a1 1 0 0 1 .09 1.33l-.09.1a1 1 0 1 1 0-1.43Zm8.69-.04a.75.75 0 1 1 0 1.5h-5.26a.75.75 0 1 1 0-1.5h5.26ZM6.83 5.42a1 1 0 0 1 .09 1.32l-.09.1a1 1 0 1 1 0-1.42Zm8.69-.04a.75.75 0 1 1 0 1.5h-5.26a.75.75 0 0 1 0-1.5h5.26Z" />
        </Icon>
    );
};
