import classNames from 'classnames';
import React, {FC} from 'react';
import {FormCheck as FormCheckComponent, FormCheckProps as FormCheckComponentProps} from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import styles from './Check.module.scss';

export type FormCheckProps = Omit<FormCheckComponentProps, 'size'> & {
    controlOnRight?: boolean;
    asToggleButton?: boolean;
    size?: 'xs' | 'sm';
    variant?: 'person' | 'highlighted';
    standalone?: boolean;
};

export const FormCheck: FC<React.PropsWithChildren<FormCheckProps>> = ({
    controlOnRight,
    asToggleButton,
    size,
    variant,
    standalone,
    className,
    ...props
}) => {
    return (
        <FormCheckComponent
            {...props}
            className={classNames(className, {
                [styles['form-check--control-on-right']]: controlOnRight,
                [styles[`form-check--${variant}`]]: variant,
                [`form-check-${size}`]: size,
                ['btn-check']: asToggleButton,
                [styles['form-check--standalone']]: standalone,
            })}
        />
    );
};

export default Object.assign(FormCheck as FormCheckProps, {
    Input: FormCheckInput,
    Label: FormCheckLabel,
});
