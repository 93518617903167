import {getLocalizedRoute, routes} from '@utils/routes';
import {Nav} from '@components/Nav';
import {FormattedMessage} from 'react-intl';
import {useLocale} from '@utils/locale';
import Link from 'next/link';
import {LocaleEnum} from 'src/types/general';

export type RentMenuprops = {
    onHide?: (e?: React.MouseEvent<HTMLElement>) => void;
};

export const RentMenu = ({onHide}: RentMenuprops) => {
    const locale = useLocale();

    const onClick = onHide ? {onClick: onHide} : {};

    return (
        <Nav as="ul" className="flex-column">
            {locale !== LocaleEnum.sk && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreGuaranteeRent, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Pronájem bez starostí" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            <Nav.Item as="li">
                <Link href={getLocalizedRoute(routes.priceListRent, locale)} passHref legacyBehavior>
                    <Nav.Link {...onClick}>
                        <FormattedMessage defaultMessage="Inzerce nemovitosti" />
                    </Nav.Link>
                </Link>
            </Nav.Item>
            {locale !== LocaleEnum.en && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreContractRent, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Nájemní smlouva online" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            <Nav.Item as="li">
                <Link href={getLocalizedRoute(routes.serviceCentreCreditcheck, locale)} passHref legacyBehavior>
                    <Nav.Link {...onClick}>
                        <FormattedMessage defaultMessage="Ověření bezdlužnosti nájemce" />
                    </Nav.Link>
                </Link>
            </Nav.Item>
            {locale === LocaleEnum.cs && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreInsurance, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Pojištění odpovědnosti" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            {locale !== LocaleEnum.sk && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.propertyAssessment, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Odhad ceny nájmu" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            {![LocaleEnum.sk, LocaleEnum.en].includes(locale as LocaleEnum) && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreEnergy, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Stěhování energie" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
        </Nav>
    );
};
