import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconWallet: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="wallet">
            <rect width="24" height="24" fill="none" />
            <path
                d="M19.5,11H21a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1H19.5A2.5,2.5,0,0,1,17,13.5v0A2.5,2.5,0,0,1,19.5,11Z"
                fill="none"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <line
                x1="18"
                y1="3"
                x2="5"
                y2="3"
                fill="none"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M21,16v2.5A2.5,2.5,0,0,1,18.5,21H5.5A2.5,2.5,0,0,1,3,18.5V8.5A2.5,2.5,0,0,1,5.5,6h13A2.5,2.5,0,0,1,21,8.5V11"
                fill="none"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </Icon>
    );
};
