import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconUserCheck: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="user-check">
			<path d="M16.75 12.75a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-6.75 0a.75.75 0 01.102 1.493L10 15.75H7a3.25 3.25 0 00-3.245 3.066L3.75 19v1a.75.75 0 01-1.493.102L2.25 20v-1a4.75 4.75 0 014.533-4.745L7 14.25h3zm8.71 2.128a.75.75 0 01.072.977l-.073.084-1.785 1.784a.75.75 0 01-.975.073l-.084-.072-1.074-1.07a.75.75 0 01.975-1.135l.084.072.543.541 1.256-1.254a.75.75 0 011.06 0zM11 2.25a4.75 4.75 0 110 9.5 4.75 4.75 0 010-9.5zm0 1.5a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5z" />
		</Icon>
	);
};
