import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconWarningTriangle: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="warning-triangle">
            <path d="M12 14.2c-.5 0-.9-.4-.9-.9V8.4c0-.5.4-.9.9-.9s.9.4.9.9v4.8c-.1.6-.4 1-.9 1z" />
            <circle cx={12} cy={17.2} r={1.2} />
            <path d="M19.3 22H4.8c-.5 0-1-.2-1.7-.5-1.7-.9-2.3-3.1-1.4-4.9L9 3.8c.3-.5.8-1.1 1.4-1.4.4-.2.9-.4 1.6-.4.7 0 1.3.2 1.8.5.5.2 1 .8 1.3 1.4l7.3 12.7c.5.9.6 1.8.3 2.7-.2 1-.8 1.7-1.7 2.2-.7.3-1.3.5-1.7.5zM12 3.5c-.3 0-.6.1-.9.3-.3.2-.6.5-.8.8L3 17.3c-.6 1-.2 2.3.8 2.9.6.3.9.3 1 .3h14.5c.1 0 .4 0 1-.3.5-.3.8-.7.9-1.2.1-.5.1-1.1-.2-1.6L13.8 4.6c-.2-.3-.5-.6-.7-.8-.4-.2-.8-.3-1.1-.3z" />
        </Icon>
    );
};
