import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconFacebook: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="facebook">
			<path d="M7.496 21.75a5.246 5.246 0 01-5.242-5.03l-.004-.216V7.5a5.25 5.25 0 015.034-5.246L7.5 2.25h9.004a5.246 5.246 0 015.242 5.03l.004.216v9.009a5.246 5.246 0 01-5.03 5.24l-.216.005H7.496zm9.008-18H7.5A3.75 3.75 0 003.755 7.3l-.005.2v9.004a3.746 3.746 0 003.547 3.74l.199.006h4.654v-6.6H11.1a.75.75 0 01-.102-1.493l.102-.007h1.05v-1.095a3.406 3.406 0 013.218-3.4l.187-.005h.945a.75.75 0 01.102 1.493l-.102.007h-.945a1.906 1.906 0 00-1.9 1.756l-.005.149v1.095h2.85a.75.75 0 01.102 1.493l-.102.007h-2.85v6.6h2.854a3.746 3.746 0 003.74-3.546l.006-.199V7.496a3.746 3.746 0 00-3.547-3.74l-.199-.006z" />
		</Icon>
	);
};
