import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEye: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="eye">
			<path d="M12 4.25c3.851 0 7.373 2.021 9.087 5.234l.14.274c.345.704.523 1.468.523 2.242 0 .677-.136 1.347-.41 1.995l-.112.247c-1.644 3.36-5.256 5.508-9.228 5.508-3.851 0-7.373-2.021-9.087-5.234l-.14-.274A5.096 5.096 0 012.25 12c0-.677.136-1.347.41-1.995l.112-.247C4.416 6.398 8.028 4.25 12 4.25zm0 1.5c-3.41 0-6.494 1.834-7.871 4.649l-.095.208A3.583 3.583 0 003.75 12c0 .544.126 1.084.364 1.57l.128.252C5.684 16.524 8.692 18.25 12 18.25c3.41 0 6.494-1.834 7.871-4.649l.095-.208c.188-.445.284-.917.284-1.393 0-.544-.126-1.084-.364-1.57l-.128-.252C18.316 7.476 15.308 5.75 12 5.75zm2.653 3.6a3.754 3.754 0 010 5.305 3.754 3.754 0 01-5.306 0 3.754 3.754 0 010-5.305 3.75 3.75 0 015.306 0zm-4.245 1.06a2.254 2.254 0 00-.001 3.184c.88.879 2.306.879 3.184.001a2.254 2.254 0 00.12-3.056l-.121-.13-.128-.119a2.25 2.25 0 00-3.054.12z" />
		</Icon>
	);
};
