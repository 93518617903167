import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMobileCheck: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="mobile-check">
			<path d="M15.001 2.246a2.751 2.751 0 012.746 2.584l.005.167V11.4a5.254 5.254 0 01-1.034 10.35l-.216.004-.217-.005a5.22 5.22 0 01-2.864-.994l-6.423-.002a2.751 2.751 0 01-2.746-2.583l-.005-.168V4.998A2.751 2.751 0 016.83 2.251l.168-.005H15zm1.512 10.504a3.819 3.819 0 00-3.763 3.752 3.752 3.752 0 003.553 3.747l.198.004.2-.004A3.752 3.752 0 0020.25 16.7l.005-.2a3.75 3.75 0 00-3.74-3.751zM7.748 3.746h-.75c-.648 0-1.18.492-1.244 1.123l-.007.128v13.006c0 .647.492 1.18 1.123 1.244l.128.006 5.032.001a5.25 5.25 0 01-.78-2.763 5.323 5.323 0 015.002-5.232V4.997c0-.648-.492-1.18-1.123-1.244l-.128-.007h-.752v.99c.054.666-.402 1.258-1.105 1.391l-.144.02-4.075-.003c-.666-.067-1.166-.621-1.18-1.226l.004-.121v-1.05zm10.785 11.475a.75.75 0 01.073.977l-.073.084-1.875 1.876a.75.75 0 01-.976.072l-.084-.072-1.127-1.126a.75.75 0 01.976-1.133l.084.072.596.596 1.345-1.346a.75.75 0 011.06 0zM12.75 3.746H9.247l-.001.901 3.504.003v-.904z" />
		</Icon>
	);
};
