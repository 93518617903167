import {useEffect, useReducer, useState} from 'react';
import {Header} from '@components/Header';
import {Nav} from '@components/Nav';
import {VisuallyHidden} from '@components/VisuallyHidden';
import {Logo} from '@components/Logo';
import {ButtonGroup} from '@components/ButtonGroup';
import {Button} from '@components/Button';
import {Typography} from '@components/Typography';
import {Icon} from '@components/Icon';
import {FormattedMessage, useIntl} from 'react-intl';
import Link from 'next/link';
import NextImage from 'next/image';
import {getLocalizedRoute, isRoute, routes} from '@utils/routes';
import {Avatar} from '@components/Avatar';
import {SidebarLogin} from './SidebarLogin';
import {useUserNotificationCountsLazyQuery} from '@gql/query/user/counts.gql';
import {ButtonBadge} from '@components/ButtonBadge';
import {Badge} from '@components/Badge';
// import {InteractionLoadable} from '@liveComponents/Loadable';
import dynamic from 'next/dynamic';
import {useLocale} from '@utils/locale';
import {useRouter} from 'next/router';
import {SidebarMenu} from './SidebarMenu';
import {fullName} from '@pageComponents/MyBezrealitky/utils';
import {RentMenu} from './RentMenu';
import {SellMenu} from './SellMenu';
import {LocaleEnum} from 'src/types/general';
import {useUser} from '@utils/user';
import {InfoTooltip} from '@components/InfoTooltip';
import {EstateType, OfferType} from '.cache/__types__';
import {useMaintenance} from '@utils/maintenance';
import {MaintenanceWarning} from '@pageComponents/Layout/MaintenanceWarning';
import {Collapse} from 'react-bootstrap';
import {HeaderBanner} from '@pageComponents/Layout/HeaderBanner';
import {useInfoBannerQuery} from '@gql/query/misc/infoBanner.gql';
import {getGraphQlLocale} from 'src/types/graphql/general';
import {initialState, onClickToggle, onReset, reducer} from './reducer';
import {Toggles} from './types';
import {LanguageSwitcher} from '@pageComponents/Layout/LanguageSwitcher';
import {getEstatePath} from './utils';
import {loadEhubVisitId, removeEhubVisitId} from '@utils/ehub';
import {useSaveEhubMutation} from '@gql/mutation/user/saveEhub.gql';

const SidebarMenuUser = dynamic(() => import('./SidebarMenuUser'), {ssr: false});

type PageHeaderProps = {
    withHeaderBorder?: boolean;
    headerVariant?: 'default' | 'insert';
};

const onHide = (e?: React.MouseEvent<HTMLElement>) => {
    e?.currentTarget.blur();
};

export const PageHeader = ({withHeaderBorder, headerVariant = 'default'}: PageHeaderProps) => {
    const locale = useLocale();
    const router = useRouter();
    const intl = useIntl();
    const {user} = useUser();
    const [state, dispatch] = useReducer(reducer, initialState);

    const [adBlockMessage, setAdBlockMessage] = useState<string>();
    const [saveEhub] = useSaveEhubMutation();

    useEffect(() => {
        if (
            !document.getElementById('GHEaqNgbZwvB') &&
            !!user &&
            (router.pathname.includes('my-bezrealitky/property') || router.pathname.includes('insert'))
        ) {
            setAdBlockMessage(
                intl.formatMessage({defaultMessage: 'Váš prohlížeč používá AdBlocker. Prosím, vypněte ho pro správnou funkčnost stránky.'}),
            );
        } else {
            setAdBlockMessage(undefined);
        }
    }, [intl, user, router.pathname]);

    const {inMaintenance} = useMaintenance();

    const [getCounts, countData] = useUserNotificationCountsLazyQuery({
        fetchPolicy: 'network-only',
        pollInterval: 300000,
    });

    const message = useInfoBannerQuery({
        variables: {
            locale: getGraphQlLocale(locale),
        },
        fetchPolicy: 'network-only',
        pollInterval: 300000,
    }).data?.infoBanner;

    useEffect(() => {
        if (user) {
            void getCounts({
                variables: {
                    user: user.id,
                },
            });
        }
    }, [user, getCounts]);

    const ehub = loadEhubVisitId();
    useEffect(() => {
        if (user && ehub) {
            void saveEhub({variables: {ehub}}).then(() => {
                removeEhubVisitId();
            });
        }
    }, [user, ehub, saveEhub]);

    const userMenuButton = (
        <Header.Button
            onClick={onClickToggle(dispatch)(Toggles.UserBar)}
            title={intl.formatMessage({defaultMessage: 'Přihlaste se nebo zaregistrujte'})}
        >
            {!!user ? (
                user.photo ? (
                    <>
                        <VisuallyHidden>
                            <FormattedMessage defaultMessage="Uživatelský profil" />
                        </VisuallyHidden>

                        {state.showUserBar ? (
                            <Icon.Close position="middle" />
                        ) : (
                            <Avatar size="xs">
                                <NextImage
                                    src={user.photo}
                                    width={28}
                                    height={28}
                                    alt={fullName(user.firstname, user.lastname)}
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </Avatar>
                        )}
                    </>
                ) : (
                    <Avatar iconName={state.showUserBar ? 'Close' : 'Profile'} size="xxs" />
                )
            ) : (
                <Icon name={state.showUserBar ? 'Close' : 'Profile'} position="middle" />
            )}
        </Header.Button>
    );

    const salePath = getEstatePath(locale, OfferType.Prodej, EstateType.Byt);
    const rentPath = getEstatePath(locale, OfferType.Pronajem, EstateType.Byt);

    return (
        <>
            <Collapse in={inMaintenance}>
                <div>
                    <MaintenanceWarning />
                </div>
            </Collapse>

            {!!message && <HeaderBanner message={message} />}
            {!!adBlockMessage && <HeaderBanner message={adBlockMessage} />}
            <Header withBorder={withHeaderBorder} background={headerVariant !== 'insert' ? 'white' : 'grey-ultra-light'}>
                {headerVariant !== 'insert' && (
                    <Header.Toolbar placement="left" className="d-xl-none">
                        <ButtonGroup>
                            <Header.Button onClick={onClickToggle(dispatch)(Toggles.MenuBar)}>
                                <VisuallyHidden>
                                    <FormattedMessage defaultMessage="Hlavní menu" />
                                </VisuallyHidden>
                                <Icon name={state.showMenuBar ? 'Close' : 'Menu'} position="middle" />
                            </Header.Button>
                        </ButtonGroup>
                    </Header.Toolbar>
                )}

                <Header.Logo>
                    <Link href={getLocalizedRoute(routes.homepage, locale)} onClick={onReset(dispatch)}>
                        <VisuallyHidden>Bezrealitky</VisuallyHidden>
                        <Logo />
                    </Link>
                </Header.Logo>

                {headerVariant !== 'insert' && (
                    <Header.Nav>
                        <Nav as="ul">
                            <Nav.Item as="li">
                                <Link href={getLocalizedRoute(routes.howToRent, locale)} passHref legacyBehavior>
                                    <Nav.Link>
                                        <Typography fontWeight="bold" color="blue">
                                            <FormattedMessage defaultMessage="Jak pronajmout" />
                                        </Typography>
                                    </Nav.Link>
                                </Link>
                            </Nav.Item>

                            <Nav.Item as="li">
                                <Link href={getLocalizedRoute(routes.howToSell, locale)} passHref legacyBehavior>
                                    <Nav.Link>
                                        <Typography fontWeight="bold" color="blue">
                                            <FormattedMessage defaultMessage="Jak prodat" />
                                        </Typography>
                                    </Nav.Link>
                                </Link>
                            </Nav.Item>

                            <Nav.Item as="li">
                                {/* <Link href={getLocalizedRoute(routes.search, locale)} passHref legacyBehavior> */}
                                <Nav.Link
                                // active={isRoute(router.asPath, routes.list, locale) || isRoute(router.asPath, routes.search, locale)}
                                // onClick={onHide}
                                >
                                    <FormattedMessage defaultMessage="Nemovitosti" />
                                </Nav.Link>
                                <Header.NavDropdown>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <Link href={getLocalizedRoute(routes.list, locale, {list: rentPath})} passHref legacyBehavior>
                                                <Nav.Link onClick={onHide}>
                                                    <FormattedMessage defaultMessage="Pronájem" />
                                                </Nav.Link>
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Link href={getLocalizedRoute(routes.list, locale, {list: salePath})} passHref legacyBehavior>
                                                <Nav.Link onClick={onHide}>
                                                    <FormattedMessage defaultMessage="Prodej" />
                                                </Nav.Link>
                                            </Link>
                                        </Nav.Item>
                                    </Nav>
                                </Header.NavDropdown>
                            </Nav.Item>

                            <Nav.Item as="li">
                                <Link href={getLocalizedRoute(routes.serviceCentrePremium, locale)} passHref legacyBehavior>
                                    <Nav.Link active={isRoute(router.asPath, routes.serviceCentrePremium, locale)}>
                                        <FormattedMessage defaultMessage="Premium profil" />
                                    </Nav.Link>
                                </Link>
                            </Nav.Item>

                            <Nav.Item as="li">
                                <Link href={getLocalizedRoute(routes.serviceCentreSale, locale)} passHref legacyBehavior>
                                    <Nav.Link active={isRoute(router.asPath, routes.serviceCentreSale, locale)} onClick={onHide}>
                                        <FormattedMessage defaultMessage="Služby" />
                                    </Nav.Link>
                                </Link>

                                {locale !== LocaleEnum.sk && (
                                    <Header.NavDropdown>
                                        <Nav as="ul">
                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.serviceCentreRent, locale)} passHref legacyBehavior>
                                                    <Nav.Link
                                                        active={isRoute(router.asPath, routes.serviceCentreRent, locale)}
                                                        onClick={onHide}
                                                    >
                                                        <FormattedMessage defaultMessage="Pronájem" />
                                                    </Nav.Link>
                                                </Link>

                                                <Header.NavDropdown>
                                                    <RentMenu onHide={onHide} />
                                                </Header.NavDropdown>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link href={getLocalizedRoute(routes.serviceCentreSale, locale)} passHref legacyBehavior>
                                                    <Nav.Link
                                                        active={isRoute(router.asPath, routes.serviceCentreSale, locale)}
                                                        onClick={onHide}
                                                    >
                                                        <FormattedMessage defaultMessage="Prodej a nákup" />
                                                    </Nav.Link>
                                                </Link>

                                                <Header.NavDropdown>
                                                    <SellMenu onHide={onHide} />
                                                </Header.NavDropdown>
                                            </Nav.Item>
                                        </Nav>
                                    </Header.NavDropdown>
                                )}
                            </Nav.Item>

                            <Nav.Item as="li">
                                <Link href={getLocalizedRoute(routes.priceListRent, locale)} passHref legacyBehavior>
                                    <Nav.Link active={isRoute(router.asPath, routes.priceListRent, locale)}>
                                        <FormattedMessage defaultMessage="Ceník inzerce" />
                                    </Nav.Link>
                                </Link>
                            </Nav.Item>

                            <Nav.Item as="li">
                                {locale == LocaleEnum.cs ? (
                                    <Link href={getLocalizedRoute(routes.blog, locale)} passHref legacyBehavior>
                                        <Nav.Link active={isRoute(router.asPath, routes.blog, locale)}>
                                            <FormattedMessage defaultMessage="Blog" />
                                        </Nav.Link>
                                    </Link>
                                ) : (
                                    locale != LocaleEnum.en && (
                                        <Nav.Link
                                            active={isRoute(router.asPath, routes.blog, locale)}
                                            href={getLocalizedRoute(routes.blog, locale)}
                                        >
                                            <FormattedMessage defaultMessage="Blog" />
                                        </Nav.Link>
                                    )
                                )}
                            </Nav.Item>
                        </Nav>
                    </Header.Nav>
                )}
                <Header.Toolbar>
                    {headerVariant !== 'insert' && (
                        <>
                            <Header.Cta>
                                <Link href={getLocalizedRoute(routes.insert, locale)} passHref legacyBehavior>
                                    <Button size="sm" variant="secondary">
                                        <Typography as="span" variant="cta">
                                            <FormattedMessage defaultMessage="Vložit inzerát" />
                                        </Typography>

                                        <Icon.Key position="end" />
                                    </Button>
                                </Link>
                            </Header.Cta>

                            <ButtonGroup>
                                <InfoTooltip
                                    content={intl.formatMessage({
                                        defaultMessage: 'Hlídací pes za vás pohlídá nové nabídky a pošle vám na ně upozornění.',
                                    })}
                                    iconName={false}
                                >
                                    <Link
                                        href={
                                            user
                                                ? getLocalizedRoute(routes.watchdogList, locale)
                                                : getLocalizedRoute(routes.serviceCentreWatchdog, locale)
                                        }
                                        onClick={onReset(dispatch)}
                                    >
                                        <Header.Button>
                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Hlídací pes" />
                                            </VisuallyHidden>
                                            {user?.id && !!countData.data?.user?.menuCounts?.watchdogs && (
                                                <ButtonBadge>
                                                    <Badge>
                                                        {countData.data.user.menuCounts.watchdogs}
                                                        <VisuallyHidden>
                                                            <FormattedMessage defaultMessage="Upozornění" />
                                                        </VisuallyHidden>
                                                    </Badge>
                                                </ButtonBadge>
                                            )}
                                            <Icon.Watchdog position="middle" />
                                        </Header.Button>
                                    </Link>
                                </InfoTooltip>
                            </ButtonGroup>

                            <ButtonGroup>
                                <InfoTooltip
                                    content={intl.formatMessage({
                                        defaultMessage: 'V komunikační platformě najdete odeslané a přijaté zprávy.',
                                    })}
                                    iconName={false}
                                >
                                    <Link href={getLocalizedRoute(routes.conversation, locale, {params: ''})} onClick={onReset(dispatch)}>
                                        <Header.Button>
                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Zprávy" />
                                            </VisuallyHidden>

                                            {user?.id && !!countData.data?.user?.menuCounts?.conversations && (
                                                <ButtonBadge>
                                                    <Badge>
                                                        {countData.data.user.menuCounts.conversations}

                                                        <VisuallyHidden>
                                                            <FormattedMessage defaultMessage="nepřečtených" />
                                                        </VisuallyHidden>
                                                    </Badge>
                                                </ButtonBadge>
                                            )}

                                            <Icon.Envelope position="middle" />
                                        </Header.Button>
                                    </Link>
                                </InfoTooltip>
                            </ButtonGroup>

                            <ButtonGroup>
                                <InfoTooltip
                                    content={intl.formatMessage({
                                        defaultMessage: 'V sekci “Upozornění” najdete zprávy od Bezrealitky.',
                                    })}
                                    iconName={false}
                                >
                                    <Link href={getLocalizedRoute(routes.notifications, locale)} onClick={onReset(dispatch)}>
                                        <Header.Button>
                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Upozornění" />
                                            </VisuallyHidden>

                                            {user?.id && !!countData.data?.user?.menuCounts?.notifications && (
                                                <ButtonBadge>
                                                    <Badge>
                                                        {countData.data.user.menuCounts.notifications}

                                                        <VisuallyHidden>
                                                            <FormattedMessage defaultMessage="nepřečtených" />
                                                        </VisuallyHidden>
                                                    </Badge>
                                                </ButtonBadge>
                                            )}

                                            <Icon.Bell position="middle" />
                                        </Header.Button>
                                    </Link>
                                </InfoTooltip>
                            </ButtonGroup>

                            <ButtonGroup>{userMenuButton}</ButtonGroup>

                            <ButtonGroup className={'vertically-separate d-none d-md-inline-flex'}>
                                <LanguageSwitcher variant="button" header />
                            </ButtonGroup>
                        </>
                    )}

                    {headerVariant === 'insert' && (
                        <ButtonGroup>
                            <Link href={getLocalizedRoute(routes.homepage, locale)} passHref legacyBehavior>
                                <Button size="sm" variant="outline-dark">
                                    <Typography as="span" variant="cta" textTransform={'capitalize'} fontWeight={'normal'}>
                                        <FormattedMessage defaultMessage="Odejít" />
                                    </Typography>
                                </Button>
                            </Link>
                        </ButtonGroup>
                    )}
                    {/*}
                <ButtonGroup className="d-none d-md-inline-flex">
                    {userMenuButton}

                    <Header.Button onClick={state.showMenuBar ? closeMenuSidebar : openMenuSidebar}>
                        <VisuallyHidden>
                            <FormattedMessage defaultMessage="Hlavní menu" />
                        </VisuallyHidden>
                        <Icon name={state.showMenuBar ? 'Close' : 'Menu'} position="middle" />
                    </Header.Button>
                </ButtonGroup>
                {*/}
                </Header.Toolbar>
            </Header>

            {!!user ? (
                <SidebarMenuUser
                    show={state.showUserBar}
                    onHide={onReset(dispatch)}
                    unreadMessageCount={countData.data?.user?.menuCounts?.conversations}
                    unreadNotificationsCount={countData.data?.user?.menuCounts?.notifications}
                />
            ) : (
                <SidebarLogin show={state.showUserBar} onHide={onReset(dispatch)} />
            )}

            <SidebarMenu show={state.showMenuBar} onHide={onReset(dispatch)} />
        </>
    );
};
