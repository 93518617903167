import {Form as FormComponent} from 'react-bootstrap';
import FormDefaultProps from 'react-bootstrap/Form';
import {FormCheck, FormCheckProps} from './Check';
import {FormControl, FormControlProps} from './Control';
import {FormDatepickerProps, FormDatepicker} from './Datepicker';
import {FormLimitedTextarea, FormLimitedTextareaProps} from './LimitedTextarea';
import {FormLimitedTextareaGPT, FormLimitedTextareaGPTProps} from './LimitedTextareaGPT';
import {FormMultiSelect, MultiSelectProps} from './MultiSelect';
import FormRangeSlider, {FormRangeSliderProps} from './RangeSlider';
import {FormSelect, FormSelectProps} from './Select';
import {FormTag, FormTagProps} from './Tag';
import {FormRangeDatepicker, FormRangeDatepickerProps} from '@components/Form/Datepicker/RangeDatepicker';

export type FormProps = {
    Control: FormControlProps;
    Check: FormCheckProps;
    Datepicker: FormDatepickerProps;
    LimitedTextarea: FormLimitedTextareaProps;
    LimitedTextareaGPT: FormLimitedTextareaGPTProps;
    RangeDatepicker: FormRangeDatepickerProps;
    RangeSlider: FormRangeSliderProps;
    Select: FormSelectProps;
    Tag: FormTagProps;
    MultiSelect: MultiSelectProps;
};

export default Object.assign(FormComponent as typeof FormDefaultProps & FormProps, {
    Control: FormControl,
    Check: FormCheck,
    Datepicker: FormDatepicker,
    LimitedTextarea: FormLimitedTextarea,
    LimitedTextareaGPT: FormLimitedTextareaGPT,
    RangeDatepicker: FormRangeDatepicker,
    RangeSlider: FormRangeSlider,
    Select: FormSelect,
    Tag: FormTag,
    MultiSelect: FormMultiSelect,
});
