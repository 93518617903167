import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconNursery: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="nursery">
			<path d="M14.281 1.776a.75.75 0 010 1.5 1.53 1.53 0 00-1.358.824 9.083 9.083 0 017.576 5.852l.1.285.02.063.017.005a2.962 2.962 0 012.028 2.137l.04.194.025.178a2.961 2.961 0 01-1.787 3.053l-.162.063-.161.051-.03.097a9.08 9.08 0 01-8.03 6.129l-.282.013-.277.004a9.08 9.08 0 01-8.504-5.91l-.117-.335-.074-.021a2.961 2.961 0 01-1.968-2.118l-.04-.19-.026-.183a2.961 2.961 0 011.943-3.113l.167-.055.047-.144A9.073 9.073 0 019.33 4.458l.288-.083.259-.067c.4-.096.801-.165 1.2-.206a1.528 1.528 0 00-1.358-.826.75.75 0 010-1.5c.91 0 1.726.401 2.282 1.036a3.018 3.018 0 012.28-1.036zm-2.325 3.778a7.589 7.589 0 00-7.098 5.056l-.087.261-.082.283a.75.75 0 01-.637.535 1.46 1.46 0 00-1.29 1.613 1.46 1.46 0 001.023 1.237l.126.03.17.026a.75.75 0 01.612.545A7.579 7.579 0 0012 20.724a7.577 7.577 0 007.153-5.085l.083-.254.075-.257a.75.75 0 01.637-.536 1.46 1.46 0 00.134-2.881l-.234-.04a.75.75 0 01-.54-.53 7.576 7.576 0 00-7.274-5.587L12 5.557l-.044-.003zm2.831 10.1a.75.75 0 01-.072 1.058 4.13 4.13 0 01-5.43 0 .75.75 0 01.986-1.13 2.63 2.63 0 003.458 0 .75.75 0 011.058.072zm1.559-4.255a.75.75 0 01.138 1.052c-.418.544-1.06.87-1.783.884a2.279 2.279 0 01-1.738-.881.75.75 0 011.111-1.002l.075.082a.768.768 0 00.557.301.762.762 0 00.512-.212l.076-.086a.75.75 0 011.052-.138zm-5.445 0a.75.75 0 01.138 1.052 2.278 2.278 0 01-1.781.884 2.28 2.28 0 01-1.74-.881.75.75 0 011.111-1.003l.074.083a.769.769 0 00.558.301.762.762 0 00.512-.212l.076-.086a.75.75 0 011.052-.138z" />
		</Icon>
	);
};
