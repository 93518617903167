import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-right">
			<path d="M9.47 7.47a.75.75 0 01.976-.073l.084.073 4 4a.75.75 0 01.073.976l-.073.084-4 4a.75.75 0 01-1.133-.976l.073-.084L12.939 12l-3.47-3.47a.75.75 0 01-.072-.976l.073-.084z" />
		</Icon>
	);
};
