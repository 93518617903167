import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDeviceMobile: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="device-mobile">
			<path d="M16 2.25a2.75 2.75 0 012.745 2.582L18.75 5v14a2.75 2.75 0 01-2.582 2.745L16 21.75H8a2.75 2.75 0 01-2.745-2.582L5.25 19V5a2.75 2.75 0 012.582-2.745L8 2.25h8zm0 1.5H8a1.25 1.25 0 00-1.244 1.122L6.75 5v14c0 .648.491 1.18 1.122 1.244L8 20.25h8a1.25 1.25 0 001.244-1.122L17.25 19V5a1.25 1.25 0 00-1.122-1.244L16 3.75zM11.999 16.5l.117.007a1 1 0 11-.226-.001l.109-.006zM13.25 5.25a.75.75 0 01.102 1.493l-.102.007h-2.5a.75.75 0 01-.102-1.493l.102-.007h2.5z" />
		</Icon>
	);
};
