import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconMagic: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="magic">
            <path d="M10.5 4.5L11.25 3L12.75 2.25L11.25 1.5L10.5 0L9.75 1.5L8.25 2.25L9.75 3L10.5 4.5ZM3.75 7.5L4.99969 5.00016L7.5 3.75L4.99969 2.49984L3.75 0L2.50031 2.49984L0 3.75L2.50031 5.00016L3.75 7.5ZM20.25 13.5L19.0003 15.9998L16.5 17.25L19.0003 18.5002L20.25 21L21.4997 18.5002L24 17.25L21.4997 15.9998L20.25 13.5ZM23.5603 4.41703L19.583 0.439687C19.2905 0.14625 18.9066 0 18.5227 0C18.1387 0 17.7548 0.14625 17.4619 0.439687L0.439687 17.4619C-0.14625 18.0478 -0.14625 18.9975 0.439687 19.583L4.41703 23.5603C4.71 23.8533 5.09391 23.9995 5.47734 23.9995C5.86125 23.9995 6.24516 23.8533 6.53813 23.5603L23.5603 6.53766C24.1462 5.95266 24.1462 5.0025 23.5603 4.41703ZM16.8492 9.53719L14.4628 7.15078L18.5222 3.09141L20.9086 5.47781L16.8492 9.53719Z" />
        </Icon>
    );
};
