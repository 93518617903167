import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowLeft: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-left">
			<path d="M4.3 11.73l.023-.054.041-.074.033-.048a.785.785 0 01.073-.084l5-5a.75.75 0 011.133.976l-.073.084-3.72 3.72H19a.75.75 0 01.102 1.493L19 12.75H6.811l3.72 3.72a.75.75 0 01-.977 1.133l-.084-.073-5-5-.052-.057-.056-.078-.053-.103-.033-.096-.018-.088L4.25 12l.002-.057.017-.111.031-.103z" />
		</Icon>
	);
};
