const routes = {
    homepage: {
        path: '/',
        localized: {
            cs: '/',
            en: '/',
            sk: '/',
        },
    },
    detail: {
        path: '/detail/:slug',
        localized: {
            cs: '/nemovitosti-byty-domy/:slug',
            sk: '/nehnutelnosti-byty-domy/:slug',
            en: '/properties-flats-houses/:slug',
        },
    },
    share: {
        path: '/share/:hash',
        localized: {
            cs: '/sdileni/:hash',
            sk: '/share/:hash',
            en: '/zdielanie/:hash',
        },
    },
    list: {
        path: '/list/:list*',
        localized: {
            cs: '/vypis/:list*',
            sk: '/vypis/:list*',
            en: '/listings/:list*',
        },
    },
    search: {
        path: '/search',
        localized: {
            cs: '/vyhledat',
            sk: '/vyhladat',
            en: '/search',
        },
    },
    serviceCentreRent: {
        path: '/service-centre/index-rent',
        localized: {
            cs: '/centrum-sluzeb/pronajem',
            sk: '/centrum-sluzieb/prenajom',
            en: '/service-centre/rent',
        },
    },
    serviceCentreSale: {
        path: '/service-centre/index-sale',
        localized: {
            cs: '/centrum-sluzeb/prodej',
            sk: '/centrum-sluzieb',
            en: '/service-centre/sale',
        },
    },
    serviceCentreIntroCustomer: {
        path: '/service-centre/intro-customer',
        localized: {
            cs: '/tipy-pro-zajemce',
            sk: '/tipy-pre-zaujemcov',
            en: '/rent-or-buy',
        },
    },
    serviceCentreIntroOwner: {
        path: '/service-centre/intro-owner',
        localized: {
            cs: '/pro-majitele',
            sk: '/pre-majitelov',
            en: '/for-owners',
        },
    },
    serviceCentreComfort: {
        path: '/service-centre/comfort',
        localized: {
            cs: '/centrum-sluzeb/prodej-komfort',
            sk: '/centrum-sluzieb/predaj-komfort',
            en: '/service-centre/comfort-sale',
        },
    },
    serviceCentreComfortForm: {
        path: '/service-centre/comfort/form',
        localized: {
            cs: '/centrum-sluzeb/prodej-komfort-form',
            sk: '/centrum-sluzieb/predaj-komfort-form',
            en: '/service-centre/comfort-sale-form',
        },
    },
    serviceCentreComfortThankYou: {
        path: '/service-centre/comfort/thank-you',
        localized: {
            cs: '/centrum-sluzeb/prodej-komfort-thank-you',
            sk: '/centrum-sluzieb/predaj-komfort-thank-you',
            en: '/service-centre/comfort-sale-thank-you',
        },
    },
    serviceCentreContractRent: {
        path: '/service-centre/contract-rent',
        localized: {
            cs: '/centrum-sluzeb/najemni-smlouva',
            sk: '/centrum-sluzieb/najomna-zmluva',
            en: '/service-centre/lease-contract',
        },
    },
    serviceCentreCreditcheck: {
        path: '/service-centre/creditcheck',
        localized: {
            cs: '/centrum-sluzeb/overeni-bezdluznosti',
            sk: '/centrum-sluzieb/overenie-bezdlznosti',
            en: '/service-centre/financial-standing-verification',
        },
    },
    serviceCentreEnergy: {
        path: '/service-centre/energy',
        localized: {
            cs: '/centrum-sluzeb/stehovani-energie',
            sk: '/centrum-sluzieb/stehovanie-energie',
            en: '/service-centre/energy-transfer',
        },
    },
    serviceCentreGuarantee: {
        path: '/service-centre/guarantee',
        localized: {
            cs: '/garance-vraceni-penez',
            sk: '/garancia-vratenia-penazi',
            en: '/money-back-guarantee',
        },
    },
    serviceCentreGuaranteeRent: {
        path: '/service-centre/guarantee-rent',
        localized: {
            cs: '/centrum-sluzeb/garance-najmu',
            sk: '/centrum-sluzieb/garancia-najmu',
            en: '/service-centre/rental-guarantee',
        },
    },
    serviceCentreGuaranteeRentForm: {
        path: '/service-centre/guarantee-rent/form',
        localized: {
            cs: '/centrum-sluzeb/garance-najmu-form',
            sk: '/centrum-sluzieb/garancia-najmu-form',
            en: '/service-centre/rental-guarantee-form',
        },
    },
    serviceCentreGuaranteeRentThankYou: {
        path: '/service-centre/guarantee-rent/thank-you',
        localized: {
            cs: '/centrum-sluzeb/garance-najmu-thank-you',
            sk: '/centrum-sluzieb/garancia-najmu-thank-you',
            en: '/service-centre/rental-guarantee-thank-you',
        },
    },
    serviceCentreLegalService: {
        path: '/service-centre/legal-service',
        localized: {
            cs: '/centrum-sluzeb/advokatni-sluzby',
            sk: '/centrum-sluzieb/advokatske-sluzby',
            en: '/service-centre/legal-services',
        },
    },
    serviceCentrePremium: {
        path: '/service-centre/premium',
        localized: {
            cs: '/centrum-sluzeb/premium-profil',
            sk: '/centrum-sluzieb/premium-profil',
            en: '/service-centre/premium-profile',
        },
    },
    serviceCentreMortgage: {
        path: '/service-centre/mortgage',
        localized: {
            cs: '/centrum-sluzeb/bezrealitky-hypoteka',
            sk: '/centrum-sluzieb/bezrealitky-hypoteka',
            en: '/service-centre/bezrealitky-mortgage',
        },
    },
    serviceCentreWatchdog: {
        path: '/service-centre/watchdog',
        localized: {
            cs: '/hlidaci-pes',
            sk: '/strazny-pes',
            en: '/watchdog',
        },
    },
    serviceCentreOwnest: {
        path: '/service-centre/ownest',
        localized: {
            cs: '/centrum-sluzeb/muj-prvni-byt',
            sk: '/centrum-sluzieb/moj-prvy-byt',
            en: '/service-centre/my-first-flat',
        },
    },
    serviceCentreOwnestForm: {
        path: '/service-centre/ownest-form',
        localized: {
            cs: '/centrum-sluzeb/muj-prvni-byt-form',
            sk: '/centrum-sluzieb/moj-prvy-byt-form',
            en: '/service-centre/my-first-flat-form',
        },
    },
    serviceCentreNemoReport: {
        path: '/service-centre/nemo-report',
        localized: {
            cs: '/centrum-sluzeb/nemo-report',
            sk: '/centrum-sluzieb/nemo-report',
            en: '/service-centre/nemo-report',
        },
    },
    serviceCentreNemoReportForm: {
        path: '/service-centre/nemo-report/form',
        localized: {
            cs: '/centrum-sluzeb/nemo-report/form',
            sk: '/centrum-sluzieb/nemo-report/form',
            en: '/service-centre/nemo-report/form',
        },
    },
    serviceCentreInsurance: {
        path: '/service-centre/insurance',
        localized: {
            cs: '/centrum-sluzeb/pojisteni-odpovednosti',
            sk: '/service-centre/insurance',
            en: '/service-centre/insurance',
        },
    },
    propertyAssessment: {
        path: '/service-centre/property-assessment',
        localized: {
            cs: '/centrum-sluzeb/odhad-ceny-nemovitosti',
            sk: '/centrum-sluzieb/odhad-ceny-nehnutelnosti',
            en: '/service-centre/property-assessment',
        },
    },
    propertyAssessmentResult: {
        path: '/service-centre/property-assessment/result',
        localized: {
            cs: '/centrum-sluzeb/odhad-ceny-nemovitosti/vysledek',
            sk: '/centrum-sluzieb/odhad-ceny-nehnutelnosti/vysledok',
            en: '/service-centre/property-assessment/result',
        },
    },
    aboutUs: {
        path: '/about-us',
        localized: {
            cs: '/o-nas',
            sk: '/o-nas',
            en: '/about-us',
        },
    },
    myBezrealitky: {
        path: '/my-bezrealitky',
        localized: {
            cs: '/moje-bezrealitky',
            sk: '/moje-bezrealitky',
            en: '/my-bezrealitky',
        },
    },
    insert: {
        path: '/insert',
        localized: {
            cs: '/vlozit-inzerat',
            sk: '/vlozit-inzerat',
            en: '/add-listing',
        },
    },
    insertSuccess: {
        path: '/insert/success/:paymentId',
        localized: {
            cs: '/vlozit-inzerat/uspech/:paymentId',
            sk: '/vlozit-inzerat/uspech/:paymentId',
            en: '/add-listing/success/:paymentId',
        },
    },
    preview: {
        path: '/preview',
        localized: {
            cs: '/nahled',
            sk: '/nahled',
            en: '/preview',
        },
    },
    conversation: {
        path: '/my-bezrealitky/messages/:params*',
        localized: {
            cs: '/moje-bezrealitky/zpravy/:params*',
            sk: '/moje-bezrealitky/spravy/:params*',
            en: '/my-bezrealitky/messages/:params*',
        },
    },
    archiveConv: {
        path: '/my-bezrealitky/archive/:params*',
        localized: {
            cs: '/moje-bezrealitky/archiv/:params*',
            sk: '/moje-bezrealitky/archiv/:params*',
            en: '/my-bezrealitky/archive/:params*',
        },
    },
    notifications: {
        path: '/my-bezrealitky/notifications',
        localized: {
            cs: '/moje-bezrealitky/notifikace',
            sk: '/moje-bezrealitky/notifikacie',
            en: '/my-bezrealitky/notifications',
        },
    },
    notification: {
        path: '/my-bezrealitky/notifications/:id',
        localized: {
            cs: '/moje-bezrealitky/notifikace/:id',
            sk: '/moje-bezrealitky/notifikacie/:id',
            en: '/my-bezrealitky/notifications/:id',
        },
    },
    watchdogList: {
        path: '/my-bezrealitky/watchdog',
        localized: {
            cs: '/moje-bezrealitky/hlidaci-pes',
            sk: '/moje-bezrealitky/strazny-pes',
            en: '/my-bezrealitky/watchdog',
        },
    },
    watchdogEdit: {
        path: '/my-bezrealitky/watchdog/edit/:id',
        localized: {
            cs: '/moje-bezrealitky/hlidaci-pes/upravit/:id',
            sk: '/moje-bezrealitky/strazny-pes/upravit/:id',
            en: '/my-bezrealitky/watchdog/edit/:id',
        },
    },
    myServices: {
        path: '/my-bezrealitky/services',
        localized: {
            cs: '/moje-bezrealitky/sluzby',
            sk: '/moje-bezrealitky/sluzby',
            en: '/my-bezrealitky/services',
        },
    },
    contractGenerator: {
        path: '/my-bezrealitky/contract-generator/:id',
        localized: {
            cs: '/moje-bezrealitky/generator-smluv/:id',
            sk: '/moje-bezrealitky/generator-smluv/:id',
            en: '/my-bezrealitky/contract-generator/:id',
        },
    },
    contractGeneratorSignature: {
        path: '/my-bezrealitky/contract-generator-signature/signature/:orderPackageId',
        localized: {
            cs: '/podpis/:orderPackageId',
            sk: '/podpis/:orderPackageId',
            en: '/podpis/:orderPackageId',
        },
    },
    contractGeneratorSignatureSent: {
        path: '/my-bezrealitky/contract-generator-signature/sent/:orderPackageId',
        localized: {
            cs: '/podpis/:orderPackageId/odeslano',
            sk: '/podpis/:orderPackageId/odeslano',
            en: '/podpis/:orderPackageId/odeslano',
        },
    },
    contractGeneratorSignatureSuccess: {
        path: '/my-bezrealitky/contract-generator-signature/success/:orderPackageId',
        localized: {
            cs: '/podpis/:orderPackageId/dokonceno',
            sk: '/podpis/:orderPackageId/dokonceno',
            en: '/podpis/:orderPackageId/dokonceno',
        },
    },
    verify: {
        path: '/verify',
        localized: {
            cs: '/overeni',
            sk: '/overenie',
            en: '/verify',
        },
    },
    changePassword: {
        path: '/change-password/:userHash',
        localized: {
            cs: '/zmena-hesla/:userHash',
            sk: '/zmena-hesla/:userHash',
            en: '/change-password/:userHash',
        },
    },
    priceListSale: {
        path: '/page/price-list-sale',
        localized: {
            cs: '/cenik-inzerce/prodej',
            sk: '/cennik-inzercie/predaj',
            en: '/price-list/sale',
        },
    },
    priceListRent: {
        path: '/page/price-list-rent',
        localized: {
            cs: '/cenik-inzerce/pronajem',
            sk: '/cennik-inzercie/prenajom',
            en: '/price-list/rent',
        },
    },
    page: {
        path: '/page/:uri*',
        localized: {
            cs: '/informace/:uri*',
            sk: '/informacie/:uri*',
            en: '/information/:uri*',
        },
    },
    paymentProjectActivation: {
        path: '/payment-project/activation/:id',
        localized: {
            cs: '/aktivace-projektu/:id',
            sk: '/aktivace-projektu/:id',
            en: '/project-activation/:id',
        },
    },
    paymentProjectTop: {
        path: '/payment-project/top/:id',
        localized: {
            cs: '/topovani-projektu/:id',
            sk: '/topovani-projektu/:id',
            en: '/project-top/:id',
        },
    },
    paymentPackage: {
        path: '/payment-package/:uri',
        localized: {
            cs: '/platba-balicku/:uri',
            sk: '/platba-balicku/:uri',
            en: '/payment-package/:uri',
        },
    },
    paymentPackageSuccess: {
        path: '/payment-package/success/:paymentId',
        localized: {
            cs: '/platba-balicku/uspech/:paymentId',
            sk: '/platba-balicku/uspech/:paymentId',
            en: '/payment-package/success/:paymentId',
        },
    },
    profile: {
        path: '/my-bezrealitky/profile',
        localized: {
            cs: '/moje-bezrealitky/profil',
            sk: '/moje-bezrealitky/profil',
            en: '/my-bezrealitky/profile',
        },
    },
    profileBasicInfo: {
        path: '/my-bezrealitky/profile/basic-info',
        localized: {
            cs: '/moje-bezrealitky/profil/zakladni-informace',
            sk: '/moje-bezrealitky/profil/zakladni-informacie',
            en: '/my-bezrealitky/profile/basic-info',
        },
    },
    profileNotifications: {
        path: '/my-bezrealitky/profile/notifications',
        localized: {
            cs: '/moje-bezrealitky/profil/upozorneni',
            sk: '/moje-bezrealitky/profil/upozornenie',
            en: '/my-bezrealitky/profile/notifications',
        },
    },
    profileSecurity: {
        path: '/my-bezrealitky/profile/security',
        localized: {
            cs: '/moje-bezrealitky/profil/zabezpeceni',
            sk: '/moje-bezrealitky/profil/zabezpecenie',
            en: '/my-bezrealitky/profile/security',
        },
    },
    profileTransactions: {
        path: '/my-bezrealitky/profile/transactions',
        localized: {
            cs: '/moje-bezrealitky/profil/transakce',
            sk: '/moje-bezrealitky/profil/transakce',
            en: '/my-bezrealitky/profile/transactions',
        },
    },
    profileWallet: {
        path: '/my-bezrealitky/profile/wallet',
        localized: {
            cs: '/moje-bezrealitky/profil/penezenka',
            sk: '/moje-bezrealitky/profil/penazenka',
            en: '/my-bezrealitky/profile/wallet',
        },
    },
    myAdverts: {
        path: '/my-bezrealitky/property',
        localized: {
            cs: '/moje-bezrealitky/moje-inzeraty',
            sk: '/moje-bezrealitky/moje-inzeraty',
            en: '/my-bezrealitky/my-adverts',
        },
    },
    myEstimates: {
        path: '/my-bezrealitky/estimates',
        localized: {
            cs: '/moje-bezrealitky/moje-odhady',
            sk: '/moje-bezrealitky/moje-odhady',
            en: '/my-bezrealitky/my-estimates',
        },
    },
    advertEdit: {
        path: '/my-bezrealitky/property/edit/:id',
        localized: {
            cs: '/moje-bezrealitky/moje-inzeraty/upravit/:id',
            sk: '/moje-bezrealitky/moje-inzeraty/upravit/:id',
            en: '/my-bezrealitky/my-adverts/edit/:id',
        },
    },
    advertHighLight: {
        path: '/my-bezrealitky/highlight/:id',
        localized: {
            cs: '/moje-bezrealitky/topovat/:id',
            sk: '/moje-bezrealitky/topovat/:id',
            en: '/my-bezrealitky/highlight/:id',
        },
    },
    advertActivate: {
        path: '/my-bezrealitky/activate/:id',
        localized: {
            cs: '/moje-bezrealitky/aktivovat/:id',
            sk: '/moje-bezrealitky/aktivovat/:id',
            en: '/my-bezrealitky/activate/:id',
        },
    },
    advertGalleryEdit: {
        path: '/my-bezrealitky/property/edit/:id/gallery',
        localized: {
            cs: '/moje-bezrealitky/moje-inzeraty/upravit/:id/galerie',
            sk: '/moje-bezrealitky/moje-inzeraty/upravit/:id/galeria',
            en: '/my-bezrealitky/my-adverts/edit/:id/gallery',
        },
    },
    favourites: {
        path: '/my-bezrealitky/favourites',
        localized: {
            cs: '/moje-bezrealitky/oblibene',
            sk: '/moje-bezrealitky/oblubene',
            en: '/my-bezrealitky/favourites',
        },
    },
    login: {
        path: '/login',
        localized: {
            cs: '/prihlaseni',
            sk: '/prihlasenie',
            en: '/login',
        },
    },
    register: {
        path: '/register',
        localized: {
            cs: '/registrace',
            sk: '/registrace',
            en: '/register',
        },
    },
    resetPassword: {
        path: '/reset-password',
        localized: {
            cs: '/obnova-hesla',
            sk: '/obnova-hesla',
            en: '/reset-password',
        },
    },
    marketingConsentSubscribe: {
        path: '/marketing-consent/subscribe',
        localized: {
            cs: '/prihlaseni-k-odberu-novinek',
            sk: '/prihlaseni-k-odberu-novinek',
            en: '/prihlaseni-k-odberu-novinek',
        },
    },
    marketingConsentSubscribeSuccess: {
        path: '/marketing-consent/subscribe-success',
        localized: {
            cs: '/prihlaseni-k-odberu-novinek-uspech',
            sk: '/prihlaseni-k-odberu-noviniek-uspech',
            en: '/newsletter-subscription-success',
        },
    },
    marketingConsentUnsubscribe: {
        path: '/marketing-consent/unsubscribe/:token',
        localized: {
            cs: '/odhlaseni-z-odberu-novinek/:token',
            sk: '/odhlaseni-z-odberu-novinek/:token',
            en: '/odhlaseni-z-odberu-novinek/:token',
        },
    },
    propertyMove: {
        path: '/service-centre/propertyMove',
        localized: {
            cs: '/nemovitosti-v-pohybu',
            sk: '/nehnutelnosti-v-pohybe',
            en: '/property-move',
        },
    },
    petFriendly: {
        path: '/page/petFriendly',
        localized: {
            cs: '/mazlicci-vitani',
            sk: '/mazlickovia-vitani',
            en: '/pet-friendly',
        },
    },
    highlight: {
        path: '/highlight',
        localized: {
            cs: '/topovani',
            sk: '/topovanie',
            en: '/highlight',
        },
    },
    blog: {
        path: '/blog',
        localized: {
            cs: '/blog',
            sk: '/blog',
            en: '/blog',
        },
    },
    blogAuthor: {
        path: '/blog/author/:slug*',
        localized: {
            cs: '/blog/autor/:slug*',
            sk: '/blog/autor/:slug*',
            en: '/blog/author/:slug*',
        },
    },
    blogTag: {
        path: '/blog/tag/:slug*',
        localized: {
            cs: '/blog/tag/:slug*',
            sk: '/blog/tag/:slug*',
            en: '/blog/tag/:slug*',
        },
    },
    blogArticle: {
        path: '/blog/:slug',
        localized: {
            cs: '/blog/:slug',
            sk: '/blog/:slug',
            en: '/blog/:slug',
        },
    },
    calendar: {
        path: '/my-bezrealitky/calendar',
        localized: {
            cs: '/moje-bezrealitky/kalendar',
            sk: '/moje-bezrealitky/kalendar',
            en: '/my-bezrealitky/calendar',
        },
    },
    eventsCalendar: {
        path: '/my-bezrealitky/calendar/events/:params*',
        localized: {
            cs: '/moje-bezrealitky/kalendar/prohlidky/:params*',
            sk: '/moje-bezrealitky/kalendar/prehliadky/:params*',
            en: '/my-bezrealitky/calendar/events/:params*',
        },
    },
    advertCalendar: {
        path: '/my-bezrealitky/calendar/:id/:params*',
        localized: {
            cs: '/moje-bezrealitky/kalendar/:id/:params*',
            sk: '/moje-bezrealitky/kalendar/:id/:params*',
            en: '/my-bezrealitky/calendar/:id/:params*',
        },
    },
    blogSearch: {
        path: '/blog/search/:searchTerm*',
        localized: {
            cs: '/blog/vyhledat/:searchTerm*',
            sk: '/blog/vyhladat/:searchTerm*',
            en: '/blog/search/:searchTerm*',
        },
    },
    error500: {
        path: '/error/500',
        localized: {
            cs: '/error/500',
            sk: '/error/500',
            en: '/error/500',
        },
    },
    howToSell: {
        path: '/page/how-to-sell',
        localized: {
            cs: '/jak-prodat',
            sk: '/ako-predat',
            en: '/how-to-sell',
        },
    },
    howToRent: {
        path: '/page/how-to-rent',
        localized: {
            cs: '/jak-pronajmout',
            sk: '/ako-prenajat',
            en: '/how-to-rent',
        },
    },
    removeWatchdogs: {
        path: '/remove-watchdogs/:hash',
        localized: {
            cs: '/zrusit-hlidaci-psy/:hash',
            sk: '/zrusit-strazneho-psa/:hash',
            en: '/remove-watchdogs/:hash',
        },
    },
    paymentInsuranceSuccess: {
        path: '/payment-insurance/success',
        localized: {
            cs: '/payment-insurance/success',
            sk: '/payment-insurance/success',
            en: '/payment-insurance/success',
        },
    },
    onBoarding: {
        path: '/onboarding',
        localized: {
            cs: '/onboarding',
            sk: '/onboarding',
            en: '/onboarding',
        },
    },
    loginFailed: {
        path: '/loginfailed',
        localized: {
            cs: '/loginfailed',
            sk: '/loginfailed',
            en: '/loginfailed',
        },
    },
};

module.exports = {
    routes: routes,
    getRewrites: (i18nConfig) => async () => {
        const domainByLocale = {};
        for (const domain of i18nConfig.domains) {
            domainByLocale[domain.defaultLocale] = domain.domain;
        }

        const rewrites = [
            {
                source: '/robots.txt',
                destination: '/api/robots',
            },
            {
                source: '/manifest.json',
                destination: '/api/manifest',
            },
            {
                source: '/sitemap/:file',
                destination: '/api/sitemap/:file',
            },
            {
                source: '/.well-known/:path*',
                destination: '/api/well-known/:path*',
            },
            {
                source: '/vlozit-inzerat/uspech/prodej/:id',
                destination: '/vlozit-inzerat/uspech/:id',
            },
            {
                source: '/vlozit-inzerat/uspech/pronajem/:id',
                destination: '/vlozit-inzerat/uspech/:id',
            },
            {
                source: '/add-listing/success/sale/:id',
                destination: '/add-listing/success/:id',
            },
            {
                source: '/add-listing/success/rent/:id',
                destination: '/add-listing/success/:id',
            },
            {
                source: '/vypis/:list*',
                destination: '/search/:list*',
                has: [
                    {
                        type: 'host',
                        value: domainByLocale['cs'],
                    },
                ],
            },
            {
                source: '/vypis/:list*',
                destination: '/search/:list*',
                has: [
                    {
                        type: 'host',
                        value: domainByLocale['sk'],
                    },
                ],
            },
            {
                source: '/listings/:list*',
                destination: '/search/:list*',
                has: [
                    {
                        type: 'host',
                        value: domainByLocale['en'],
                    },
                ],
            },
        ];
        for (const locale of i18nConfig.locales) {
            for (const route of Object.values(routes)) {
                if (typeof route.localized[locale] !== 'undefined' && route.localized[locale] !== route.path) {
                    rewrites.push({
                        source: route.localized[locale],
                        destination: route.path,
                        has: [
                            {
                                type: 'host',
                                value: domainByLocale[locale],
                            },
                        ],
                    });
                }
            }
        }

        return rewrites;
    },
    getRedirects: (i18nConfig) => async () => {
        const domainByLocale = {};
        for (const domain of i18nConfig.domains) {
            domainByLocale[domain.defaultLocale] = domain.domain;
        }

        const redirects = [];

        const permanentRedirects = [
            {
                cs: ['/vypis/:path(.*)/0', '/vypis/:path'],
                sk: ['/vypis/:path(.*)/0', '/vypis/:path'],
                en: ['/listings/:path(.*)/0', '/listings/:path'],
            },
            {
                cs: ['/connect/:path*', '/prihlaseni'],
                sk: ['/connect/:path*', '/prihlaseni'],
                en: ['/connect/:path*', '/login'],
            },
            {
                cs: ['/centrum-sluzeb', '/centrum-sluzeb/prodej'],
                //sk: ['/centrum-sluzieb', '/centrum-sluzieb/predaj'], //
                en: ['/service-centre', '/service-centre/sale'],
            },
            {
                cs: ['/cenik-inzerce', '/cenik-inzerce/prodej'],
                sk: ['/cennik-inzercie', '/cennik-inzercie/predaj'],
                en: ['/price-list', '/price-list/sale'],
            },
            {
                cs: ['/vypis/nabidka-spolubydleni/:path*', '/vypis/nabidka-pronajem/:path*'],
                sk: ['/vypis/ponuka-spolubyvanie/:path*', '/vypis/ponuka-prenajom/:path*'],
                en: ['/listings/offer-share/:path*', '/listings/offer-rent/:path*'],
            },
            {
                cs: ['/garance', '/garance-vraceni-penez'],
                sk: ['/garancia', '/garancia-vratenia-penazi'],
                en: ['/guarantee', '/money-back-guarantee'],
            },
            {
                cs: ['/cenik-sluzeb', '/cenik-inzerce'],
                sk: ['/cennik-sluzieb', '/cennik-inzercie'],
                en: ['/service-price-list', '/price-list'],
            },
            {
                cs: ['/pomoc-ua', '/centrum-sluzeb/premium-profil'],
                sk: ['/pomoc-ua', '/centrum-sluzieb/premium-profil'],
                en: ['/help-ua', '/service-centre/premium-profile'],
            },
            {
                cs: ['/centrum-sluzeb/bleskova-rekonstrukce', '/centrum-sluzeb/prodej'],
                sk: ['/centrum-sluzieb/bleskova-rekonstrukcia', '/centrum-sluzieb'],
                en: ['/service-centre/bleskova-rekonstrukce', '/service-centre/sale'],
            },
            {
                cs: ['/informace/o-nas', '/o-nas'],
                sk: ['/informacie/o-nas', '/o-nas'],
                en: ['/information/o-nas', '/about-us'],
            },
            {
                cs: ['/informace', '/'],
                sk: ['/informacie', '/'],
                en: ['/information', '/'],
            },
            {
                cs: ['/informace/cenik-sluzeb', '/cenik-inzerce'],
            },
            {
                cs: ['/centrum-sluzeb/pravni-servis', '/centrum-sluzeb/advokatni-sluzby'],
            },
            {
                cs: ['/informace/casto-kladene-otazky', '/'],
            },
            {
                cs: ['/centrum-sluzeb', '/centrum-sluzeb/prodej'],
            },
            {
                cs: ['/informace/vzorove-smlouvy', '/'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/smlouva-o-najmu-prostoru-slouziciho-podnikan', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/predavaci-protokol-k-bytu', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/souhlas-manzela-s-prevodem-bytu-domu', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/souhlas-vlastnika-nemovite-veci-s-umistenim-sidla', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/souhlas-vlastnika-domu-s-podnajmem', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/oznameni-o-zmene-poctu-osob-zijicich-v-domacnosti', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/dohoda-o-skonceni-najmu', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/uznani-dluhu-z-najemneho', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/souhlas-manzela-s-ukoncenim-najmu-bytu', '/centrum-sluzeb/pronajem'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/plna-moc', '/centrum-sluzeb/prodej'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/souhlas-manzela-s-prevodem-bytu-domu', '/centrum-sluzeb/prodej'],
            },
            {
                cs: ['/informace/vzorove-smlouvy/smlouva-o-budouci-smlouve-kupni', '/centrum-sluzeb/prodej'],
            },
            {
                cs: ['/centrum-sluzeb/kupni-smlouva', '/centrum-sluzeb/pravni-servis'],
            },
            {
                cs: ['/informace/realitni-pruvodci', '/'],
            },
            {
                cs: ['/informace/realitni-pruvodci/:path*', '/'],
            },
            {
                cs: ['/informace/overeny-uzivatel', '/'],
            },
            {
                cs: ['/informace/validace-mojeid', '/'],
            },
            {
                cs: ['/informace/smluvni-podminky/affiliate', '/informace/smluvni-podminky'],
            },
            {
                cs: ['/informace/smluvni-podminky/hypotecni-sluzby', '/informace/smluvni-podminky'],
            },
            {
                cs: ['/informace/smluvni-podminky/informacni-povinnost-energeticky-stitek', '/informace/smluvni-podminky'],
            },
            {
                cs: ['/init-moje-id-registration', '/'],
            },
            {
                cs: ['/informace/smluvni-podminky/pravni-sluzby', '/informace/smluvni-podminky/pravni-sluzby-nove'],
            },
            {
                cs: ['/informace/smluvni-podminky/sluzby-inzerce', '/informace/smluvni-podminky/sluzby-inzerce-nove'],
            },
            {
                cs: ['/informace/smluvni-podminky/obecne', '/informace/smluvni-podminky/obecne-nove'],
            },
            {
                sk: ['/centrum-sluzieb/prenajom', '/centrum-sluzieb/predaj'],
            },
            {
                cs: ['/moje-bezrealitky/profil/nastaveni', '/moje-bezrealitky/profil'],
            },
            {
                sk: ['/prihlaseni', '/prihlasenie'],
            },
            {
                cs: ['/centrum-sluzeb/prodej-expres', '/'],
                sk: ['/centrum-sluzieb/predaj-expres', '/'],
                en: ['/service-centre/express-sale', '/'],
            },
            {
                cs: ['/informace/hypoteka', '/blog/co-je-hypoteka-a-jak-ji-ziskat-a-co-delat-kdyz-ji-nedostanete'],
            },
            {
                cs: ['/nove-bydleni/:path*', '/'],
            },
        ];

        for (const r of permanentRedirects) {
            if (r.cs) {
                redirects.push({
                    source: r['cs'][0],
                    destination: r['cs'][1],
                    permanent: true,
                    has: [
                        {
                            type: 'host',
                            value: domainByLocale['cs'],
                        },
                    ],
                });
            }

            if (r.sk) {
                redirects.push({
                    source: r['sk'][0],
                    destination: r['sk'][1],
                    permanent: true,
                    has: [
                        {
                            type: 'host',
                            value: domainByLocale['sk'],
                        },
                    ],
                });
            }

            if (r.en) {
                redirects.push({
                    source: r['en'][0],
                    destination: r['en'][1],
                    permanent: true,
                    has: [
                        {
                            type: 'host',
                            value: domainByLocale['en'],
                        },
                    ],
                });
            }
        }

        /*
        for (const locale of i18nConfig.locales) {
            for (const route of Object.values(routes)) {
                const target = route.localized[locale] ?? route.path;
                for (const fromLocale of i18nConfig.locales) {
                    if (fromLocale === locale) {
                        continue;
                    }

                    if (typeof route.localized[fromLocale] !== 'undefined' && route.localized[fromLocale] !== target) {
                        redirects.push({
                            source: route.localized[fromLocale],
                            destination: target,
                            permanent: true,
                            has: [
                                {
                                    type: 'host',
                                    value: domainByLocale[locale],
                                },
                            ],
                        });
                    }
                }
                if (target !== route.path) {
                    redirects.push({
                        source: route.path,
                        destination: target,
                        permanent: true,
                        has: [
                            {
                                type: 'host',
                                value: domainByLocale[locale],
                            },
                        ],
                    });
                }
            }
        }
        */

        return redirects;
    },
};
