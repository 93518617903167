import {createContext, useContext} from "react";

type MaintenanceContextType = {
    inMaintenance: boolean;
}

export const MaintenanceContext = createContext<MaintenanceContextType>({
    inMaintenance: false,
});

export const useMaintenance = () => {
    return useContext(MaintenanceContext);
}
