import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconClipboard: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="clipboard">
			<path d="M15.644 2.61a5.75 5.75 0 015.745 5.53l.005.22v7.287a5.75 5.75 0 01-5.53 5.746l-.22.004H8.356a5.75 5.75 0 01-5.745-5.53l-.005-.22V8.36a5.75 5.75 0 015.53-5.746l.22-.004h7.288zm0 1.5H8.356a4.25 4.25 0 00-4.245 4.044l-.005.206v7.287a4.25 4.25 0 004.044 4.245l.206.005h7.288a4.25 4.25 0 004.245-4.044l.005-.206V8.36a4.25 4.25 0 00-4.044-4.245l-.206-.005zM12 9.84a3.631 3.631 0 01.193 7.257l-.193.005H9.121A3.622 3.622 0 016.7 10.778a.75.75 0 01.998 1.12 2.122 2.122 0 001.257 3.698l.165.006H12a2.131 2.131 0 00.152-4.257L12 11.34h-.72a.75.75 0 01-.102-1.493l.102-.007H12zm2.879-2.881a3.622 3.622 0 012.422 6.324.75.75 0 01-.998-1.12 2.122 2.122 0 00-1.257-3.699l-.165-.005H12a2.131 2.131 0 00-.152 4.257l.152.005h.72a.75.75 0 01.102 1.493l-.102.007H12a3.631 3.631 0 01-.193-7.257L12 6.959h2.879z" />
		</Icon>
	);
};
