import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCamera: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="camera">
			<path d="M14.63 3.25c.562 0 1.087.27 1.415.72l.085.127 1.294 2.153H19a2.75 2.75 0 012.73 2.411l.015.171.005.168v9a2.75 2.75 0 01-2.582 2.745L19 20.75H5a2.75 2.75 0 01-2.745-2.582L2.25 18V9a2.75 2.75 0 012.582-2.745L5 6.25h1.569l1.245-2.132a1.75 1.75 0 011.19-.838l.167-.023.155-.007h5.304zm0 1.5H9.326a.25.25 0 00-.176.072l-.04.052-1.462 2.504a.75.75 0 01-.535.363L7 7.75H5a1.25 1.25 0 00-1.244 1.122L3.75 9v9c0 .648.491 1.18 1.122 1.244L5 19.25h14a1.25 1.25 0 001.244-1.122L20.25 18V9a1.25 1.25 0 00-1.122-1.244L19 7.75h-2a.75.75 0 01-.578-.272l-.065-.091-1.512-2.516a.251.251 0 00-.15-.113l-.065-.008zm.78 4.887a4.737 4.737 0 11-6.7 6.7 4.737 4.737 0 016.7-6.7zM9.77 10.698a3.237 3.237 0 104.713.144l-.135-.144-.144-.135a3.237 3.237 0 00-4.434.135z" />
		</Icon>
	);
};
