import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconLightbulb: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="lightbulb">
			<path d="M11.188 3.298C15.24 2.823 18.75 5.967 18.75 10a6.734 6.734 0 01-2.86 5.51.327.327 0 00-.131.166l-.009.057V18.5a3.25 3.25 0 01-3.066 3.245l-.184.005h-1a3.25 3.25 0 01-3.245-3.066L8.25 18.5v-2.763c0-.077-.047-.161-.142-.228a6.739 6.739 0 01-2.774-6.586c.457-2.938 2.896-5.278 5.854-5.625zm3.044 15.452H9.768a1.75 1.75 0 001.578 1.493l.154.007h1a1.75 1.75 0 001.732-1.5zm.018-2.811H9.749v1.311h4.501v-1.311zm6.196-1.542l.084.073 1.19 1.19a.75.75 0 01-.976 1.133l-.084-.073-1.19-1.19a.75.75 0 01.976-1.133zM4.53 14.47a.75.75 0 01.073.976l-.073.084-1.19 1.19a.75.75 0 01-1.133-.976l.073-.084 1.19-1.19a.75.75 0 011.06 0zM17.25 10c0-3.138-2.73-5.583-5.888-5.212-2.29.268-4.192 2.094-4.546 4.365a5.238 5.238 0 001.967 4.99l.191.14c.068.049.132.1.193.156h5.667l.092-.08.098-.074A5.235 5.235 0 0017.25 10zm5.44-.75a.75.75 0 01.102 1.493l-.102.007H21a.75.75 0 01-.102-1.493L21 9.25h1.69zM3 9.25a.75.75 0 01.102 1.493L3 10.75H1.31a.75.75 0 01-.102-1.493l.102-.007H3zm18.72-5.97a.75.75 0 01.073.976l-.073.084-1.19 1.19a.75.75 0 01-1.133-.976l.073-.084 1.19-1.19a.75.75 0 011.06 0zM4.256 2.207l.084.073 1.19 1.19a.75.75 0 01-.976 1.133L4.47 4.53 3.28 3.34a.75.75 0 01.976-1.133z" />
		</Icon>
	);
};
