import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDelivery: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="delivery">
			<path d="M10.625 1.62a2.751 2.751 0 012.577-.091l.173.091 6.926 4c.359.206.66.488.887.817l.13.208c.198.349.32.74.35 1.15l.008.206v7.998a2.75 2.75 0 01-1.21 2.278l-.165.104-6.926 3.999c-.35.201-.733.32-1.122.356L12 22.75l-.058-.003a2.75 2.75 0 01-1.14-.274l-.177-.094-6.926-4a2.748 2.748 0 01-1.368-2.185l-.007-.196V8.001c0-.413.093-.814.264-1.176l.117-.22c.203-.345.481-.646.82-.876l.174-.11 6.926-3.999zM3.824 8.145v7.853c0 .397.188.767.502 1.001l.123.082 6.801 3.927v-8.575L3.824 8.145zm16.352 0l-7.426 4.287v8.575l6.801-3.926a1.25 1.25 0 00.616-.935l.009-.147V8.145zM6.455 11.936l.091.045 3 1.74a.75.75 0 01-.66 1.343l-.092-.045-3-1.74a.75.75 0 01.66-1.343zM16.6 5.216L9.171 9.498 12 11.133l7.425-4.287-2.825-1.63zM12.625 2.92a1.251 1.251 0 00-1.118-.067l-.132.067-6.801 3.926 3.097 1.787L15.1 4.35l-2.475-1.43z" />
		</Icon>
	);
};
