import {getLocalizedRoute, routes} from '@utils/routes';
import {Nav} from '@components/Nav';
import {FormattedMessage} from 'react-intl';
import {useLocale} from '@utils/locale';
import Link from 'next/link';
import {LocaleEnum} from 'src/types/general';

export type SellMenuprops = {
    onHide?: (e?: React.MouseEvent<HTMLElement>) => void;
};

export const SellMenu = ({onHide}: SellMenuprops) => {
    const locale = useLocale();

    const onClick = onHide ? {onClick: onHide} : {};

    return (
        <Nav as="ul" className="flex-column">
            <Nav.Item as="li">
                <Link href={getLocalizedRoute(routes.serviceCentreComfort, locale)} passHref legacyBehavior>
                    <Nav.Link {...onClick}>
                        <FormattedMessage defaultMessage="Komfort" />
                    </Nav.Link>
                </Link>
            </Nav.Item>
            {locale !== LocaleEnum.en && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.priceListSale, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Inzerce nemovitosti" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            <Nav.Item as="li">
                <Link href={getLocalizedRoute(routes.serviceCentreLegalService, locale)} passHref legacyBehavior>
                    <Nav.Link {...onClick}>
                        <FormattedMessage defaultMessage="Advokátní služby" />
                    </Nav.Link>
                </Link>
            </Nav.Item>
            {locale === LocaleEnum.cs && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreOwnest, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Ownest" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            {locale !== LocaleEnum.en && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreMortgage, locale, {reload: true})} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Hypotéka" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            {locale === LocaleEnum.cs && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreNemoReport, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Prověření nemovitosti" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
            <Nav.Item as="li">
                <Link href={getLocalizedRoute(routes.propertyAssessment, locale)} passHref legacyBehavior>
                    <Nav.Link {...onClick}>
                        <FormattedMessage defaultMessage="Odhad kupní ceny" />
                    </Nav.Link>
                </Link>
            </Nav.Item>
            {locale !== LocaleEnum.en && (
                <Nav.Item as="li">
                    <Link href={getLocalizedRoute(routes.serviceCentreEnergy, locale)} passHref legacyBehavior>
                        <Nav.Link {...onClick}>
                            <FormattedMessage defaultMessage="Stěhování energie" />
                        </Nav.Link>
                    </Link>
                </Nav.Item>
            )}
        </Nav>
    );
};
