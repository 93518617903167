import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowDownLeft: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-down-left">
			<path d="M17.48 6.52a.75.75 0 01.073.976l-.073.084L8.861 16.2l5.189.001a.75.75 0 01.102 1.493l-.102.007-7.045-.001-.057-.006-.07-.013-.067-.019-.09-.037-.04-.02-.082-.055-.08-.07-.051-.056-.056-.078-.04-.073-.04-.104a.737.737 0 01-.025-.116L6.3 16.95v-7a.75.75 0 011.493-.102l.007.102v5.188l8.62-8.618a.75.75 0 011.06 0z" />
		</Icon>
	);
};
