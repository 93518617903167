import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconEnergy: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="energy">
            <path d="M12,21a9,9,0,1,0-9-9A9,9,0,0,0,12,21Z" fill="none" stroke="#323232" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="1.5"/>
            <path d="M14,10.5V8" fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.5"/>
            <path d="M10,10.5V8" fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.5"/>
            <path d="M12,21V16" fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.5"/>
            <path d="M9.25,10.5h5.5a1,1,0,0,1,1,1V13a3,3,0,0,1-3,3h-1.5a3,3,0,0,1-3-3V11.5A1,1,0,0,1,9.25,10.5Z"
                  fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        </Icon>
    );
};
