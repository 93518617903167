import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMessage: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="message">
			<path d="M18 2.25a3.75 3.75 0 013.745 3.55l.005.2v8a3.75 3.75 0 01-3.55 3.745l-.2.005h-4.738l-4.793 3.836a.75.75 0 01-1.212-.484L7.25 21v-3.25H6a3.75 3.75 0 01-3.745-3.55L2.25 14V6A3.75 3.75 0 015.8 2.255L6 2.25h12zm0 1.5H6a2.25 2.25 0 00-2.245 2.096L3.75 6v8a2.25 2.25 0 002.096 2.245L6 16.25h2a.75.75 0 01.743.648L8.75 17v2.439l3.785-3.028.086-.058.1-.05.08-.026.125-.023.104-.004H18a2.25 2.25 0 002.245-2.096L20.25 14V6a2.25 2.25 0 00-2.096-2.245L18 3.75z" />
		</Icon>
	);
};
