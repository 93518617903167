import {useRouter} from 'next/router';
import {useMemo} from 'react';
import {LocaleEnum, verifyLocale} from 'src/types/general';
import {IntlShape} from 'react-intl';
import {Country, Currency} from '.cache/__types__';

export const useLocale = () => {
    const router = useRouter();
    return useMemo(() => verifyLocale(router.locale), [router.locale]);
};

export const getCurrencySymbol = (intl: IntlShape, currency: Currency) => {
    return intl
        .formatNumberToParts(0, {
            style: 'currency',
            currency,
        })
        .filter((part) => part.type === 'currency')
        .at(0)?.value;
};

export const getLocaleFromCountry = (country: Country) => {
    switch (country) {
        case Country.Cze:
            return LocaleEnum.cs;
        case Country.Svk:
            return LocaleEnum.sk;
    }
};
