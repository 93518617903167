import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCross: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="cross">
			<path d="M18.602 4.46l.943.943-6.601 6.599 6.601 6.6-.943.943-6.6-6.601-6.599 6.601-.943-.943 6.6-6.6-6.6-6.599.943-.943 6.599 6.6z" />
		</Icon>
	);
};
