import {PropsWithChildren, createContext, useContext, useEffect, useMemo} from 'react';
import {AccountType, AdvertImportType, CreditcheckCheckStatus, InsuranceStatus, Locale} from '.cache/__types__';
import {useSession} from 'next-auth/react';
import {useAuthorizedUserLazyQuery} from '@gql/query/user/authUser.gql';
import {propertyRequiredArray} from '../types/general';
import * as Sentry from '@sentry/nextjs';
import {AccessTokenContext} from '@utils/auth';
import {getCountries, getCountryCallingCode} from 'libphonenumber-js';

export type User = {
    id: string;
    firstname: string;
    lastname: string;
    accountType: AccountType;
    timeAccountExpiration?: number | null;
    email: string;
    phone?: string | null;
    photo?: string | null;
    locale: Locale;
    isOwner?: boolean | null;
    isDeveloper: boolean;
    canAddWatchdog: boolean;
    phoneVerified: boolean;
    importType: AdvertImportType;
    insuranceStatus: InsuranceStatus;
    checkStatus: CreditcheckCheckStatus;
};

export const UserContext = createContext<{user: User | null; refetch: () => any; loading: boolean}>({
    user: null,
    refetch: () => {},
    loading: true,
});

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({children}: PropsWithChildren) => {
    const {data, status} = useSession();
    const at = useContext(AccessTokenContext);

    const [getAuthData, {data: authData, refetch}] = useAuthorizedUserLazyQuery({fetchPolicy: 'network-only'});

    const user = useMemo(() => {
        if (data?.user.id && authData?.authUser && data.user.id === authData.authUser.id) {
            return (
                propertyRequiredArray(
                    [authData.authUser],
                    [
                        'id',
                        'firstname',
                        'lastname',
                        'email',
                        'accountType',
                        'locale',
                        'isOwner',
                        'isDeveloper',
                        'canAddWatchdog',
                        'phoneVerified',
                        'importType',
                        'insuranceStatus',
                        'checkStatus',
                    ],
                ).at(0) ?? null
            );
        }
        return null;
    }, [data?.user.id, authData?.authUser]);

    useEffect(() => {
        if (user) {
            Sentry.setUser({
                id: user.id,
                email: user.email,
            });
        } else {
            Sentry.getCurrentScope().setUser(null);
        }
    }, [user]);

    useEffect(() => {
        if (data?.user.id && at !== '') {
            getAuthData();
        }
    }, [at, data?.user.id, getAuthData]);

    const value = useMemo(
        () => ({user, refetch, loading: status === 'loading' || (data?.user.id && !authData)}),
        [status, user, refetch, authData, data],
    );

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function getCountryFromPrefix(prefix: string) {
    const countries = getCountries();
    for (const country of countries) {
        if (getCountryCallingCode(country) === prefix) {
            return country;
        }
    }
    return null;
}
