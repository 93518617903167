import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPercentage: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="percentage">
			<path d="M10.08 2.9a3.161 3.161 0 013.674-.118l.165.118 1.404 1.072 1.756.235a3.162 3.162 0 012.647 2.356l.04.18.03.182.231 1.749 1.072 1.406a3.163 3.163 0 01.229 3.495l-.111.18-.118.165-1.074 1.403-.234 1.755a3.162 3.162 0 01-2.356 2.647l-.18.04-.183.03-1.748.231-1.405 1.074a3.161 3.161 0 01-3.495.23l-.18-.112-.164-.118-1.405-1.073-1.754-.235a3.162 3.162 0 01-2.647-2.356l-.04-.18-.03-.182-.232-1.75-1.073-1.405a3.163 3.163 0 01-.229-3.494l.111-.18.118-.165 1.073-1.405.236-1.753a3.162 3.162 0 012.356-2.647l.18-.04.182-.03 1.747-.232L10.08 2.9zm2.929 1.192a1.661 1.661 0 00-1.888-.09l-.13.09L9.526 5.21l-.075.071a.71.71 0 01-.17.098l-.1.034-.11.021-1.949.26a1.662 1.662 0 00-1.402 1.28l-.027.147-.26 1.952a.84.84 0 01-.184.429l-.07.075-.023.02L4.13 10.94l-.13.182a1.663 1.663 0 000 1.756l.091.13 1.195 1.565a.75.75 0 01.128.26l.02.096.258 1.948a1.662 1.662 0 001.28 1.402l.147.027 1.952.26a.75.75 0 01.275.094l.081.054 1.563 1.194a1.661 1.661 0 001.888.09l.13-.09 1.461-1.116.01-.01a.698.698 0 01.238-.161l.1-.034.11-.022 1.949-.258a1.662 1.662 0 001.402-1.28l.027-.147.26-1.952a.75.75 0 01.094-.277l.055-.082 1.193-1.56a1.662 1.662 0 00.09-1.888l-.09-.131-1.193-1.564a.75.75 0 01-.128-.26l-.02-.096-.258-1.948a1.662 1.662 0 00-1.28-1.402l-.147-.027-1.953-.26a.75.75 0 01-.275-.093l-.081-.054-1.563-1.194zm1.81 9.33a.988.988 0 010 1.4.988.988 0 01-1.4 0 .989.989 0 111.4-1.4zm-.19-4.052a.75.75 0 01.073.976l-.073.084-4.2 4.2a.75.75 0 01-1.133-.976l.073-.084 4.2-4.2a.75.75 0 011.06 0zm-4.14-.273l.09.08a.99.99 0 01.083 1.308l-.083.092a.989.989 0 11-.09-1.48z" />
		</Icon>
	);
};
