import classNames from 'classnames';
import {useMemo} from 'react';
import {FormControl, FormControlProps} from 'react-bootstrap';
import {BsPrefixRefForwardingComponent} from 'react-bootstrap/esm/helpers';
import styles from './LimitedTextareaGPT.module.scss';
import {Button} from '@components/Button';
import {FormattedMessage} from 'react-intl';
import {Icon} from '@components/Icon';
import {Box} from '@components/Box';
import {Typography} from '@components/Typography';
import {LocaleType} from '@localTypes/general';
import {getLanguageSelectorText} from '@utils/getLanguageSelectorText';

export type FormLimitedTextareaGPTProps = BsPrefixRefForwardingComponent<
    'textarea',
    FormControlProps & {
        boxClassName?: string;
        onGenerate: () => void;
        label?: string;
        locale?: LocaleType;
    }
> &
    FormControlProps & {
        maxLength: number;
        value?: string;
        boxClassName?: string;
        onGenerate: () => void;
        label?: string;
        locale?: LocaleType;
    };

export const FormLimitedTextareaGPT = ({
    maxLength,
    value = '',
    onChange,
    boxClassName,
    onGenerate,
    label,
    locale,
    ...props
}: FormLimitedTextareaGPTProps) => {
    const content = useMemo(() => value.slice(0, maxLength), [value, maxLength]);

    return (
        <Box className={classNames(styles.limitedTextarea, 'limitedTextarea', boxClassName)}>
            <FormControl
                {...props}
                onChange={(event) => {
                    event.target.value = event.target.value.slice(0, maxLength);

                    if (onChange) {
                        onChange(event);
                    }
                }}
                maxLength={maxLength}
                size="lg"
                as="textarea"
                value={content}
            />

            <div className={classNames(styles.limitedTextareaBottom, 'gap-5')}>
                <Button variant="link" className={classNames(styles.limitedTextareaButton, 'flex-grow-1')} onClick={onGenerate}>
                    <Icon.Edit position="start" />
                    {label ? (
                        label
                    ) : content.length ? (
                        <FormattedMessage defaultMessage="Přegenerovat text" />
                    ) : (
                        <FormattedMessage defaultMessage="Vygenerovat text" />
                    )}
                </Button>

                {locale && (
                    <Typography variant={'perex'} className={styles.limitedTextareaLanguage}>
                        {getLanguageSelectorText(locale)}
                    </Typography>
                )}

                <Typography variant="perex" className={styles.limitedTextareaCount}>
                    {content.length} / {maxLength}
                </Typography>
            </div>
        </Box>
    );
};
