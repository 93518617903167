import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCertificate: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="certificate">
			<path d="M10.734 1.678a2.087 2.087 0 012.39-.1l.143.1.711.543.89.12c.824.11 1.492.694 1.724 1.472l.04.158.028.162.112.88.55.72c.123.16.22.335.29.517H19a2.75 2.75 0 012.745 2.582L21.75 9v10a2.75 2.75 0 01-2.582 2.745L19 21.75H5a2.75 2.75 0 01-2.745-2.582L2.25 19V9a2.75 2.75 0 012.582-2.745L5 6.25h1.39a2.08 2.08 0 01.117-.253l.072-.121.1-.143.543-.712.12-.89a2.087 2.087 0 011.472-1.723l.158-.04.162-.028.881-.114.719-.548zM6.39 7.75H5a1.25 1.25 0 00-1.244 1.122L3.75 9v10c0 .648.491 1.18 1.122 1.244L5 20.25h14a1.25 1.25 0 001.244-1.122L20.25 19V9a1.25 1.25 0 00-1.122-1.244L19 7.75h-1.389c-.033.086-.072.17-.117.252l-.071.12-.1.144-.545.713-.119.888a2.087 2.087 0 01-1.472 1.724l-.158.04-.162.028-.881.111-.719.55c-.654.5-1.53.563-2.24.188l-.15-.087-.143-.1-.712-.544-.888-.118a2.085 2.085 0 01-1.725-1.472l-.04-.159-.028-.162-.119-.889-.543-.71a2.084 2.084 0 01-.289-.517zm8.61 9.5a.75.75 0 01.102 1.493L15 18.75H9a.75.75 0 01-.102-1.493L9 17.25h6zm2-3a.75.75 0 01.102 1.493L17 15.75H7a.75.75 0 01-.102-1.493L7 14.25h10zM12.357 2.87a.587.587 0 00-.63-.053l-.083.053-.87.665a.75.75 0 01-.357.148l-.098.006-.987.137a.587.587 0 00-.447.32l-.036.089-.02.095-.139.987-.006.099a.75.75 0 01-.094.276l-.054.081-.665.87a.587.587 0 00-.053.63l.053.083.665.87a.75.75 0 01.128.26l.02.096.143 1.086c.031.23.193.416.409.483l.095.02.987.139.099.006a.75.75 0 01.276.094l.081.054.87.665c.184.14.43.158.63.053l.083-.053.87-.665a.75.75 0 01.357-.148l.098-.006.987-.137a.587.587 0 00.447-.32l.036-.089.02-.095.146-1.086a.75.75 0 01.093-.275l.054-.081.665-.871a.587.587 0 00.053-.63l-.053-.083-.665-.87a.75.75 0 01-.097-.169l-.032-.092-.018-.096-.144-1.085a.587.587 0 00-.409-.483l-.095-.02-.987-.139a.752.752 0 01-.375-.1l-.081-.054-.87-.665zm1.73 2.822a.75.75 0 01.072.976l-.073.084-1.945 1.945a.75.75 0 01-.976.073l-.085-.073L9.914 7.53a.75.75 0 01.977-1.133l.085.073.635.635 1.415-1.413a.75.75 0 011.06 0z" />
		</Icon>
	);
};
