import {useState} from 'react';

export const useShowPassword = (): [string, boolean, () => void] => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordShown = () => {
        setPasswordShown(!passwordShown);
    };

    return [passwordShown ? 'text' : 'password', passwordShown, togglePasswordShown];
};
