import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconShield: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="shield">
            <path d="M16 2.25A5.75 5.75 0 0 1 21.75 8v3.2c0 5.07-3.58 9.44-8.54 10.43-.8.16-1.62.16-2.42 0-4.96-1-8.54-5.35-8.54-10.42V8A5.75 5.75 0 0 1 8 2.25h8Zm0 1.5H8A4.25 4.25 0 0 0 3.75 8v3.2c0 4.36 3.07 8.1 7.34 8.96.6.12 1.22.12 1.82 0l.27-.06a9.13 9.13 0 0 0 7.07-8.9V8c0-2.35-1.9-4.25-4.25-4.25Zm-4 3c.41 0 .75.34.75.75v1.35c.92.26 1.64.98 1.9 1.9H16a.75.75 0 1 1 0 1.5h-1.35a2.76 2.76 0 0 1-1.9 1.9v1.35a.75.75 0 1 1-1.5 0v-1.35a2.76 2.76 0 0 1-1.9-1.9H8a.75.75 0 0 1 0-1.5h1.35c.26-.92.98-1.64 1.9-1.9V7.5c0-.41.34-.75.75-.75Zm.14 3.5h-.27a1.25 1.25 0 1 0 .27 0Z" />
        </Icon>
    );
};
