import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCheck: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="check">
			<path d="M19.47 5.97a.75.75 0 011.133.976l-.073.084-11 11a.75.75 0 01-.976.073l-.084-.073-5-5a.75.75 0 01.976-1.133l.084.073L9 16.439l10.47-10.47z" />
		</Icon>
	);
};
