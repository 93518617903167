import classNames from 'classnames';
import React, {FC} from 'react';
import {Container as ContainerComponent, ContainerProps as ContainerComponentProps} from 'react-bootstrap';
import styles from './Container.module.scss';

export type ContainerProps = ContainerComponentProps & {
    variant?: 'thin' | 'medium' | 'narrower' | 'narrow' | 'wide' | 'md-wide' | 'large';
};

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({variant, className, children, ...props}) => {
    return (
        <ContainerComponent
            {...props}
            className={classNames(className, {
                [styles[`container--${variant}`]]: variant,
            })}
        >
            {children}
        </ContainerComponent>
    );
};
