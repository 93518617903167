import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconLinkedIn: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="linkedin">
            <g fill="none" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                <path d="M8.649,10.574v4.909" />
                <path d="M11.92,15.483v-2.864c0,-1.13 0.915,-2.045 2.045,-2.045v0c1.13,0 2.045,0.915 2.045,2.045v2.864" />
                <path d="M8.647,7.608c-0.113,0 -0.205,0.092 -0.204,0.205c0,0.113 0.092,0.205 0.205,0.205c0.113,0 0.205,-0.092 0.205,-0.205c-0.001,-0.114 -0.093,-0.205 -0.206,-0.205" />
                <path d="M19.071,4.929c3.905,3.905 3.905,10.237 0,14.142c-3.905,3.905 -10.237,3.905 -14.142,0c-3.905,-3.905 -3.905,-10.237 0,-14.142c3.905,-3.905 10.237,-3.905 14.142,1.77636e-15" />
            </g>
        </Icon>
    );
};
