import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPictureAdd: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="picture-add">
			<path d="M11 2.25a.75.75 0 01.102 1.493L11 3.75H7a3.25 3.25 0 00-3.245 3.066L3.75 7v4.189l.015-.013a3.161 3.161 0 014.318-.143l.152.143 3.766 3.765.767-.765a3.161 3.161 0 014.324-.137l.146.137 3.006 3.005.006-.181v-4a.75.75 0 011.493-.102l.007.102v4a4.75 4.75 0 01-4.533 4.745L17 21.75H7a4.75 4.75 0 01-4.745-4.533L2.25 17V7a4.75 4.75 0 014.533-4.745L7 2.25h4zm2.947 12.879l-.118.108-5.015 5.013H17c1.138 0 2.14-.585 2.72-1.47l-3.542-3.543a1.661 1.661 0 00-2.231-.108zM6 11.75c-.385 0-.757.134-1.053.376l-.122.11-1.075 1.075V17a3.25 3.25 0 002.957 3.237l4.233-4.235-3.766-3.766A1.661 1.661 0 006 11.75zm12-10.5a4.75 4.75 0 110 9.5 4.75 4.75 0 010-9.5zm0 1.5a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM18 4a.75.75 0 01.743.648l.007.102v.5h.5a.75.75 0 01.102 1.493l-.102.007h-.5v.5a.75.75 0 01-1.493.102l-.007-.102v-.5h-.5a.75.75 0 01-.102-1.493l.102-.007h.5v-.5A.75.75 0 0118 4z" />
		</Icon>
	);
};
