import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import { Stack } from '../Stack';
import styles from './MenuSidebar.module.scss';
import { MenuSidebarFooter } from './MenuSidebarFooter';
import { MenuSidebarHeader } from './MenuSidebarHeader';

export const MenuSidebar: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.menuSidebar, 'menuSidebar', 'px-md-10 py-md-8 h-100')} rounded={false}>
			<Stack>{children}</Stack>
		</ContentBox>
	);
};

export default Object.assign(MenuSidebar, {
	Footer: MenuSidebarFooter,
	Header: MenuSidebarHeader,
});
