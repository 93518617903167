import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconProfile: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="profile">
			<path d="M14.553 14.812A5.188 5.188 0 0119.741 20a.741.741 0 11-1.482 0 3.706 3.706 0 00-3.51-3.7l-.196-.006H9.447A3.706 3.706 0 005.741 20a.741.741 0 11-1.482 0 5.188 5.188 0 014.969-5.184l.219-.004h5.106zm.983-10.098a5 5 0 11-7.072 7.072 5 5 0 017.072-7.072zm-6.01 1.061a3.5 3.5 0 105.09.15l-.141-.15-.15-.141a3.5 3.5 0 00-4.8.141z" />
		</Icon>
	);
};
