import {useReducer} from 'react';
import {LoginSidebar} from '@components/LoginSidebar';
import {Button} from '@components/Button';
import {Sidebar} from '@components/Sidebar';
import {Stack} from '@components/Stack';
import {VisuallyHidden} from '@components/VisuallyHidden';
import {Icon} from '@components/Icon';
import {FormattedMessage} from 'react-intl';
import {LoginForm} from '@liveComponents/Auth/LoginForm';
import {RegistrationForm} from '@liveComponents/Auth/RegistrationForm';
import {reducer} from '@liveComponents/Auth/reducer';
import {RegistrationEmailCheck} from '@liveComponents/Auth/RegistrationEmailCheck';
import {PasswordResetForm} from '@liveComponents/Auth/PasswordResetForm';
import {PasswordResetEmailCheck} from '@liveComponents/Auth/PasswordResetEmailCheck';
import {MenuSidebar} from '@components/MenuSidebar';
import {ButtonGroup, ButtonToolbar} from '@components/index';
import {LanguageSwitcher} from '@pageComponents/Layout/LanguageSwitcher';
import {PasswordResetEmailRegister} from '@liveComponents/Auth/PasswordResetEmailRegister';
import {TouchPointEnum} from '@liveComponents/Auth/utils';

export type SidebarLoginProps = {
    show: boolean;
    onHide: () => void;
};

export const SidebarLogin = ({show, onHide}: SidebarLoginProps) => {
    const [{page, flow}, dispatch] = useReducer(reducer, {flow: 'login', page: 'start'});

    return (
        <Sidebar specialVariant="login" show={show} onHide={onHide}>
            <LoginSidebar>
                <Stack direction="horizontal" className="mb-4 mb-md-4 d-none d-md-flex justify-content-end">
                    <Button variant="icon" size="sm" onClick={onHide}>
                        <VisuallyHidden>
                            <FormattedMessage defaultMessage="Zavřít" />
                        </VisuallyHidden>
                        <Icon.Close position="middle" />
                    </Button>
                </Stack>

                {flow === 'login' && page === 'start' && (
                    <LoginForm
                        id={TouchPointEnum.SideBar}
                        onSuccess={onHide}
                        onRegister={() => {
                            dispatch({type: 'change_flow', flow: 'registration'});
                        }}
                        onForgottenPassword={() => {
                            dispatch({type: 'change_flow', flow: 'reset_password'});
                        }}
                    />
                )}

                {flow === 'reset_password' && page === 'start' && (
                    <PasswordResetForm
                        onSuccess={() => {
                            dispatch({type: 'change_page', page: 'check_email'});
                        }}
                        onFailed={() => {
                            dispatch({type: 'change_page', page: 'email_failed'});
                        }}
                        onRegister={() => {
                            dispatch({type: 'change_flow', flow: 'registration'});
                        }}
                        onLogin={() => {
                            dispatch({type: 'change_flow', flow: 'login'});
                        }}
                    />
                )}

                {flow === 'reset_password' && page === 'check_email' && (
                    <PasswordResetEmailCheck
                        onClose={() => {
                            dispatch({type: 'change_flow', flow: 'login'});
                        }}
                    />
                )}

                {flow === 'reset_password' && page === 'email_failed' && (
                    <PasswordResetEmailRegister
                        onClose={() => {
                            dispatch({type: 'change_flow', flow: 'registration'});
                        }}
                    />
                )}

                {flow === 'registration' && page === 'start' && (
                    <RegistrationForm
                        id={TouchPointEnum.SideBar}
                        onSuccess={() => {
                            dispatch({type: 'change_page', page: 'check_email'});
                        }}
                        onLogin={() => {
                            dispatch({type: 'change_flow', flow: 'login'});
                        }}
                    />
                )}

                {flow === 'registration' && page === 'check_email' && (
                    <RegistrationEmailCheck
                        withoutButton={false}
                        onClose={() => {
                            dispatch({type: 'change_flow', flow: 'login'});
                            onHide();
                        }}
                    />
                )}

                {flow === 'login' && (
                    <MenuSidebar.Footer>
                        <ButtonToolbar className="mt-10 mt-md-12">
                            <ButtonGroup></ButtonGroup>

                            <ButtonGroup>
                                <LanguageSwitcher variant="button" />
                            </ButtonGroup>
                        </ButtonToolbar>
                    </MenuSidebar.Footer>
                )}
            </LoginSidebar>
        </Sidebar>
    );
};
