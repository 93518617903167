import {Advert, AdvertImportType, EstateType, Image, OfferType, User} from '.cache/__types__';
import {IconType} from '@components/Icon/Icon';
import {IntlShape} from 'react-intl';
import {LocaleEnum, LocaleType, nonNullArray, propertyRequiredArray} from 'src/types/general';
import {getLocalizedRoute, routes} from '@utils/routes';
import {DateTime} from 'luxon';
import {premiumPackageUri, premiumPackageUriSk} from '@constants/Package';
import {getNemoreportLandingUriFromAdvert, getNemoReportUrls, isNemoreportCompatible, isNemoreportFullData} from '@constants/Nemoreport';
import {NextRouter} from 'next/router';

export const fullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`.trim();

export const prepareImages = (images: Image[]) =>
    propertyRequiredArray(nonNullArray(images ?? []), ['url']).map((i) => ({src: i.url, alt: i.description ?? ''}));

type DashBoardTip = {iconName: IconType; href: string; linkTitle: string | string[]; desc: string; variant?: 'blue'};

export const getOwnerTips = (
    router: NextRouter,
    intl: IntlShape,
    locale: LocaleType,
    user: User,
    advertList: Advert[],
    conversationList: {advert: Partial<Advert>}[],
): DashBoardTip[] => {
    const tips: DashBoardTip[] = [];

    const now = DateTime.now();

    if (!user.phoneValid) {
        tips.push({
            iconName: 'CheckSquare' as IconType,
            href: getLocalizedRoute(routes.profile, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Ověřte své telefonní číslo'}),
            desc: intl.formatMessage({defaultMessage: 'Získejte možnost využívat služeb Bezrealitky naplno.'}),
        });
    }

    // Count of active adverts
    const activeAdverts = advertList.filter((advert) => advert.active);

    if (!activeAdverts.length) {
        // No Active Advert and registered last 2weeks
        if (user.timeCreated && DateTime.fromSeconds(user.timeCreated).plus({weeks: 2}) > now && locale !== LocaleEnum.sk) {
            tips.push({
                iconName: 'QuestionMarkCircle' as IconType,
                href: getLocalizedRoute(routes.propertyAssessment, locale),
                linkTitle: intl.formatMessage({defaultMessage: 'Zjistěte cenu vaší nemovitosti'}),
                desc: intl.formatMessage({defaultMessage: 'Získejte ihned, zdarma a nezávazně odhad ceny vaší nemovitosti.'}),
            });
        }

        // No Active Advert
        tips.push({
            iconName: 'DocumentHouse' as IconType,
            href: getLocalizedRoute(routes.insert, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Vložte inzerát'}),
            desc: intl.formatMessage({defaultMessage: 'Vytvořte inzerci a najděte vhodného zájemce.'}),
        });
    }

    // Activate adverts (new, never active)
    tips.push(
        ...advertList
            .filter((advert) => advert.new)
            .map((advert) => ({
                iconName: 'HandCheck' as IconType,
                href: getLocalizedRoute(routes.myAdverts, locale),
                linkTitle: intl.formatMessage({defaultMessage: 'Aktivujte svůj inzerát {number}'}, {number: advert.id}),
                desc: intl.formatMessage({defaultMessage: 'Váš inzerát zatím nebyl zaplacen a není vidět na webu.'}),
            })),
    );

    // NR missing user data
    tips.push(
        ...activeAdverts
            .filter((advert) => isNemoreportCompatible(advert) && !isNemoreportFullData(advert))
            .map((advert) => ({
                iconName: 'Edit' as const,
                href: getLocalizedRoute(routes.advertEdit, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Doplňte číslo bytové jednotky'}),
                desc: intl.formatMessage({defaultMessage: 'Zvyšte atraktivitu a důvěryhodnost v inzerátu {number}.'}, {number: advert.id}),
            })),
    );

    // Active adverts with less than 5 photos
    tips.push(
        ...activeAdverts
            .filter((advert) => (advert.publicImages?.length ?? 0) < 6)
            .map((advert) => ({
                iconName: 'PictureAdd' as IconType,
                href: getLocalizedRoute(routes.advertEdit, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Přidejte fotografie'}),
                desc: intl.formatMessage(
                    {defaultMessage: 'Zvyšte počet fotografií u svého inzerátu {number}. Získáte více zájemců.'},
                    {number: advert.id},
                ),
            })),
    );

    // Active adverts with no videos or panoramas
    /* Not showing as there is no way for user to upload it. Refs #17401
    tips.push(
        ...activeAdverts
            // .filter((advert) => !advert.videos?.length || !advert.tour360)
            .filter((advert) => !advert.tour360)
            .map((advert) => ({
                iconName: 'PictureAdd' as IconType,
                href: getLocalizedRoute(routes.advertEdit, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Přidejte virtuální prohlídku'}),
                desc: intl.formatMessage({defaultMessage: 'Zvyšte atraktivitu vašeho inzerátu {number}.'}, {number: advert.id}),
            })),
    );
    */

    // Activate adverts (new, never active)
    tips.push(
        ...advertList
            .filter((advert) => advert.incomplete)
            .map((advert) => ({
                iconName: 'Edit' as IconType,
                href: getLocalizedRoute(routes.advertEdit, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Přidejte další informace'}),
                desc: intl.formatMessage(
                    {defaultMessage: 'Doplňte v editaci inzerátu {number} co nejvíce informací o nemovitosti.'},
                    {number: advert.id},
                ),
            })),
    );

    const sellActiveAdverts = activeAdverts.filter((advert) => advert.offerType === OfferType.Prodej);
    const rentActiveAdverts = activeAdverts.filter((advert) => advert.offerType === OfferType.Pronajem);

    // Sell adverts active more then week
    const sellActiveAdvertsMoreThenWeek = sellActiveAdverts.filter(
        (advert) => advert.timeActivated && DateTime.fromSeconds(advert.timeActivated).plus({weeks: 1}) < now,
    );
    tips.push(
        ...sellActiveAdvertsMoreThenWeek.map((advert) => ({
            iconName: 'ArrowUp' as IconType,
            href: getLocalizedRoute(routes.advertHighLight, locale, {id: advert.id}),
            linkTitle: intl.formatMessage({defaultMessage: 'Propagujte svůj inzerát {number}'}, {number: advert.id}),
            desc: intl.formatMessage({defaultMessage: 'Získejte více zájemců díky topování nemovitosti.'}),
        })),
    );
    // Rent adverts active more then three days
    tips.push(
        ...rentActiveAdverts
            .filter((advert) => advert.timeActivated && DateTime.fromSeconds(advert.timeActivated).plus({days: 3}) < now)
            .map((advert) => ({
                iconName: 'ArrowUp' as IconType,
                href: getLocalizedRoute(routes.advertHighLight, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Propagujte svůj inzerát {number}'}, {number: advert.id}),
                desc: intl.formatMessage({defaultMessage: 'Získejte více zájemců díky topování nemovitosti.'}),
            })),
    );

    // Rent adverts active more then 2 days
    tips.push(
        ...rentActiveAdverts
            .filter(
                (advert) =>
                    advert.timeActivated &&
                    DateTime.fromSeconds(advert.timeActivated).plus({days: 2}) < now &&
                    advert.type === undefined &&
                    advert.estateType === EstateType.Byt,
            )
            .map(() => ({
                iconName: 'CheckSquare' as IconType,
                href: 'https://tvujspravce.cz',
                linkTitle: intl.formatMessage({defaultMessage: 'Zbavte se starostí s bytem'}),
                desc: intl.formatMessage({defaultMessage: 'Zajistíme kompletní správu vaší nemovitosti.'}),
            })),
    );

    // Extend activation of active advert
    tips.push(
        ...activeAdverts
            .filter((advert) => advert.timeExpiration && DateTime.fromSeconds(advert.timeExpiration).minus({week: 1}) < now)
            .map((advert) => ({
                iconName: 'Document' as IconType,
                href: getLocalizedRoute(routes.advertActivate, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Prodlužte si platnost inzerce'}),
                desc: intl.formatMessage(
                    {defaultMessage: 'Prodlužte si inzerci {number} již nyní za zvýhodněnou cenu.'},
                    {number: advert.id},
                ),
            })),
    );

    // 50:50
    const sellActiveAdverts3 = sellActiveAdverts.filter((advert) => advert.threesome);
    const blueSell = sellActiveAdverts3.length && sellActiveAdvertsMoreThenWeek.length ? {variant: ('blue' as const) || undefined} : {};
    const random = Math.random();

    const sellYourProperty = sellActiveAdvertsMoreThenWeek.length
        ? {
              iconName: 'DocumentCheck' as IconType,
              href: getLocalizedRoute(routes.serviceCentreComfort, locale),
              linkTitle: intl.formatMessage({defaultMessage: 'Prodejte svou nemovitost'}),
              desc: intl.formatMessage({defaultMessage: 'Pomůžeme vám prodat nemovitost rychle a za vyšší cenu.'}),
              ...blueSell,
          }
        : null;

    const legalService = sellActiveAdverts3.length
        ? {
              iconName: 'DocumentHouse' as IconType,
              href: getLocalizedRoute(routes.serviceCentreLegalService, locale),
              linkTitle: intl.formatMessage({defaultMessage: 'Zajistěte si advokátní služby'}),
              desc: intl.formatMessage({defaultMessage: 'Získejte do 5 pracovních dnů smlouvu zpracovanou advokátem.'}),
              ...blueSell,
          }
        : null;

    if (sellYourProperty && legalService) {
        if (random < 0.5) {
            tips.push(sellYourProperty);
        } else {
            tips.push(legalService);
        }
    } else if (sellYourProperty) {
        tips.push(sellYourProperty);
    } else if (legalService) {
        tips.push(legalService);
    }

    // 50:50
    const rentActiveAdverts5 = rentActiveAdverts.filter((advert) => advert.fivesome);
    const rentActiveAdvertsMoreThenWeek = rentActiveAdverts.filter(
        (advert) => advert.timeActivated && DateTime.fromSeconds(advert.timeActivated).plus({weeks: 1}) < now,
    );
    const blueRent =
        rentActiveAdverts5.length && locale !== LocaleEnum.en && rentActiveAdvertsMoreThenWeek.length && locale !== LocaleEnum.sk
            ? {variant: ('blue' as const) || undefined}
            : {};
    const rentYourProperty =
        rentActiveAdvertsMoreThenWeek.length && locale !== LocaleEnum.sk
            ? {
                  iconName: 'DocumentHouse' as IconType,
                  href: getLocalizedRoute(routes.serviceCentreGuaranteeRent, locale),
                  linkTitle: intl.formatMessage({defaultMessage: 'Pronajměte svou nemovitost'}),
                  desc: intl.formatMessage({defaultMessage: 'Pomůžeme vám pronajmout okamžitě a s garancí nájmu.'}),
                  ...blueRent,
              }
            : null;
    const rentContract =
        rentActiveAdverts5.length && locale !== LocaleEnum.en
            ? {
                  iconName: 'DocumentCheck' as IconType,
                  href: getLocalizedRoute(routes.serviceCentreContractRent, locale),
                  linkTitle: intl.formatMessage({defaultMessage: 'Vyřešte nájemní smlouvu'}),
                  desc: intl.formatMessage({defaultMessage: 'Získejte do 5ti minut smlouvu zpracovanou advokátem.'}),
                  ...blueSell,
              }
            : null;

    if (rentYourProperty && rentContract) {
        if (random < 0.5) {
            tips.push(rentYourProperty);
        } else {
            tips.push(rentContract);
        }
    } else if (rentYourProperty) {
        tips.push(rentYourProperty);
    } else if (rentContract) {
        tips.push(rentContract);
    }

    if (activeAdverts.some((advert) => advert.reserved && locale === LocaleEnum.cs)) {
        tips.push({
            iconName: 'DocumentEnergy' as IconType,
            href: getLocalizedRoute(routes.serviceCentreEnergy, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Vyřešte přepis energií'}),
            desc: intl.formatMessage({defaultMessage: 'Přepište energie rychle a z pohodlí domova.'}),
        });
    }

    // NR conversations
    conversationList
        .map((c) => c.advert)
        .forEach((advert) => {
            if (isNemoreportFullData(advert)) {
                const {refererUrl, advertUrl} = getNemoReportUrls(router, locale, user, advert);
                tips.push({
                    iconName: 'Lightbulb' as const,
                    href: getNemoreportLandingUriFromAdvert(
                        advert,
                        intl,
                        user.email && user.id ? {email: user.email, id: user.id} : undefined,
                        refererUrl,
                        advertUrl,
                    ),
                    linkTitle: intl.formatMessage({defaultMessage: 'Prověřte si nemovitost {number}'}, {number: advert.id}),
                    desc: intl.formatMessage({defaultMessage: 'Vygenerujte si report k nemovitosti a odhalte případná rizika před koupí.'}),
                });
            }
        });

    return tips;
};

export const getTakerTips = (
    router: NextRouter,
    intl: IntlShape,
    locale: LocaleType,
    user: User,
    advertList: Advert[],
    conversationList: {advert: Partial<Advert>}[],
): DashBoardTip[] => {
    const tips: DashBoardTip[] = [];

    const now = DateTime.now();

    if (!user) {
        return [];
    }

    if (!user.premium) {
        tips.push({
            iconName: 'CheckSquare' as IconType,
            href: getLocalizedRoute(routes.serviceCentrePremium, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Získejte Premium profil'}),
            desc: intl.formatMessage({defaultMessage: 'Zvyšte šance na získání vysněného bydlení.'}),
        });
    }

    if (user.premium && user.timeAccountExpiration && DateTime.fromSeconds(user.timeAccountExpiration).minus({week: 1}) < now) {
        tips.push({
            iconName: 'CheckSquare' as IconType,
            href: getLocalizedRoute(routes.paymentPackage, locale, {
                uri: locale === LocaleEnum.sk ? premiumPackageUriSk : premiumPackageUri,
            }),
            linkTitle: intl.formatMessage({defaultMessage: 'Prodlužte si platnost Premium profilu'}),
            desc: intl.formatMessage({defaultMessage: 'Zvyšte šance na získání vysněného bydlení.'}),
        });
    }

    const isMissingBasicInfo =
        !user.firstname ||
        !user.lastname ||
        !user.email ||
        !user.phone ||
        !user.street ||
        !user.streetNumber ||
        !user.city ||
        !user.zip ||
        (user.premium && (!user.yearBorn || !user.familyMembers || !user.workplace || !user.education));

    if (isMissingBasicInfo) {
        tips.push({
            iconName: 'Edit' as IconType,
            href: getLocalizedRoute(routes.profileBasicInfo, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Vyplňte si osobní profil'}),
            desc: intl.formatMessage({defaultMessage: 'Zvyšte své šance na to, že váš profil zaujme majitele.'}),
        });
    }

    if (user.premium && !user.creditcheck) {
        tips.push({
            iconName: 'CheckSquare' as IconType,
            href: getLocalizedRoute(routes.profile, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Ověřte svou bezdlužnost'}),
            desc: intl.formatMessage({defaultMessage: 'Ukažte majitelům, že jste solidní zájemce.'}),
        });
    }

    if (!parseInt(user.watchdogCount ?? '0')) {
        tips.push({
            iconName: 'Watchdog' as IconType,
            href: getLocalizedRoute(routes.watchdogEdit, locale, {id: 'new'}),
            linkTitle: intl.formatMessage({defaultMessage: 'Nastavte si hlídacího psa'}),
            desc: intl.formatMessage({defaultMessage: 'Nechte hledání nemovitostí na našem psovi.'}),
        });
    }

    if (user.tipInfo?.respondedToSale) {
        if (locale === LocaleEnum.cs) {
            tips.push({
                iconName: 'Market' as IconType,
                href: getLocalizedRoute(routes.serviceCentreMortgage, locale, {reload: true}),
                linkTitle: intl.formatMessage({defaultMessage: 'Spočítejte si hypotéku'}),
                desc: intl.formatMessage({defaultMessage: 'Využijte největší srovnávač a najděte si tu nejvýhodnější hypotéku.'}),
            });
        }

        if (locale !== LocaleEnum.sk) {
            tips.push({
                iconName: 'DocumentHouse' as IconType,
                href: getLocalizedRoute(routes.serviceCentreLegalService, locale),
                linkTitle: intl.formatMessage({defaultMessage: 'Vyřešte právní servis'}),
                desc: intl.formatMessage({defaultMessage: 'Nechte si připravit či zrevidovat kupní smlouvu advokátem.'}),
            });
        }
    }

    if (user.tipInfo?.hasReservedAdvert && locale === LocaleEnum.cs) {
        tips.push({
            iconName: 'DocumentEnergy' as IconType,
            href: getLocalizedRoute(routes.serviceCentreEnergy, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Vyřešte přepis energií'}),
            desc: intl.formatMessage({defaultMessage: 'Přepište energie rychle a z pohodlí domova.'}),
        });

        tips.push({
            iconName: 'Internet' as IconType,
            href: 'https://www.o2.cz/osobni/5gnadoma?cid=dbm-q1_Internet99adres_20m01-20m03-direct_23669309-dcom-6023309-270337666-464935868-130403663',
            linkTitle: intl.formatMessage({defaultMessage: 'Připojte se k internetu'}),
            desc: intl.formatMessage({defaultMessage: 'Získejte rychlý a spolehlivý 5G internet.'}),
        });
    }

    if (user.tipInfo?.respondedToRent) {
        tips.push({
            iconName: 'DocumentHouse' as IconType,
            href: getLocalizedRoute(routes.serviceCentreLegalService, locale),
            linkTitle: intl.formatMessage({defaultMessage: 'Vyřešte nájemní smlouvu'}),
            desc: intl.formatMessage({defaultMessage: 'Nechte si připravit či zrevidovat nájemní smlouvu advokátem.'}),
        });
    }

    // NR missing user data
    tips.push(
        ...advertList
            .filter((advert) => isNemoreportCompatible(advert) && !isNemoreportFullData(advert))
            .map((advert) => ({
                iconName: 'Edit' as const,
                href: getLocalizedRoute(routes.advertEdit, locale, {id: advert.id}),
                linkTitle: intl.formatMessage({defaultMessage: 'Doplňte číslo bytové jednotky'}),
                desc: intl.formatMessage({defaultMessage: 'Zvyšte atraktivitu a důvěryhodnost v inzerátu {number}.'}, {number: advert.id}),
            })),
    );

    // NR conversations
    conversationList
        .map((c) => c.advert)
        .forEach((advert) => {
            if (isNemoreportFullData(advert)) {
                const {refererUrl, advertUrl} = getNemoReportUrls(router, locale, user, advert);
                tips.push({
                    iconName: 'Lightbulb' as const,
                    href: getNemoreportLandingUriFromAdvert(
                        advert,
                        intl,
                        user.email && user.id ? {email: user.email, id: user.id} : undefined,
                        refererUrl,
                        advertUrl,
                    ),
                    linkTitle: intl.formatMessage({defaultMessage: 'Prověřte si nemovitost {number}'}, {number: advert.id}),
                    desc: intl.formatMessage({defaultMessage: 'Vygenerujte si report k nemovitosti a odhalte případná rizika před koupí.'}),
                });
            }
        });

    return tips;
};

type AdvertShowPGR = {
    id?: string | null;
    active?: boolean | null;
    timeActivated?: number | null;
    project?: {id?: string | null} | null;
    estateType?: EstateType | null;
    offerType?: OfferType | null;
    roommate?: boolean | null;
    isTSRegion?: boolean | null;
    type?: AdvertImportType | null;
};

export const showPromoGuaranteeRent = (advert?: AdvertShowPGR, activeOnly = false, daysActive = 0): boolean => {
    let activeOnlyCondition = true;
    let daysActiveCondition = true;

    if (activeOnly) {
        activeOnlyCondition = !!advert?.active;
    }

    if (daysActive > 0 && advert?.timeActivated) {
        daysActiveCondition = DateTime.fromSeconds(advert.timeActivated).plus({days: daysActive}) < DateTime.now();
    }

    return (
        daysActiveCondition &&
        activeOnlyCondition &&
        !advert?.project &&
        advert?.estateType === EstateType.Byt &&
        advert.offerType == OfferType.Pronajem &&
        !advert.roommate &&
        !!advert.isTSRegion &&
        advert.type === AdvertImportType.Undefined
    );
};

//     {
//     },
//     {
//         iconName: 'Calendar' as IconType,
//         href: '#',
//         linkTitle: intl.formatMessage({defaultMessage: 'Přidejte fotografie'}),
//         desc: intl.formatMessage({defaultMessage: 'Přidejte více fotek do vašeho inzerátu. Získáte více zájemců.'}),
//     },
//     {
//         iconName: 'Calendar' as IconType,
//         href: getLocalizedRoute(routes.serviceCentreContractRent, locale),
//         linkTitle: [intl.formatMessage({defaultMessage: 'Ověřte svůj profil'}), intl.formatMessage({defaultMessage: '{premium_price, number, ::currency/CZK}', premium_price: pkg.price})],
//         desc: intl.formatMessage({defaultMessage: 'Doplňte svůj profil a staňte se ověřeným uživatelem.'}),
//         variant: 'blue' as 'blue' | undefined,
//     },
