import {Icon, IconProps} from '.';

export const IconQuickReply = ({...props}: IconProps) => {
    return (
        <Icon {...props} id="quickreply">
            <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                <path d="M10.042,17.885l10.28,-10.28c0.781,-0.781 0.781,-2.047 0,-2.828l-1.122,-1.123c-0.781,-0.781 -2.047,-0.781 -2.828,0l-10.281,10.281c-0.146,0.146 -0.268,0.314 -0.363,0.497l-2.63,5.096c-0.446,0.865 0.481,1.793 1.346,1.348l5.099,-2.626c0.185,-0.096 0.353,-0.218 0.499,-0.365Z"></path>
                <path d="M5.91,14.19l3.88,3.88"></path>
                <path d="M18,21h-13.96"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
        </Icon>
    );
};
