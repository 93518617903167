import classNames from 'classnames';
import React, { FC } from 'react';
import { FormSelect as FormSelectComponent, FormSelectProps as FormSelectComponentProps } from 'react-bootstrap';
import styles from './Select.module.scss';

export type FormSelectProps = FormSelectComponentProps & {
	variant?: 'blank';
};

export const FormSelect: FC<React.PropsWithChildren<FormSelectProps>> = ({ variant, ...props }) => {
	return (
		<FormSelectComponent
			{...props}
			className={classNames(styles.select, props.className, {
				[styles[`select--${variant}`]]: variant,
			})}
		/>
	);
};
