import classNames from 'classnames';
import React, {forwardRef} from 'react';
import {Button as ButtonComponent, ButtonProps as ButtonComponentProps} from 'react-bootstrap';

export type ButtonProps = Omit<ButtonComponentProps, 'size'> & {
    size?: 'sm' | 'md' | 'lg';
    narrow?: boolean;
    wide?: boolean;
    blank?: boolean;
    withSeparator?: boolean;
    sideToSide?: boolean;
    circle?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({size, narrow, wide, blank, withSeparator, sideToSide, circle, className, ...props}, ref) => {
        return (
            <ButtonComponent
                ref={ref}
                {...props}
                className={classNames(className, {
                    [`btn-${size}`]: size,
                    [`btn-narrow`]: narrow,
                    [`btn-wide`]: wide,
                    [`btn-blank`]: blank,
                    [`btn-separator`]: withSeparator,
                    [`btn-side-to-side`]: sideToSide,
                    [`btn-circle`]: circle,
                })}
            />
        );
    },
);
