import {ComponentProps, createContext, PropsWithChildren, ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {ToastContainer, Toast as ToastComponent} from '@components/Toast';
import {useIntl} from 'react-intl';
import {Typography} from '@components/Typography';

type ToastProps = ComponentProps<typeof ToastComponent>;
type TypographyProps = ComponentProps<typeof Typography>;

type Toast = {
    id: number;
    show: boolean;
    autohide?: boolean;
    delay?: number;
    header: ReactNode;
    message: ReactNode;
    bg?: ToastProps['bg'];
    color?: TypographyProps['color'];
};

export type ToastConfig = {
    duration?: number;
    header: ReactNode;
    message: ReactNode;
    bg?: ToastProps['bg'];
    color?: TypographyProps['color'];
};

export type ToastContextValue = {
    toast: (config: ToastConfig) => void;
    success: (config: PredefinedToastConfig) => void;
    error: (config: PredefinedToastConfig) => void;
};

export type PredefinedToastConfig = Pick<ToastConfig, 'duration' | 'message' | 'header'>;

const ToastContext = createContext<ToastContextValue>({
    toast: () => null,
    success: () => null,
    error: () => null,
});

export const ToastProvider = ({children}: PropsWithChildren) => {
    const [toasts, setToasts] = useState<Toast[]>([]);
    const intl = useIntl();

    const handleClose = useCallback((id: any) => {
        setToasts((arr) => {
            const newArr = arr.slice(0);
            const idx = newArr.findIndex((t) => t.id === id);
            newArr[idx].show = false;
            return newArr;
        });
    }, []);

    const handleAdd = useCallback((config: ToastConfig) => {
        setToasts((arr) => {
            const newArr = arr.slice(0);
            const last = newArr.at(-1);
            newArr.push({
                id: last ? last.id + 1 : 0,
                message: config.message,
                header: config.header,
                show: true,
                autohide: !!config.duration && config.duration > 0,
                delay: config.duration ?? undefined,
                color: config.color,
                bg: config.bg,
            });

            return newArr;
        });
    }, []);

    const handleSuccess = useCallback(
        (config: PredefinedToastConfig) => {
            handleAdd({
                ...config,
                bg: 'success',
                color: 'green',
            });
        },
        [handleAdd],
    );

    const handleError = useCallback(
        (config: PredefinedToastConfig) => {
            handleAdd({
                ...config,
                bg: 'danger',
                color: 'red',
            });
        },
        [handleAdd],
    );

    return (
        <ToastContext.Provider
            value={useMemo(
                () => ({
                    toast: handleAdd,
                    success: handleSuccess,
                    error: handleError,
                }),
                [handleAdd, handleError, handleSuccess],
            )}
        >
            {children}

            <ToastContainer position="bottom-end" className="position-fixed">
                {toasts.map((toast) => (
                    <ToastComponent
                        key={toast.id}
                        show={toast.show}
                        autohide={toast.autohide}
                        delay={toast.delay}
                        onClose={() => {
                            handleClose(toast.id);
                        }}
                        bg={toast.bg}
                    >
                        <ToastComponent.Header closeLabel={intl.formatMessage({defaultMessage: 'Zavřít'})}>
                            <Typography as="strong" className="me-auto pe-3" color={toast.color}>
                                {toast.header}
                            </Typography>
                        </ToastComponent.Header>
                        <ToastComponent.Body>{toast.message}</ToastComponent.Body>
                    </ToastComponent>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};

export const useToaster = () => {
    return useContext(ToastContext);
};
