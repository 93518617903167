import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHospital: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hospital">
			<path d="M15.33 2.236a6.42 6.42 0 016.416 6.189l.004.23v6.661a6.42 6.42 0 01-6.189 6.416l-.23.004H8.669a6.419 6.419 0 01-6.415-6.189l-.004-.23V8.655A6.419 6.419 0 018.439 2.24l.23-.004h6.661zm0 1.5H8.669a4.919 4.919 0 00-4.914 4.706l-.005.213v6.662a4.919 4.919 0 004.706 4.914l.213.005h6.662a4.92 4.92 0 004.914-4.707l.005-.213V8.655a4.92 4.92 0 00-4.707-4.914l-.213-.005zM9.5 6.25a1.75 1.75 0 011.744 1.607L11.25 8v1.5h1.5V8a1.75 1.75 0 011.459-1.726l.148-.018.143-.006H16a1.75 1.75 0 011.744 1.607L17.75 8v8a1.75 1.75 0 01-1.607 1.744L16 17.75h-1.5a1.75 1.75 0 01-1.744-1.607L12.75 16v-1.5h-1.5V16a1.75 1.75 0 01-1.459 1.726l-.148.018-.143.006H8a1.75 1.75 0 01-1.744-1.607L6.25 16V8a1.75 1.75 0 011.607-1.744L8 6.25h1.5zm0 1.5H8a.25.25 0 00-.243.193L7.75 8v8a.25.25 0 00.193.243L8 16.25h1.5a.25.25 0 00.243-.193L9.75 16v-2.25a.75.75 0 01.648-.743L10.5 13h3a.75.75 0 01.743.648l.007.102V16a.25.25 0 00.193.243l.057.007H16a.25.25 0 00.243-.193L16.25 16V8a.25.25 0 00-.193-.243L16 7.75h-1.5a.25.25 0 00-.243.193L14.25 8v2.25a.75.75 0 01-.648.743L13.5 11h-3a.75.75 0 01-.743-.648l-.007-.102V8a.25.25 0 00-.193-.243L9.5 7.75z" />
		</Icon>
	);
};
