import React, {FC} from 'react';
import {useLocale} from '@utils/locale';
import {LocaleEnum} from '../../types/general';

import CS from './cs';
import EN from './en';
import SK from './sk';

export const IntlProvider: FC<React.PropsWithChildren> = ({children}) => {
    const locale = useLocale();

    switch (locale) {
        default:
        case LocaleEnum.cs:
            return <CS>{children}</CS>;
        case LocaleEnum.sk:
            return <SK>{children}</SK>;
        case LocaleEnum.en:
            return <EN>{children}</EN>;
    }
};
