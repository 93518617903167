import {LocaleEnum, LocaleType} from "../types/general";

export const getTermsUriForLocale = (locale: LocaleType) => {
    switch(locale) {
        case LocaleEnum.sk: return 'zmluvne-podmienky';
        case LocaleEnum.en: return 'terms-and-conditions';
        default: return 'smluvni-podminky';
    }
}

export const getCookiesUriForLocale = (locale: LocaleType) => {
    switch(locale) {
        case LocaleEnum.sk: return 'cookies';
        case LocaleEnum.en: return 'privacy-policy ';
        default: return 'cookies';
    }
}
