import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconUserCheckSquare: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="user-check-square">
            <path d="M17.5 12.75a4.75 4.75 0 1 1 0 9.5 4.75 4.75 0 0 1 0-9.5Zm-1.5-11c2.9 0 5.25 2.35 5.25 5.25v3.5a.75.75 0 1 1-1.5 0V7A3.75 3.75 0 0 0 16 3.25H7A3.75 3.75 0 0 0 3.25 7v9A3.75 3.75 0 0 0 7 19.75h3.5a.75.75 0 1 1 0 1.5H7A5.25 5.25 0 0 1 1.75 16V7C1.75 4.1 4.1 1.75 7 1.75h9Zm1.5 12.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5Zm1.78 2.1c.3.3.3.77 0 1.07l-1.5 1.5c-.3.29-.77.29-1.06 0l-1-1a.75.75 0 0 1 1.06-1.06l.47.47.97-.97c.3-.3.77-.3 1.06 0Zm-7.78-3.6a.75.75 0 1 1 0 1.5h-3c-.65 0-1.18.5-1.24 1.12l-.01.13a.75.75 0 1 1-1.5 0 2.75 2.75 0 0 1 2.75-2.75h3ZM10 6.25a2.75 2.75 0 0 1 .17 5.5H10a2.75 2.75 0 1 1 0-5.5Zm4.5 0a2.75 2.75 0 0 1 .17 5.5h-.17a.75.75 0 0 1-.1-1.5h.1a1.25 1.25 0 0 0 .13-2.5h-.13a.75.75 0 1 1 0-1.5ZM10 7.75a1.25 1.25 0 1 0 .13 0H10Z" />
        </Icon>
    );
};
