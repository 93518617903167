import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowUp: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-up">
			<path d="M12 19.75a.75.75 0 00.743-.648L12.75 19V6.812l3.72 3.72a.75.75 0 00.977.072l.084-.073a.75.75 0 00.073-.976l-.073-.084-5-5.001a.753.753 0 00-.12-.097l-.09-.05-.103-.04A.747.747 0 0012 4.25l-.057.002-.09.013-.038.008-.106.035-.069.034-.086.055a.785.785 0 00-.084.073l-5.001 5a.75.75 0 00.976 1.134l.084-.073 3.721-3.719V19c0 .414.336.75.75.75z" />
		</Icon>
	);
};
