import {Container} from '@components/Container';
import {Typography} from '@components/Typography';
import {FormattedMessage} from 'react-intl';

export const MaintenanceWarning = () => (
    <div className="bg-warning py-3" data-nosnippet>
        <Container className="text-center">
            <Typography as="span" textAlign="center" variant="caption">
                <FormattedMessage defaultMessage="Vypadá to, že náš server prochází údržbou nebo jste offline. Některé funkce webu mohou být dočasně nedostupné." />
            </Typography>
        </Container>
    </div>
);
