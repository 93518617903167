import {FC, useEffect} from 'react';
import {useToaster} from '@utils/toast';
import {FormattedMessage} from 'react-intl';

export const WorkerUpdateWatcher: FC = () => {
    const toaster = useToaster();

    useEffect(() => {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
            const wb = window.workbox;

            const refreshHandler = () => {
                // Send a message to the waiting service worker, instructing it to activate.
                wb.messageSkipWaiting();
            };

            // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
            // NOTE: MUST set skipWaiting to false in next.config.js pwa object
            // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
            const promptNewVersionAvailable = () => {
                wb.addEventListener('controlling', () => {
                    window.location.reload();
                });
                // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
                // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
                // You may want to customize the UI prompt accordingly.
                toaster.success({
                    header: <FormattedMessage defaultMessage={'Novější verze aplikace je k dispozici'} />,
                    message: (
                        <FormattedMessage
                            defaultMessage={'Pro načtení nové verze <refresh>obnovte stránku</refresh>'}
                            values={{
                                refresh: (chunks: any) => (
                                    <a className={'clickable'} onClick={refreshHandler}>
                                        {chunks}
                                    </a>
                                ),
                            }}
                        />
                    ),
                    duration: 10000,
                });
            };

            wb.addEventListener('waiting', promptNewVersionAvailable);

            return () => {
                wb.removeEventListener('waiting', promptNewVersionAvailable);
            };
        }
    }, [toaster]);

    return null;
};
