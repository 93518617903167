import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconWheelchair: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="wheelchair">
            <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" fill="none" stroke="#323232">
                <circle cx="8" cy="16" r="5" />
                <circle cx="8" cy="16" r="2" />
                <path d="M21,20H19.39039a.5.5,0,0,1-.48507-.37873l-1.52659-6.10634A2,2,0,0,0,15.43845,12H11" />
                <line x1="16" y1="8" x2="7" y2="8" />
                <line x1="14" y1="12" x2="14" y2="8" />
                <path d="M4,3H5A2,2,0,0,1,7,5v6" />
                <line x1="18.5" y1="18" x2="12.58263" y2="18" />
            </g>
        </Icon>
    );
};
