import {LocaleEnum, LocaleType} from '@localTypes/general';

export const getLanguageSelectorText = (locale: LocaleType) => {
    switch (locale) {
        case LocaleEnum.cs:
            return '🇨🇿 Česky';
        case LocaleEnum.sk:
            return '🇸🇰 Slovenčina';
        case LocaleEnum.en:
            return '🇬🇧 English';
    }
};
