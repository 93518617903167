import React, {FC, forwardRef} from 'react';
import {Button, ButtonProps} from '../Button';
import styles from './Header.module.scss';
import classNames from 'classnames';

export const HeaderButton: FC<React.PropsWithChildren<ButtonProps>> = forwardRef<HTMLButtonElement, ButtonProps>(
    ({children, ...props}, ref) => {
        return (
            <Button {...props} variant="outline-dark" size="sm" className={classNames(styles.headerButton, props.className)} ref={ref}>
                {children}
            </Button>
        );
    },
);
