import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTrash: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="thrash">
			<path d="M14.813 2.25c.986 0 1.794.761 1.869 1.728l.006.147-.001 1.5H20a.75.75 0 01.102 1.493L20 7.125h-.556l-.911 11.855a3 3 0 01-2.812 2.765l-.18.005H8.46a3 3 0 01-2.973-2.591l-.019-.179-.912-11.855H4a.75.75 0 01-.102-1.493L4 5.625h3.312v-1.5c0-.986.762-1.795 1.729-1.87l.146-.005h5.626zm3.127 4.875H6.059l.904 11.74a1.5 1.5 0 001.225 1.36l.134.019.137.006h7.082a1.5 1.5 0 001.48-1.249l.016-.136.903-11.74zm-3.971 3a.75.75 0 01.743.648l.007.102V16.5a.75.75 0 01-1.493.102l-.007-.102v-5.625a.75.75 0 01.75-.75zm-3.938 0a.75.75 0 01.743.648l.007.102V16.5a.75.75 0 01-1.493.102l-.007-.102v-5.625a.75.75 0 01.75-.75zm4.781-6.375H9.189a.375.375 0 00-.368.3l-.008.075v1.5h6.375v-1.5a.375.375 0 00-.229-.346l-.07-.021-.075-.008z" />
		</Icon>
	);
};
