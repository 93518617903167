import React, { FC, ReactNode, useContext } from 'react';
import { Nav } from '../Nav';
import { NavLinkProps } from 'react-bootstrap';
import classNames from 'classnames';
import { Typography, TypographyProps } from '../Typography';
import styles from './TabsNav.module.scss';
import { TabsNavContext } from './TabsNavContext';

const VARIANTS: Record<string, TypographyProps> = {
	default: {
		textTransform: 'uppercase',
		variant: 'caption',
		letterSpacing: true,
		fontWeight: 'medium',
	},
	pricelist: {
		variant: 'perex',
		fontWeight: 'semibold',
	},
};

type TabsNavButtonProps = NavLinkProps & {
	activeUnderlineColor?: 'blue' | 'green';
	additionalInfo?: ReactNode;
};

export const TabsNavButton: FC<React.PropsWithChildren<TabsNavButtonProps>> = ({ activeUnderlineColor, additionalInfo, children, ...props }) => {
	const { variant } = useContext(TabsNavContext);
	const variantProps = variant ? VARIANTS[variant] : VARIANTS.default;

	return (
		<>
			<Nav.Link
				{...props}
				className={classNames(
					styles.tabsNavButton,
					{
						[styles[`tabsNavButton--${activeUnderlineColor}`]]: activeUnderlineColor,
					},
					additionalInfo ? 'flex-column text-center' : null,
				)}
			>
				<Typography {...variantProps} as="span">
					{children}
				</Typography>
				{additionalInfo ? additionalInfo : null}
			</Nav.Link>
		</>
	);
};
