import {ComponentProps, FC} from 'react';
import {PageFooter} from './PageFooter';
import {PageHeader} from './PageHeader';

type LayoutProps = {
    withFooter?: boolean;
} & ComponentProps<typeof PageFooter> &
    ComponentProps<typeof PageHeader>;

export const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({
    children,
    withFooter = true,
    withHeaderBorder = false,
    contactBoxVariant,
    withChatBot,
    headerVariant,
}) => (
    <>
        <PageHeader withHeaderBorder={withHeaderBorder} headerVariant={headerVariant} />
        {children}
        {withFooter && <PageFooter contactBoxVariant={contactBoxVariant} withChatBot={withChatBot} />}
    </>
);
