import {Icon, IconProps} from '.';

export const IconDocumentSearch = ({...props}: IconProps) => {
    return (
        <Icon {...props} id="document-seach">
            <path
                d="M19.47489,3.52514a3.5,3.5,0,1,1-4.94978,0,3.5,3.5,0,0,1,4.94978,0l0,0h0"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                x1="19.5"
                y1="8.5"
                x2="21"
                y2="10"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="M21,14v4a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3h4"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                x1="13"
                y1="12.5"
                x2="8"
                y2="12.5"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                x1="8"
                y1="16"
                x2="16"
                y2="16"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <line
                x1="8"
                y1="9"
                x2="10"
                y2="9"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </Icon>
    );
};
