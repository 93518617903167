import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTag: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="tag">
			<path d="M15.006 4.252a2.751 2.751 0 012.746 2.583l.005.168v3.56c0 .662-.239 1.3-.67 1.798l-.134.145-6.493 6.503a2.55 2.55 0 01-3.46.14l-.148-.138-3.853-3.85a2.55 2.55 0 01-.137-3.46l.137-.149 6.498-6.495a2.75 2.75 0 011.746-.798l.198-.007h3.565zm6.003 3.001a.75.75 0 01.743.649l.007.101v3.8a2.75 2.75 0 01-.67 1.8l-.136.144-5.665 5.665a.75.75 0 01-1.133-.976l.073-.085 5.664-5.664a1.25 1.25 0 00.359-.744l.008-.14v-3.8a.75.75 0 01.75-.75zm-6.003-1.5h-3.565c-.284 0-.558.096-.779.271l-.105.094-6.498 6.495a1.05 1.05 0 00-.091 1.382l.091.104 3.853 3.851a1.05 1.05 0 001.383.09l.104-.09 6.492-6.503c.201-.201.326-.464.358-.743l.008-.141v-3.56c0-.648-.492-1.18-1.123-1.244l-.128-.007z" />
		</Icon>
	);
};
