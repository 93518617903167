import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './ContactBox.module.scss';
import { ContactBoxHeading } from './ContactBoxHeading';
import { ContactBoxLink } from './ContactBoxLink';
import { ContactBoxLinkDesc } from './ContactBoxLinkDesc';

export const ContactBox: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={classNames(styles.contactBox, 'contactBox mb-last-0')}>{children}</div>;
};

export default Object.assign(ContactBox, {
	Heading: ContactBoxHeading,
	Link: ContactBoxLink,
	LinkDesc: ContactBoxLinkDesc,
});
