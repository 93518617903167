import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconLegalScale: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="legal-scale">
            <path d="M11.2 3.08c.42 0 .75.34.75.75v2.8l5.46-1.6.05-.01.1-.01h.16l.05.01.04.01c.17.05.33.17.44.34l3.81 6.46.08.15c.04.07.08.15.1.23l.05.16.01.04a2 2 0 0 1 .06.41V13a4.9 4.9 0 0 1-4.78 4.63A4.9 4.9 0 0 1 12.86 13c-.01-.18 0-.36.03-.53v.08c0-.08.01-.16.04-.24.05-.17.12-.34.22-.5l2.84-4.81-4.6 1.35-3.85 1.13 3.31 5.62.09.15.07.17.08.21.02.1c.02.12.04.25.04.37v.17a4.9 4.9 0 0 1-4.78 4.63 4.9 4.9 0 0 1-4.72-4.62 2.04 2.04 0 0 1 .3-1.18l3.78-6.4c.04-.09.1-.16.16-.22l.08-.07.08-.04.09-.04 4.31-1.27V3.83c0-.4.34-.75.75-.75ZM9.6 16.6H3.2a3.38 3.38 0 0 0 3.18 2.82A3.4 3.4 0 0 0 9.6 16.6Zm11.21-3.28H14.4a3.38 3.38 0 0 0 3.18 2.82 3.4 3.4 0 0 0 3.23-2.82Zm-14.4-2.8L3.7 15.1h5.4L6.4 10.5Zm13.9 1.3-2.7-4.58-2.71 4.58h5.4Z" />
        </Icon>
    );
};
