import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconEnvelopes: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="envelopes">
            <g fill="none" data-name="Layer 2">
                <path d="M0 0h24v24H0z" />
                <rect
                    width={15}
                    height={12}
                    x={6.5}
                    y={3.5}
                    stroke="#323232"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    rx={3.5}
                />
                <path
                    stroke="#323232"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="m10 7.5 3.018 1.297a2.5 2.5 0 0 0 1.967.003L18 7.515M17.5 15.5V17a3.5 3.5 0 0 1-3.5 3.5H6A3.5 3.5 0 0 1 2.5 17v-5A3.5 3.5 0 0 1 6 8.5h.5"
                />
            </g>
        </Icon>
    );
};
