import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMenu: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="menu">
			<path d="M18.503 15.254a.75.75 0 01.101 1.492l-.101.007h-13a.75.75 0 01-.102-1.492l.101-.007h13zm0-4a.75.75 0 01.101 1.492l-.101.007h-13A.75.75 0 015.4 11.26l.101-.006h13zm0-4a.75.75 0 01.101 1.492l-.102.007h-13A.75.75 0 015.4 7.26l.102-.007h13z" />
		</Icon>
	);
};
