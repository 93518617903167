import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowDown: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-down">
			<path d="M12 4.25a.75.75 0 01.743.648L12.75 5v12.188l3.72-3.72a.75.75 0 01.977-.072l.084.073a.75.75 0 01.073.976l-.073.084-5 5.001a.753.753 0 01-.12.097l-.09.05-.103.04a.747.747 0 01-.218.033l-.057-.002-.09-.013-.038-.008-.106-.035-.069-.034-.086-.055a.785.785 0 01-.084-.073l-5.001-5a.75.75 0 01.976-1.134l.084.073 3.721 3.719V5a.75.75 0 01.75-.75z" />
		</Icon>
	);
};
