import {AdvertImportType} from '.cache/__types__';

export const importTypeToTerms: Record<AdvertImportType, string> = {
    [AdvertImportType.TvujSpravce]: 'https://www.bezrealitky.cz/informace/smluvni-podminky/informacni-povinnost-inzerce-tvujspravce',
    [AdvertImportType.Maxima]: 'https://www.bezrealitky.cz/informace/smluvni-podminky/informacni-povinnost-inzerce-maxima',
    [AdvertImportType.DokonalyNajemnik]:
        'https://www.bezrealitky.cz/informace/smluvni-podminky/informacni-povinnost-inzerce-dokonalynajemnik',
    [AdvertImportType.Heimstaden]: 'https://www.bezrealitky.cz/informace/smluvni-podminky/informacni-povinnost-inzerce-heimstaden',
    [AdvertImportType.Comfort]: 'https://www.bezrealitky.cz/informace/smluvni-podminky/informacni-povinnost-sluzba-komfort',
    [AdvertImportType.EChalupy]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Flatio]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.CsChalupy]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.DomaJeDoma]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Ideveloper]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.PragueOffice]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Mm]: 'https://www.bezrealitky.cz/informace/smluvni-podminky/inzerce-MM',
    [AdvertImportType.ManualBzrMajitelProdej]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.ManualBzrMajitelPronajem]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.ManualBzrRkProdej]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.ManualBzrRkPronajem]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.ManualBzrSpravceProdej]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.ManualBzrSpravcePronajem]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Bures]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Beyvak]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.InvestujDoPole]: 'https://www.bezrealitky.cz/informace/smluvni-podminky',
    [AdvertImportType.Undefined]: '',
};

export const ManualBzrArray = [
    AdvertImportType.ManualBzrMajitelProdej,
    AdvertImportType.ManualBzrMajitelPronajem,
    AdvertImportType.ManualBzrRkProdej,
    AdvertImportType.ManualBzrRkPronajem,
    AdvertImportType.ManualBzrSpravceProdej,
    AdvertImportType.ManualBzrSpravcePronajem,
];

export const isDirectAdvert = (importType: AdvertImportType) => {
    return [AdvertImportType.Undefined, ...ManualBzrArray].includes(importType);
};
