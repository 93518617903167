import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronBigUp: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-big-up">
			<path d="M11.458 6.97a.75.75 0 01.977-.073l.084.072 9.011 9a.75.75 0 01-.976 1.134l-.084-.072-8.481-8.47-8.458 8.469a.75.75 0 01-.977.073l-.084-.072a.75.75 0 01-.073-.977l.072-.084 8.99-9z" />
		</Icon>
	);
};
