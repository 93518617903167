import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconArchiveArrow: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="archive-arrow">
            <path d="m20.54 5.23-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM6.24 5h11.52l.81.97H5.44l.8-.97zM5 19V8h14v11H5zm8.45-9h-2.9v3H8l4 4 4-4h-2.55z" />
        </Icon>
    );
};
