import classNames from 'classnames';
import React, {FC} from 'react';
import {Offcanvas, OffcanvasProps} from '../Offcanvas';
import styles from './Sidebar.module.scss';

type SidebarProps = OffcanvasProps & {
    specialVariant?: 'menu' | 'login' | 'overlay' | 'messages' | 'dashboard';
    className?: string;
};

export const Sidebar: FC<React.PropsWithChildren<SidebarProps>> = ({specialVariant, placement = 'end', className, children, ...props}) => {
    return (
        <Offcanvas
            {...props}
            placement={placement}
            className={classNames(styles.sidebar, 'sidebar', className, {
                [styles[`sidebar--${specialVariant}`]]: specialVariant,
            })}
        >
            {children}
        </Offcanvas>
    );
};
