import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPublicTransport: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="public-transport">
			<path d="M14.586 1.25c1.104 0 2.164.439 2.944 1.22a.75.75 0 01-1.06 1.06 2.666 2.666 0 00-1.692-.773l-.192-.007-.626 2.5H16a3.75 3.75 0 013.745 3.55l.005.2v7c0 1.678-.87 3.154-2.185 3.999l1.059 1.584a.75.75 0 01-1.18.921l-.068-.087-1.209-1.811c-.28.07-.57.116-.867.135l-.3.009H9a4.76 4.76 0 01-1.167-.144l-1.21 1.81a.75.75 0 01-1.301-.736l.054-.097L6.436 20a4.747 4.747 0 01-2.18-3.76L4.25 16V9A3.75 3.75 0 017.8 5.255L8 5.25h2.039l-.625-2.5c-.706 0-1.384.281-1.884.78a.75.75 0 01-1.06-1.06 4.166 4.166 0 012.708-1.213l.236-.007h5.172zm3.664 12.5H5.75V16c0 1.338.808 2.487 1.963 2.985a.643.643 0 01.18.068c.275.103.57.167.875.189L9 19.25h6c.39 0 .763-.069 1.109-.194l-.056.03a.749.749 0 01.233-.103 3.245 3.245 0 001.958-2.784L18.25 16v-2.25zM9 15.25a.75.75 0 01.102 1.493L9 16.75H7.92a.75.75 0 01-.102-1.493l.102-.007H9zm7 0a.75.75 0 01.102 1.493L16 16.75h-1.08a.75.75 0 01-.102-1.493l.102-.007H16zm0-8.5H8a2.25 2.25 0 00-2.245 2.096L5.75 9v3.25h12.5V9a2.25 2.25 0 00-2.096-2.245L16 6.75zm-2.961-4H10.96l.625 2.5h.829l.625-2.5z" />
		</Icon>
	);
};
