import classNames from 'classnames';
import React, {CSSProperties, forwardRef, useCallback, useEffect, PropsWithChildren} from 'react';
import {BsPrefixProps} from 'react-bootstrap/esm/helpers';
import {BsPrefixRefForwardingComponent} from 'react-bootstrap';
import useElementScrollbarWidth from '../../hooks/useElementScrollbarWidth';
import styles from './Box.module.scss';

export type BoxProps = {
    scrollable?: 'horizontal' | boolean;
    rounded?: 'sm' | 'lg' | 'xl' | false;
    className?: string;
    id?: string;
    style?: CSSProperties;
};

export const Box: BsPrefixRefForwardingComponent<'div', BoxProps> = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps> & BsPrefixProps>(
    ({as = 'div', scrollable, rounded, className, children, ...rest}, fwRef) => {
        const Component = as;
        const {ref, setRef: setScrollRef, scrollbarWidth, scrollbarHeight} = useElementScrollbarWidth();

        const setRef = useCallback(
            (ref: any) => {
                if (typeof fwRef === 'function') {
                    fwRef(ref);
                } else if (fwRef) {
                    fwRef.current = ref;
                }
                setScrollRef(ref);
            },
            [setScrollRef, fwRef],
        );

        useEffect(() => {
            if (scrollable) {
                ref?.style.setProperty('--element-scrollbar-width', `${scrollbarWidth}px`);
                ref?.style.setProperty('--element-scrollbar-height', `${scrollbarHeight}px`);
            }

            return () => {
                if (scrollable) {
                    ref?.style.removeProperty('--element-scrollbar-width');
                    ref?.style.removeProperty('--element-scrollbar-height');
                }
            };
        }, [ref, scrollbarWidth, scrollbarHeight, scrollable]);

        return (
            <Component
                ref={setRef}
                className={classNames(styles.box, 'box', className, {
                    [styles[`box--scrollable`]]: scrollable,
                    [styles[`box--scrollable-${scrollable}`]]: scrollable,
                    [styles[`box--rounded-${rounded}`]]: rounded,
                })}
                {...rest}
            >
                {children}
            </Component>
        );
    },
);
