import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconDashboard: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="dashboard">
            <g strokeLinecap="round" strokeWidth="1.5" stroke="#323232" fill="none" strokeLinejoin="round">
                <path d="M20,21h-16c-1.105,0 -2,-0.895 -2,-2v-14c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2v14c0,1.105 -0.895,2 -2,2Z" />
                <path d="M2,8h20" />
                <path d="M4.779,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"></path>
                <path d="M7.322,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.003,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c0,-0.003 -0.002,-0.005 -0.005,-0.005"></path>
                <path d="M9.861,5.49c-0.003,0 -0.005,0.002 -0.005,0.005c0,0.003 0.002,0.005 0.005,0.005c0.003,0 0.005,-0.002 0.005,-0.005c-1.77636e-15,-0.003 -0.002,-0.005 -0.005,-0.005" />
                <path d="M9,8v13" />
                <path d="M9,14h13" />
            </g>
        </Icon>
    );
};
