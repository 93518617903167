import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './LangSwitcher.module.scss';
import { LangSwitcherLabel } from './LangSwitcherLabel';
import { LangSwitcherSelect } from './LangSwitcherSelect';

export const LangSwitcher: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={classNames(styles.langSwitcher, 'langSwitcher')}>{children}</div>;
};

export default Object.assign(LangSwitcher, {
	Label: LangSwitcherLabel,
	Select: LangSwitcherSelect,
});
