import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandCheck: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-check">
			<path d="M20 1.25a2.75 2.75 0 012.745 2.582L22.75 4v5a2.75 2.75 0 01-2.582 2.745L20 11.75h-8.696v.612l3.595.587a2.75 2.75 0 012.307 2.712l-.006.174-.016.169-.542 4.338a2.751 2.751 0 01-2.557 2.403l-.172.005H8.625a2.75 2.75 0 01-2.183-1.076l-.106-.149-2.699-4.049a2.307 2.307 0 01.187-2.796l.128-.137.147-.132a2.305 2.305 0 012.498-.268l.156.087.051.033V11.75H4a2.75 2.75 0 01-2.745-2.582L1.25 9V4a2.75 2.75 0 012.582-2.745L4 1.25h16zM9.047 7.75l-.095.007a.751.751 0 00-.641.641l-.007.102V16a.75.75 0 01-1.203.598l-.077-.068-.901-.9a.809.809 0 00-.956-.144l-.091.063-.09.08a.804.804 0 00-.157.916l.056.099 2.7 4.05c.205.308.537.508.902.548l.138.008h5.288a1.25 1.25 0 001.22-.972l.02-.123.543-4.337a1.25 1.25 0 00-.917-1.363l-.122-.026-4.224-.689a.75.75 0 01-.621-.632L9.804 13V8.5a.754.754 0 00-.548-.728l-.093-.016-.116-.006zM20 2.75H4a1.25 1.25 0 00-1.244 1.122L2.75 4v5c0 .648.491 1.18 1.122 1.244L4 10.25h2.804V8.5c0-1.136.843-2.077 1.938-2.229l.158-.016.19-.004.18.01a2.251 2.251 0 012.029 2.082l.005.157v1.75H20a1.25 1.25 0 001.244-1.122L21.25 9V4a1.25 1.25 0 00-1.122-1.244L20 2.75zm-.97 2.22a.75.75 0 01.073.976l-.073.084-2.5 2.5a.75.75 0 01-.976.073l-.084-.073-1.5-1.5a.75.75 0 01.976-1.133l.084.073.97.969 1.97-1.97a.75.75 0 011.06 0z" />
		</Icon>
	);
};
