import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconWallCalendar: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="wall-calendar">
			<path d="M14.38 2.25a.75.75 0 01.743.648L15.13 3v.25h1.253a2.75 2.75 0 012.745 2.582l.005.168v1.25h.311c1.221 0 2.22.948 2.3 2.148l.006.158v9.889c0 1.22-.948 2.219-2.148 2.3l-.158.005H8.556a2.305 2.305 0 01-2.3-2.148l-.006-.158v-.694H4a1.75 1.75 0 01-1.622-2.407l.057-.125.854-1.707c.193-.389.307-.81.336-1.24l.007-.215V6a2.75 2.75 0 012.582-2.745l.168-.005H7.63V3a.75.75 0 011.493-.102L9.13 3v.25h4.5V3a.75.75 0 01.75-.75zm5.064 6.5h-.311v5.038a4.75 4.75 0 01-.65 2.397l-.148.238-.734 1.102a2.748 2.748 0 01-2.105 1.219l-.183.006H7.75v.694c0 .411.307.75.705.8l.101.006h10.888c.411 0 .75-.307.8-.704l.006-.101V9.556a.805.805 0 00-.705-.8l-.101-.006zm-11.814-4H6.383a1.25 1.25 0 00-1.244 1.122L5.133 6v7.056c0 .644-.131 1.281-.385 1.873l-.116.251-.854 1.709a.25.25 0 00.164.354L4 17.25h11.312c.372 0 .721-.165.957-.446l.083-.11.734-1.103a3.25 3.25 0 00.537-1.563l.009-.24V6a1.25 1.25 0 00-1.122-1.244l-.128-.006H15.13V5a.75.75 0 01-1.493.102L13.63 5v-.25h-4.5V5a.75.75 0 01-1.493.102L7.63 5v-.25zm6.56 7.5a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493l.102-.007h6zm0-4a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493l.102-.007h6z" />
		</Icon>
	);
};
