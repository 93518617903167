import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTriangleDown: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="triangle-down">
			<path d="M12 15L7 9h10z" />
		</Icon>
	);
};
