import classNames from 'classnames';
import React, { FC } from 'react';
import { Color } from '../../types/colors';
import { Typography } from '../Typography';
import styles from './Divider.module.scss';

type DividerProps = {
	className?: string;
	color?: Color;
}

export const Divider: FC<React.PropsWithChildren<DividerProps>> = ({ color, className, children }) => {
	const dividerClassName = classNames(styles.divider, className, {
		[styles['divider--text']]: children,
		[`text-${color}`]: color,
	});

	return children ? (
		<div className={dividerClassName}>
			<Typography variant="perex" color="grey-dark">
				{children}
			</Typography>

			<hr />
		</div>
	) : (
		<hr className={dividerClassName} />
	);
};
