import classNames from 'classnames';
import React, {FC} from 'react';
import styles from './CalendarSelectionGroup.module.scss';
import {Typography} from '@components/Typography';

export const CalendarSelectionGroupSeparator: FC<React.PropsWithChildren> = () => {
    return (
        <div
            className={classNames(
                styles.calendarSelectionGroupSeparator,
                'flex-grow-0 flex-shrink-0 d-flex align-items-center justify-content-center px-2 px-sm-3',
            )}
        >
            <Typography color={'grey-dark'} fontWeight={'bold'}>
                &ndash;
            </Typography>
        </div>
    );
};
