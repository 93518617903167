import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandDoubleClick: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-double-click">
			<path d="M12.09 7.25l.18.01a2.251 2.251 0 012.029 2.083l.005.157v2.862l3.595.587a2.75 2.75 0 012.307 2.712l-.006.174-.016.169-.542 4.338a2.751 2.751 0 01-2.557 2.403l-.172.005h-5.288a2.75 2.75 0 01-2.183-1.076l-.106-.149-2.699-4.049a2.307 2.307 0 01.187-2.796l.128-.137.147-.132a2.305 2.305 0 012.498-.268l.156.087.051.033V9.5c0-1.136.843-2.077 1.938-2.229l.158-.016.19-.004zm-.043 1.5l-.095.007a.751.751 0 00-.641.641l-.007.102V16a.75.75 0 01-1.203.598l-.077-.068-.901-.9a.809.809 0 00-.956-.144l-.091.063-.09.08a.804.804 0 00-.157.916l.056.099 2.7 4.05c.205.308.537.508.902.548l.138.008h5.288a1.25 1.25 0 001.22-.972l.02-.123.543-4.337a1.25 1.25 0 00-.917-1.363l-.122-.026-4.224-.689a.75.75 0 01-.621-.632L12.804 13V9.5a.754.754 0 00-.548-.728l-.093-.016-.116-.006zM12 1.25A7.75 7.75 0 0119.75 9a.75.75 0 11-1.5 0 6.25 6.25 0 10-12.5 0 .75.75 0 01-1.5 0A7.75 7.75 0 0112 1.25zm0 3A4.75 4.75 0 0116.75 9a.75.75 0 11-1.5 0 3.25 3.25 0 00-6.5 0 .75.75 0 01-1.5 0A4.75 4.75 0 0112 4.25z" />
		</Icon>
	);
};
