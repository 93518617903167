import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconShare: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="share">
			<path d="M19.75 4.25a3.417 3.417 0 11-5.066 4.576l-4.7 2.35c.133.54.133 1.107 0 1.648l4.7 2.35a3.417 3.417 0 11-.67 1.342l-4.702-2.352a3.417 3.417 0 110-4.327l4.703-2.353A3.417 3.417 0 0119.75 4.25zm-3.772 11.727c-.12.12-.22.251-.302.391a.755.755 0 01-.108.221 1.917 1.917 0 103.238-.483l-.117-.129-.13-.117a1.917 1.917 0 00-2.581.117zM5.31 10.644a1.917 1.917 0 103.016 2.315.64.64 0 01.042-.104l.058-.097.046-.114a1.924 1.924 0 00-.046-1.404.756.756 0 01-.101-.199 1.822 1.822 0 00-.186-.268l-.117-.129-.13-.117a1.917 1.917 0 00-2.582.117zm10.667-5.333a1.918 1.918 0 00-.412 2.101.755.755 0 01.111.219 1.917 1.917 0 103.129-2.19l-.117-.13-.13-.117a1.917 1.917 0 00-2.581.117z" />
		</Icon>
	);
};
