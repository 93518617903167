import classNames from 'classnames';
import React, {FC} from 'react';
import {Icon} from '../Icon';
import styles from './LangSwitcher.module.scss';
import {HeaderButton} from '@components/Header/HeaderButton';

type LangSwitcherProps = {
    variant?: 'button';
    header?: boolean;
};

export const LangSwitcherLabel: FC<React.PropsWithChildren<LangSwitcherProps>> = ({variant, header = false, children}) => {
    const Component = header ? HeaderButton : 'div';

    return (
        <Component
            className={classNames(styles.langSwitcherLabel, 'langSwitcherLabel', {
                [styles[`langSwitcherLabel--${variant}`]]: variant,
                'btn btn-outline-dark': variant === 'button',
                'btn-sm': header && variant === 'button',
                [styles[`langSwitcherLabel--button--small`]]: header && variant === 'button',
            })}
        >
            {!header && (
                <span className={styles.langSwitcherIcon}>
                    <Icon.Globe />
                </span>
            )}
            {children}
        </Component>
    );
};
