import React, { AnchorHTMLAttributes, FC } from 'react';
import { Icon, IconProps } from '../Icon';
import { Typography } from '../Typography';
import styles from './ContactBox.module.scss';

type ContactBoxLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'color'> & {
	iconName?: IconProps['name'];
};

export const ContactBoxLink: FC<React.PropsWithChildren<ContactBoxLinkProps>> = ({ iconName, children, ...props }) => {
	return (
		<p className={styles.contactBoxItem}>
			<Typography {...props} as="a" variant="subheadline" className={styles.contactBoxLink}>
				{iconName && <Icon name={iconName} size="lg" className={styles.contactBoxLinkIcon} />}
				<span>{children}</span>
			</Typography>
		</p>
	);
};
