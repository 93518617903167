import {FC, ReactNode} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';
import {InputGroup} from '@components/InputGroup';
import {FormCheckProps} from '@components/Form/Check';

type Props = {
    label: ReactNode;
    className?: string;
    value?: string | number;
} & FormCheckProps;

export type CheckboxInputProps = Props & {name: string};

export const CheckboxInput: FC<React.PropsWithChildren<CheckboxInputProps>> = ({className, value: v, label, name, type, ...props}) => {
    const [field, meta, actions] = useField(name);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {value, onChange, ...restField} = field;

    const isTouched = meta.touched;
    const ownErrors = meta.error;
    const hasErrors = !!ownErrors;

    return (
        <Form.Group className={className} controlId={field.name}>
            <InputGroup hasValidation={isTouched && hasErrors}>
                <Form.Check
                    id={name}
                    checked={type === 'radio' ? field.value === v : field.value}
                    onChange={(e) => {
                        actions.setValue(type === 'radio' ? v : e.currentTarget.checked).then(() => actions.setTouched(true));
                    }}
                    {...restField}
                    label={label}
                    name={name}
                    {...props}
                    type={type}
                    isInvalid={isTouched && hasErrors}
                />
                {isTouched && hasErrors && <Form.Control.Feedback type={'invalid'}>{ownErrors}</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    );
};
