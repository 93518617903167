import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconBalcony: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="balcony">
			<path d="M3 21.75a1.75 1.75 0 01-1.744-1.606L1.25 20v-4a1.75 1.75 0 011.606-1.744L3 14.25h1.25V5.182a2.932 2.932 0 012.72-2.924l.212-.008h9.636a2.932 2.932 0 012.924 2.72l.008.212v9.068H21a1.75 1.75 0 011.744 1.606l.006.144v4a1.75 1.75 0 01-1.606 1.744L21 21.75H3zm3.25-6H3a.25.25 0 00-.243.193L2.75 16v4a.25.25 0 00.193.243L3 20.25h3.25v-4.5zm5 0h-3.5v4.5h3.5v-4.5zm5 0h-3.5v4.5h3.5v-4.5zm4.75 0h-3.25v4.5H21a.25.25 0 00.243-.193L21.25 20v-4a.25.25 0 00-.193-.243L21 15.75zm-4.182-12H7.182a1.432 1.432 0 00-1.425 1.29l-.007.142v9.068h12.5V5.182a1.432 1.432 0 00-1.29-1.425l-.142-.007zM16 10.25a.75.75 0 01.102 1.493L16 11.75h-1a.75.75 0 01-.102-1.493L15 10.25h1z" />
		</Icon>
	);
};
