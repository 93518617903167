import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTwitter: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="twitter">
			<path d="M16.504 2.25a5.246 5.246 0 015.242 5.03l.004.216v9.009a5.246 5.246 0 01-5.03 5.24l-.216.005H7.496a5.246 5.246 0 01-5.242-5.03l-.004-.216V7.5a5.25 5.25 0 015.034-5.246L7.5 2.25h9.004zm0 1.5H7.5A3.75 3.75 0 003.755 7.3l-.005.2v9.004a3.746 3.746 0 003.547 3.74l.199.006h9.008a3.746 3.746 0 003.74-3.546l.006-.199V7.496a3.746 3.746 0 00-3.547-3.74l-.199-.006zM14.05 6.8c1.135 0 1.999.354 2.642 1.046l.135.154h1.423a.75.75 0 01.676 1.076l-.052.09-1.077 1.616v.045c-.109 4.583-2.922 7.673-7.347 7.673-2.197 0-3.32-.735-4.645-2.492l-.16-.216a.755.755 0 01-.067-.11l-.04-.093-.023-.089-.013-.096v-.108l.018-.117a.748.748 0 01.127-.275l.033-.041.05-.053.078-.066a.755.755 0 01.11-.066l.093-.04.087-.022.035-.007.037-.018.606-.206-.112-.168a6.28 6.28 0 01.023-6.88l.153-.224a.75.75 0 011.27.082c.525.975 1.372 1.825 2.275 2.3l.061.03.054-.18c.467-1.467 1.712-2.463 3.344-2.54l.206-.005zm0 1.5c-1.321 0-2.205.903-2.25 2.275a.75.75 0 01-.927.704c-1.164-.283-2.295-1.046-3.166-2.049l-.202-.243c-.715 1.76-.345 3.796 1.078 5.236a.75.75 0 01-.295 1.238l-.771.258-.013.006C8.335 16.663 9.084 17 10.45 17c3.559 0 5.751-2.395 5.847-6.19l.005-.315.01-.083.025-.096.034-.085.055-.097.421-.634h-.397a.75.75 0 01-.56-.252l-.066-.085c-.39-.592-.898-.863-1.774-.863z" />
		</Icon>
	);
};
