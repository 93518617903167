import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMessageSend: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="message-send">
			<path d="M2.874 5.995C2.28 4.337 3.902 2.77 5.502 3.384l.137.058 14.429 6.697c1.524.707 1.575 2.84.152 3.642l-.152.078-14.43 6.7c-1.596.74-3.278-.761-2.81-2.411l.046-.142 2.142-6.009-2.142-6.002zm16.02 6.754H6.34l-2.054 5.762c-.154.43.24.831.64.717l.081-.03 13.888-6.449zM5.008 4.802c-.4-.186-.828.177-.744.603l.023.086 2.056 5.758H18.9L5.007 4.802z" />
		</Icon>
	);
};
