import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDownload: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="download">
			<path d="M12 4.25c3.41 0 6.23 2.53 6.686 5.823l.028.231.14.023a4.753 4.753 0 013.876 4.234l.015.222.005.217a4.75 4.75 0 01-4.533 4.745L18 19.75H7A5.75 5.75 0 011.25 14a5.73 5.73 0 014.272-5.535l.252-.062.026-.06a6.747 6.747 0 015.675-4.073l.261-.015L12 4.25zm0 1.5a5.247 5.247 0 00-4.966 3.56.75.75 0 01-.609.501A4.234 4.234 0 002.75 14a4.25 4.25 0 004.044 4.245L7 18.25h11a3.25 3.25 0 000-6.5.75.75 0 01-.75-.75c0-2.9-2.35-5.25-5.25-5.25zm0 4a.75.75 0 01.743.648l.007.102v3.189l.887-.886a.75.75 0 01.976-.073l.084.073a.75.75 0 01.073.976l-.073.084-2.167 2.167a.753.753 0 01-.119.097l-.09.05-.103.04a.747.747 0 01-.218.033l-.057-.002-.09-.013-.038-.008-.106-.035-.069-.034-.086-.055a.785.785 0 01-.084-.073l-2.167-2.167a.75.75 0 01.976-1.133l.084.073.887.886V10.5a.75.75 0 01.75-.75z" />
		</Icon>
	);
};
