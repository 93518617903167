import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChart: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chart">
			<path d="M22 2.25a.75.75 0 01.102 1.493L22 3.75h-.25v11.132c0 1.538-1.258 2.777-2.825 2.863l-.175.005h-4.537l1.458 2.915a.75.75 0 01-1.29.758l-.052-.088-1.793-3.585h-1.073l-1.792 3.585a.75.75 0 01-1.381-.576l.04-.094 1.456-2.915H5.25c-1.589 0-2.903-1.184-2.995-2.698l-.005-.17V3.75H2a.75.75 0 01-.102-1.493L2 2.25h20zm-1.75 1.5H3.75v11.132c0 .701.586 1.294 1.354 1.362l.146.006h13.5c.79 0 1.422-.552 1.493-1.238l.007-.13V3.75zm-3.72 3.72a.75.75 0 01.073.976l-.073.084-3.333 3.333a.75.75 0 01-.976.073l-.084-.073-1.47-1.469L8.53 12.53a.75.75 0 01-.976.073l-.084-.073a.75.75 0 01-.073-.976l.073-.084 2.667-2.667a.75.75 0 01.976-.073l.084.073 1.47 1.469L15.47 7.47a.75.75 0 011.06 0z" />
		</Icon>
	);
};
