import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Footer.module.scss';

type FooterCopyrightItemProps = {
	variant?: 'lang-switcher' | 'link' | 'year' | 'copy';
}

export const FooterCopyrightItem: FC<React.PropsWithChildren<FooterCopyrightItemProps>> = ({ variant, children }) => {
	return (
		<span
			className={classNames(styles.footerCopyrightItem, 'text-center', {
				'w-100 w-md-auto': variant !== 'link',
				[styles[`footerCopyrightItem--${variant}`]]: variant,
			})}
		>
			{children}
		</span>
	);
};
