import {FC, useEffect} from 'react';
import {useFormikContext} from 'formik';

export const FocusError: FC = () => {
    const {errors, isSubmitting, isValidating} = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const keys = Object.keys(errors);
            for (const key of keys) {
                let selector = `[name=${key}]`;
                let errorElement = document.querySelector(selector);
                if (errorElement instanceof HTMLInputElement || errorElement instanceof HTMLTextAreaElement) {
                    errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                    errorElement.focus({preventScroll: true});
                    break;
                } else if (key.endsWith('Feature')) {
                    // Autocomplete field adds virtual field usually with suffix Feature
                    selector = `[name=${key.substring(0, key.length - 7)}]`;
                    errorElement = document.querySelector(selector);
                    if (errorElement instanceof HTMLInputElement || errorElement instanceof HTMLTextAreaElement) {
                        errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                        errorElement.focus({preventScroll: true});
                        break;
                    }
                } else {
                    // Phone field might have suffix -name
                    selector = `[name=${key + '-number'}]`;
                    errorElement = document.querySelector(selector);
                    if (errorElement instanceof HTMLInputElement || errorElement instanceof HTMLTextAreaElement) {
                        errorElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                        errorElement.focus({preventScroll: true});
                        break;
                    }
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};
