import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCopyright: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="copyright">
			<path d="M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm0 1.5l-.244.004c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.994-8.002L12 3.75zm0 3.5c1.17 0 2.274.428 3.135 1.188a.75.75 0 01-.992 1.124A3.224 3.224 0 0012 8.75a3.25 3.25 0 00-3.245 3.066L8.75 12a3.25 3.25 0 003.056 3.244l.194.006c.798 0 1.55-.292 2.144-.813a.75.75 0 01.99 1.126 4.734 4.734 0 01-2.865 1.18L12 16.75a4.75 4.75 0 010-9.5z" />
		</Icon>
	);
};
