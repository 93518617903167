import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconDeactivate: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="deactivate">
            <g strokeLinecap="round" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinejoin="round">
                <path d="M7.554,11h-3.554c-1.105,0 -2,-0.895 -2,-2v-5c0,-1.105 0.895,-2 2,-2h16c1.105,0 2,0.895 2,2v5c0,1.105 -0.895,2 -2,2h-9.446"></path>
                <path d="M18.77,5.23l-2.54,2.54"></path>
                <path d="M18.77,7.77l-2.54,-2.54"></path>
                <path d="M10.554,13v-4.5c0,-0.828 -0.672,-1.5 -1.5,-1.5v0c-0.828,0 -1.5,0.672 -1.5,1.5v2.5v5l-0.901,-0.901c-0.607,-0.607 -1.591,-0.607 -2.198,0v0c-0.524,0.524 -0.605,1.345 -0.194,1.961l2.699,4.049c0.371,0.557 0.996,0.891 1.665,0.891h5.288c1.009,0 1.859,-0.751 1.985,-1.752l0.542,-4.337c0.133,-1.067 -0.601,-2.049 -1.662,-2.222l-4.224,-0.689Z"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
        </Icon>
    );
};
