import {FormattedMessage, IntlShape} from 'react-intl';
import {EstateType, OfferType} from '.cache/__types__';
import {LocaleType, LocaleEnum} from 'src/types/general';

export const translations = {
    [OfferType.Undefined]: <FormattedMessage defaultMessage="Nevyplněno" />,
    [OfferType.Prodej]: <FormattedMessage defaultMessage="Prodej" />,
    [OfferType.Pronajem]: <FormattedMessage defaultMessage="Pronájem" />,
};

export const imperativeTranslations = {
    [OfferType.Undefined]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nevyplněno'}),
    [OfferType.Prodej]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Prodej'}),
    [OfferType.Pronajem]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Pronájem'}),
};

export const getImperativeTranslation = (intl: IntlShape, key: OfferType) => imperativeTranslations[key](intl);

export const offerEstateTypeImperativeTranslation = (intl: IntlShape, offerType?: OfferType, estateType?: EstateType) =>
    intl.formatMessage(
        {
            defaultMessage:
                '{offerType, select, PRODEJ {Prodej} PRONAJEM {Pronájem} other {}} {estateType, select, BYT {bytu} DUM {domu} KANCELAR {kanceláře} POZEMEK {pozemku} GARAZ {garáže} REKREACNI_OBJEKT {chaty, chalupy} NEBYTOVY_PROSTOR {nebytového prostoru} other {}}',
        },
        {
            estateType,
            offerType,
        },
    );

type OfferEstateTypeTranslationType = {
    offerType: OfferType;
    estateType: EstateType;
};
export const OfferEstateTypeTranslation = ({offerType, estateType}: OfferEstateTypeTranslationType) => (
    <FormattedMessage
        defaultMessage="{offerType, select, PRODEJ {Prodej} PRONAJEM {Pronájem} other {}} {estateType, select, BYT {bytu} DUM {domu} KANCELAR {kanceláře} POZEMEK {pozemku} GARAZ {garáže} REKREACNI_OBJEKT {chaty, chalupy} NEBYTOVY_PROSTOR {nebytového prostoru} other {}}"
        values={{
            estateType,
            offerType,
        }}
    />
);

export const localizedListSlugs: Record<LocaleType, Record<Exclude<OfferType, OfferType.Undefined>, string>> = {
    [LocaleEnum.cs]: {
        [OfferType.Prodej]: 'nabidka-prodej',
        [OfferType.Pronajem]: 'nabidka-pronajem',
    },
    [LocaleEnum.sk]: {
        [OfferType.Prodej]: 'ponuka-predaj',
        [OfferType.Pronajem]: 'ponuka-prenajom',
    },
    [LocaleEnum.en]: {
        [OfferType.Prodej]: 'offer-sale',
        [OfferType.Pronajem]: 'offer-rent',
    },
};

export const OFFER_TYPE_LIST = Object.values(OfferType).filter((item) => item !== OfferType.Undefined);

export const offerTypeLabel = <FormattedMessage defaultMessage="Typ nabídky" />;

export const listDefaultType = OfferType.Pronajem;
