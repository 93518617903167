import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconGift: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="gift">
            <path
                d="M20.844 11.23v10.668H3.184V11.23H1.539v11.497c0 .453.367.824.824.824h19.309a.827.827 0 0 0 .824-.824V11.23Zm0 0"
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: '#e63d2e',
                    fillOpacity: 1,
                }}
            />
            <path
                d="M23.176 5.063H.824a.824.824 0 0 0-.824.82v5.375c0 .457.371.828.824.828H23.18a.828.828 0 0 0 .824-.828V5.883a.83.83 0 0 0-.828-.82Zm-.82 5.37H1.644v-3.73h20.71Zm0 0"
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: '#e63d2e',
                    fillOpacity: 1,
                }}
            />
            <path
                d="M12.813 5.676c-.06-.215-1.418-5.223-5.38-5.223A3.13 3.13 0 0 0 4.31 3.582c0 1.723 1.39 3.125 3.097 3.125h4.61c.25 0 .496-.117.648-.316a.852.852 0 0 0 .149-.715Zm-5.407-.614c-.816 0-1.453-.652-1.453-1.48 0-.8.68-1.48 1.48-1.48 1.805 0 2.887 1.773 3.41 2.96Zm0 0"
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: '#e63d2e',
                    fillOpacity: 1,
                }}
            />
            <path
                d="M16.594.453c-3.961 0-5.32 5.012-5.375 5.223a.822.822 0 0 0 .797 1.031h4.605c1.711 0 3.098-1.402 3.098-3.125A3.127 3.127 0 0 0 16.594.453Zm.027 4.61h-3.433c.515-1.18 1.593-2.961 3.41-2.961.8 0 1.48.68 1.48 1.48-.004.828-.64 1.48-1.457 1.48Zm0 0"
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: '#e63d2e',
                    fillOpacity: 1,
                }}
            />
            <path
                d="M11.188 5.883h1.648v16.844h-1.649Zm0 0"
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: '#e63d2e',
                    fillOpacity: 1,
                }}
            />
        </Icon>
    );
};
