import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconGym: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="gym">
			<path d="M15.719 1.947a1.265 1.265 0 011.686-.092l.103.092 4.545 4.545c.46.46.491 1.189.092 1.686l-.092.102-1.085 1.083 1.084 1.084c.461.461.492 1.19.092 1.686l-.092.103-1.249 1.25a1.265 1.265 0 01-1.686.091l-.102-.092-2.402-2.402-5.53 5.53 2.401 2.402c.461.46.492 1.189.092 1.686l-.092.102-1.249 1.25a1.265 1.265 0 01-1.686.092l-.102-.093-1.083-1.083-1.083 1.084a1.265 1.265 0 01-1.686.092l-.102-.092-4.545-4.545a1.265 1.265 0 01-.093-1.686l.093-.102 1.082-1.084-1.083-1.083a1.265 1.265 0 01-.092-1.686l.092-.102 1.25-1.25a1.265 1.265 0 011.685-.092l.103.093 2.401 2.4 5.53-5.53-2.4-2.4a1.265 1.265 0 01-.093-1.686l.092-.102 1.25-1.25a1.265 1.265 0 011.685-.092l.103.092 1.082 1.082 1.084-1.083zM4.091 11.742l-.916.916 8.166 8.166.917-.916-8.167-8.166zm0 3.955l-.916.916 4.212 4.212.917-.916-4.213-4.212zm9.886-7.25l-5.53 5.53 1.575 1.575 5.53-5.53-1.575-1.576zm-1.318-5.27l-.917.916 8.167 8.166.916-.916-8.166-8.167zm3.955-.003l-.917.917 4.213 4.212.916-.916-4.212-4.213z" />
		</Icon>
	);
};
