import {useCallback} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';

export type HiddenInputProps = {name: string};

export const HiddenInput = ({name}: HiddenInputProps) => {
    const [field, , actions] = useField(name);
    const {value, ...fieldRest} = field;

    const handleChange = useCallback(
        (e: any) => {
            actions.setValue(e.currentTarget.value);
        },
        [actions],
    );

    return <Form.Control type={'hidden'} value={value} {...fieldRest} onChange={handleChange} />;
};
