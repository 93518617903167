export enum TouchPointEnum {
    Modal = 'modal',
    Estimation = 'estimation',
    Insert = 'insert',
    SideBar = 'sidebar',
    Page = 'page',
}

export const getTouchPoint = (touchPoint: TouchPointEnum) => {
    switch (touchPoint) {
        case TouchPointEnum.Modal:
            return 'Detail inzerátu';
        case TouchPointEnum.Estimation:
            return 'Odhad ceny';
        case TouchPointEnum.Insert:
            return 'Vkládání inzerátu';
        case TouchPointEnum.SideBar:
            return 'Modální okno';
        case TouchPointEnum.Page:
            return 'Samostatná URL';
    }
};
