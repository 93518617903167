import {FC, HTMLProps, ReactNode} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';
import {InputGroup} from '@components/InputGroup';
import {FormattedMessage} from 'react-intl';
import {useShowPassword} from '@hooks/useShowPassword';
import {FormControlProps} from 'react-bootstrap';

type Props = {
    className?: string;
    label?: ReactNode;
    placeholder?: string;
} & ({label: ReactNode} | {placeholder: string}) &
    FormControlProps &
    Omit<HTMLProps<HTMLInputElement>, 'label'>;

export type PasswordInputProps = Props & {name: string};

export const PasswordInput: FC<React.PropsWithChildren<PasswordInputProps>> = ({className, label, placeholder, name, ...innerProps}) => {
    const [passwordInputType, shown, togglePasswordShown] = useShowPassword();
    const [field, meta] = useField(name);

    const isTouched = meta.touched;
    const ownErrors = meta.error;
    const hasErrors = !!ownErrors;

    return (
        <Form.Group className={className} controlId={field.name}>
            <Form.Label visuallyHidden={!label}>{label ?? placeholder}</Form.Label>
            <InputGroup hasValidation={isTouched && hasErrors}>
                <Form.Control
                    type={passwordInputType}
                    placeholder={placeholder}
                    {...field}
                    {...innerProps}
                    isInvalid={isTouched && hasErrors}
                />
                <InputGroup.ShowPassword onClick={togglePasswordShown} shown={shown}>
                    <FormattedMessage defaultMessage="Zobrazit heslo" />
                </InputGroup.ShowPassword>
                {isTouched && hasErrors && <Form.Control.Feedback type="invalid">{ownErrors}</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    );
};
