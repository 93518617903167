import React, { FC } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Typography } from '../Typography';

export type InputGroupRequiredProps = {}

export const InputGroupRequired: FC<React.PropsWithChildren<InputGroupRequiredProps>> = ({ children }) => {
	return (
		<InputGroup.Text className="input-group-text--required">
			<Typography variant="caption">{children}</Typography>
		</InputGroup.Text>
	);
};
