import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconCopyAddPlus: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="copy-add-plus">
            <g strokeLinecap={'round'} strokeWidth={'1.5'} fill={'none'} stroke={'#323232'} strokeLinejoin={'round'}>
                <rect x="3" y="9" width="12" height="12" rx="3" />
                <path d="M9,9V6a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3H15" />
                <line x1="9" y1="13" x2="9" y2="17" />
                <line x1="11" y1="15" x2="7" y2="15" />
            </g>
        </Icon>
    );
};
