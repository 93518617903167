import {FormattedMessage, IntlShape} from 'react-intl';
import {Disposition, EstateType} from '.cache/__types__';
import {LocaleEnum, LocaleType} from 'src/types/general';

export const translations = {
    [Disposition.Undefined]: <FormattedMessage defaultMessage="Nevyplněno" />,
    [Disposition.Garsoniera]: <FormattedMessage defaultMessage="Garsoniéra" />,
    [Disposition.Disp_1Kk]: <FormattedMessage defaultMessage="1+kk" />,
    [Disposition.Disp_1_1]: <FormattedMessage defaultMessage="1+1" />,
    [Disposition.Disp_1Izb]: <FormattedMessage defaultMessage="Jednopokojový" />,
    [Disposition.Disp_2Kk]: <FormattedMessage defaultMessage="2+kk" />,
    [Disposition.Disp_2_1]: <FormattedMessage defaultMessage="2+1" />,
    [Disposition.Disp_2Izb]: <FormattedMessage defaultMessage="Dvoupokojový" />,
    [Disposition.Disp_3Kk]: <FormattedMessage defaultMessage="3+kk" />,
    [Disposition.Disp_3_1]: <FormattedMessage defaultMessage="3+1" />,
    [Disposition.Disp_3Izb]: <FormattedMessage defaultMessage="Třípokojový" />,
    [Disposition.Disp_4Kk]: <FormattedMessage defaultMessage="4+kk" />,
    [Disposition.Disp_4_1]: <FormattedMessage defaultMessage="4+1" />,
    [Disposition.Disp_4Izb]: <FormattedMessage defaultMessage="Čtyřpokojový" />,
    [Disposition.Disp_5Kk]: <FormattedMessage defaultMessage="5+kk" />,
    [Disposition.Disp_5_1]: <FormattedMessage defaultMessage="5+1" />,
    [Disposition.Disp_5Izb]: <FormattedMessage defaultMessage="Pětipokojový" />,
    [Disposition.Disp_6Kk]: <FormattedMessage defaultMessage="6+kk" />,
    [Disposition.Disp_6_1]: <FormattedMessage defaultMessage="6+1" />,
    [Disposition.Disp_6Izb]: <FormattedMessage defaultMessage="Šestipokojový" />,
    [Disposition.Disp_7Kk]: <FormattedMessage defaultMessage="7+kk" />,
    [Disposition.Disp_7_1]: <FormattedMessage defaultMessage="7+1" />,
    [Disposition.Disp_7Izb]: <FormattedMessage defaultMessage="Sedmipokojový" />,
    [Disposition.Ostatni]: <FormattedMessage defaultMessage="Ostatní" />,
};

export const imperativeTranslations = {
    [Disposition.Undefined]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nevyplněno'}),
    [Disposition.Garsoniera]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garsoniéra'}),
    [Disposition.Disp_1Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+kk'}),
    [Disposition.Disp_1_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+1'}),
    [Disposition.Disp_1Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Jednopokojový'}),
    [Disposition.Disp_2Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+kk'}),
    [Disposition.Disp_2_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+1'}),
    [Disposition.Disp_2Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Dvoupokojový'}),
    [Disposition.Disp_3Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+kk'}),
    [Disposition.Disp_3_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+1'}),
    [Disposition.Disp_3Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Třípokojový'}),
    [Disposition.Disp_4Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+kk'}),
    [Disposition.Disp_4_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+1'}),
    [Disposition.Disp_4Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Čtyřpokojový'}),
    [Disposition.Disp_5Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+kk'}),
    [Disposition.Disp_5_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+1'}),
    [Disposition.Disp_5Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Pětipokojový'}),
    [Disposition.Disp_6Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+kk'}),
    [Disposition.Disp_6_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+1'}),
    [Disposition.Disp_6Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Šestipokojový'}),
    [Disposition.Disp_7Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+kk'}),
    [Disposition.Disp_7_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+1'}),
    [Disposition.Disp_7Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Sedmipokojový'}),
    [Disposition.Ostatni]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Ostatní'}),
};

export const getImperativeTranslation = (intl: IntlShape, key: Disposition) => {
    return imperativeTranslations[key](intl);
};

export const localizedTranslation = {
    [LocaleEnum.cs]: {
        [Disposition.Garsoniera]: <FormattedMessage defaultMessage="Garsoniéra" />,
        [Disposition.Disp_1Kk]: <FormattedMessage defaultMessage="1+kk" />,
        [Disposition.Disp_1_1]: <FormattedMessage defaultMessage="1+1" />,
        [Disposition.Disp_2Kk]: <FormattedMessage defaultMessage="2+kk" />,
        [Disposition.Disp_2_1]: <FormattedMessage defaultMessage="2+1" />,
        [Disposition.Disp_3Kk]: <FormattedMessage defaultMessage="3+kk" />,
        [Disposition.Disp_3_1]: <FormattedMessage defaultMessage="3+1" />,
        [Disposition.Disp_4Kk]: <FormattedMessage defaultMessage="4+kk" />,
        [Disposition.Disp_4_1]: <FormattedMessage defaultMessage="4+1" />,
        [Disposition.Disp_5Kk]: <FormattedMessage defaultMessage="5+kk" />,
        [Disposition.Disp_5_1]: <FormattedMessage defaultMessage="5+1" />,
        [Disposition.Disp_6Kk]: <FormattedMessage defaultMessage="6+kk" />,
        [Disposition.Disp_6_1]: <FormattedMessage defaultMessage="6+1" />,
        [Disposition.Disp_7Kk]: <FormattedMessage defaultMessage="7+kk" />,
        [Disposition.Disp_7_1]: <FormattedMessage defaultMessage="7+1" />,
        [Disposition.Ostatni]: <FormattedMessage defaultMessage="Ostatní" />,
    },
    [LocaleEnum.sk]: {
        [Disposition.Garsoniera]: <FormattedMessage defaultMessage="Garsoniéra" />,
        [Disposition.Disp_1Izb]: <FormattedMessage defaultMessage="Jednopokojový" />,
        [Disposition.Disp_2Izb]: <FormattedMessage defaultMessage="Dvoupokojový" />,
        [Disposition.Disp_3Izb]: <FormattedMessage defaultMessage="Třípokojový" />,
        [Disposition.Disp_4Izb]: <FormattedMessage defaultMessage="Čtyřpokojový" />,
        [Disposition.Disp_5Izb]: <FormattedMessage defaultMessage="Pětipokojový" />,
        [Disposition.Disp_6Izb]: <FormattedMessage defaultMessage="Šestipokojový" />,
        [Disposition.Disp_7Izb]: <FormattedMessage defaultMessage="Sedmipokojový" />,
        [Disposition.Ostatni]: <FormattedMessage defaultMessage="Ostatní" />,
    },
    [LocaleEnum.en]: {
        [Disposition.Garsoniera]: <FormattedMessage defaultMessage="Garsoniéra" />,
        [Disposition.Disp_1Kk]: <FormattedMessage defaultMessage="1+kk" />,
        [Disposition.Disp_1_1]: <FormattedMessage defaultMessage="1+1" />,
        [Disposition.Disp_2Kk]: <FormattedMessage defaultMessage="2+kk" />,
        [Disposition.Disp_2_1]: <FormattedMessage defaultMessage="2+1" />,
        [Disposition.Disp_3Kk]: <FormattedMessage defaultMessage="3+kk" />,
        [Disposition.Disp_3_1]: <FormattedMessage defaultMessage="3+1" />,
        [Disposition.Disp_4Kk]: <FormattedMessage defaultMessage="4+kk" />,
        [Disposition.Disp_4_1]: <FormattedMessage defaultMessage="4+1" />,
        [Disposition.Disp_5Kk]: <FormattedMessage defaultMessage="5+kk" />,
        [Disposition.Disp_5_1]: <FormattedMessage defaultMessage="5+1" />,
        [Disposition.Disp_6Kk]: <FormattedMessage defaultMessage="6+kk" />,
        [Disposition.Disp_6_1]: <FormattedMessage defaultMessage="6+1" />,
        [Disposition.Disp_7Kk]: <FormattedMessage defaultMessage="7+kk" />,
        [Disposition.Disp_7_1]: <FormattedMessage defaultMessage="7+1" />,
        [Disposition.Ostatni]: <FormattedMessage defaultMessage="Ostatní" />,
    },
};

export const localizedTranslationString = {
    [LocaleEnum.cs]: {
        [Disposition.Garsoniera]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garsoniéra'}),
        [Disposition.Disp_1Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+kk'}),
        [Disposition.Disp_1_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+1'}),
        [Disposition.Disp_2Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+kk'}),
        [Disposition.Disp_2_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+1'}),
        [Disposition.Disp_3Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+kk'}),
        [Disposition.Disp_3_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+1'}),
        [Disposition.Disp_4Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+kk'}),
        [Disposition.Disp_4_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+1'}),
        [Disposition.Disp_5Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+kk'}),
        [Disposition.Disp_5_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+1'}),
        [Disposition.Disp_6Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+kk'}),
        [Disposition.Disp_6_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+1'}),
        [Disposition.Disp_7Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+kk'}),
        [Disposition.Disp_7_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+1'}),
        [Disposition.Ostatni]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Ostatní'}),
    },
    [LocaleEnum.sk]: {
        [Disposition.Garsoniera]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garsoniéra'}),
        [Disposition.Disp_1Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Jednopokojový'}),
        [Disposition.Disp_2Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Dvoupokojový'}),
        [Disposition.Disp_3Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Třípokojový'}),
        [Disposition.Disp_4Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Čtyřpokojový'}),
        [Disposition.Disp_5Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Pětipokojový'}),
        [Disposition.Disp_6Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Šestipokojový'}),
        [Disposition.Disp_7Izb]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Sedmipokojový'}),
        [Disposition.Ostatni]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Ostatní'}),
    },
    [LocaleEnum.en]: {
        [Disposition.Garsoniera]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garsoniéra'}),
        [Disposition.Disp_1Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+kk'}),
        [Disposition.Disp_1_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '1+1'}),
        [Disposition.Disp_2Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+kk'}),
        [Disposition.Disp_2_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '2+1'}),
        [Disposition.Disp_3Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+kk'}),
        [Disposition.Disp_3_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '3+1'}),
        [Disposition.Disp_4Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+kk'}),
        [Disposition.Disp_4_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '4+1'}),
        [Disposition.Disp_5Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+kk'}),
        [Disposition.Disp_5_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '5+1'}),
        [Disposition.Disp_6Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+kk'}),
        [Disposition.Disp_6_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '6+1'}),
        [Disposition.Disp_7Kk]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+kk'}),
        [Disposition.Disp_7_1]: (intl: IntlShape) => intl.formatMessage({defaultMessage: '7+1'}),
        [Disposition.Ostatni]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Ostatní'}),
    },
};

export const getLocalizedTranslationString = (intl: IntlShape, locale: LocaleType, key: Disposition) => {
    const dispositions = localizedTranslationString[locale] as Record<Disposition, (intl: IntlShape) => string>;
    return dispositions[key](intl);
};

export const DISPOSITION_LIST = Object.values(Disposition).filter((item) => item !== Disposition.Undefined);

export const localizedListSlugs: Record<LocaleType, Record<Exclude<Disposition, Disposition.Undefined>, string>> = {
    [LocaleEnum.cs]: {
        [Disposition.Garsoniera]: 'garsoniera',
        [Disposition.Disp_1Kk]: '1-kk',
        [Disposition.Disp_1_1]: '1-1',
        [Disposition.Disp_1Izb]: '1-1',
        [Disposition.Disp_2Kk]: '2-kk',
        [Disposition.Disp_2_1]: '2-1',
        [Disposition.Disp_2Izb]: '2-1',
        [Disposition.Disp_3Kk]: '3-kk',
        [Disposition.Disp_3_1]: '3-1',
        [Disposition.Disp_3Izb]: '3-1',
        [Disposition.Disp_4Kk]: '4-kk',
        [Disposition.Disp_4_1]: '4-1',
        [Disposition.Disp_4Izb]: '4-1',
        [Disposition.Disp_5Kk]: '5-kk',
        [Disposition.Disp_5_1]: '5-1',
        [Disposition.Disp_5Izb]: '5-1',
        [Disposition.Disp_6Kk]: '6-kk',
        [Disposition.Disp_6_1]: '6-1',
        [Disposition.Disp_6Izb]: '6-1',
        [Disposition.Disp_7Kk]: '7-kk',
        [Disposition.Disp_7_1]: '7-1',
        [Disposition.Disp_7Izb]: '7-1',
        [Disposition.Ostatni]: 'ostatni',
    },
    [LocaleEnum.sk]: {
        [Disposition.Garsoniera]: 'garsoniera',
        [Disposition.Disp_1Kk]: '1-kk',
        [Disposition.Disp_1_1]: '1-1',
        [Disposition.Disp_1Izb]: '1-izb',
        [Disposition.Disp_2Kk]: '2-kk',
        [Disposition.Disp_2_1]: '2-1',
        [Disposition.Disp_2Izb]: '2-izb',
        [Disposition.Disp_3Kk]: '3-kk',
        [Disposition.Disp_3_1]: '3-1',
        [Disposition.Disp_3Izb]: '3-izb',
        [Disposition.Disp_4Kk]: '4-kk',
        [Disposition.Disp_4_1]: '4-1',
        [Disposition.Disp_4Izb]: '4-izb',
        [Disposition.Disp_5Kk]: '5-kk',
        [Disposition.Disp_5_1]: '5-1',
        [Disposition.Disp_5Izb]: '5-izb',
        [Disposition.Disp_6Kk]: '6-kk',
        [Disposition.Disp_6_1]: '6-1',
        [Disposition.Disp_6Izb]: '6-izb',
        [Disposition.Disp_7Kk]: '7-kk',
        [Disposition.Disp_7_1]: '7-1',
        [Disposition.Disp_7Izb]: '7-izb',
        [Disposition.Ostatni]: 'ostatni',
    },
    [LocaleEnum.en]: {
        [Disposition.Garsoniera]: 'garsoniera',
        [Disposition.Disp_1Kk]: '1-kk',
        [Disposition.Disp_1_1]: '1-1',
        [Disposition.Disp_1Izb]: '1-1',
        [Disposition.Disp_2Kk]: '2-kk',
        [Disposition.Disp_2_1]: '2-1',
        [Disposition.Disp_2Izb]: '2-1',
        [Disposition.Disp_3Kk]: '3-kk',
        [Disposition.Disp_3_1]: '3-1',
        [Disposition.Disp_3Izb]: '3-1',
        [Disposition.Disp_4Kk]: '4-kk',
        [Disposition.Disp_4_1]: '4-1',
        [Disposition.Disp_4Izb]: '4-1',
        [Disposition.Disp_5Kk]: '5-kk',
        [Disposition.Disp_5_1]: '5-1',
        [Disposition.Disp_5Izb]: '5-1',
        [Disposition.Disp_6Kk]: '6-kk',
        [Disposition.Disp_6_1]: '6-1',
        [Disposition.Disp_6Izb]: '6-1',
        [Disposition.Disp_7Kk]: '7-kk',
        [Disposition.Disp_7_1]: '7-1',
        [Disposition.Disp_7Izb]: '7-1',
        [Disposition.Ostatni]: 'ostatni',
    },
};

export const listDefaultType = Disposition.Undefined;

export const dispositionLabel = <FormattedMessage defaultMessage="Dispozice" />;
export const dispositionImperativeLabel = (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Dispozice'});

export const localeDispositions = {
    [LocaleEnum.cs]: [
        Disposition.Garsoniera,
        Disposition.Disp_1Kk,
        Disposition.Disp_1_1,
        Disposition.Disp_2Kk,
        Disposition.Disp_2_1,
        Disposition.Disp_3Kk,
        Disposition.Disp_3_1,
        Disposition.Disp_4Kk,
        Disposition.Disp_4_1,
        Disposition.Disp_5Kk,
        Disposition.Disp_5_1,
        Disposition.Disp_6Kk,
        Disposition.Disp_6_1,
        Disposition.Disp_7Kk,
        Disposition.Disp_7_1,
        Disposition.Ostatni,
    ],
    [LocaleEnum.sk]: [
        Disposition.Garsoniera,
        Disposition.Disp_1Izb,
        Disposition.Disp_2Izb,
        Disposition.Disp_3Izb,
        Disposition.Disp_4Izb,
        Disposition.Disp_5Izb,
        Disposition.Disp_6Izb,
        Disposition.Disp_7Izb,
        Disposition.Ostatni,
    ],
    [LocaleEnum.en]: [
        Disposition.Garsoniera,
        Disposition.Disp_1Kk,
        Disposition.Disp_1_1,
        Disposition.Disp_2Kk,
        Disposition.Disp_2_1,
        Disposition.Disp_3Kk,
        Disposition.Disp_3_1,
        Disposition.Disp_4Kk,
        Disposition.Disp_4_1,
        Disposition.Disp_5Kk,
        Disposition.Disp_5_1,
        Disposition.Disp_6Kk,
        Disposition.Disp_6_1,
        Disposition.Disp_7Kk,
        Disposition.Disp_7_1,
        Disposition.Ostatni,
    ],
};

export const actionFilterSlugs: Record<LocaleType, Record<Disposition, Disposition[]>> = {
    [LocaleEnum.cs]: {
        [Disposition.Garsoniera]: [Disposition.Garsoniera, Disposition.Disp_1Kk],
        [Disposition.Disp_1Kk]: [Disposition.Garsoniera, Disposition.Disp_1Kk, Disposition.Disp_1_1],
        [Disposition.Disp_1_1]: [Disposition.Disp_1Kk, Disposition.Disp_1_1, Disposition.Disp_2Kk],
        [Disposition.Disp_1Izb]: [Disposition.Disp_1Kk, Disposition.Disp_1_1, Disposition.Disp_2Kk],
        [Disposition.Disp_2Kk]: [Disposition.Disp_1_1, Disposition.Disp_2Kk, Disposition.Disp_2_1],
        [Disposition.Disp_2_1]: [Disposition.Disp_2Kk, Disposition.Disp_2_1, Disposition.Disp_3Kk],
        [Disposition.Disp_2Izb]: [Disposition.Disp_2Kk, Disposition.Disp_2_1, Disposition.Disp_3Kk],
        [Disposition.Disp_3Kk]: [Disposition.Disp_2_1, Disposition.Disp_3Kk, Disposition.Disp_3_1],
        [Disposition.Disp_3_1]: [Disposition.Disp_3Kk, Disposition.Disp_3_1, Disposition.Disp_4Kk],
        [Disposition.Disp_3Izb]: [Disposition.Disp_3Kk, Disposition.Disp_3_1, Disposition.Disp_4Kk],
        [Disposition.Disp_4Kk]: [Disposition.Disp_3_1, Disposition.Disp_4Kk, Disposition.Disp_4_1],
        [Disposition.Disp_4_1]: [Disposition.Disp_4Kk, Disposition.Disp_4_1, Disposition.Disp_5Kk],
        [Disposition.Disp_4Izb]: [Disposition.Disp_4Kk, Disposition.Disp_4_1, Disposition.Disp_5Kk],
        [Disposition.Disp_5Kk]: [Disposition.Disp_4_1, Disposition.Disp_5Kk, Disposition.Disp_5_1],
        [Disposition.Disp_5_1]: [Disposition.Disp_5Kk, Disposition.Disp_5_1, Disposition.Disp_6Kk],
        [Disposition.Disp_5Izb]: [Disposition.Disp_5Kk, Disposition.Disp_5_1, Disposition.Disp_6Kk],
        [Disposition.Disp_6Kk]: [Disposition.Disp_5_1, Disposition.Disp_6Kk, Disposition.Disp_6_1],
        [Disposition.Disp_6_1]: [Disposition.Disp_6Kk, Disposition.Disp_6_1, Disposition.Disp_7Kk],
        [Disposition.Disp_6Izb]: [Disposition.Disp_6Kk, Disposition.Disp_6_1, Disposition.Disp_7Kk],
        [Disposition.Disp_7Kk]: [Disposition.Disp_6_1, Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7_1]: [Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7Izb]: [Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Ostatni]: [Disposition.Ostatni],
        [Disposition.Undefined]: [],
    },
    [LocaleEnum.sk]: {
        [Disposition.Garsoniera]: [Disposition.Garsoniera, Disposition.Disp_1Kk, Disposition.Disp_1Izb],
        [Disposition.Disp_1Kk]: [Disposition.Garsoniera, Disposition.Disp_1Kk, Disposition.Disp_1_1],
        [Disposition.Disp_1_1]: [Disposition.Disp_1Kk, Disposition.Disp_1_1, Disposition.Disp_2Kk],
        [Disposition.Disp_1Izb]: [Disposition.Garsoniera, Disposition.Disp_1Izb, Disposition.Disp_2Izb],
        [Disposition.Disp_2Kk]: [Disposition.Disp_1_1, Disposition.Disp_2Kk, Disposition.Disp_2_1],
        [Disposition.Disp_2_1]: [Disposition.Disp_2Kk, Disposition.Disp_2_1, Disposition.Disp_3Kk],
        [Disposition.Disp_2Izb]: [Disposition.Disp_1Izb, Disposition.Disp_2Izb, Disposition.Disp_3Izb],
        [Disposition.Disp_3Kk]: [Disposition.Disp_2_1, Disposition.Disp_3Kk, Disposition.Disp_3_1],
        [Disposition.Disp_3_1]: [Disposition.Disp_3Kk, Disposition.Disp_3_1, Disposition.Disp_4Kk],
        [Disposition.Disp_3Izb]: [Disposition.Disp_2Izb, Disposition.Disp_3Izb, Disposition.Disp_4Izb],
        [Disposition.Disp_4Kk]: [Disposition.Disp_3_1, Disposition.Disp_4Kk, Disposition.Disp_4_1],
        [Disposition.Disp_4_1]: [Disposition.Disp_4Kk, Disposition.Disp_4_1, Disposition.Disp_5Kk],
        [Disposition.Disp_4Izb]: [Disposition.Disp_3Izb, Disposition.Disp_4Izb, Disposition.Disp_5Izb],
        [Disposition.Disp_5Kk]: [Disposition.Disp_4_1, Disposition.Disp_5Kk, Disposition.Disp_5_1],
        [Disposition.Disp_5_1]: [Disposition.Disp_5Kk, Disposition.Disp_5_1, Disposition.Disp_6Kk],
        [Disposition.Disp_5Izb]: [Disposition.Disp_4Izb, Disposition.Disp_5Izb, Disposition.Disp_6Izb],
        [Disposition.Disp_6Kk]: [Disposition.Disp_5_1, Disposition.Disp_6Kk, Disposition.Disp_6_1],
        [Disposition.Disp_6_1]: [Disposition.Disp_6Kk, Disposition.Disp_6_1, Disposition.Disp_7Kk],
        [Disposition.Disp_6Izb]: [Disposition.Disp_5Izb, Disposition.Disp_6Izb, Disposition.Disp_7Izb],
        [Disposition.Disp_7Kk]: [Disposition.Disp_6_1, Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7_1]: [Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7Izb]: [Disposition.Disp_6Izb, Disposition.Disp_7Izb],
        [Disposition.Ostatni]: [Disposition.Ostatni],
        [Disposition.Undefined]: [],
    },
    [LocaleEnum.en]: {
        [Disposition.Garsoniera]: [Disposition.Garsoniera, Disposition.Disp_1Kk],
        [Disposition.Disp_1Kk]: [Disposition.Garsoniera, Disposition.Disp_1Kk, Disposition.Disp_1_1],
        [Disposition.Disp_1_1]: [Disposition.Disp_1Kk, Disposition.Disp_1_1, Disposition.Disp_2Kk],
        [Disposition.Disp_1Izb]: [Disposition.Disp_1Kk, Disposition.Disp_1_1, Disposition.Disp_2Kk],
        [Disposition.Disp_2Kk]: [Disposition.Disp_1_1, Disposition.Disp_2Kk, Disposition.Disp_2_1],
        [Disposition.Disp_2_1]: [Disposition.Disp_2Kk, Disposition.Disp_2_1, Disposition.Disp_3Kk],
        [Disposition.Disp_2Izb]: [Disposition.Disp_2Kk, Disposition.Disp_2_1, Disposition.Disp_3Kk],
        [Disposition.Disp_3Kk]: [Disposition.Disp_2_1, Disposition.Disp_3Kk, Disposition.Disp_3_1],
        [Disposition.Disp_3_1]: [Disposition.Disp_3Kk, Disposition.Disp_3_1, Disposition.Disp_4Kk],
        [Disposition.Disp_3Izb]: [Disposition.Disp_3Kk, Disposition.Disp_3_1, Disposition.Disp_4Kk],
        [Disposition.Disp_4Kk]: [Disposition.Disp_3_1, Disposition.Disp_4Kk, Disposition.Disp_4_1],
        [Disposition.Disp_4_1]: [Disposition.Disp_4Kk, Disposition.Disp_4_1, Disposition.Disp_5Kk],
        [Disposition.Disp_4Izb]: [Disposition.Disp_4Kk, Disposition.Disp_4_1, Disposition.Disp_5Kk],
        [Disposition.Disp_5Kk]: [Disposition.Disp_4_1, Disposition.Disp_5Kk, Disposition.Disp_5_1],
        [Disposition.Disp_5_1]: [Disposition.Disp_5Kk, Disposition.Disp_5_1, Disposition.Disp_6Kk],
        [Disposition.Disp_5Izb]: [Disposition.Disp_5Kk, Disposition.Disp_5_1, Disposition.Disp_6Kk],
        [Disposition.Disp_6Kk]: [Disposition.Disp_5_1, Disposition.Disp_6Kk, Disposition.Disp_6_1],
        [Disposition.Disp_6_1]: [Disposition.Disp_6Kk, Disposition.Disp_6_1, Disposition.Disp_7Kk],
        [Disposition.Disp_6Izb]: [Disposition.Disp_6Kk, Disposition.Disp_6_1, Disposition.Disp_7Kk],
        [Disposition.Disp_7Kk]: [Disposition.Disp_6_1, Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7_1]: [Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Disp_7Izb]: [Disposition.Disp_7Kk, Disposition.Disp_7_1],
        [Disposition.Ostatni]: [Disposition.Ostatni],
        [Disposition.Undefined]: [],
    },
};

// #20207
export const localizedOrderedByFrequency = {
    [LocaleEnum.cs]: {
        [EstateType.Byt]: [
            Disposition.Disp_1Kk,
            Disposition.Disp_1_1,
            Disposition.Disp_2Kk,
            Disposition.Disp_2_1,
            Disposition.Disp_3Kk,
            Disposition.Disp_3_1,
            Disposition.Garsoniera,
            Disposition.Disp_4Kk,
            Disposition.Disp_4_1,
            Disposition.Disp_5Kk,
            Disposition.Disp_5_1,
            Disposition.Disp_6Kk,
            Disposition.Disp_6_1,
            Disposition.Disp_7Kk,
            Disposition.Disp_7_1,
            Disposition.Ostatni,
        ],
        [EstateType.Dum]: [
            Disposition.Disp_2Kk,
            Disposition.Disp_3Kk,
            Disposition.Disp_4Kk,
            Disposition.Disp_4_1,
            Disposition.Disp_5Kk,
            Disposition.Ostatni,
            Disposition.Garsoniera,
            Disposition.Disp_1Kk,
            Disposition.Disp_1_1,
            Disposition.Disp_2_1,
            Disposition.Disp_3_1,
            Disposition.Disp_5_1,
            Disposition.Disp_6Kk,
            Disposition.Disp_6_1,
            Disposition.Disp_7Kk,
            Disposition.Disp_7_1,
        ],
        [EstateType.Garaz]: [],
        [EstateType.Pozemek]: [],
        [EstateType.NebytovyProstor]: [],
        [EstateType.RekreacniObjekt]: [],
        [EstateType.Kancelar]: [],
        [EstateType.Undefined]: [],
    },
    [LocaleEnum.sk]: {
        [EstateType.Byt]: [
            Disposition.Garsoniera,
            Disposition.Disp_1Izb,
            Disposition.Disp_2Izb,
            Disposition.Disp_3Izb,
            Disposition.Disp_4Izb,
            Disposition.Disp_5Izb,
            Disposition.Disp_6Izb,
            Disposition.Disp_7Izb,
            Disposition.Ostatni,
        ],
        [EstateType.Dum]: [
            Disposition.Garsoniera,
            Disposition.Disp_2Izb,
            Disposition.Disp_3Izb,
            Disposition.Disp_4Izb,
            Disposition.Disp_5Izb,
            Disposition.Ostatni,
            Disposition.Disp_1Izb,
            Disposition.Disp_6Izb,
            Disposition.Disp_7Izb,
        ],
        [EstateType.Garaz]: [],
        [EstateType.Pozemek]: [],
        [EstateType.NebytovyProstor]: [],
        [EstateType.RekreacniObjekt]: [],
        [EstateType.Kancelar]: [],
        [EstateType.Undefined]: [],
    },
    [LocaleEnum.en]: {
        [EstateType.Byt]: [
            Disposition.Disp_1Kk,
            Disposition.Disp_1_1,
            Disposition.Disp_2Kk,
            Disposition.Disp_2_1,
            Disposition.Disp_3Kk,
            Disposition.Disp_3_1,
            Disposition.Garsoniera,
            Disposition.Disp_4Kk,
            Disposition.Disp_4_1,
            Disposition.Disp_5Kk,
            Disposition.Disp_5_1,
            Disposition.Disp_6Kk,
            Disposition.Disp_6_1,
            Disposition.Disp_7Kk,
            Disposition.Disp_7_1,
            Disposition.Ostatni,
        ],
        [EstateType.Dum]: [
            Disposition.Disp_2Kk,
            Disposition.Disp_3Kk,
            Disposition.Disp_4Kk,
            Disposition.Disp_4_1,
            Disposition.Disp_5Kk,
            Disposition.Ostatni,
            Disposition.Garsoniera,
            Disposition.Disp_1Kk,
            Disposition.Disp_1_1,
            Disposition.Disp_2_1,
            Disposition.Disp_3_1,
            Disposition.Disp_5_1,
            Disposition.Disp_6Kk,
            Disposition.Disp_6_1,
            Disposition.Disp_7Kk,
            Disposition.Disp_7_1,
        ],
        [EstateType.Garaz]: [],
        [EstateType.Pozemek]: [],
        [EstateType.NebytovyProstor]: [],
        [EstateType.RekreacniObjekt]: [],
        [EstateType.Kancelar]: [],
        [EstateType.Undefined]: [],
    },
};
