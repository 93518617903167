import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconRestaurant: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="restaurant">
			<path d="M19.004 2.249a.725.725 0 01.503.206l.079.088a.728.728 0 01.169.562l-.015.07v10.843l.01.982v4.5c0 1.11-.806 2.041-1.91 2.222l-.16.02-.18.008a2.25 2.25 0 01-2.245-2.096l-.005-.154v-3.75h-.75a1.25 1.25 0 01-1.244-1.122l-.006-.128v-3.186c0-4.855 2.371-8.902 5.547-9.06l.158-.003.049-.002zM10 2.25a.75.75 0 01.743.648L10.75 3v5.4a2.75 2.75 0 01-2.499 2.739v1.24a2.252 2.252 0 011.493 1.95l.006.171v5a2.25 2.25 0 01-4.495.154L5.25 19.5v-5c0-.98.626-1.814 1.5-2.122v-1.24a2.75 2.75 0 01-2.495-2.57L4.25 8.4V3a.75.75 0 011.493-.102L5.75 3v3.75h1v-3.5a.75.75 0 011.493-.102l.007.102v3.5h1V3a.75.75 0 01.75-.75zm8.25 13.5h-1.5v3.75c0 .342.229.63.521.721l.1.023.093.007.11-.004a.75.75 0 00.669-.643l.007-.104v-3.75zm-10.75-2a.75.75 0 00-.743.648l-.007.102v5a.75.75 0 001.493.102l.007-.102v-5a.75.75 0 00-.75-.75zm10.74-9.875l-.13.047c-1.803.711-3.271 3.55-3.356 7.07l-.004.322v2.936h3.49l-.001-.434.001-.035V3.875zM5.75 8.25v.15c0 .647.492 1.18 1.122 1.244L7 9.65h1a1.25 1.25 0 001.244-1.122L9.25 8.4v-.15h-3.5z" />
		</Icon>
	);
};
