import classNames from 'classnames';
import React, {FC} from 'react';
import styles from './Image.module.scss';

export type ImageProps = {
    layout?: 'fill';
    className?: string;
    caption?: string;
    smallCaption?: boolean;
};

export const Image: FC<React.PropsWithChildren<ImageProps>> = ({layout, className, caption, smallCaption, children}) => {
    return (
        <span
            className={classNames(styles.image, 'image', className, {
                [styles[`image--${layout}`]]: layout,
                ['position-absolute w-100 h-100 top-0 start-0']: layout === 'fill',
                ['text-center']: caption,
            })}
        >
            <span className="position-relative d-block w-100 h-100">{children}</span>
            {caption && (
                <span className={classNames(styles['image-caption--wrapper'])}>
                    <span className={classNames(styles['image-caption'], 'image-caption', {[styles['image-caption-small']]: smallCaption})}>
                        {caption}
                    </span>
                </span>
            )}
        </span>
    );
};
