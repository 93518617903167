import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconCalendarViewMonth: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="calendar-view-month">
            <path d="M20 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h16ZM8 13H4v5h4v-5Zm6 0h-4v5h4v-5Zm6 0h-4v5h4v-5ZM8 6H4v5h4V6Zm6 0h-4v5h4V6Zm6 0h-4v5h4V6Z" />
        </Icon>
    );
};
