import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconUser: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="user">
            <path d="M13.996 12.277a4.5 4.5 0 0 0-3.988-8.066 4.5 4.5 0 0 0 0 8.066A7.497 7.497 0 0 0 4.5 19.5v.75c0 .414.336.75.75.75h13.5a.75.75 0 0 0 .75-.75v-.75a7.498 7.498 0 0 0-5.504-7.223ZM9 8.25a3 3 0 1 1 6.002.002A3 3 0 0 1 9 8.25ZM6 19.5c0-3.313 2.688-6 6-6 3.313 0 6 2.688 6 6Zm0 0" />
        </Icon>
    );
};
