import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDocumentHouse: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="document-house">
			<path d="M18 2.25a2.75 2.75 0 012.745 2.582L20.75 5v4a.75.75 0 01-1.493.102L19.25 9V5a1.25 1.25 0 00-1.122-1.244L18 3.75H5a1.25 1.25 0 00-1.244 1.122L3.75 5v14c0 .647.492 1.18 1.122 1.244L5 20.25h3a.75.75 0 01.102 1.493L8 21.75H5a2.75 2.75 0 01-2.745-2.582L2.25 19V5a2.75 2.75 0 012.582-2.745L5 2.25h13zm-.864 8.174l.114.076 5.2 3.9a.75.75 0 01-.7 1.307V20.5a1.25 1.25 0 01-1.122 1.244l-.128.006h-8a1.25 1.25 0 01-1.244-1.122l-.006-.128v-4.793a.75.75 0 01-.777-.173l-.073-.084a.75.75 0 01.066-.977l.084-.073 5.2-3.9a1.25 1.25 0 011.386-.076zm-.636 1.389l-3.75 2.812v5.625h1.45V17.5c0-.647.492-1.18 1.122-1.244l.128-.006h2c.647 0 1.18.492 1.244 1.122l.006.128v2.75h1.55v-5.625l-3.75-2.812zm.7 5.937h-1.5v2.5h1.5v-2.5zM8 14.25a.75.75 0 01.102 1.493L8 15.75H7a.75.75 0 01-.102-1.493L7 14.25h1zm2-4a.75.75 0 01.102 1.493L10 11.75H7a.75.75 0 01-.102-1.493L7 10.25h3zm6-4a.75.75 0 01.102 1.493L16 7.75H7a.75.75 0 01-.102-1.493L7 6.25h9z" />
		</Icon>
	);
};
