import {Icon, IconProps} from '.';

export const IconDocumentCalculator = ({...props}: IconProps) => {
    return (
        <Icon {...props} id="document-calculator">
            <g strokeLinecap="round" strokeWidth="1.5" stroke="#323232" fill="none" strokeLinejoin="round">
                <path d="M21 11.5v8.5 0c0 .552285-.447715 1-1 1h-6 -4.37114e-08c-.552285-2.41411e-08-1-.447715-1-1 0 0 0-3.55271e-15 0-3.55271e-15v-8.5l1.06581e-14 1.50996e-07c-8.33927e-08-.552285.447715-1 1-1h6l-1.19249e-08 3.55271e-15c.552285-8.99786e-08 1 .447715 1 1 1.06581e-14 5.09785e-08 1.42109e-14 1.00017e-07 1.42109e-14 1.50996e-07Z"></path>
                <path d="M21 14h-8"></path>
                <path d="M6.5 7h8.5"></path>
                <path d="M6.5 10.5h2.5"></path>
                <path d="M6.5 14h2.5"></path>
                <path d="M10 18h-5l-8.74228e-08-3.55271e-15c-1.10457-4.82823e-08-2-.895431-2-2 0 0 0 0 0 0v-11l2.30926e-14 3.01992e-07c-1.66785e-07-1.10457.89543-2 2-2h12l-8.74228e-08 1.77636e-15c1.10457-4.82823e-08 2 .89543 2 2v3"></path>
                <path d="M15.75 16.2h-2.18557e-09c-.0276142 1.20706e-09-.05.0223858-.05.05 1.20706e-09.0276142.0223858.05.05.05 .0276142-1.20706e-09.05-.0223858.05-.05v-3.55271e-15c0-.0276142-.0223858-.05-.05-.05 0 0-1.77636e-15 0-1.77636e-15 0"></path>
                <path d="M18.25 16.2h-2.18557e-09c-.0276142 1.20706e-09-.05.0223858-.05.05 1.20706e-09.0276142.0223858.05.05.05 .0276142-1.20706e-09.05-.0223858.05-.05v-3.55271e-15c0-.0276142-.0223858-.05-.05-.05 0 0 0 0 0 0"></path>
                <path d="M15.75 18.7h-2.18557e-09c-.0276142 1.20706e-09-.05.0223858-.05.05 1.20706e-09.0276142.0223858.05.05.05 .0276142-1.20706e-09.05-.0223858.05-.05v-3.55271e-15c0-.0276142-.0223858-.05-.05-.05 0 0-1.77636e-15 0-1.77636e-15 0"></path>
                <path d="M18.25 18.7h-2.18557e-09c-.0276142 1.20706e-09-.05.0223858-.05.05 1.20706e-09.0276142.0223858.05.05.05 .0276142-1.20706e-09.05-.0223858.05-.05v-3.55271e-15c0-.0276142-.0223858-.05-.05-.05 0 0 0 0 0 0"></path>
            </g>
            <path fill="none" d="M0 0h24v24h-24Z"></path>
        </Icon>
    );
};
