import {Container} from '@components/Container';
import {Typography} from '@components/Typography';

type HeaderBannerProps = {
    message: string;
};

export const HeaderBanner = ({message}: HeaderBannerProps) => (
    <div className="bg-warning py-3">
        <Container className="text-center">
            <Typography as="span" textAlign="center" variant="caption">
                {message}
            </Typography>
        </Container>
    </div>
);
