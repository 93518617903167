import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconGlobe: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="globe">
			<path d="M12 2.25A9.735 9.735 0 0121.75 12a9.735 9.735 0 01-9.38 9.743l-.37.007A9.735 9.735 0 012.25 12 9.735 9.735 0 0112 2.25zM9.499 15.865l-.238.021c.343 1.28.86 2.517 1.55 3.67a1.365 1.365 0 002.379 0 14.406 14.406 0 001.55-3.669 29.437 29.437 0 00-4.59-.07l-.651.048zm-1.74.204l-.437.068c-.744.123-1.472.277-2.177.462a8.237 8.237 0 004.061 3.169 15.9 15.9 0 01-1.318-3.221l-.13-.478zm8.484 0l-.013.048a15.97 15.97 0 01-1.435 3.651 8.25 8.25 0 004.06-3.168 24.484 24.484 0 00-2.612-.532zM4.41 8.756l-.058.137A8.258 8.258 0 003.75 12c0 1.153.235 2.25.66 3.246a25.48 25.48 0 013.045-.65 16.571 16.571 0 010-5.193 26.481 26.481 0 01-2.454-.49l-.592-.156zm15.182-.003l-.404.111c-.854.221-1.738.4-2.641.538a16.575 16.575 0 010 5.195 25.81 25.81 0 013.045.645c.424-.993.659-2.09.659-3.243s-.235-2.25-.66-3.246zm-4.534.835l-.223.023c-1.947.18-3.946.172-5.889-.022a15.064 15.064 0 000 4.82 30.942 30.942 0 016.111 0c.233-1.434.257-2.9.07-4.341l-.07-.48zM12 3.75l-.124.006a1.386 1.386 0 00-1.065.69 14.406 14.406 0 00-1.55 3.668c1.81.17 3.67.17 5.479 0a14.4 14.4 0 00-1.55-3.668 1.388 1.388 0 00-1.066-.69L12 3.75zm2.795.483l.06.11a15.984 15.984 0 011.388 3.588c.895-.133 1.77-.31 2.612-.53a8.24 8.24 0 00-3.75-3.05l-.31-.118zm-5.59 0l-.088.032A8.246 8.246 0 005.144 7.4c.842.221 1.718.398 2.614.532a15.995 15.995 0 011.448-3.7z" />
		</Icon>
	);
};
