import classNames from 'classnames';
import React, {FC} from 'react';
import {Spinner as SpinnerComponent, SpinnerProps as SpinnerComponentProps} from 'react-bootstrap';
import {Box} from '../Box';
import {Icon} from '../Icon';
import styles from './Spinner.module.scss';

type SpinnerProps = SpinnerComponentProps & {
    overlay?: boolean;
    fullPage?: boolean;
    className?: string;
    showSymbol?: boolean;
};

export const Spinner: FC<React.PropsWithChildren<SpinnerProps>> = ({
    animation = 'border',
    role = 'status',
    variant = 'green',
    overlay,
    fullPage,
    className,
    showSymbol = true,
    children,
    ...props
}) => {
	return (
		<Box
			className={classNames(styles.spinner, 'd-inline-flex align-items-center justify-content-center', className, {
				[styles['spinner--overlay']]: overlay,
				[styles['spinner--fullpage']]: fullPage,
				['position-absolute top-0 start-0 w-100 h-100']: overlay,
				['position-fixed top-0 start-0 w-100 h-100']: fullPage,
			})}
		>
			{showSymbol && <Icon.LogoSymbol className={styles.spinnerIcon} />}

            <SpinnerComponent {...props} animation={animation} role={role} variant={variant}>
                {children}
            </SpinnerComponent>
        </Box>
    );
};
