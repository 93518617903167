import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEnvelopeCheck: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="envelope-check">
			<path d="M16.79 3.117a2.68 2.68 0 012.675 2.51l.005.17v6.29a4.59 4.59 0 11-6.24 5.01H4.32a2.67 2.67 0 01-2.665-2.501l-.005-.17V5.77l.008-.18a2.68 2.68 0 012.505-2.467l.168-.005h12.458zm-1.216 11.045a3.078 3.078 0 00-.896 1.96l-.008.235a3.09 3.09 0 105.409-2.053l-.134-.142-.143-.134a3.09 3.09 0 00-4.228.134zm4.21.908a.75.75 0 01.072.976l-.073.084-1.867 1.868a.75.75 0 01-.977.072l-.084-.073-1.12-1.12a.75.75 0 01.978-1.133l.084.073.588.589 1.337-1.336a.75.75 0 011.061 0zM3.15 8.235v6.192c0 .603.456 1.1 1.042 1.163l.128.007h8.91a4.57 4.57 0 011.284-2.495 4.578 4.578 0 013.457-1.34l-.001-3.526-4.818 3.266a4.62 4.62 0 01-4.984.126l-.198-.127-4.82-3.266zm13.64-3.618H4.33a1.18 1.18 0 00-1.16.943l-.015.115-.005.108v.03c.005.342.157.663.415.883l.103.078 5.143 3.486a3.12 3.12 0 003.32.112l.18-.112 5.141-3.485a1.183 1.183 0 00-.542-2.152l-.12-.006z" />
		</Icon>
	);
};
