import {addMethod, object, Message, string, TestContext, ValidationError} from 'yup';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js';
import {DateTime} from 'luxon';
import {LazyQueryExecFunction} from '@apollo/client';
import {CalendarEntriesQuery} from '@gql/query/calendar/entries.gql';
import {nonNullArray, propertyRequiredArray} from '../types/general';

type IntervalObject = {from: Date | undefined | null; to: Date | null | undefined} | null | undefined;
type RootValues =
    | {
          dateFrom: Date | null | undefined;
          dateTo: Date | null | undefined;
          interval: number | null | undefined;
      }
    | null
    | undefined;

addMethod(string, 'phone', function (defaultCountry: CountryCode, errorMessage: Message) {
    return this.test({
        name: 'test-phone-number',
        message: errorMessage,
        skipAbsent: true,
        test: function (value: string | undefined) {
            if (typeof value !== 'string') {
                return this.createError({path: this.path, message: errorMessage});
            }
            try {
                const phone = parsePhoneNumber(value, defaultCountry);
                if (!phone.isValid()) {
                    return this.createError({path: this.path, message: errorMessage});
                }
            } catch (e) {
                return this.createError({path: this.path, message: errorMessage});
            }

            return true;
        },
    });
});

addMethod(object, 'selfIntervalOverlap', function (errorMessage: Message) {
    return this.test({
        name: 'test-self-interval-overlap',
        message: errorMessage,
        test: function (value: IntervalObject, context: TestContext) {
            const parentValues = context.parent as IntervalObject[];
            if (!value || !Array.isArray(parentValues)) {
                return true;
            }

            try {
                if (
                    parentValues.filter((target) => {
                        if (
                            typeof value !== 'object' ||
                            !value.hasOwnProperty('from') ||
                            !value.from ||
                            !value.hasOwnProperty('to') ||
                            !value.to ||
                            !target ||
                            typeof target !== 'object' ||
                            !target.hasOwnProperty('from') ||
                            !target.hasOwnProperty('to') ||
                            !target.from ||
                            !target.to
                        ) {
                            throw this.createError({path: this.path, message: 'Invalid object schema'});
                        }

                        return (
                            (value.from < target.to && value.from >= target.from) ||
                            (target.from < value.to && target.from >= value.from) ||
                            (value.to <= target.to && value.to > target.from) ||
                            (target.to <= value.to && target.from > value.from)
                        );
                    }).length > 1
                ) {
                    throw this.createError({path: this.path, message: errorMessage});
                }
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }

            return true;
        },
    });
});

/*
addMethod(array, 'intervalOverlap', function (intervals: {from: Date; to: Date}[], errorMessage: Message) {
    return this.test({
        name: 'test-interval-overlap',
        message: errorMessage,
        test: function (value: any[] | undefined) {
            if (!value || !Array.isArray(value)) {
                return true;
            }

            try {
                value.forEach((origin) => {
                    intervals.forEach((origTarget, targetIdx) => {
                        //const origin = value[originIdx];
                        const originFromDate = DateTime.fromJSDate(origin.from);
                        const originToDate = DateTime.fromJSDate(origin.to);
                        //const origTarget = intervals[targetIdx];
                        const target = {
                            from: DateTime.fromJSDate(origTarget.from)
                                .set({
                                    day: originFromDate.day,
                                    month: originFromDate.month,
                                    year: originFromDate.year,
                                })
                                .toJSDate(),
                            to: DateTime.fromJSDate(origTarget.to)
                                .set({
                                    day: originToDate.day,
                                    month: originToDate.month,
                                    year: originToDate.year,
                                })
                                .toJSDate(),
                        };
                        if (typeof origin !== 'object' || !origin.hasOwnProperty('from') || !origin.hasOwnProperty('to')) {
                            throw this.createError({path: this.path, message: 'Invalid object schema'});
                        }

                        if (
                            (origin.from < target.to && origin.from >= target.from) ||
                            (target.from < origin.to && target.from >= origin.from) ||
                            (origin.to <= target.to && origin.to > target.from) ||
                            (target.to <= origin.to && target.to > origin.from)
                        ) {
                            throw this.createError({path: this.path + '.' + targetIdx, message: errorMessage});
                        }
                    });
                });
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }

                throw e;
            }

            return true;
        },
    });
});
*/

addMethod(object, 'intervalLazyOverlap', function (getter: LazyQueryExecFunction<CalendarEntriesQuery, any>, errorMessage: Message) {
    return this.test({
        name: 'test-interval-overlap',
        message: errorMessage,
        test: async function (value: IntervalObject, context: TestContext) {
            const rootValues = context.from?.at(-1)?.value as RootValues;

            if (!rootValues?.dateFrom || !rootValues.dateTo || !value?.from || !value.to) {
                return true;
            }

            const data = await getter({
                variables: {
                    from: DateTime.fromJSDate(rootValues.dateFrom).toSeconds(),
                    to: DateTime.fromJSDate(rootValues.dateTo).endOf('day').set({millisecond: 0}).toSeconds(),
                },
            });
            const intervals = propertyRequiredArray(nonNullArray(data.data?.calendarEntryList?.list ?? []), ['timeTo', 'timeFrom']).map(
                (entry) => ({
                    from: DateTime.fromSeconds(entry.timeFrom).toJSDate(),
                    to: DateTime.fromSeconds(entry.timeTo).toJSDate(),
                    advert: entry.advert,
                }),
            );

            try {
                intervals.forEach((origTarget) => {
                    if (!value.from || !value.to) {
                        return true;
                    }

                    const valueFromDate = DateTime.fromJSDate(value.from);
                    const valueToDate = DateTime.fromJSDate(value.to);
                    const target = {
                        from: DateTime.fromJSDate(origTarget.from)
                            .set({
                                day: valueFromDate.day,
                                month: valueFromDate.month,
                                year: valueFromDate.year,
                            })
                            .toJSDate(),
                        to: DateTime.fromJSDate(origTarget.to)
                            .set({
                                day: valueToDate.day,
                                month: valueToDate.month,
                                year: valueToDate.year,
                            })
                            .toJSDate(),
                    };

                    if (
                        (value.from < target.to && value.from >= target.from) ||
                        (target.from < value.to && target.from >= value.from) ||
                        (value.to <= target.to && value.to > target.from) ||
                        (target.to <= value.to && target.to > value.from)
                    ) {
                        throw this.createError({
                            path: this.path,
                            message: errorMessage,
                            params: {advert: origTarget.advert},
                        });
                    }
                });
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }

                throw e;
            }

            return true;
        },
    });
});

addMethod(object, 'minIntervalLength', function (errorMessage: Message) {
    return this.test({
        name: 'test-min-interval-length',
        message: errorMessage,
        test: function (value: IntervalObject, context: TestContext) {
            const rootValues = context.from?.at(-1)?.value as RootValues;
            if (!rootValues?.interval) {
                return true;
            }

            const length = rootValues.interval;

            try {
                //const origin = value[originIdx];
                if (
                    !value ||
                    typeof value !== 'object' ||
                    !value.hasOwnProperty('from') ||
                    !value.hasOwnProperty('to') ||
                    !(value.from instanceof Date) ||
                    !(value.to instanceof Date)
                ) {
                    throw this.createError({path: this.path, message: 'Invalid object schema'});
                }

                if (DateTime.fromJSDate(value.to).diff(DateTime.fromJSDate(value.from)).as('minute') < length - 1) {
                    throw this.createError({path: this.path, message: errorMessage});
                }
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }

            return true;
        },
    });
});

addMethod(object, 'isUpcomingInterval', function (errorMessage: Message) {
    return this.test({
        name: 'test-is-upcoming-interval',
        message: errorMessage,
        test: function (value: IntervalObject, context: TestContext) {
            const rootValues = context.from?.at(-1)?.value as RootValues;

            if (!value || !rootValues?.dateFrom || !rootValues.dateTo || !value.from || !value.to) {
                return true;
            }

            try {
                const today = DateTime.now().startOf('day');
                const startDate = DateTime.fromJSDate(rootValues.dateFrom);
                const endDate = DateTime.fromJSDate(rootValues.dateTo);

                if (endDate && endDate.diff(today, 'days').as('days') >= 1) {
                    return true;
                }

                const originDate = DateTime.fromJSDate(value.from);

                if (
                    startDate
                        .set({
                            hour: originDate.hour,
                            minute: originDate.minute,
                        })
                        .diffNow('minutes')
                        .as('minutes') <= 0
                ) {
                    throw this.createError({path: this.path, message: errorMessage});
                }

                return true;
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }
        },
    });
});
