import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-right">
			<path d="M12.414 3.354a2.33 2.33 0 01.119 3.165l-.119.127-.603.603 8.689.001a2.25 2.25 0 01.154 4.495l-.154.005-2.878-.001a2.25 2.25 0 01-1.103 2.758 2.25 2.25 0 01-1 3 2.25 2.25 0 01-1.865 3.238l-.154.005H9.165A7.915 7.915 0 014.542 6.41l.233-.161 4.77-3.18a2.275 2.275 0 012.87.285zM12 11.75a.75.75 0 01-.102-1.493L12 10.25h8.5a.75.75 0 00.102-1.493L20.5 8.75 9.953 8.749l-.124-.019-.062-.017-.07-.027-.06-.03L9.55 8.6l-.08-.07a.749.749 0 01-.096-.118l-.044-.076-.007-.013A.747.747 0 019.25 8l.002-.057.013-.09.029-.107.039-.089.029-.051.035-.052a.785.785 0 01.073-.084l1.884-1.884a.829.829 0 000-1.172.775.775 0 00-.872-.156l-.106.059-4.769 3.18a6.415 6.415 0 003.328 11.749l.23.004H13.5a.75.75 0 00.102-1.493l-.102-.007H11a.75.75 0 01-.102-1.493L11 16.25h3.5a.75.75 0 00.102-1.493l-.102-.007H12a.75.75 0 01-.102-1.493L12 13.25h3.5a.75.75 0 00.102-1.493l-.102-.007H12z" />
		</Icon>
	);
};
