import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMessageUnread: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="message-unread">
			<path d="M12.56 3.236a.75.75 0 01.102 1.493l-.102.007H5.833c-1.1 0-2.002.853-2.078 1.934l-.005.149v7.556c0 1.1.853 2.002 1.934 2.078l.149.005h1.89a.75.75 0 01.743.648l.007.102-.001 2.217 3.504-2.803a.75.75 0 01.368-.157l.101-.007h4.722c1.1 0 2.002-.853 2.078-1.934l.005-.149V11.56a.75.75 0 011.493-.102l.007.102v2.815a3.583 3.583 0 01-3.393 3.578l-.19.005h-4.46l-4.515 3.614a.75.75 0 01-1.212-.484l-.007-.102v-3.028h-1.14a3.583 3.583 0 01-3.578-3.393l-.005-.19V6.819a3.583 3.583 0 013.393-3.578l.19-.005h6.727zm8.354-.163a2.856 2.856 0 11-4.04 4.04 2.856 2.856 0 114.04-4.04zm-2.98 1.06a1.356 1.356 0 102.018.11l-.1-.11-.11-.099a1.356 1.356 0 00-1.808.1z" />
		</Icon>
	);
};
