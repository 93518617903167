import classNames from 'classnames';
import {PropsWithChildren, forwardRef} from 'react';
import {BsPrefixProps} from 'react-bootstrap/esm/helpers';
import {Background, Color} from '../../types/colors';
import {Box, BoxProps} from '../Box';
import styles from './ContentBox.module.scss';

export type ContentBoxProps = BoxProps & {
    background?: Background | false;
    color?: Color;
    shadow?: boolean;
    outline?: 'grey-medium' | 'grey-light' | boolean;
    selected?: boolean;
    className?: string;
};

export const ContentBox = forwardRef<HTMLDivElement, PropsWithChildren<ContentBoxProps> & BsPrefixProps>(
    ({background = 'white', color, rounded = 'lg', shadow, outline, selected, className, children, ...props}, ref) => {
        return (
            <Box
                ref={ref}
                {...props}
                rounded={rounded}
                className={classNames(styles.contentBox, 'contentBox mb-last-0', className, {
                    [styles[`contentBox--outline-${outline === true ? 'grey-medium' : outline}`]]: outline,
                    [styles[`contentBox--selected`]]: selected,
                    [styles[`contentBox--shadow`]]: shadow,
                    [`bg-${background}`]: background,
                    [`text-${color}`]: color,
                })}
            >
                {children}
            </Box>
        );
    },
);
