import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconPin: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="pin">
            <path d="m15.94 4 4.07 4.06a2.54 2.54 0 0 1-.39 3.9 10.28 10.28 0 0 0-4.27 6.06l-.37 1.48a1.64 1.64 0 0 1-2.76.77l-3.71-3.72-3.98 3.98a.75.75 0 0 1-1.06-1.06l3.98-3.98-3.72-3.71a1.64 1.64 0 0 1 .77-2.76l1.48-.37a10.28 10.28 0 0 0 6.06-4.27 2.54 2.54 0 0 1 3.9-.39Zm-1.76.75h-.13c-.31.03-.6.2-.76.46a11.78 11.78 0 0 1-6.95 4.9l-1.48.37a.14.14 0 0 0-.07.24l4.24 4.24h.01l4.24 4.25a.14.14 0 0 0 .24-.07l.37-1.48a11.78 11.78 0 0 1 4.9-6.94 1.04 1.04 0 0 0 .16-1.6l-4.07-4.07a1.04 1.04 0 0 0-.57-.29l-.13-.01Z" />
        </Icon>
    );
};
