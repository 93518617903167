import {PropsWithChildren} from 'react';

import messages from 'src/translations/compiled/en.json';
import {IntlProvider} from 'react-intl';
import {defaultRichTextElements} from '@utils/intl/defaults';

// import {polyfill} from '../../polyfills/intl';

// polyfill('en');

const Provider = ({children}: PropsWithChildren) => {
    return (
        <IntlProvider locale="en-GB" messages={messages} defaultRichTextElements={defaultRichTextElements} textComponent="span">
            {children}
        </IntlProvider>
    );
};

export default Provider;
