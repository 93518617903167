// import {getCookie} from 'cookies-next';
import {ParsedUrlQuery} from 'querystring';

export type EhubSaleType = {
    orderId: string;
    actionCode: string;
};

export type EhubType = {
    cookieConsentMarketing: boolean;
    visitId?: string;
    saleTracking?: EhubSaleType;
};

type EhubPaymentType = {
    id?: string | null;
    mainPackage?: {ehubCode?: string | null} | null;
};

const getCookie = (cname: string): string => {
    if (typeof document === 'undefined') {
        return '';
    }

    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (const c of ca) {
        const cTrim = c.trimStart();
        if (cTrim.startsWith(name)) {
            return cTrim.substring(name.length, cTrim.length);
        }
    }

    return '';
};

export const processEhub = (query: ParsedUrlQuery, payment?: EhubPaymentType | null): EhubType => {
    // const cookieConsent =
    //     '{stamp:%27cliKvohg+oWJjAxRD0xg9Rf3YkC485bUa+2B/tpWJuxnX9YLukMh3Q==%27%2Cnecessary:true%2Cpreferences:true%2Cstatistics:true%2Cmarketing:true%2Cmethod:%27explicit%27%2Cver:1%2Cutc:1717593798618%2Cregion:%27cz%27}'.includes(
    //         'marketing:true',
    //     );
    const cookieConsent = getCookie('CookieConsent');
    const cookieConsentMarketing: boolean = cookieConsent.includes('marketing:true');

    const visitId = query.ehub ? {visitId: query.ehub.toString()} : {};
    if (visitId.visitId) {
        saveEhubVisitId(visitId.visitId);
    }

    const saleTracking: {saleTracking?: EhubSaleType} =
        payment?.mainPackage?.ehubCode && payment.id ? {saleTracking: {orderId: payment.id, actionCode: payment.mainPackage.ehubCode}} : {};

    return {
        cookieConsentMarketing,
        ...visitId,
        ...saleTracking,
    };
};

export const loadEhubVisitId = (): string | null => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('ehub');
    }

    return null;
};

export const removeEhubVisitId = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('ehub');
    }
};

export const saveEhubVisitId = (ehub: string) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('ehub', ehub);
    }
};
