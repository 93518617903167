import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconInternet: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="internet">
			<path d="M16 13.249c1.34 0 2.544.711 3.209 1.813l.106.187.065.133.097.028a3.242 3.242 0 012.25 2.71l.018.192.005.187a3.25 3.25 0 01-3.066 3.245l-.184.005H13a2.75 2.75 0 01-.975-5.322l.167-.057.117-.034.022-.116a3.752 3.752 0 013.273-2.95l.197-.016.199-.005zm0 1.5a2.25 2.25 0 00-2.25 2.25.75.75 0 01-.648.743l-.23.013a1.25 1.25 0 000 2.488l.128.006h5.5a1.75 1.75 0 001.75-1.75c0-.873-.65-1.605-1.513-1.723a.75.75 0 01-.61-.502A2.25 2.25 0 0016 14.75zm.932-3.499c-2.693 0-4.15-3.114-2.497-5.176l.112-.133 1.516-1.706A7.716 7.716 0 0011.5 2.75c-.988 0-1.933.185-2.801.522l-.082.032c.14.156.394.32.819.533l.494.238c1.636.77 2.32 1.36 2.32 2.651 0 .87-.35 1.438-1.1 2.096l-.292.245-.294.242c-.777.647-1.103 1.132-1.143 1.957l-.004.182c0 2.428-1.056 3.302-3.727 3.522l-.493.033a7.805 7.805 0 002.37 2.166.75.75 0 01-.764 1.292 9.29 9.29 0 01-3.397-3.487l-.188-.354-.022-.048a9.247 9.247 0 013.88-12.197l.312-.167.06-.027A9.22 9.22 0 0111.5 1.25a9.25 9.25 0 019.246 8.982l.004.268a.75.75 0 01-.648.743L20 11.25h-3.068zM7.243 4.022l-.171.116A7.74 7.74 0 003.75 10.5c0 1.064.217 2.09.617 3.029l.565-.012.43-.019.198-.013.37-.034c1.512-.17 1.955-.654 1.985-1.86l.002-.143c0-1.376.483-2.235 1.491-3.124l.324-.273.29-.239c.556-.462.728-.714.728-1.086 0-.422-.258-.694-1.093-1.116l-.507-.245c-.98-.46-1.59-.836-1.907-1.343zm9.941 1.21L15.67 6.936c-.937 1.055-.243 2.703 1.124 2.808l.139.005h2.282a7.721 7.721 0 00-2.03-4.519z" />
		</Icon>
	);
};
