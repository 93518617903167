import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMarket: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="market">
			<path d="M10.264 13.298a.75.75 0 01.467.868l-.029.098-.56 1.486h3.714l-.558-1.486a.75.75 0 011.362-.62l.042.092.758 2.014a1.75 1.75 0 011.704 1.98l-.025.14-.758 3.501a1.75 1.75 0 01-1.557 1.372l-.153.007H9.329a1.749 1.749 0 01-1.671-1.231l-.039-.148-.758-3.5a1.75 1.75 0 011.572-2.116l.106-.004.759-2.015a.75.75 0 01.966-.438zM17.938 1.25c1.111 0 2.135.57 2.686 1.492l.098.177 1.716 3.377c.83 1.633-.066 3.473-1.688 4.05v9.904H22a.75.75 0 01.102 1.493L22 21.75h-3a.75.75 0 01-.102-1.493L19 20.25h.25v-9.749a3.505 3.505 0 01-1.919-.844l-.146-.14-.06-.064-.041.046a3.451 3.451 0 01-2.101 1.006l-.229.018-.186.005c-.922 0-1.765-.337-2.371-.887l-.147-.142L12 9.443l-.05.056a3.451 3.451 0 01-2.1 1.006l-.229.018-.186.005c-.921 0-1.764-.337-2.37-.887l-.146-.142-.042-.047-.06.066c-.52.527-1.248.885-2.066.983v9.749H5a.75.75 0 01.102 1.493L5 21.75H2a.75.75 0 01-.102-1.493L2 20.25h1.25v-9.904C1.68 9.79.79 8.045 1.49 6.451l.073-.155L3.28 2.92c.489-.965 1.477-1.593 2.576-1.663l.207-.007h11.876zm-2.509 16H8.571a.25.25 0 00-.25.246l.006.057.758 3.501c.02.092.089.162.176.187l.068.009h5.342a.25.25 0 00.22-.132l.024-.065.758-3.5a.25.25 0 00-.186-.296l-.058-.007zm-2.269.9a.75.75 0 01.743.648l.007.102v.6a.75.75 0 01-1.493.102l-.007-.102v-.6a.75.75 0 01.75-.75zm-2.32 0a.75.75 0 01.743.648l.007.102v.6a.75.75 0 01-1.493.102l-.007-.102v-.6a.75.75 0 01.75-.75zm7.098-15.4H6.062c-.572 0-1.09.28-1.374.724l-.071.125L2.9 6.976c-.477.939.25 2.044 1.4 2.052.975 0 1.733-.596 1.81-1.295l.007-.118v-.048c0-.958 1.378-.998 1.492-.12l.014.242c.078.728.838 1.339 1.81 1.339.972 0 1.732-.611 1.81-1.339l.014-.242c.11-.84 1.375-.84 1.484 0l.014.242c.078.728.839 1.339 1.812 1.339.972 0 1.732-.611 1.81-1.339l.014-.242c.11-.84 1.375-.84 1.484 0l.008.12v.048c0 .75.787 1.413 1.81 1.413 1.11-.008 1.824-1.027 1.458-1.939l-.052-.113-1.716-3.377c-.24-.472-.73-.792-1.292-.842l-.154-.007z" />
		</Icon>
	);
};
