import classNames from 'classnames';
import React, {FC} from 'react';
import {Icon, IconProps} from '../Icon';
import {Typography, TypographyProps} from '../Typography';
import styles from './InfoText.module.scss';

export type InfoTextProps = TypographyProps & {
    iconName?: IconProps['name'] | false;
    className?: string;
};

export const InfoText: FC<React.PropsWithChildren<InfoTextProps>> = ({
    iconName = 'InfoCircle',
    className,
    children,
    color = 'grey-dark',
    ...props
}) => {
    return (
        <Typography as="p" {...props} color={color} className={classNames(styles.infoText, 'infoText', className)}>
            {iconName && <Icon name={iconName} className={classNames(styles.infoTextIcon, 'ms-4 me-2')} />}
            <Typography variant="caption" className={styles.infoTextContent}>
                {children}
            </Typography>
        </Typography>
    );
};
