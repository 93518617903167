import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconQuestionMarkCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="question-mark-circle">
			<path d="M12.005 2.251c5.387 0 9.754 4.367 9.754 9.754s-4.367 9.754-9.754 9.754-9.754-4.367-9.754-9.754 4.367-9.754 9.754-9.754zm0 1.5a8.254 8.254 0 100 16.508 8.254 8.254 0 000-16.508zm-.001 11.306a.85.85 0 01.822.63l.022.119.007.102a.85.85 0 11-.851-.851zm3.152-4.902c0 .965-.491 1.86-1.294 2.38l-.165.1-1.328.737a.75.75 0 01-.814-1.256l.086-.055 1.327-.738c.425-.236.688-.683.69-1.122-.05-.802-.703-1.423-1.552-1.45l-.151.002a1.595 1.595 0 00-1.62 1.233.75.75 0 11-1.462-.339c.337-1.45 1.657-2.455 3.071-2.393 1.673-.092 3.106 1.182 3.212 2.9z" />
		</Icon>
	);
};
