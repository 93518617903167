import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconDocumentContract: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="document-contract">
            <path d="M18 2.25A2.75 2.75 0 0 1 20.75 5v14A2.75 2.75 0 0 1 18 21.75H6A2.75 2.75 0 0 1 3.25 19V5A2.75 2.75 0 0 1 6 2.25h12Zm0 1.5H6c-.7 0-1.25.56-1.25 1.25v14c0 .7.56 1.25 1.25 1.25h12c.7 0 1.25-.56 1.25-1.25V5c0-.7-.56-1.25-1.25-1.25Zm-2 12.5a.75.75 0 1 1 0 1.5H8a.75.75 0 1 1 0-1.5h8Zm0-3a.75.75 0 1 1 0 1.5H8a.75.75 0 1 1 0-1.5h8Zm-1.47-6.28c.3.3.3.77 0 1.06l-2.5 2.5c-.3.3-.77.3-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97 1.97-1.97c.3-.3.77-.3 1.06 0Z" />
        </Icon>
    );
};
