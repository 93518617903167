import React, { FC } from 'react';
import { FormCheck } from 'react-bootstrap';
import { FormCheckInputProps } from 'react-bootstrap/FormCheckInput';
import styles from './Tag.module.scss';

export type FormTagInputProps = FormCheckInputProps & {};

export const FormTagInput: FC<React.PropsWithChildren<FormTagInputProps>> = ({ ...props }) => {
	return <FormCheck.Input {...props} className={styles.tagInput} bsPrefix="form-tag-input" />;
};
