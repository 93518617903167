import {routes as iRoutes} from 'src/config/routes';
import {LocaleType, LanguageObj, StringObj} from 'src/types/general';
import {ListFilter} from './query';
import {localizedListSlugs as offerListSlugs} from '@constants/OfferType';
import {localizedListSlugs as estateListSlugs} from '@constants/EstateType';
import {localizedListSlugs as dispositionListSlugs} from '@constants/Disposition';
import {OfferType, EstateType, Disposition} from '.cache/__types__';

type routeSlugs = keyof typeof iRoutes;

export type Route = {
    path: string;
    localized: LanguageObj;
};

export const getLocalizedRoute = (route: Route, locale: LocaleType, params: Record<string, any> = {}, hash?: string) => {
    const localizedRoute = route.localized[locale] ?? route.path;

    return processParams(localizedRoute, params) + (hash ? '#' + hash : '');
};

const prepareLocalizedRoute = (path: string, locale: LocaleType) => {
    const convertedPath = path.replace(/\[([^\]]*)]/g, ':$1');
    const route = Object.values(routes).find((route) => route.path === convertedPath) ?? routes.homepage;
    return route.localized[locale] ?? route.path;
};

export const getLocalizedPathSeparated = (path: string, locale: LocaleType, params: Record<string, any> = {}) => {
    const localizedRoute = prepareLocalizedRoute(path, locale);

    return processParamsSeparated(localizedRoute, params);
};

export const getLocalizedPath = (path: string, locale: LocaleType, params: Record<string, any> = {}) => {
    const localizedRoute = prepareLocalizedRoute(path, locale);

    return processParams(localizedRoute, params);
};

const getParamRegularExpression = (param: string, flags?: string) => {
    return new RegExp(':' + param + '\\*?', flags);
};

const processParamsSeparated = (path: string, params: Record<string, any>) => {
    let pathname = path;
    const searchParams = new URLSearchParams();
    for (const param in params) {
        const search = getParamRegularExpression(param);
        let value = params[param];
        if (pathname.search(search) !== -1) {
            if (Array.isArray(params[param])) {
                value = params[param].join('/');
            }
            pathname = pathname.replace(search, value);
        } else if (Array.isArray(params[param])) {
            params[param].forEach((v: any) => {
                searchParams.append(param, v);
            });
        } else {
            searchParams.append(param, value);
        }
    }
    const unusedParams = pathname.match(getParamRegularExpression('[a-zA-Z0-9_-]+', 'g'));
    if (unusedParams && unusedParams.length > 0) {
        throw new Error(
            'Missing route parameters for route ' + path + ': ' + unusedParams.map((i) => i.match(/[a-zA-Z\d_-]+/)?.at(0)).join(', '),
        );
    }

    const query: StringObj = {};
    for (const [key, value] of searchParams) {
        query[key] = value;
    }

    return {
        pathname,
        query,
        searchParams,
    };
};

const processParams = (path: string, params: Record<string, any>) => {
    const {pathname, searchParams} = processParamsSeparated(path, params);
    const searchString = searchParams.toString();

    return pathname + (searchString !== '' ? '?' + searchString : '');
};

export const isRoute = (url: string, route: Route, locale: LocaleType) => {
    const r = route.localized[locale];
    const p = route.path;
    const expr = getParamRegularExpression('[^/*]*', 'g');
    const re = new RegExp(
        '^' +
            r.replaceAll(expr, (match) => {
                if (match.endsWith('*')) {
                    return '.*';
                }
                return '[^/]*';
            }) +
            '$',
    );
    const pre = new RegExp(
        '^' +
            p.replaceAll(expr, (match) => {
                if (match.endsWith('*')) {
                    return '.*';
                }
                return '[^/]*';
            }) +
            '$',
    );
    return re.test(url) || pre.test(url);
};

export const routes: Record<routeSlugs, Route> = iRoutes;

export const getLoginRoute = (callbackRoute: Route, locale: LocaleType, params: Record<string, any> = {}, permanent = false) => ({
    redirect: {
        destination: getLocalizedRoute(routes.login, locale, {
            callbackUrl: getLocalizedRoute(callbackRoute, locale, params),
        }),
        permanent,
    },
});

export const getListParams = (locale: LocaleType, filter?: Partial<ListFilter>, regionUri?: string) => {
    const params = [];
    const response: Record<string, any> = {};

    if (filter?.offerType?.length && filter.offerType[0] !== OfferType.Undefined) {
        params.push(offerListSlugs[locale][filter.offerType[0]]);
    }

    if (filter?.estateType?.length && filter.estateType[0] !== EstateType.Undefined) {
        params.push(estateListSlugs[locale][filter.estateType[0]]);
    }

    if (regionUri && filter?.region) {
        params.push(regionUri);
    }

    if (filter?.disposition?.length && filter.disposition[0] !== Disposition.Undefined) {
        params.push(dispositionListSlugs[locale][filter.disposition[0]]);
    }

    response.list = params.join('/');

    if (filter?.page && filter.page > 1) {
        response.page = filter.page;
    }

    if (filter?.hideResults) {
        response.hideResults = filter.hideResults;
    }

    if (filter?.lat) {
        response.lat = filter.lat;
    }

    if (filter?.lng) {
        response.lng = filter.lng;
    }

    if (filter?.zoom) {
        response.zoom = filter.zoom;
    }

    return response;
};
