import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDeviceUnclear: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="device-unclear">
			<path d="M6.5 20.75a.75.75 0 01-.102-1.493l.102-.007h2.75v-1.5H4a1.75 1.75 0 01-1.744-1.607L2.25 16V8a1.75 1.75 0 011.607-1.744L4 6.25h7.754l-.004-.063V4a1.75 1.75 0 011.607-1.744l.143-.006H20a1.75 1.75 0 011.744 1.607L21.75 4v15a1.75 1.75 0 01-1.607 1.744L20 20.75h-3.25a.75.75 0 01-.102-1.493l.102-.007H20a.25.25 0 00.243-.193L20.25 19V4a.25.25 0 00-.193-.243L20 3.75h-6.5a.25.25 0 00-.243.193L13.25 4v2.187l-.004.063H16a1.75 1.75 0 011.744 1.607L17.75 8v8a1.75 1.75 0 01-1.607 1.744L16 17.75h-5.25v1.5h2.75a.75.75 0 01.102 1.493l-.102.007h-7zm9.5-13H4a.25.25 0 00-.243.193L3.75 8v8a.25.25 0 00.193.243L4 16.25h12a.25.25 0 00.243-.193L16.25 16V8a.25.25 0 00-.193-.243L16 7.75z" />
		</Icon>
	);
};
