import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconSport: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="sport">
			<path d="M14.97 2.188c.09.011.173.038.25.078a10.209 10.209 0 014.028 2.486c.375.375.714.771 1.019 1.185a.71.71 0 01.13.18 10.228 10.228 0 011.841 5.392l.012.411v.029a10.22 10.22 0 01-3.002 7.299c-4.003 4.003-10.493 4.003-14.496 0a10.215 10.215 0 01-2.999-6.997l-.002-.358a10.224 10.224 0 011.85-5.77.74.74 0 01.13-.186c.306-.413.646-.81 1.021-1.185a10.207 10.207 0 014.031-2.487.73.73 0 01.246-.078 10.31 10.31 0 015.577-.102l.364.103zm-.95 13.877H9.979l-.782 1.078 1.245 3.468a8.8 8.8 0 003.116 0l1.244-3.468-.782-1.078zm4.634 1.618l-2.479.074-.837 2.334a8.724 8.724 0 003.316-2.408zm-12.841.504a8.723 8.723 0 002.849 1.904l-.838-2.334-2.478-.074c.148.172.303.34.467.504zm.437-7.253l-2.919 2.26c.14 1.022.461 2.025.963 2.955l3.689.112.783-1.081-1.246-3.833-1.27-.413zm11.499 0l-1.27.413-1.246 3.833.783 1.08 3.69-.11c.502-.931.823-1.934.963-2.956l-2.92-2.26zM12 8.852l-3.004 2.182 1.148 3.531h3.711l1.148-3.531L12 8.852zm7.456-1.435l-.701 2.397 1.97 1.526a8.73 8.73 0 00-1.09-3.618l-.179-.305zM3.275 11.34l1.969-1.526-.7-2.397a8.724 8.724 0 00-1.27 3.923zm2.538-5.527l-.135.137 1.039 3.558 1.264.41 3.269-2.374V6.212L8.183 4.124a8.737 8.737 0 00-2.37 1.689zm10.004-1.69L12.75 6.213v1.332l3.268 2.375 1.264-.411 1.039-3.558-.134-.137-.207-.201a8.724 8.724 0 00-2.163-1.488zM9.93 3.498L12 4.907l2.07-1.41a8.796 8.796 0 00-3.799-.076l-.34.076z" />
		</Icon>
	);
};
