import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDrugstore: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="drugstore">
			<path d="M15.889 2.25a2.86 2.86 0 012.861 2.861v.778c0 .77-.304 1.47-.8 1.984a3.91 3.91 0 011.8 3.294v6.667a3.917 3.917 0 01-3.917 3.916H8.167a3.917 3.917 0 01-3.917-3.917v-6.667c0-1.382.717-2.597 1.798-3.294a2.85 2.85 0 01-.798-1.983v-.778A2.86 2.86 0 018.111 2.25zm-.055 6.5H8.167a2.417 2.417 0 00-2.417 2.416v6.667a2.417 2.417 0 002.417 2.417h7.666a2.417 2.417 0 002.417-2.416v-6.667a2.417 2.417 0 00-2.416-2.417zM12 11.81a.75.75 0 01.743.648l.007.102v1.36h1.36a.75.75 0 01.102 1.493l-.102.007h-1.36v1.36a.75.75 0 01-1.493.102l-.007-.102v-1.36H9.89a.75.75 0 01-.102-1.493l.102-.007h1.36v-1.36a.75.75 0 01.75-.75zm3.889-8.06H8.111A1.36 1.36 0 006.75 5.111v.778c0 .752.61 1.361 1.361 1.361h7.778a1.36 1.36 0 001.361-1.361v-.778a1.36 1.36 0 00-1.361-1.361zM12 4.55a.75.75 0 01.743.648l.007.102v.51a.75.75 0 01-1.493.102l-.007-.102V5.3a.75.75 0 01.75-.75zm-2.75 0a.75.75 0 01.743.648L10 5.3v.51a.75.75 0 01-1.493.102L8.5 5.81V5.3a.75.75 0 01.75-.75zm5.5 0a.75.75 0 01.743.648l.007.102v.51a.75.75 0 01-1.493.102L14 5.81V5.3a.75.75 0 01.75-.75z" />
		</Icon>
	);
};
