import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPhone: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="phone">
			<path d="M9.082 9.415l-.173-.374.824-.82c.945-.945.983-2.37.085-3.267L8.644 3.78a2.75 2.75 0 00-3.888 0l-.652.652c-.915.915-1.303 2.233-1.057 3.54.54 2.86 2.192 5.838 4.668 8.313 2.475 2.476 5.454 4.127 8.314 4.668 1.306.246 2.624-.142 3.54-1.057l.65-.65a2.75 2.75 0 000-3.89l-1.173-1.172a2.25 2.25 0 00-3.182 0l-.905.909.009.005a11.913 11.913 0 01-3.582-2.484 12.003 12.003 0 01-2.304-3.199zM7.584 4.84l1.174 1.174c.299.3.286.774-.085 1.146l-.819.817a1.498 1.498 0 00-.322 1.66 13.386 13.386 0 002.794 4.037 13.412 13.412 0 004.036 2.796c.566.25 1.225.114 1.66-.322l.904-.904a.75.75 0 011.06 0l1.173 1.173a1.25 1.25 0 010 1.768l-.651.65c-.56.56-1.375.8-2.2.644-2.553-.483-5.263-1.985-7.533-4.254-2.269-2.27-3.771-4.98-4.254-7.532-.156-.826.084-1.642.643-2.2l.652-.653a1.25 1.25 0 011.768 0z" />
		</Icon>
	);
};
