import classNames from 'classnames';
import React, {FC, useMemo} from 'react';
import {Nav} from '../Nav';
import styles from './TabsNav.module.scss';
import {TabsNavButton} from './TabsNavButton';
import {TabsNavContext} from './TabsNavContext';

export type TabsNavProps = {
    className?: string;
    align?: 'center';
    spacing?: 'lg';
    variant?: 'pricelist' | 'pricelistv2' | 'buttons';
};

export const TabsNav: FC<React.PropsWithChildren<TabsNavProps>> = ({className, align, spacing, variant, children}) => {
    const context = useMemo(() => ({variant}), [variant]);

    return (
        <TabsNavContext.Provider value={context}>
            <Nav
                role="tablist"
                className={classNames(styles.tabsNav, 'tabsNav', className, {
                    [styles[`tabsNav--${align}`]]: align,
                    [styles[`tabsNav--${spacing}`]]: spacing,
                    [styles[`tabsNav--${variant}`]]: variant,
                })}
            >
                {children}
            </Nav>
        </TabsNavContext.Provider>
    );
};

export default Object.assign(TabsNav, {
    Button: TabsNavButton,
});
