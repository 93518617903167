import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDotsVertical: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="dots-vertical">
			<path d="M11.997 16.75l.128.006A1.252 1.252 0 0113.25 18a1.25 1.25 0 11-1.381-1.243l.128-.007zm0-6l.128.006A1.252 1.252 0 0113.25 12a1.25 1.25 0 11-1.381-1.243l.128-.007zm0-6l.128.006A1.252 1.252 0 0113.25 6a1.25 1.25 0 11-1.381-1.243l.128-.007z" />
		</Icon>
	);
};
