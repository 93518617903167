import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveEhubMutationVariables = Types.Exact<{
  ehub: Types.Scalars['String']['input'];
}>;


export type SaveEhubMutation = { __typename?: 'Mutation', saveEhub?: { __typename?: 'User', id?: string | null, ehub?: string | null } | null };


export const SaveEhubDocument = gql`
    mutation SaveEhub($ehub: String!) {
  saveEhub(ehub: $ehub) {
    id
    ehub
  }
}
    `;
export type SaveEhubMutationFn = Apollo.MutationFunction<SaveEhubMutation, SaveEhubMutationVariables>;

/**
 * __useSaveEhubMutation__
 *
 * To run a mutation, you first call `useSaveEhubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEhubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEhubMutation, { data, loading, error }] = useSaveEhubMutation({
 *   variables: {
 *      ehub: // value for 'ehub'
 *   },
 * });
 */
export function useSaveEhubMutation(baseOptions?: Apollo.MutationHookOptions<SaveEhubMutation, SaveEhubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEhubMutation, SaveEhubMutationVariables>(SaveEhubDocument, options);
      }
export type SaveEhubMutationHookResult = ReturnType<typeof useSaveEhubMutation>;
export type SaveEhubMutationResult = Apollo.MutationResult<SaveEhubMutation>;
export type SaveEhubMutationOptions = Apollo.BaseMutationOptions<SaveEhubMutation, SaveEhubMutationVariables>;