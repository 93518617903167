import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconStack: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="stack">
			<path d="M20.644 15.851a.75.75 0 01-.176.97l-.084.058-8 4.765a.75.75 0 01-.663.052l-.105-.052-8-4.765a.75.75 0 01.677-1.335l.09.047L12 20.127l7.616-4.536a.75.75 0 011.028.26zm0-4.235a.75.75 0 01-.176.97l-.084.058-8 4.765a.75.75 0 01-.663.052l-.105-.052-8-4.765a.75.75 0 01.677-1.335l.09.047L12 15.892l7.616-4.536a.75.75 0 011.028.26zm-9.028-9.26a.75.75 0 01.768 0l8 4.765a.75.75 0 010 1.288l-8 4.764a.75.75 0 01-.768 0l-8-4.764a.75.75 0 010-1.288zM12 3.872L5.465 7.764 12 11.656l6.534-3.892L12 3.872z" />
		</Icon>
	);
};
