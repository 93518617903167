import classNames from 'classnames';
import React, {forwardRef} from 'react';
import {Row as RowComponent, RowProps as RowComponentProps, BsPrefixRefForwardingComponent} from 'react-bootstrap';

type RowProps = RowComponentProps & {
    colsNumber?: '12';
    className?: string;
};

export const Row: BsPrefixRefForwardingComponent<'div', RowProps> = forwardRef(({colsNumber, className, ...props}, ref) => {
    return (
        <RowComponent
            {...props}
            ref={ref}
            className={classNames(className, {
                [`row--${colsNumber}`]: colsNumber,
            })}
        />
    );
});
