import classNames from 'classnames';
import {useMemo} from 'react';
import {FormControl, FormControlProps} from 'react-bootstrap';
import {BsPrefixRefForwardingComponent} from 'react-bootstrap/esm/helpers';
import {Box} from '@components/Box';
import {Typography} from '@components/Typography';
import styles from './LimitedTextarea.module.scss';
import {convertEmoticons} from '@utils/emoji';

export type FormLimitedTextareaProps = BsPrefixRefForwardingComponent<'textarea', FormControlProps & {boxClassName?: string}> &
    FormControlProps & {
        maxLength: number;
        value?: string;
        boxClassName?: string;
        convertEmoticons?: boolean;
    };

export const FormLimitedTextarea = ({
    maxLength,
    value = '',
    onChange,
    boxClassName,
    convertEmoticons: emoticons,
    ...props
}: FormLimitedTextareaProps) => {
    const content = useMemo(() => value.slice(0, maxLength), [value, maxLength]);

    return (
        <Box className={classNames(styles.limitedTextarea, 'limitedTextarea', boxClassName)}>
            <FormControl
                {...props}
                onChange={(event) => {
                    event.target.value = emoticons ? convertEmoticons(event.target.value) : event.target.value;
                    event.target.value = event.target.value.slice(0, maxLength);

                    if (onChange) {
                        onChange(event);
                    }
                }}
                maxLength={maxLength}
                size="lg"
                as="textarea"
                value={content}
            />

            <div className={styles.limitedTextareaBottom}>
                <Typography variant="perex" className={styles.limitedTextareaCount}>
                    {content.length} / {maxLength}
                </Typography>
            </div>
        </Box>
    );
};
