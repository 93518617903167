import React, { FC } from 'react';
import { Typography } from '../Typography';
import styles from './ContactBox.module.scss';

export const ContactBoxLinkDesc: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Typography variant="link-perex" className={styles.contactBoxLinkDesc}>
			{children}
		</Typography>
	);
};
