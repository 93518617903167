import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconLogout: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="logout">
			<path d="M16.555 14.804a5.199 5.199 0 015.199 5.2.75.75 0 11-1.5 0 3.699 3.699 0 00-3.503-3.694l-.196-.006h-5.108a3.699 3.699 0 00-3.7 3.7.75.75 0 11-1.5 0c0-2.798 2.21-5.08 4.98-5.195l.22-.005h5.108zM4.527 9.47a.75.75 0 01.073.976l-.073.084-.721.72 3.192.001a.75.75 0 01.102 1.493l-.102.007H3.807l.72.72a.75.75 0 01.073.977l-.073.084a.75.75 0 01-.977.073l-.084-.073-2-2-.064-.073a.753.753 0 01-.007-.01l.07.082a.753.753 0 01-.144-.859.687.687 0 01.072-.117.785.785 0 01.073-.084l2-2.001a.75.75 0 011.061 0zm9.474-6.222a5.002 5.002 0 110 10.003 5.002 5.002 0 010-10.003zm0 1.5a3.502 3.502 0 100 7.003 3.502 3.502 0 000-7.003z" />
		</Icon>
	);
};
