import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTriangleUp: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="triangle-up">
			<path d="M12 9l-5 6h10z" />
		</Icon>
	);
};
