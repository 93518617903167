import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDocumentBan: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="document-ban">
			<path d="M14.172 2.25c.663 0 1.301.24 1.8.67l.144.136 2.828 2.828c.47.468.751 1.09.799 1.746l.007.198.001 4.122a5.75 5.75 0 01-2.53 10.796L17 22.75l-.22-.004a5.72 5.72 0 01-3.019-.995L5 21.75a2.75 2.75 0 01-2.745-2.582L2.25 19V5a2.75 2.75 0 012.582-2.745L5 2.25h9.172zm6.323 12.33l-5.914 5.915c.634.44 1.393.711 2.213.75l.206.005.206-.005a4.25 4.25 0 004.04-4.04L21.25 17a4.23 4.23 0 00-.755-2.42zM13.25 3.75H5a1.25 1.25 0 00-1.244 1.122L3.75 5v14c0 .648.491 1.18 1.122 1.244L5 20.25h7.257a5.75 5.75 0 015.994-8.864L18.25 8.75H15a1.75 1.75 0 01-1.744-1.607L13.25 7V3.75zm3.75 9a4.25 4.25 0 00-3.482 6.687l5.92-5.919a4.25 4.25 0 00-.47-.286l-.268-.127a4.219 4.219 0 00-1.7-.355zm-2.249-8.857L14.75 7a.25.25 0 00.193.243L15 7.25h3.108a1.251 1.251 0 00-.13-.2l-.094-.106-2.828-2.828a1.25 1.25 0 00-.305-.223z" />
		</Icon>
	);
};
