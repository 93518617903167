import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconEvaluate: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="evaluate">
            <g strokeLinecap="round" strokeWidth={1.5} stroke="#323232" fill="none" strokeLinejoin="round">
                <path d="M16.747 6.187a7.467 7.467 0 1 1-10.56 10.56 7.467 7.467 0 0 1 10.56-10.56M7.93 12.89v-1M11.25 12.89v-2M14.57 12.89v-3M20 20l-3.25-3.25" />
            </g>
            <path fill="none" d="M0 0h24v24H0V0Z" />
        </Icon>
    );
};
