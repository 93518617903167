import React from 'react';
import { CaptionProps, useDayPicker, useNavigation } from 'react-day-picker';
import { Button } from '../Button';
import { ButtonGroup } from '../ButtonGroup';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { VisuallyHidden } from '../VisuallyHidden';

export const DatepickerCaption = ({ displayMonth }: CaptionProps) => {
	const {
		locale,
		classNames,
		formatters: { formatCaption },
		labels: { labelPrevious, labelNext },
		onMonthChange,
	} = useDayPicker();
	const { previousMonth, nextMonth, goToMonth } = useNavigation();

	if (!previousMonth && !nextMonth) {
		return <></>;
	}

	const previousLabel = labelPrevious(previousMonth, { locale });
	const nextLabel = labelNext(nextMonth, { locale });

	const handlePreviousClick = () => {
		if (!previousMonth) {
			return;
		}
		goToMonth(previousMonth);
		onMonthChange?.(previousMonth);
	};

	const handleNextClick = () => {
		if (!nextMonth) {
			return;
		}
		goToMonth(nextMonth);
		onMonthChange?.(nextMonth);
	};

	return (
		<div className={classNames.caption}>
			<Typography>{formatCaption(displayMonth, { locale })}</Typography>

			<ButtonGroup className={classNames.nav}>
				<Button
					variant="outline-dark"
					size="sm"
					className={classNames.nav_button_previous}
					onClick={handlePreviousClick}
					disabled={!previousMonth}
				>
					<VisuallyHidden>{previousLabel}</VisuallyHidden>
					<Icon.ChevronLeft position="middle" />
				</Button>
				<Button
					variant="outline-dark"
					size="sm"
					className={classNames.nav_button_next}
					onClick={handleNextClick}
					disabled={!nextMonth}
				>
					<VisuallyHidden>{nextLabel}</VisuallyHidden>
					<Icon.ChevronRight position="middle" />
				</Button>
			</ButtonGroup>
		</div>
	);
};
