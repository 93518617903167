import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArchive: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="archive">
			<path d="M17.007 2.251a2.751 2.751 0 012.746 2.584l.005.167v8.268a2.251 2.251 0 011.995 2.072l.006.164v3.502a2.751 2.751 0 01-2.584 2.746l-.167.005H5.002a2.751 2.751 0 01-2.746-2.584l-.005-.167v-3.502c0-1.158.875-2.112 2-2.236l.001-8.268a2.751 2.751 0 012.583-2.746l.168-.005h10.004zM6.382 14.756h-1.88a.75.75 0 00-.744.649l-.007.101v3.502c0 .648.492 1.18 1.123 1.244l.128.007h14.006c.648 0 1.18-.492 1.244-1.123l.007-.128v-3.502a.75.75 0 00-.649-.743l-.102-.007h-1.672a1.25 1.25 0 00-.779.272l-.105.093-.83.83a2.75 2.75 0 01-1.747.799l-.198.007H9.832c-.663 0-1.3-.24-1.799-.67l-.145-.135-.976-.976a.75.75 0 00-.412-.21l-.118-.01zM17.007 3.75H7.003c-.648 0-1.18.492-1.244 1.123l-.007.128v8.253h.63c.53 0 1.04.188 1.444.526l.146.134.977.976c.2.2.463.326.742.358l.141.008h4.345c.285 0 .56-.097.78-.273l.105-.094.83-.83a2.75 2.75 0 011.745-.797l.198-.007h.422V5.001c0-.648-.491-1.18-1.122-1.244l-.128-.007zm-2 6.504a.75.75 0 01.101 1.493l-.102.007H9.004a.75.75 0 01-.102-1.494l.102-.006h6.002zm0-4.002a.75.75 0 01.101 1.493l-.102.007H9.004a.75.75 0 01-.102-1.493l.102-.007h6.002z" />
		</Icon>
	);
};
