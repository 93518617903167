import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconList: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="list">
            <path d="M4.25 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm0-6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5Zm3 2.5h14v-2h-14v2Zm0-6h14v-2h-14v2Zm0-8v2h14V5h-14Z" />
        </Icon>
    );
};
