import {createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSession} from 'next-auth/react';
import {initializeApollo, loadApolloCache} from '@utils/apollo-client';
import {ApolloProvider, FetchResult} from '@apollo/client';
import {MaintenanceContext} from '@utils/maintenance';
import {ErrorResponse} from '@apollo/client/link/error';

export type AuthorizedApolloClientProps = {
    initialCache: any;
} & PropsWithChildren;

export const AccessTokenContext = createContext('');

export const AuthorizedApolloClient = ({children, initialCache}: AuthorizedApolloClientProps) => {
    const {data} = useSession();
    const sessionRef = useRef(data);
    const [accessToken, setAccessToken] = useState<string>('');
    const [inMaintenance, setInMaintenance] = useState<boolean>(false);

    const errorProcessor = useCallback((error: ErrorResponse) => {
        if (error.networkError) {
            setInMaintenance(true);
        }
    }, []);

    const successProcessor = useCallback((value: FetchResult) => {
        setInMaintenance(false);
        return value;
    }, []);

    const apolloClient = useMemo(() => {
        return initializeApollo(sessionRef, errorProcessor, successProcessor);
    }, [errorProcessor, successProcessor]);

    useMemo(() => {
        loadApolloCache(apolloClient, initialCache);
    }, [apolloClient, initialCache]);

    useEffect(() => {
        sessionRef.current = data;
        setAccessToken(data?.accessToken ?? '');
    }, [data]);

    const maintenanceContextValue = useMemo(
        () => ({
            inMaintenance,
        }),
        [inMaintenance],
    );

    return (
        <ApolloProvider client={apolloClient}>
            <MaintenanceContext.Provider value={maintenanceContextValue}>
                <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>
            </MaintenanceContext.Provider>
        </ApolloProvider>
    );
};
