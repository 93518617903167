import {Typography} from '@components/Typography';
import {Icon} from '@components/Icon';
import {Button} from '@components/Button';
import {FormattedMessage} from 'react-intl';

export type PasswordResetEmailCheckProps = {
    onClose?: () => void;
};

export const PasswordResetEmailCheck = ({onClose}: PasswordResetEmailCheckProps) => (
    <>
        <Typography as="p" textAlign="center" color="grey-dark" className="mb-8">
            <Icon.Envelope size="xl" />
        </Typography>
        <Typography as="h2" variant="h4" className="mb-4" textAlign="center">
            <FormattedMessage defaultMessage="Zapomenuté heslo" />
        </Typography>

        <Typography as="p" className="mb-8" textAlign="center">
            <FormattedMessage defaultMessage="Pokračujte potvrzením e-mailu, který jsme vám právě poslali na vaši adresu." />
        </Typography>

        <Button className="w-100" onClick={onClose}>
            <FormattedMessage defaultMessage="Zpět na přihlášení" />
        </Button>
    </>
);
