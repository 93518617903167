import classNames from 'classnames';
import React, {FC, useEffect, useRef} from 'react';
import {useResizeObserver} from 'usehooks-ts';
import {Background} from '../../types/colors';
import {Container} from '../Container';
import styles from './Header.module.scss';
import {HeaderButton} from './HeaderButton';
import {HeaderCta} from './HeaderCta';
import {HeaderLogo} from './HeaderLogo';
import {HeaderNav} from './HeaderNav';
import {HeaderNavDropdown} from './HeaderNavDropdown';
import {HeaderNavNotification} from './HeaderNavNotification';
import {HeaderToolbar} from './HeaderToolbar';

type HeaderProps = {
    withBorder?: 'mobile' | boolean;
    background?: Background;
};

const Header: FC<React.PropsWithChildren<HeaderProps>> = ({children, withBorder, background = 'white'}) => {
    const headerRef = useRef<HTMLElement>(null);
    const {height} = useResizeObserver({ref: headerRef});

    useEffect(() => {
        document.documentElement.style.setProperty('--header-height', `${height}px`);

        return () => {
            document.documentElement.style.removeProperty('--header-height');
        };
    }, [height]);

    return (
        <header
            className={classNames(styles.header, 'header', {
                [styles[`header--bd`]]: withBorder === true,
                [styles[`header--bd-mobile`]]: withBorder === 'mobile',
                [`bg-${background}`]: background,
            })}
            ref={headerRef}
        >
            <Container>
                <div className={styles.headerHolder}>{children}</div>
            </Container>
        </header>
    );
};

export default Object.assign(Header, {
    Button: HeaderButton,
    Cta: HeaderCta,
    Logo: HeaderLogo,
    Nav: HeaderNav,
    NavDropdown: HeaderNavDropdown,
    NavNotification: HeaderNavNotification,
    Toolbar: HeaderToolbar,
});
