import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEyeCheck: FC<IconProps> = ({ ...props }) => {
	return (
		<Icon {...props} id="eye-check">
			<path d="M12 4.25c3.972 0 7.584 2.149 9.228 5.508.344.704.522 1.468.522 2.242a.75.75 0 11-1.5 0c0-.544-.126-1.084-.37-1.582C18.494 7.584 15.41 5.75 12 5.75s-6.494 1.834-7.88 4.668A3.596 3.596 0 003.75 12c0 .544.126 1.084.37 1.582C5.506 16.416 8.59 18.25 12 18.25a.75.75 0 110 1.5c-3.972 0-7.584-2.149-9.228-5.508A5.096 5.096 0 012.25 12c0-.774.178-1.538.522-2.242C4.416 6.398 8.028 4.25 12 4.25zm9.53 11.22a.75.75 0 01.073.976l-.073.084-3 3a.75.75 0 01-.976.073l-.084-.073-2-2a.75.75 0 01.976-1.133l.084.073L18 17.939l2.47-2.47a.75.75 0 011.06 0zm-6.877-6.12a3.754 3.754 0 010 5.305 3.754 3.754 0 01-5.306 0 3.754 3.754 0 010-5.305 3.75 3.75 0 015.306 0zm-4.245 1.06a2.254 2.254 0 00-.001 3.184c.88.879 2.306.879 3.184.001a2.254 2.254 0 00.12-3.056l-.121-.13-.128-.119a2.25 2.25 0 00-3.054.12z" />
		</Icon>
	);
};
