import {LocaleEnum} from 'src/types/general';
import {Currency, Country} from '.cache/__types__';

export const defaultCurrencyByLocale = {
    [LocaleEnum.cs]: Currency.Czk,
    [LocaleEnum.en]: Currency.Czk,
    [LocaleEnum.sk]: Currency.Eur,
};

export const priceStep = {
    [Currency.Eur]: 5,
    [Currency.Czk]: 100,
};

export const currencyByCountry = {
    [Country.Cze]: Currency.Czk,
    [Country.Svk]: Currency.Eur,
};
