import classNames from 'classnames';
import React, { FC } from 'react';
import { ButtonToolbar } from '../ButtonToolbar';
import styles from './Header.module.scss';

type HeaderToolbarProps = {
	placement?: 'left' | 'right';
	className?: string;
}

export const HeaderToolbar: FC<React.PropsWithChildren<HeaderToolbarProps>> = ({ placement = 'right', className, children }) => {
	return (
		<div
			className={classNames(styles.headerToolbar, className, {
				[styles[`headerToolbar--${placement}`]]: placement,
			})}
		>
			<ButtonToolbar className="flex-nowrap">{children}</ButtonToolbar>
		</div>
	);
};
