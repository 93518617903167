import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHandClick: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="hand-click">
			<path d="M11.206 1.25a7.25 7.25 0 015.725 11.694 2.751 2.751 0 012.303 2.712l-.006.174-.016.168-.543 4.344a2.751 2.751 0 01-2.557 2.403l-.172.005h-4.51c-.57 0-1.125-.178-1.59-.507l-.171-.13-4.056-3.38a2.404 2.404 0 01-.256-3.435l.123-.131.141-.128c.43-.355.965-.545 1.509-.55A7.234 7.234 0 013.956 8.5a7.25 7.25 0 017.25-7.25zm-.007 6.5l-.095.007a.751.751 0 00-.641.641l-.007.102V16a.75.75 0 01-.89.737l-.097-.025-2.033-.678a.906.906 0 00-.748.078l-.085.06-.089.08a.901.901 0 00-.027 1.247l.087.083 4.053 3.377c.19.157.418.254.658.283l.145.008h4.51a1.25 1.25 0 001.22-.972l.02-.123.544-4.343a1.25 1.25 0 00-.906-1.36l-.13-.028-4.105-.684a.75.75 0 01-.62-.632L11.957 13V8.5a.754.754 0 00-.548-.728l-.093-.016-.116-.006zm.007-5a5.747 5.747 0 00-2.25 11.037V8.5c0-1.136.843-2.077 1.938-2.229l.158-.016.19-.004.18.01a2.251 2.251 0 012.029 2.082l.005.157v3.864l1.723.288A5.725 5.725 0 0016.956 8.5a5.75 5.75 0 00-5.75-5.75z" />
		</Icon>
	);
};
