import {GpsPointInput} from '.cache/__types__';

export type Props = {
    className?: string;
};

export type StringObj = Record<string, string>;

export type LanguageObj = Record<LocaleType, string>;

export type Menu = {
    id: number;
    name: string;
    url: string;
};

export type Required<T> = {[K in keyof T]-?: NonNullable<T[K]>};

export type RequiredDeep<T> = {
    [K in keyof T]-?: Required<RequiredDeep<NonNullable<T[K]>>>;
};

export const PoiKeys: PoiKey[] = [
    'bank',
    'kindergarten',
    'pharmacy',
    'playground',
    'post',
    'public_transport',
    'restaurant',
    'school',
    'shop',
    'sports_field',
];

export type PoiKey =
    | 'bank'
    | 'kindergarten'
    | 'pharmacy'
    | 'playground'
    | 'post'
    | 'public_transport'
    | 'restaurant'
    | 'school'
    | 'shop'
    | 'sports_field';

export type Entries<T extends object> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

export function entries<T extends object>(obj: T): Entries<T> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.entries(obj) as Entries<T>;
}

export type Keys<T extends object> = {
    [K in keyof T]: [K];
}[keyof T];

export function keys<T extends object>(obj: T): Keys<T> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.keys(obj) as Keys<T>;
}

export type Values<T> = {
    [K in keyof T]: [T[K]];
}[];

export function values<T extends object>(obj: T): Values<T> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(obj) as Values<T>;
}

export enum LocaleEnum {
    cs = 'cs',
    sk = 'sk',
    en = 'en',
}

export const langOptions: LanguageObj = {
    [LocaleEnum.cs]: 'Čeština',
    [LocaleEnum.sk]: 'Slovenčina',
    [LocaleEnum.en]: 'English',
};

export const langFlags: Record<LocaleEnum, string> = {
    [LocaleEnum.cs]: '🇨🇿',
    [LocaleEnum.sk]: '🇸🇰',
    [LocaleEnum.en]: '🇬🇧',
};

export type LocaleType = 'cs' | 'sk' | 'en';

export const verifyLocale = (locale: string | undefined): LocaleType => {
    switch (locale?.toLowerCase()) {
        default:
        case LocaleEnum.cs:
            return LocaleEnum.cs;

        case LocaleEnum.sk:
            return LocaleEnum.sk;

        case LocaleEnum.en:
            return LocaleEnum.en;
    }
};

export function nonNullArray<T>(arr: T[]): NonNullable<T>[] {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return arr.filter((i) => !!i) as NonNullable<T>[];
}

export type NonNullableProperties<T, K> = {
    [J in keyof T]: null extends T[J]
        ? Exclude<NonNullableProperties<T[J], K>, null> | K
        : T[J] extends object
        ? NonNullableProperties<T[J], K>
        : T[J];
};

export function nonNullProperties<T, K>(obj: T, defaultValue: K): NonNullableProperties<T, K> {
    const cp = Object.assign({}, obj);
    for (const property in cp) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (cp[property] === null) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-assignment
            cp[property] = defaultValue as any;
        }

        if (!Array.isArray(cp[property]) && typeof cp[property] === 'object') {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-assignment
            cp[property] = nonNullProperties(cp[property], defaultValue) as any;
        }
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return cp as NonNullableProperties<T, K>;
}

export type RequiredProperties<T, K extends keyof T> = T & Required<Pick<T, K>>;

export function propertyRequiredArray<T extends object, K extends keyof T>(arr: T[], keys: K[]): RequiredProperties<T, K>[] {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return arr.filter((i) =>
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unnecessary-condition
        entries(i).every(([key, value]) => !keys.includes(key as any) || (value !== null && value !== undefined)),
    ) as RequiredProperties<T, K>[];
}

export function propertyRequired<T extends object, K extends keyof T>(elem: T, keys: K[]): RequiredProperties<T, K> | undefined {
    return propertyRequiredArray([elem], keys).at(0) ?? undefined;
}

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const getGpsPointInput = (coordinates: number[]): GpsPointInput => {
    return {lng: coordinates[0], lat: coordinates[1]};
};
