import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconPicture: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="picture">
			<path d="M16 2.25a5.75 5.75 0 015.746 5.53l.004.22v8a5.75 5.75 0 01-5.53 5.746l-.22.004H8a5.75 5.75 0 01-5.746-5.53L2.25 16V8a5.75 5.75 0 015.53-5.746L8 2.25h8zm4.25 7.56l-4.305 4.306a2.75 2.75 0 01-3.752.129l-.138-.129-2.171-2.171a1.25 1.25 0 00-1.666-.092l-.102.092L3.76 16.3a4.25 4.25 0 004.03 3.945l.21.005h8a4.25 4.25 0 004.245-4.044L20.25 16V9.81zM16 3.75H8a4.25 4.25 0 00-4.245 4.044L3.75 8v6.188l3.305-3.304a2.75 2.75 0 013.752-.129l.138.129 2.171 2.171a1.25 1.25 0 001.666.092l.102-.092L20.24 7.7a4.25 4.25 0 00-4.03-3.945L16 3.75zM14 7.2a.8.8 0 01.775.603l.018.096.007.102A.8.8 0 1114 7.2z" />
		</Icon>
	);
};
