import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEdit: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="edit">
			<path d="M3.53 20.333l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm4 0l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm4 0l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm4-.03l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm4 0l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm-16-3.97l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm16-.03l.083.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zM17.762 2.177a1.75 1.75 0 012.35-.113l.125.113 1.585 1.586c.643.642.68 1.662.114 2.35l-.114.124-10 10a1.75 1.75 0 01-1.064.504l-.173.009H8a.75.75 0 01-.743-.648L7.25 16v-2.586c0-.407.141-.8.396-1.11l.117-.127 10-10zM16.499 5.56l-7.677 7.679a.244.244 0 00-.064.11l-.008.065-.001 1.836h1.837a.25.25 0 00.085-.015l.04-.018.052-.04L18.439 7.5l-1.94-1.94zm-12.97 6.773l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm16-.03l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm-16-3.97l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm15.647-5.096a.249.249 0 00-.3-.04l-.053.04L17.56 4.5l1.94 1.94 1.263-1.262a.251.251 0 00.04-.3l-.04-.054-1.587-1.586zM7.529 4.282l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm-4 0l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316zm8 0l.084.074c.319.318.345.81.084 1.152l-.078.09a.875.875 0 11-.09-1.316z" />
		</Icon>
	);
};
