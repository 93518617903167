import {useCallback} from 'react';
import {Typography} from '@components/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Form} from '@components/Form';
import {InfoText} from '@components/InfoText';
import {Box} from '@components/Box';
import {Input} from '@liveComponents/Field';
import {useResetPasswordMutation} from '@gql/mutation/user/resetPassword.gql';
import {SubmitButton} from '@liveComponents/Field/SubmitButton';

export type PasswordResetProps = {
    username?: string;
    onSuccess?: () => void;
    onFailed?: () => void;
    onLogin?: () => void;
    onRegister?: () => void;
};

type PasswordResetFormType = {
    username: string;
};

export const PasswordResetForm = ({onSuccess, onRegister, onLogin, onFailed, username = ''}: PasswordResetProps) => {
    const intl = useIntl();
    const [resetPassword] = useResetPasswordMutation();
    const processSubmit = useCallback(
        (values: PasswordResetFormType) => {
            void resetPassword({
                variables: {
                    login: values.username,
                },
            }).then((res) => {
                if (res.data?.resetPassword) {
                    onSuccess?.();
                    return;
                } else {
                    onFailed?.();
                }
            });
        },
        [resetPassword, onSuccess, onFailed],
    );

    return (
        <>
            <Typography as="h2" variant="h4" className="mb-2">
                <FormattedMessage defaultMessage="Zapomněli jste heslo?" />
            </Typography>
            <Typography as="p" variant="perex" color="grey-dark" className="mb-10">
                <FormattedMessage defaultMessage="Obnovte si jej zde" />
            </Typography>

            <Formik
                onSubmit={processSubmit}
                initialValues={{
                    username,
                }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .email(intl.formatMessage({defaultMessage: 'Zadejte validní emailovou adresu'}))
                        .required(intl.formatMessage({defaultMessage: 'Povinné pole'})),
                })}
            >
                {({handleSubmit}) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-4" controlId="email">
                            <Form.Label visuallyHidden>
                                <FormattedMessage defaultMessage="Vaše e-mailová adresa" />
                            </Form.Label>
                            <Input
                                name="username"
                                type="text"
                                autoComplete="email"
                                trimOnBlur
                                placeholder={intl.formatMessage({defaultMessage: 'Vaše e-mailová adresa'})}
                            />
                        </Form.Group>

                        <SubmitButton className="w-100">
                            <FormattedMessage defaultMessage="Resetovat heslo" />
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            {(onLogin ?? onRegister) && (
                <Box className="d-flex justify-content-between mt-4">
                    {onLogin && (
                        <InfoText iconName={false} className="mb-0">
                            <a role="button" onClick={onLogin} className="link">
                                <FormattedMessage defaultMessage="Zpět na přihlášení" />
                            </a>
                        </InfoText>
                    )}
                    {onRegister && (
                        <InfoText iconName={false} className="mb-0">
                            <FormattedMessage
                                defaultMessage="Nemáte účet? <link>Vytvořit nový</link>"
                                values={{
                                    link: (chunks: any) => (
                                        <a role="button" onClick={onRegister} className="link">
                                            {chunks}
                                        </a>
                                    ),
                                }}
                            />
                        </InfoText>
                    )}
                </Box>
            )}
        </>
    );
};
