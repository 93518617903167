import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconStore: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="store">
			<path d="M8.697 18.517l.122.007a1.117 1.117 0 11-.244 0l.122-.007zm8.412 0l.121.007a1.116 1.116 0 11-.243 0l.122-.007zM5.224 3.25c.318 0 .597.2.704.49l.029.101.521 2.409H18.5a1.75 1.75 0 011.726 2.037l-.028.138-1.348 5.392a2.75 2.75 0 01-2.214 2.045l-.18.024-6.817.682a2.75 2.75 0 01-2.922-1.997l-.042-.17-1.538-7.263-.518-2.388H3.5a.75.75 0 01-.743-.648L2.75 4a.75.75 0 01.648-.743L3.5 3.25h1.724zM18.5 7.75H6.799l1.344 6.34c.123.583.637.991 1.221.992l.126-.006 6.816-.682a1.25 1.25 0 001.05-.816l.038-.125 1.348-5.392a.25.25 0 00-.183-.304L18.5 7.75z" />
		</Icon>
	);
};
