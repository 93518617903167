import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconArrowDownCircle: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="arrow-down-circle">
            <g id="Arrow" transform="matrix(0.77419355,0,0,0.77419355,-10.064516,-658.06452)">
                <path
                    id="Path_395"
                    d="M 15.5,0 A 15.5,15.5 0 1 1 0,15.5 15.5,15.5 0 0 1 15.5,0 Z"
                    transform="rotate(90,-403,447)"
                    fill="#249b71"
                />
                <g id="Group_1414" transform="rotate(90,-412.9755,445.4875)">
                    <path
                        id="Path_394"
                        d="m 0.684,4.585 h 11.484 l -2.152,2.151 a 0.65548799,0.65548799 0 0 0 0.927,0.927 L 12.9,5.7 14.208,4.39 a 0.654,0.654 0 0 0 0,-0.922 L 10.943,0.2 a 0.65548799,0.65548799 0 1 0 -0.927,0.927 l 2.156,2.151 H 0.651 a 0.655,0.655 0 1 0 0.033,1.307 z"
                        fill="#ffffff"
                    />
                </g>
            </g>
        </Icon>
    );
};
