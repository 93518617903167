import {useCallback, useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';

function useElementScrollbarWidth<T extends HTMLElement = HTMLDivElement>() {
    const [ref, setRef] = useState<T | null>(null);
    const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
    const [scrollbarHeight, setScrollbarHeight] = useState<number>(0);

    const handleSize = useCallback(() => {
        const scrollbarWidth = (ref?.offsetWidth || 0) - (ref?.clientWidth || 0);
        const scrollbarHeight = (ref?.offsetHeight || 0) - (ref?.clientHeight || 0);

        setScrollbarWidth(scrollbarWidth);
        setScrollbarHeight(scrollbarHeight);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.offsetWidth, ref?.clientWidth, ref?.offsetHeight, ref?.clientHeight]);

    useEventListener('resize', handleSize);

    useIsomorphicLayoutEffect(() => {
        handleSize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.offsetWidth, ref?.clientWidth, ref?.offsetHeight, ref?.clientHeight]);

    return {ref, setRef, scrollbarWidth, scrollbarHeight};
}

export default useElementScrollbarWidth;
