import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconTagColor: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="tagcolor">
            <path d="M15.006 4.252a2.751 2.751 0 0 1 2.746 2.583l.005.168v3.56c0 .662-.239 1.3-.67 1.798l-.134.145-6.493 6.503a2.55 2.55 0 0 1-3.46.14l-.148-.138-3.853-3.85a2.55 2.55 0 0 1-.137-3.46l.137-.149 6.498-6.495a2.75 2.75 0 0 1 1.746-.798l.198-.007z" />
            <circle
                style={{
                    fill: '#fff',
                    strokeWidth: 1.2748,
                }}
                cx={13.965}
                cy={7.844}
                r={1.25}
            />
        </Icon>
    );
};
