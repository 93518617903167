import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconTarget: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="archive">
            <path fill="none" d="M0 0h24v24H0Z" />
            <path
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 5a6.994 6.994 0 0 0-6.317 4M19 12a6.994 6.994 0 0 0-4-6.317M12 19a6.994 6.994 0 0 0 6.317-4M5 12a6.994 6.994 0 0 0 4 6.317M12 3v4M21 12h-4M12 21v-4M3 12h4"
            />
        </Icon>
    );
};
