import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Footer.module.scss';

export const FooterCopyright: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.footerCopyright}>
			<div className={classNames(styles.footerCopyrightHolder, 'd-flex flex-wrap justify-content-center justify-content-md-start')}>
				{children}
			</div>
		</div>
	);
};
