import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconMoney: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="money">
            <path d="M20 4.58a2.75 2.75 0 0 1 2.75 2.75v9.34A2.75 2.75 0 0 1 20 19.42H4a2.75 2.75 0 0 1-2.75-2.75V7.33A2.75 2.75 0 0 1 4 4.58h16Zm0 1.5H4c-.7 0-1.25.56-1.25 1.25v9.34c0 .69.56 1.25 1.25 1.25h16c.69 0 1.25-.56 1.25-1.25V7.33c0-.69-.56-1.25-1.25-1.25Zm-1 7.17c.41 0 .75.34.75.75A2.75 2.75 0 0 1 17 16.75a.75.75 0 0 1-.1-1.5h.1c.65 0 1.18-.5 1.24-1.12l.01-.13c0-.41.34-.75.75-.75Zm-14 0c.38 0 .7.28.74.65l.01.1c0 .65.5 1.18 1.12 1.24l.13.01a.75.75 0 1 1 0 1.5A2.75 2.75 0 0 1 4.25 14c0-.41.34-.75.75-.75Zm9.3-3.55a3.25 3.25 0 1 1-4.6 4.6 3.25 3.25 0 0 1 4.6-4.6Zm-3.54 1.06a1.75 1.75 0 1 0 2.48 0l-.13-.11a1.75 1.75 0 0 0-2.35.11ZM17 7.25A2.75 2.75 0 0 1 19.75 10a.75.75 0 0 1-1.5.1V10c0-.65-.5-1.18-1.12-1.24L17 8.75a.75.75 0 1 1 0-1.5Zm-10 0a.75.75 0 0 1 .1 1.5H7c-.65 0-1.18.5-1.24 1.12l-.01.13a.75.75 0 1 1-1.5 0A2.75 2.75 0 0 1 7 7.25Z" />
        </Icon>
    );
};
