import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconLocation: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="location">
			<path d="M12 2.25A7.75 7.75 0 0119.75 10c0 .461-.048.931-.141 1.41-.412 2.117-1.68 4.323-3.534 6.54a30.987 30.987 0 01-2.683 2.818l-.408.371-.19.168-.318.273a.75.75 0 01-.952 0l-.508-.44a27.364 27.364 0 01-.2-.18l-.425-.396a30.987 30.987 0 01-2.466-2.614c-1.853-2.217-3.122-4.423-3.534-6.54A7.369 7.369 0 014.25 10 7.75 7.75 0 0112 2.25zm0 1.5A6.25 6.25 0 005.75 10c0 .362.038.737.113 1.123.355 1.82 1.508 3.825 3.212 5.865.746.892 1.546 1.73 2.347 2.487l.403.375.175.156.272-.246.306-.285c.8-.758 1.601-1.595 2.347-2.487 1.704-2.04 2.857-4.045 3.212-5.865.075-.386.113-.76.113-1.123A6.25 6.25 0 0012 3.75zm0 2.5c1.99 0 3.63 1.553 3.745 3.552l.005.198c0 1.99-1.553 3.63-3.552 3.745L12 13.75a3.754 3.754 0 01-3.745-3.552L8.25 10c0-1.99 1.553-3.63 3.552-3.745zm0 1.5l-.133.004A2.246 2.246 0 009.75 9.978l.004.155a2.246 2.246 0 002.224 2.117l.155-.004a2.246 2.246 0 002.117-2.224l-.004-.155A2.25 2.25 0 0012 7.75z" />
		</Icon>
	);
};
