import classNames from 'classnames';
import React, { FC } from 'react';
import { ContentBox } from '../ContentBox';
import styles from './Header.module.scss';

export const HeaderNavDropdown: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<ContentBox className={classNames(styles.headerNavDropdown, 'position-absolute')} rounded="sm">
			{children}
		</ContentBox>
	);
};
