import classNames from 'classnames';
import {format} from 'date-fns';
import {cs, sk, enUS} from 'date-fns/locale';
import React, {useEffect, useRef, useState} from 'react';
import {FormControl, FormControlProps} from 'react-bootstrap';
import {DayPicker, DayPickerRangeProps, SelectRangeEventHandler} from 'react-day-picker';
import {usePopper} from 'react-popper';
import {Button} from '../../Button';
import {DatepickerCaption} from '../../Datepicker/DatepickerCaption';
import {Icon, IconProps} from '../../Icon';
import {InputGroup} from '../../InputGroup';
import {Typography} from '../../Typography';
import styles from './Datepicker.module.scss';
import {LocaleType, LocaleEnum} from 'src/types/general';
import {Form} from '@components/Form';
import {CalendarSelectionGroup} from '@components/CalendarSelectionGroup';

const locales = {
    [LocaleEnum.cs]: cs,
    [LocaleEnum.sk]: sk,
    [LocaleEnum.en]: enUS,
};

export type FormRangeDatepickerProps = Omit<DayPickerRangeProps, 'locale' | 'mode' | 'onSelect'> & {
    locale?: LocaleType;
    onSelect: SelectRangeEventHandler;
    inputProps?: FormControlProps;
    iconName?: IconProps['name'] | false;
    errors?: string;
    dateFormat?: string;
};

export const FormRangeDatepicker = ({
    locale = LocaleEnum.cs,
    inputProps = {},
    iconName = 'Calendar',
    dateFormat = 'd. M. yyyy',
    selected,
    onSelect,
    errors,
    ...props
}: FormRangeDatepickerProps) => {
    const usedLocale = locales[locale];

    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const popperRef = useRef<HTMLDivElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    });

    const inputFromValue = selected?.from ? format(selected.from, dateFormat, {locale: usedLocale}) : '';
    const inputToValue = selected?.to ? format(selected.to, dateFormat, {locale: usedLocale}) : '';

    const closeDialog = () => {
        setIsPopperOpen(false);
    };

    const toggleDialog = () => {
        setIsPopperOpen((isOpen) => !isOpen);
    };

    const handleDaySelect: SelectRangeEventHandler = (...rest) => {
        onSelect(...rest);

        //closeDialog();
    };

    useEffect(() => {
        function handleClickOutside(e: MouseEvent) {
            if (
                popperElement &&
                !popperElement.contains(e.target as any) &&
                popperRef.current &&
                !popperRef.current.contains(e.target as any)
            ) {
                closeDialog();
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [popperElement]);

    return (
        <>
            <InputGroup ref={popperRef} size={inputProps.size} hasValidation={!!errors}>
                <FormControl
                    {...inputProps}
                    value={inputFromValue}
                    //onFocus={(event) => {
                    //     openDialog();

                    //     inputProps?.onFocus?.(event);
                    //}}
                    onClick={toggleDialog}
                    className={classNames(styles.datepickerInput, styles.datepickerRangeInput, inputProps.className, {
                        ['is-invalid']: !!errors,
                    })}
                    readOnly
                />

                <CalendarSelectionGroup.Separator />

                <FormControl
                    {...inputProps}
                    value={inputToValue}
                    //onFocus={(event) => {
                    //     openDialog();

                    //     inputProps?.onFocus?.(event);
                    //}}
                    onClick={toggleDialog}
                    className={classNames(
                        styles.datepickerInput,
                        styles.datepickerRangeInput,
                        inputProps.className,
                        {['is-invalid']: !!errors},
                        'border-start-0',
                    )}
                    readOnly
                />

                {iconName && (
                    <InputGroup.Text>
                        <Button variant="blank" onClick={toggleDialog} tabIndex={-1}>
                            <Typography color="grey-dark">
                                <Icon name={iconName} size={inputProps.size} />
                            </Typography>
                        </Button>
                    </InputGroup.Text>
                )}

                {errors && <Form.Control.Feedback type={'invalid'}>{errors}</Form.Control.Feedback>}
            </InputGroup>

            {isPopperOpen && (
                <div
                    tabIndex={-1}
                    style={popper.styles.popper}
                    className={classNames(styles.datepicker, styles.datepickerDialog)}
                    {...popper.attributes.popper}
                    ref={setPopperElement}
                    role="dialog"
                >
                    <div className={styles.datepickerHolder}>
                        <DayPicker
                            {...props}
                            initialFocus={isPopperOpen}
                            numberOfMonths={2}
                            mode="range"
                            selected={selected}
                            onSelect={handleDaySelect}
                            locale={usedLocale}
                            classNames={{
                                root: styles.datepickerRoot,
                                vhidden: 'visually-hidden',
                                button_reset: styles.datepickerButtonReset,

                                caption: styles.datepickerCaption,
                                nav: styles.datepickerNav,
                                nav_button: styles.datepickerNavButton,
                                nav_button_previous: styles.datepickerNavButtonPrev,
                                nav_button_next: styles.datepickerNavButtonNext,

                                months: styles.datepickerMonths,
                                month: styles.datepickerMonth,

                                table: styles.datepickerTable,
                                head: styles.datepickerHead,
                                head_cell: styles.datepickerHeadCell,
                                cell: styles.datepickerCell,
                                day: styles.datepickerDay,
                                day_today: styles.datepickerDayToday,
                                day_outside: styles.datepickerDayOutside,
                                day_disabled: styles.datepickerDayDisabled,
                                day_selected: styles.datepickerDaySelected,
                            }}
                            components={{Caption: DatepickerCaption}}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
