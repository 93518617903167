import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconInstagram: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="instagram">
			<path d="M16.505 2.25a5.245 5.245 0 015.24 5.03l.005.216v9.009a5.245 5.245 0 01-5.03 5.24l-.216.005H7.496a5.246 5.246 0 01-5.242-5.03l-.004-.216V7.496a5.246 5.246 0 015.03-5.242l.216-.004h9.009zm0 1.5H7.496a3.746 3.746 0 00-3.74 3.547l-.006.199v9.008a3.746 3.746 0 003.547 3.74l.199.006h9.008a3.745 3.745 0 003.74-3.546l.006-.199V7.496a3.745 3.745 0 00-3.546-3.74l-.199-.006zm-1.43 5.174a4.35 4.35 0 11-6.15 6.152 4.35 4.35 0 016.15-6.152zm-5.09 1.06a2.85 2.85 0 104.158.138l-.128-.137-.137-.128a2.85 2.85 0 00-3.893.128zm6.964-4.021a1.087 1.087 0 11-.122.007l.122-.007z" />
		</Icon>
	);
};
