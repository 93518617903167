import base64 from 'base-64';
import utf8 from 'utf8';

export function utf8_to_b64(str: string) {
    return base64.encode(utf8.encode(str));
}

export function b64_to_utf8(str: string) {
    return utf8.decode(base64.decode(str));
}
