import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconEquipment: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="equipment">
			<path d="M19.369 13.81l.066.117.701 1.425 1.572.229c.957.137 1.363 1.27.759 1.983l-.085.09-1.137 1.108.268 1.566c.163.952-.79 1.69-1.656 1.331l-.108-.05-1.404-.739-1.407.74c-.856.449-1.85-.23-1.778-1.164l.015-.118.268-1.567-1.136-1.106c-.693-.673-.356-1.827.552-2.05l.121-.024 1.572-.229.702-1.424c.424-.863 1.619-.903 2.115-.118zm-.655-11.56a1.75 1.75 0 011.744 1.607l.006.143v5.266l1.024.879a.75.75 0 01-.887 1.204l-.089-.066-7.7-6.598a1.25 1.25 0 00-1.514-.085l-.112.085-6.15 5.271V19c0 .648.491 1.18 1.122 1.244l.128.006H11a.75.75 0 01.102 1.493L11 21.75H6.286a2.75 2.75 0 01-2.745-2.582L3.536 19v-7.758l-.048.041a.75.75 0 01-1.055-1.06l.079-.078 7.698-6.598a2.75 2.75 0 013.429-.12l.15.12 1.318 1.129V4a1.75 1.75 0 011.607-1.744l.143-.006h1.857zm-.37 12.858l-.621 1.262a.75.75 0 01-.463.388l-.102.022-1.391.202 1.006.98a.75.75 0 01.227.56l-.01.105-.24 1.387 1.191-.625a.772.772 0 01.272-.105l.096-.01.097.001.097.015.096.028.093.041 1.245.654-.237-1.386a.75.75 0 01.146-.586l.07-.078 1.005-.981-1.39-.202a.75.75 0 01-.512-.32l-.053-.09-.622-1.262zm.37-11.358h-1.857a.25.25 0 00-.243.193L16.607 4v1.961l2.357 2.02V4a.25.25 0 00-.193-.243l-.057-.007z" />
		</Icon>
	);
};
