import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconClock: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="clock">
			<path d="M12.01 1.76c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75a9.75 9.75 0 010-19.5zm0 1.5a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zm-.6 2.51a.75.75 0 01.743.648l.007.102v4.85h3.9a.75.75 0 01.102 1.493l-.102.007h-4.65a.75.75 0 01-.75-.75v-5.6a.75.75 0 01.75-.75z" />
		</Icon>
	);
};
