import {FC} from 'react';
import {useRouter, NextRouter} from 'next/router';

import {LangSwitcher} from '@components/LangSwitcher';

import {LocaleType, langOptions, entries, langFlags} from 'src/types/general';
import {getLocalizedPathSeparated} from '@utils/routes';
import {useLocale} from '@utils/locale';

const languages = entries(langOptions);

export const setLang = (router: NextRouter) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const locale = event.target.value as LocaleType;
    const params = {...router.query};
    if (params.host) {
        delete params.host;
    }
    const {pathname, query} = getLocalizedPathSeparated(router.route, locale, params);
    router.push({pathname, query}, undefined, {locale});
};

type Props = {
    variant?: 'button';
    header?: boolean;
};

export const LanguageSwitcher: FC<React.PropsWithChildren<Props>> = (props) => {
    const router = useRouter();
    const locale = useLocale();

    return (
        <LangSwitcher>
            <LangSwitcher.Label variant={props.variant} header={props.header}>
                <label htmlFor="lang-switcher">{props.header ? langFlags[locale] : langOptions[locale]}</label>
            </LangSwitcher.Label>

            <LangSwitcher.Select>
                <select id="lang-switcher" onChange={setLang(router)} defaultValue={locale}>
                    {languages.map((lang) => (
                        <option value={lang[0]} key={lang[0]}>
                            {langFlags[lang[0]]} {lang[1]}
                        </option>
                    ))}
                </select>
            </LangSwitcher.Select>
        </LangSwitcher>
    );
};
