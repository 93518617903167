import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconDeviceComputer: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="device-computer">
			<path d="M19 2.25a2.75 2.75 0 012.745 2.582L21.75 5v10a2.75 2.75 0 01-2.582 2.745L19 17.75h-4.151l.313 2.5H16.8a.75.75 0 01.102 1.493l-.102.007H7.2a.75.75 0 01-.102-1.493l.102-.007h1.637l.313-2.5H5a2.75 2.75 0 01-2.745-2.582L2.25 15V5a2.75 2.75 0 012.582-2.745L5 2.25h14zm-5.663 15.5h-2.675l-.313 2.5h3.301l-.313-2.5zm6.913-3.5H3.75V15c0 .648.491 1.18 1.122 1.244L5 16.25h14a1.25 1.25 0 001.244-1.122L20.25 15v-.75zM19 3.75H5a1.25 1.25 0 00-1.244 1.122L3.75 5v7.75h16.5V5a1.25 1.25 0 00-1.122-1.244L19 3.75z" />
		</Icon>
	);
};
