import {ChangeEvent, FC, FocusEvent, HTMLProps, ReactNode, useCallback} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';
import {InputGroup} from '@components/InputGroup';
import {FormControlProps} from 'react-bootstrap';
import {convertEmoticons} from '@utils/emoji';

type Props = {
    className?: string;
    inputClassName?: string;
    label?: ReactNode;
    placeholder?: string;
    multiline?: boolean;
    required?: boolean;
    trimOnBlur?: boolean;
    append?: string | ReactNode;
    convertEmoticons?: boolean;
} & ({label: ReactNode} | {placeholder: string}) &
    FormControlProps &
    (
        | ({multiline?: false} & Omit<HTMLProps<HTMLInputElement>, 'label'>)
        | ({multiline: true} & Omit<HTMLProps<HTMLTextAreaElement>, 'label'>)
    );

export type InputProps = Props & {name: string};

export const Input: FC<React.PropsWithChildren<InputProps>> = ({
    type,
    className,
    inputClassName,
    label,
    placeholder,
    multiline,
    required,
    name,
    append,
    trimOnBlur,
    convertEmoticons: emoticons,
    ...props
}) => {
    const [field, meta, actions] = useField<string | number | null>(name);
    const {value, onBlur, ...fieldRest} = field;

    const isTouched = meta.touched;
    const ownErrors = meta.error;
    const hasErrors = !!ownErrors;

    const handleBlur = useCallback(
        async (e: FocusEvent) => {
            let nv = value;
            if (emoticons && typeof value === 'string') {
                nv = convertEmoticons(value, true);
            }
            if (trimOnBlur && typeof nv === 'string') {
                nv = nv.trim();
            }
            if (nv !== value) {
                await actions.setValue(nv);
            }
            onBlur(e);
        },
        [emoticons, value, onBlur, actions, trimOnBlur],
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (type === 'number') {
                const v = parseFloat(e.currentTarget.value);
                actions.setValue(Number.isNaN(v) ? '' : v);
            } else if (emoticons) {
                actions.setValue(convertEmoticons(e.currentTarget.value));
            } else {
                actions.setValue(e.currentTarget.value);
            }
        },
        [type, actions, emoticons],
    );

    return (
        <Form.Group className={className} controlId={field.name}>
            {(label ?? placeholder) && <Form.Label>{label ?? placeholder}</Form.Label>}
            <InputGroup hasValidation={isTouched && hasErrors}>
                <Form.Control
                    as={multiline ? ('textarea' as const) : undefined}
                    type={type}
                    placeholder={placeholder}
                    value={value ?? ''}
                    onBlur={handleBlur}
                    {...fieldRest}
                    {...props}
                    onChange={handleChange}
                    isInvalid={isTouched && hasErrors}
                    // required={required}
                    className={inputClassName}
                />
                {append && <InputGroup.Text>{append}</InputGroup.Text>}
                {required && <InputGroup.Required>*</InputGroup.Required>}
                {isTouched && hasErrors && <Form.Control.Feedback type={'invalid'}>{ownErrors}</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    );
};
