import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconSearch: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="search">
			<path d="M14.723 5.052a6.722 6.722 0 01.464 8.991l5.347 5.42a.75.75 0 01-.984 1.127l-.084-.073-5.348-5.423a6.722 6.722 0 11.605-10.042zM6.28 6.112a5.221 5.221 0 007.31 7.456.18.18 0 01.034-.042l.05-.043.164-.17a5.221 5.221 0 000-7.018l-.174-.183-.183-.174a5.221 5.221 0 00-7.2.174z" />
		</Icon>
	);
};
