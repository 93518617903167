import {ReactNode} from 'react';
import {ContentBox} from '@components/ContentBox';
import {Icon} from '@components/Icon';
import {Typography} from '@components/Typography';
import styles from './UspBox.module.scss';

type UspBoxProps = {
    usps: ReactNode[];
};

export const UspBox = ({usps}: UspBoxProps) => (
    <ContentBox background="grey-light" className="mb-5 mx-n10 px-10 d-flex flex-column align-items-start" rounded={false}>
        {usps.map((usp, index) => (
            <div className={styles.usp} key={index}>
                <Icon name="CheckCircle" size="md" className={styles.uspIcon} color="green" />
                <Typography as="p" className={styles.uspText}>
                    {usp}
                </Typography>
            </div>
        ))}
    </ContentBox>
);
