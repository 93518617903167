import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronBigRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-big-right">
			<path d="M6.976 2.469a.75.75 0 01.976-.071l.084.073 8.995 9.027a.75.75 0 01.071.976l-.072.084-9 8.973a.75.75 0 01-1.132-.978l.072-.084 8.469-8.444-8.465-8.496a.75.75 0 01-.071-.976l.073-.084z" />
		</Icon>
	);
};
