import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconTreeHouse: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="tree-house">
			<path d="M5.126 6.564a1.25 1.25 0 011.666-.091l.102.09 2.499 2.5a1.25 1.25 0 01-.748 2.126l-.082.004 1.857 2.198a1.434 1.434 0 01-.957 2.352l-.139.007H6.76v1.433a7.8 7.8 0 015.771 2.287.75.75 0 11-1.06 1.06 6.321 6.321 0 00-8.94 0 .75.75 0 11-1.06-1.06 7.784 7.784 0 013.789-2.096V15.75H2.695a1.434 1.434 0 01-1.187-2.24l.092-.12 1.855-2.195a1.25 1.25 0 01-.92-2.03l.092-.102 2.499-2.5zM21 3.25a.75.75 0 01.743.648L21.75 4v3.815l.73.609a.75.75 0 01-.73 1.283v5.543H22a.75.75 0 01.102 1.493L22 16.75h-9a.75.75 0 01-.102-1.493L13 15.25h.75V12a.75.75 0 01.648-.743l.102-.007h3a.75.75 0 01.743.648l.007.102v3.25h2V8.519l-4.251-3.543-3.519 2.933a.75.75 0 01-.979-.016l-.077-.08a.75.75 0 01.016-.979l.08-.077 4-3.333a.75.75 0 01.863-.069l.097.069.77.641V4a.75.75 0 01.648-.743L18 3.25h3zm-4.25 9.5h-1.5v2.5h1.5v-2.5zM6.602 11.196H5.416l-2.58 3.054h6.346l-2.58-3.054zm-.593-3.395L4.114 9.696h3.79L6.009 7.801zM20.25 4.75h-1.5v.565l1.5 1.25V4.75z" />
		</Icon>
	);
};
