import {FC, HTMLProps, ReactComponentElement, cloneElement, ReactNode} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';
import {FormattedMessage} from 'react-intl';
import {FormSelectProps} from '@components/Form/Select';

type Props = {
    className?: string;
    label?: ReactNode;
    placeholder?: string;
    options: {
        name: ReactComponentElement<typeof FormattedMessage> | string;
        value: string;
        disabled?: boolean;
    }[];
} & ({label: ReactNode} | {placeholder: string}) &
    FormSelectProps &
    Omit<HTMLProps<HTMLSelectElement>, 'label'>;

export type SelectInputProps = Props & {name: string};

export const SelectInput: FC<React.PropsWithChildren<SelectInputProps>> = ({
    className,
    label,
    placeholder,
    name,
    options,
    ...innerProps
}) => {
    const [field, meta] = useField(name);

    const isTouched = meta.touched;
    const ownErrors = meta.error;
    const hasErrors = !!ownErrors;

    return (
        <Form.Group className={className} controlId={field.name}>
            <Form.Label visuallyHidden={!label}>{label ?? placeholder}</Form.Label>
            <Form.Select {...field} {...innerProps} isInvalid={isTouched && hasErrors}>
                {placeholder && <option value={''}>{placeholder}</option>}

                {options.map((option, idx) => {
                    if (typeof option.name === 'string') {
                        return (
                            <option key={idx} value={option.value} disabled={option.disabled}>
                                {option.name}
                            </option>
                        );
                    }

                    // @ts-expect-error
                    return cloneElement(option.name, Object.assign({}, option.name.props, {key: idx}), (message: any) => (
                        <option value={option.value}>{message}</option>
                    ));
                })}
            </Form.Select>
            {isTouched && hasErrors && <Form.Control.Feedback type={'invalid'}>{ownErrors}</Form.Control.Feedback>}
        </Form.Group>
    );
};
