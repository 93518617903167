import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconFloorAreaLand: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="floor-area-land">
            <g strokeLinecap="round" strokeWidth="1" stroke="#807878" fill="none" strokeLinejoin="round">
                <path d="M8 21h-3.286l-9.71092e-08-5.66089e-11c-.946387-.000551722-1.71345-.767613-1.714-1.714v-3.286"></path>
                <path d="M16 4h3.286l-5.28209e-08-3.07905e-11c.946387.00055164 1.71345.767612 1.714 1.714v3.286"></path>
                <path d="M7 2.5v-.5"></path>
                <path d="M7 12v-.5"></path>
                <path d="M10.18 3.82l.355-.355"></path>
                <path d="M3.465 10.535l.355-.355"></path>
                <path d="M11.5 7h.5"></path>
                <path d="M2 7h.5"></path>
                <path d="M10.18 10.18l.355.355"></path>
                <path d="M11.909 14.182v6.818h9.091v-6.818"></path>
                <path d="M10.772 14.977l5.682-3.977 5.682 3.977"></path>
                <path d="M18.159 21v-3.182h-3.55271e-15c0-.627395-.508605-1.136-1.136-1.136h-1.136 -4.96561e-08c-.627395 2.74243e-08-1.136.508605-1.136 1.136v3.182"></path>
                <path d="M3.465 3.465l.355.355"></path>
                <circle cx="7" cy="7" r="2.105"></circle>
            </g>
            <path fill="none" d="M0 0h24v24h-24Z"></path>
        </Icon>
    );
};
