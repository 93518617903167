import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronBigLeft: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-big-left">
			<path d="M17.024 2.469a.75.75 0 00-.976-.071l-.084.073-8.995 9.027a.75.75 0 00-.071.976l.072.084 9 8.973a.75.75 0 001.132-.978l-.072-.084-8.469-8.444 8.465-8.496a.75.75 0 00.071-.976l-.073-.084z" />
		</Icon>
	);
};
