import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconHealthSuitcase: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="health-suitcase">
            <g strokeLinecap={'round'} stroke={'currentColor'} strokeWidth={'1.5'} fill={'none'} strokeLinejoin={'round'}>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M16.25 7V5.5a2.5 2.5 0 0 0-2.5-2.5h-2a2.5 2.5 0 0 0-2.5 2.5V7M11 14h3.5M12.75 12.25v3.5"
                />
                <circle cx={12.75} cy={14} r={4.5} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
                <rect width={18} height={14} x={3.75} y={7} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} rx={3} />
            </g>
        </Icon>
    );
};
