import classNames from 'classnames';
import React, { FC } from 'react';
import { Typography, TypographyProps } from '../Typography';
import styles from './RecaptchaClaim.module.scss';

export const RecaptchaClaim: FC<React.PropsWithChildren<TypographyProps>> = ({ className, children, ...props }) => {
	return (
		<Typography as="p" variant="caption" color="grey-dark" className={classNames(styles.recaptchaClaim, className)} {...props}>
			{children}
		</Typography>
	);
};
