import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconWatchdog: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="watchdog">
            <g id="g2195" transform="matrix(1.0714091,0,0,1.0714091,0.10692663,0.17871494)">
                <g id="g2203" transform="translate(304.2002,191.7334)">
                    <path
                        d="m -287,-169.7 h -4.1 c -0.4,0 -0.7,-0.3 -0.7,-0.7 v -7.6 c 0,-0.4 0.3,-0.7 0.7,-0.7 0.4,0 0.7,0.3 0.7,0.7 v 6.9 h 2.7 v -0.7 c 0,-0.4 -0.3,-0.7 -0.7,-0.7 -0.4,0 -0.7,-0.3 -0.7,-0.7 v -2.7 c 0,-0.2 0.1,-0.4 0.2,-0.5 0.7,-0.7 1.2,-1.8 1.2,-2.8 v -6.3 c 0,-0.4 0.3,-0.7 0.7,-0.7 h 2.1 c 0.7,0 1.4,-0.6 1.4,-1.4 v -0.7 h -2 c -0.2,0 -0.4,-0.1 -0.5,-0.2 l -1.9,-1.9 h -1.8 c -1.5,0 -2.7,1.2 -2.7,2.7 v 2 c 0,1.2 -0.7,2.2 -1.8,2.6 l -4.4,1.6 c -0.8,0.3 -1.3,1.1 -1.3,1.9 v 4.3 c 0,0.1 0,0.2 -0.1,0.3 l -0.6,1.2 v 2.6 h 2.7 v -0.7 c 0,-0.4 -0.3,-0.7 -0.7,-0.7 -0.3,0 -0.5,-0.1 -0.6,-0.4 -0.1,-0.2 -0.1,-0.5 0.1,-0.7 l 2,-2.7 0.7,-2 c 0.1,-0.4 0.5,-0.6 0.9,-0.4 0.4,0.1 0.6,0.5 0.4,0.9 l -0.7,2.1 c 0,0.1 -0.1,0.1 -0.1,0.2 l -1.5,2 c 0.6,0.4 0.9,1 0.9,1.7 v 1.4 c 0,0.4 -0.3,0.7 -0.7,0.7 h -4.1 c -0.4,0 -0.7,-0.3 -0.7,-0.7 v -3.4 c 0,-0.1 0,-0.2 0.1,-0.3 l 0.6,-1.2 v -4.1 c 0,-1.4 0.9,-2.7 2.3,-3.2 l 4.4,-1.6 c 0.5,-0.2 0.9,-0.7 0.9,-1.3 v -2 c 0,-2.3 1.9,-4.1 4.1,-4.1 h 2.1 c 0.2,0 0.4,0.1 0.5,0.2 l 1.9,1.9 h 2.5 c 0.4,0 0.7,0.3 0.7,0.7 v 1.4 c 0,1.5 -1.2,2.8 -2.8,2.8 h -1.4 v 5.6 c 0,1.3 -0.5,2.6 -1.4,3.6 v 1.9 c 0.8,0.3 1.4,1.1 1.4,2 v 1.4 c -0.2,0.2 -0.5,0.5 -0.9,0.5 z"
                        id="path2"
                    />
                </g>
                <g id="g2207" transform="translate(175.6665,143.5332)">
                    <path
                        d="m -162.6,-127 h -5.5 c -0.4,0 -0.7,-0.3 -0.7,-0.7 0,-0.4 0.3,-0.7 0.7,-0.7 h 5.5 c 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 z"
                        id="path5"
                    />
                </g>
                <g id="g2211" transform="translate(336.3335,497)">
                    <path
                        d="m -323.6,-488.7 c -1.3,0 -2.4,-1.1 -2.4,-2.4 v -1.7 c 0,-1.1 0.4,-2.1 1.2,-2.9 0.8,-0.8 1.8,-1.2 2.9,-1.2 0.4,0 0.7,0.3 0.7,0.7 0,0.4 -0.3,0.7 -0.7,0.7 -0.7,0 -1.4,0.3 -1.9,0.8 -0.5,0.5 -0.8,1.2 -0.8,1.9 v 1.7 c 0,0.6 0.5,1 1,1 0.5,0 1,-0.5 1,-1 v -2.4 c 0,-0.4 0.3,-0.7 0.7,-0.7 0.4,0 0.7,0.3 0.7,0.7 v 2.4 c 0,1.3 -1.1,2.4 -2.4,2.4 z"
                        id="path8"
                    />
                </g>
                <g id="g2215" transform="translate(63.2002,239.9336)">
                    <path
                        d="m -59.8,-227.5 h -0.7 c -0.7,0 -1.4,-0.3 -2,-0.8 -0.5,-0.5 -0.8,-1.2 -0.8,-2 v -2.7 c 0,-0.4 0.3,-0.7 0.7,-0.7 h 0.7 c 0.7,0 1.4,0.3 2,0.8 0.5,0.5 0.8,1.2 0.8,2 v 2.7 c 0,0.3 -0.3,0.7 -0.7,0.7 z m -2,-4.9 v 2 c 0,0.4 0.1,0.7 0.4,1 0.3,0.3 0.6,0.4 0.9,0.4 v -2 c 0,-0.4 -0.1,-0.7 -0.4,-1 -0.2,-0.2 -0.5,-0.3 -0.9,-0.4 z"
                        id="path11"
                    />
                </g>
            </g>
        </Icon>
    );
};
