import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconProfileSquare: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon
            {...props}
            id="profile-square"
            style={{
                fill: 'none',
            }}
        >
            <path
                stroke="#827778"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m17.229 18.332-.318-.798a2.915 2.915 0 0 0-.73-1.064l-.058-.054a2.721 2.721 0 0 0-1.849-.726h-4.55c-.686 0-1.347.26-1.85.725l-.058.054a2.94 2.94 0 0 0-.73 1.064l-.316.799M14.246 7.93a3.177 3.177 0 1 1-4.494 4.492 3.177 3.177 0 0 1 4.494-4.492"
            />
            <path
                stroke="#827778"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Z"
                clipRule="evenodd"
            />
        </Icon>
    );
};
