import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconHouseGarage: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="house-garage">
			<path d="M12.703 3.398l.147.073 7.485 4.157a2.75 2.75 0 011.409 2.219l.006.186-.001 10.216.251.001a.75.75 0 01.102 1.493L22 21.75H2a.75.75 0 01-.102-1.493L2 20.25h.249l.001-10.217.006-.186a2.75 2.75 0 011.249-2.121l.16-.096 7.485-4.16a1.75 1.75 0 011.553-.072zm-.765 1.36l-.06.024L4.394 8.94a1.25 1.25 0 00-.636.958l-.007.134-.001 10.216H5.25V13a1.75 1.75 0 011.606-1.744L7 11.25h10a1.75 1.75 0 011.744 1.606l.006.144v7.25h1.499l.001-10.217c0-.409-.2-.789-.53-1.021l-.114-.072-7.485-4.158a.25.25 0 00-.183-.024zM17 12.75H7a.25.25 0 00-.243.193L6.75 13v7.25h10.5V13a.25.25 0 00-.193-.243L17 12.75zm-2 4.5a.75.75 0 01.102 1.493L15 18.75H9a.75.75 0 01-.102-1.493L9 17.25h6zm0-3a.75.75 0 01.102 1.493L15 15.75H9a.75.75 0 01-.102-1.493L9 14.25h6zm-1-6a.75.75 0 01.102 1.493L14 9.75h-4a.75.75 0 01-.102-1.493L10 8.25h4z" />
		</Icon>
	);
};
