import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconFloorArea: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="floor-area">
			<path d="M3 6.5a.5.5 0 01.492.41L3.5 7v11.5a2 2 0 001.85 1.995l.15.005H17a.5.5 0 01.09.992L17 21.5H5.5a3 3 0 01-2.995-2.824L2.5 18.5V7a.5.5 0 01.5-.5zm15.5-4a3 3 0 012.995 2.824l.005.176V17a.5.5 0 01-.992.09L20.5 17V5.5a2 2 0 00-1.85-1.995L18.5 3.5H7a.5.5 0 01-.09-.992L7 2.5h11.5z" />
		</Icon>
	);
};
