import { useMediaQuery } from 'usehooks-ts';
import { mediaQueries } from '../config';

export type MediaQueriesProps =
	| 'allDown'
	| 'allUp'
	| 'xxsDown'
	| 'xxsUp'
	| 'smDown'
	| 'smUp'
	| 'mdDown'
	| 'mdUp'
	| 'lgDown'
	| 'lgUp'
	| 'xlDown'
	| 'xlUp'
	| 'xxlDown'
	| 'xxlUp'
	| 'xxxlDown'
	| 'xxxlUp'
	| 'fullDown'
	| 'fullUp';

export const useMQ = (breakpoint: MediaQueriesProps) => {
	const query = (mediaQueries as any)[breakpoint];

	return useMediaQuery(`(${query})`);
};
