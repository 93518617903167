import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconPortraitHealth: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="portrait-health">
            <g strokeLinecap={'round'} stroke={'currentColor'} strokeWidth={'1.5'} fill={'none'} strokeLinejoin={'round'}>
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12.25 21h4a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5h-8a5 5 0 0 0-5 5v4"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="m7.024 15.158.226.23.226-.23a2.187 2.187 0 0 1 3.126 0v0a2.27 2.27 0 0 1 0 3.174L8.487 20.48a1.731 1.731 0 0 1-2.474 0l-2.115-2.148a2.27 2.27 0 0 1 0-3.175v0a2.187 2.187 0 0 1 3.126 0Z"
                    clipRule="evenodd"
                />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.25 16a2.5 2.5 0 0 0-2.5-2.5h-1.5" />
                <circle cx={12.25} cy={8.5} r={2.5} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
            </g>
        </Icon>
    );
};
