import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconFloorPlan: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="floor-plan">
			<path d="M18.317 2.5a3.184 3.184 0 013.178 3.002l.005.18v12.635a3.184 3.184 0 01-3.002 3.178l-.18.005H5.681a3.184 3.184 0 01-3.177-3.002l-.005-.18V5.681a3.184 3.184 0 013.002-3.177l.18-.005h12.635zM3.45 18.317c0 1.179.915 2.146 2.073 2.227l.16.006h8.664V9.375H3.449l.001 8.942zM18.317 3.45l-2.97-.001V20.55h2.97a2.234 2.234 0 002.227-2.073l.006-.16V5.683a2.234 2.234 0 00-2.073-2.227l-.16-.006zm-3.97-.001l-8.665.001a2.234 2.234 0 00-2.226 2.073l-.006.16-.001 2.692h10.898V3.449z" />
		</Icon>
	);
};
