import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowsLeftRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrows-left-right">
			<path d="M15.846 12.797l.084.073 3.6 3.6c.037.037.07.077.097.119l.05.09.04.103c.022.068.033.142.033.218l-.002.057-.013.09-.008.038-.035.106-.034.069-.064.098-.064.072-3.6 3.6a.75.75 0 01-1.133-.976l.073-.084 2.319-2.32H5a.75.75 0 01-.102-1.493L5 16.25h12.189l-2.32-2.32a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073zM9.13 2.87a.75.75 0 01.073.976l-.073.084L6.811 6.25 19 6.25a.75.75 0 01.102 1.493L19 7.75H6.81l2.32 2.32a.75.75 0 01.073.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-3.6-3.6a.753.753 0 01-.097-.119l-.05-.09-.04-.103A.747.747 0 014.25 7l.002-.057.013-.09.008-.038.035-.106.034-.069.055-.086a.785.785 0 01.073-.084l3.6-3.6a.75.75 0 011.06 0z" />
		</Icon>
	);
};
