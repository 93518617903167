import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconInfoCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="info-circle">
			<path d="M12 2.25c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12 6.615 2.25 12 2.25zm0 1.5a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zm.159 6.75a.75.75 0 01.743.648l.007.102v3.5h.401a.75.75 0 01.102 1.493l-.102.007H11a.75.75 0 01-.102-1.493L11 14.75h.409V12h-.4a.75.75 0 01-.743-.648l-.007-.102a.75.75 0 01.648-.743l.102-.007h1.15zm-.307-3.253a1 1 0 01.996.942l.002.058a1 1 0 11-1.12-.993l.122-.007z" />
		</Icon>
	);
};
