import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconMessageMove: FC<IconProps> = ({...props}) => {
    return (
        <Icon {...props} id="check-plus">
            <g fill="none">
                <path d="M0 0h24v24H0V0Z" />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 4v-4H6a3 3 0 0 1-3-3M3 10h7"
                />
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m8 12 2-2-2-2" />
            </g>
        </Icon>
    );
};
