import {FormattedMessage} from 'react-intl';
import {LocaleEnum} from '../types/general';

export enum CountryEnum {
    CR = 'ceska-republika',
    SK = 'slovenska-republika',
}

export const translations = {
    [CountryEnum.CR]: <FormattedMessage defaultMessage="Česká republika" />,
    [CountryEnum.SK]: <FormattedMessage defaultMessage="Slovenská republika" />,
};

export const defaultCountryByLocale = {
    [LocaleEnum.cs]: CountryEnum.CR,
    [LocaleEnum.en]: CountryEnum.CR,
    [LocaleEnum.sk]: CountryEnum.SK,
};

export const flags: Record<string, string> = {
    ad: '🇦🇩',
    ae: '🇦🇪',
    af: '🇦🇫',
    ag: '🇦🇬',
    al: '🇦🇱',
    am: '🇦🇲',
    ao: '🇦🇴',
    ar: '🇦🇷',
    at: '🇦🇹',
    au: '🇦🇺',
    az: '🇦🇿',
    ba: '🇧🇦',
    bb: '🇧🇧',
    bd: '🇧🇩',
    be: '🇧🇪',
    bf: '🇧🇫',
    bj: '🇧🇯',
    bg: '🇧🇬',
    bh: '🇧🇭',
    bi: '🇧🇮',
    bn: '🇧🇳',
    bo: '🇧🇴',
    br: '🇧🇷',
    bs: '🇧🇸',
    bt: '🇧🇹',
    bw: '🇧🇼',
    by: '🇧🇾',
    bz: '🇧🇿',
    ca: '🇨🇦',
    cd: '🇨🇩',
    ch: '🇨🇭',
    cf: '🇨🇫',
    cg: '🇨🇬',
    ci: '🇨🇮',
    cl: '🇨🇱',
    cm: '🇨🇲',
    cn: '🇨🇳',
    co: '🇨🇴',
    cr: '🇨🇷',
    cu: '🇨🇺',
    cv: '🇨🇻',
    cy: '🇨🇾',
    cz: '🇨🇿',
    de: '🇩🇪',
    dj: '🇩🇯',
    dk: '🇩🇰',
    dm: '🇩🇲',
    do: '🇩🇴',
    dz: '🇩🇿',
    ec: '🇪🇨',
    ee: '🇪🇪',
    eg: '🇪🇬',
    eh: '🇪🇭',
    er: '🇪🇷',
    es: '🇪🇸',
    et: '🇪🇹',
    fi: '🇫🇮',
    fj: '🇫🇯',
    fm: '🇫🇲',
    fr: '🇫🇷',
    ga: '🇬🇦',
    gb: '🇬🇧',
    gd: '🇬🇩',
    ge: '🇬🇪',
    gh: '🇬🇭',
    gm: '🇬🇲',
    gn: '🇬🇳',
    gq: '🇬🇶',
    gr: '🇬🇷',
    gt: '🇬🇹',
    gw: '🇬🇼',
    gy: '🇬🇾',
    hn: '🇭🇳',
    hr: '🇭🇷',
    ht: '🇭🇹',
    hu: '🇭🇺',
    id: '🇮🇩',
    ie: '🇮🇪',
    il: '🇮🇱',
    in: '🇮🇳',
    iq: '🇮🇶',
    ir: '🇮🇷',
    is: '🇮🇸',
    it: '🇮🇹',
    jm: '🇯🇲',
    jo: '🇯🇴',
    jp: '🇯🇵',
    ke: '🇰🇪',
    kg: '🇰🇬',
    ki: '🇰🇮',
    kh: '🇰🇭',
    km: '🇰🇲',
    kn: '🇰🇳',
    kp: '🇰🇵',
    kr: '🇰🇷',
    kw: '🇰🇼',
    kz: '🇰🇿',
    la: '🇱🇦',
    lb: '🇱🇧',
    lc: '🇱🇨',
    li: '🇱🇮',
    lk: '🇱🇰',
    lr: '🇱🇷',
    ls: '🇱🇸',
    lt: '🇱🇹',
    lu: '🇱🇺',
    lv: '🇱🇻',
    ly: '🇱🇾',
    ma: '🇲🇦',
    mc: '🇲🇨',
    md: '🇲🇩',
    me: '🇲🇪',
    mg: '🇲🇬',
    mh: '🇲🇭',
    mk: '🇲🇰',
    ml: '🇲🇱',
    mm: '🇲🇲',
    mn: '🇲🇳',
    mq: '🇲🇶',
    mr: '🇲🇷',
    mt: '🇲🇹',
    mu: '🇲🇺',
    mv: '🇲🇻',
    mw: '🇲🇼',
    mx: '🇲🇽',
    my: '🇲🇾',
    mz: '🇲🇿',
    na: '🇳🇦',
    nc: '🇳🇨',
    ne: '🇳🇪',
    ng: '🇳🇬',
    ni: '🇳🇮',
    nl: '🇳🇱',
    no: '🇳🇴',
    np: '🇳🇵',
    nr: '🇳🇷',
    nz: '🇳🇿',
    om: '🇴🇲',
    pa: '🇵🇦',
    pe: '🇵🇪',
    pf: '🇵🇫',
    pg: '🇵🇬',
    ph: '🇵🇭',
    pk: '🇵🇰',
    pl: '🇵🇱',
    pr: '🇵🇷',
    pt: '🇵🇹',
    ps: '🇵🇸',
    pw: '🇵🇼',
    py: '🇵🇾',
    qa: '🇶🇦',
    ro: '🇷🇴',
    rs: '🇷🇸',
    ru: '🇷🇺',
    rw: '🇷🇼',
    sa: '🇸🇦',
    sb: '🇸🇧',
    sc: '🇸🇨',
    sd: '🇸🇩',
    se: '🇸🇪',
    sg: '🇸🇬',
    si: '🇸🇮',
    sk: '🇸🇰',
    sl: '🇸🇱',
    sm: '🇸🇲',
    sn: '🇸🇳',
    so: '🇸🇴',
    sr: '🇸🇷',
    ss: '🇸🇸',
    st: '🇸🇹',
    sv: '🇸🇻',
    sy: '🇸🇾',
    sz: '🇸🇿',
    td: '🇹🇩',
    th: '🇹🇭',
    tg: '🇹🇬',
    tj: '🇹🇯',
    tm: '🇹🇲',
    tn: '🇹🇳',
    tr: '🇹🇷',
    tt: '🇹🇹',
    tv: '🇹🇻',
    tw: '🇹🇼',
    tz: '🇹🇿',
    ua: '🇺🇦',
    ug: '🇺🇬',
    us: '🇺🇸',
    uy: '🇺🇾',
    uz: '🇺🇿',
    va: '🇻🇦',
    vc: '🇻🇨',
    ve: '🇻🇪',
    vn: '🇻🇳',
    vu: '🇻🇺',
    ws: '🇼🇸',
    xk: '🇽🇰',
    ye: '🇾🇪',
    za: '🇿🇦',
    zm: '🇿🇲',
    zw: '🇿🇼',
};
