import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconSettings: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="settings">
			<path d="M10.854 16.202c.443.442.73.98.865 1.548H21a.75.75 0 01.102 1.493L21 19.25h-9.281a3.25 3.25 0 01-6.325 0H3a.75.75 0 01-.102-1.493L3 17.75h2.393a3.25 3.25 0 015.46-1.548zm-3.535 1.06c-.331.331-.502.762-.512 1.195l.003.043-.003.044a1.75 1.75 0 103.1-1.157l-.114-.124-.124-.114a1.75 1.75 0 00-2.35.114zm10.423-7.56c.443.442.73.98.865 1.548H21a.75.75 0 01.102 1.493L21 12.75h-2.393a3.25 3.25 0 01-6.325 0H3a.75.75 0 01-.102-1.493L3 11.25h9.281a3.25 3.25 0 015.46-1.548zm-3.535 1.06a1.75 1.75 0 102.987 1.273L17.19 12l.004-.033a1.744 1.744 0 00-.399-1.08l-.114-.124-.124-.114a1.75 1.75 0 00-2.35.114zm-3.353-7.56c.423.423.705.934.846 1.474l.019.074H21a.75.75 0 01.102 1.493L21 6.25h-9.281a3.235 3.235 0 01-.865 1.548 3.237 3.237 0 01-2.021.94l-.185.01h-.184a3.239 3.239 0 01-2.206-.95 3.235 3.235 0 01-.865-1.547L3 6.25a.75.75 0 01-.102-1.493L3 4.75h2.393l.019-.074a3.25 3.25 0 015.442-1.474zm-2.219.55h-.158c-.421.019-.837.189-1.158.51a1.743 1.743 0 00-.51 1.123.61.61 0 01.011.115l-.007.102-.003.012a1.75 1.75 0 103.097-1.227l-.114-.124-.124-.114a1.741 1.741 0 00-.876-.383l-.158-.014z" />
		</Icon>
	);
};
