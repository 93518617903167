import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import FormCheckDefaultProps from 'react-bootstrap/FormCheck';
import styles from './Tag.module.scss';
import { FormTagInput, FormTagInputProps } from './TagInput';
import { FormTagLabel, FormTagLabelProps } from './TagLabel';

export type FormTagProps = typeof FormCheckDefaultProps & {
	Input: FormTagInputProps;
	Label: FormTagLabelProps;
};

export const FormTag: FC<React.PropsWithChildren<FormTagProps>> = ({ ...props }) => {
	return <Form.Check {...props} className={styles.tag} bsPrefix="form-tag" />;
};

export default Object.assign(FormTag as FormTagProps, {
	Input: FormTagInput,
	Label: FormTagLabel,
});
