import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconShareArrow: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="share-arrow">
			<path d="M9 8.25a.75.75 0 01.102 1.493L9 9.75H7a1.25 1.25 0 00-1.244 1.122L5.75 11v8c0 .647.492 1.18 1.122 1.244L7 20.25h10a1.25 1.25 0 001.244-1.122L18.25 19v-8a1.25 1.25 0 00-1.122-1.244L17 9.75h-2a.75.75 0 01-.102-1.493L15 8.25h2a2.75 2.75 0 012.745 2.582l.005.168v8a2.75 2.75 0 01-2.582 2.745L17 21.75H7a2.75 2.75 0 01-2.745-2.582L4.25 19v-8a2.75 2.75 0 012.582-2.745L7 8.25h2zm3.102-5.993l.01.001a.752.752 0 01.346.148l.072.064 3 3a.75.75 0 01-.976 1.133l-.084-.073-1.72-1.72V15a.75.75 0 01-1.493.102L11.25 15V4.81L9.53 6.53a.75.75 0 01-.976.073L8.47 6.53a.75.75 0 01-.073-.976l.073-.084 3-3 .072-.064a.752.752 0 01.012-.01l-.084.074a.753.753 0 01.53-.22h.006l.096.007z" />
		</Icon>
	);
};
