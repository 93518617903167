import classNames from 'classnames';
import React, { FC } from 'react';
import { Badge as BadgeComponent, BadgeProps as BadgeComponentProps } from 'react-bootstrap';
import styles from './Badge.module.scss';

export type BadgeProps = BadgeComponentProps & {
	size?: 'lg';
};

export const Badge: FC<React.PropsWithChildren<BadgeProps>> = ({ size, className, children, ...props }) => {
	return (
		<BadgeComponent
			{...props}
			className={classNames(styles.badge, className, {
				[styles[`badge--${size}`]]: size,
			})}
		>
			{children}
		</BadgeComponent>
	);
};
