import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconGarage: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="garage">
            <g strokeLinecap="round" strokeWidth="1.5" stroke="#323232" fill="none" strokeLinejoin="round">
                <path d="M9 15h6"/>
                <path d="M9 18h6"/>
                <path
                    d="M3 21v-10.967l-5.60186e-08.000473063c1.09666e-07-.726282.393728-1.39548 1.02859-1.74824l7.486-4.159 -3.47145e-08 1.92784e-08c.301948-.167684.669052-.167684.971-3.85568e-08l7.485 4.158 1.42179e-07 7.89142e-08c.635343.352636 1.02944 1.02213 1.02941 1.74877v10.967"/>
                <line x1="22" x2="2" y1="21" y2="21"/>
                <path
                    d="M6 21v-8l1.06581e-14 1.50996e-07c-8.33927e-08-.552285.447715-1 1-1h10l-4.37114e-08 1.77636e-15c.552285-2.41411e-08 1 .447715 1 1v8"/>
                <path d="M10 9h4"/>
            </g>
        </Icon>
    );
};
