import {Row} from '@components/Row';
import {Col} from '@components/Col';
import {ToggleButton} from '@components/ToggleButton';
import {FormattedMessage} from 'react-intl';

export enum LoginRegisterToggleEnum {
    Login = 'login',
    Register = 'register',
}

type LoginRegisterToggleType = {
    id: string;
    type: LoginRegisterToggleEnum;
    onRegister?: () => void;
    onLogin?: () => void;
};

export const LoginRegisterToggle = ({id, type, onLogin, onRegister}: LoginRegisterToggleType) => {
    const onChangeLogin = onLogin ? onLogin : () => null;
    const onChangeRegister = onRegister ? onRegister : () => null;

    return (
        <Row className="g-3 my-6">
            <Col sm={6} xs={12}>
                <ToggleButton
                    id={`toggle-button-login-${id}`}
                    name={`toggle-button-${id}`}
                    onChange={onChangeLogin}
                    checked={type === LoginRegisterToggleEnum.Login}
                    size="lg"
                    type="radio"
                    value={LoginRegisterToggleEnum.Login}
                >
                    <FormattedMessage defaultMessage="Přihlášení" />
                </ToggleButton>
            </Col>
            <Col sm={6} xs={12}>
                <ToggleButton
                    id={`toggle-button-register-${id}`}
                    name={`toggle-button-${id}`}
                    onChange={onChangeRegister}
                    checked={type === LoginRegisterToggleEnum.Register}
                    size="lg"
                    type="radio"
                    value={LoginRegisterToggleEnum.Register}
                >
                    <FormattedMessage defaultMessage="Registrace" />
                </ToggleButton>
            </Col>
        </Row>
    );
};
