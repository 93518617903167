import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconArrowUpDownCircle: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="arrow-up-down-circle">
			<path d="M11.992 2.239c5.388.01 9.748 4.371 9.748 9.76 0 5.293-4.219 9.602-9.477 9.746l-.273.004-.12-.008-.056-.009-.065-.017h-.066a9.737 9.737 0 01-9.439-9.196l-.01-.275-.004-.277.004-.263c.15-5.08 4.166-9.181 9.208-9.45l.276-.012.274-.003zm-.004 1.5c-4.474-.009-8.112 3.543-8.254 7.989l-.004.253.003.241c.12 4.263 3.488 7.729 7.727 7.982l.256.011.265.004.06.003.058.008.064.016h.08a8.251 8.251 0 007.982-7.74l.011-.255.004-.252a8.26 8.26 0 00-8.252-8.26zm2.258 9.458l.084.073a.75.75 0 01.073.976l-.073.084-1.8 1.8a.75.75 0 01-.976.073l-.084-.073-1.8-1.8a.75.75 0 01.976-1.133l.084.073L12 14.539l1.27-1.27a.75.75 0 01.976-.072zm-1.8-5.4l.084.073 1.8 1.8a.75.75 0 01-.976 1.133l-.084-.073L12 9.461l-1.27 1.27a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084 1.8-1.8a.75.75 0 01.976-.073z" />
		</Icon>
	);
};
