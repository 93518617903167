import {ApolloClient, ApolloLink, FetchResult, from, InMemoryCache, NormalizedCacheObject, Observable, TypePolicies} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {SentryLink} from 'apollo-link-sentry';
import {ErrorResponse} from '@apollo/client/link/error';
import {EntityStore} from '@apollo/client/cache';
import {asNumber} from '@utils/query';
import {BlogArticleList} from '.cache/__types__';

const httpLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
});

const sentryLink = new SentryLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
    setTransaction: false,
    attachBreadcrumbs: {
        includeError: true,
        includeQuery: true,
        includeVariables: true,
    },
});

const getErrorDataLink = (errorHandler: Function, successHandler: Function) => {
    return new ApolloLink((operation, forward) => {
        return new Observable((observer) => {
            let sub: any;
            let retriedSub: any;
            let retriedResult: any;

            try {
                sub = forward(operation).subscribe({
                    next: (result) => {
                        if (result.errors) {
                            retriedResult = errorHandler({
                                graphQLErrors: result.errors,
                                response: result,
                                operation,
                                forward,
                            });

                            if (retriedResult) {
                                retriedSub = retriedResult.subscribe({
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                });
                                return;
                            }
                        } else {
                            result.data = successHandler(result.data);
                        }
                        observer.next(result);
                    },
                    error: (networkError) => {
                        retriedResult = errorHandler({
                            operation,
                            networkError,
                            //Network errors can return GraphQL errors on for example a 403
                            graphQLErrors: networkError?.result?.errors,
                            forward,
                        });
                        if (retriedResult) {
                            retriedSub = retriedResult.subscribe({
                                next: observer.next.bind(observer),
                                error: observer.error.bind(observer),
                                complete: observer.complete.bind(observer),
                            });
                            return;
                        }
                        observer.error(networkError);
                    },
                    complete: () => {
                        // disable the previous sub from calling complete on observable
                        // if retry is in flight.
                        if (!retriedResult) {
                            observer.complete.bind(observer)();
                        }
                    },
                });
            } catch (e) {
                errorHandler({networkError: e, operation, forward});
                observer.error(e);
            }

            return () => {
                if (sub) sub.unsubscribe();
                if (retriedSub) sub.unsubscribe();
            };
        });
    });
};

/*const getResponseApolloLink = (processor: (value: FetchResult) => any) =>
    new ApolloLink((operation, forward) => {
        return forward(operation).map(processor);
    });*/

const getAuthLink = (sessionRef: React.RefObject<any> | undefined) => {
    return setContext((_, {headers}) => {
        if (sessionRef?.current?.accessToken) {
            return {
                headers: {
                    ...headers,
                    authorization: 'Bearer ' + sessionRef.current.accessToken,
                },
            };
        }
        return {headers};
    });
};

function createApolloClient(
    sessionRef: React.RefObject<any> | undefined,
    errorProcessor: (error: ErrorResponse) => any = () => {},
    responseProcessor: (value: FetchResult) => any = (value) => {
        return value;
    },
) {
    return new ApolloClient({
        connectToDevTools: process.env.NEXT_PUBLIC_ENV === 'development',
        ssrMode: typeof window === undefined,

        link: from([sentryLink, getErrorDataLink(errorProcessor, responseProcessor), getAuthLink(sessionRef), httpLink]),
        cache: new InMemoryCache({
            typePolicies: getTypePolicies(),
        }),
    });
}

const mergeApolloCache = (mainClient: ApolloClient<NormalizedCacheObject>, client: ApolloClient<NormalizedCacheObject>) => {
    const newData = client.cache.extract();

    if (newData) {
        const currentStore: EntityStore = (mainClient.cache as any).data;

        // Extract of cache.replace code without the clear old cache part
        // https://github.com/apollographql/apollo-client/blob/83935e8e1ea2c3eb4a0f10fffbbfb4d51cfc02d2/src/cache/inmemory/entityStore.ts#L335
        const {__META, ...rest} = newData;
        Object.keys(rest).forEach((dataId) => {
            currentStore.merge(dataId, rest[dataId]!);
        });
        if (__META) {
            __META.extraRootIds.forEach(currentStore.retain, this);
        }
    }
};

export function initializeApollo(
    //initialCache: any | undefined = undefined,
    sessionRef: React.RefObject<any> | undefined = undefined,
    errorProcessor?: (error: ErrorResponse) => any,
    responseProcessor?: (value: FetchResult) => any,
) {
    return createApolloClient(sessionRef, errorProcessor, responseProcessor);
}

export function loadApolloCache(client: ApolloClient<NormalizedCacheObject>, cache: NormalizedCacheObject) {
    const tmp = new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: getTypePolicies(),
        }).restore(cache),
    });

    mergeApolloCache(client, tmp);
}

function getTypePolicies(): TypePolicies {
    return {
        Query: {
            fields: {
                advert: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Advert',
                            id: args?.id,
                        });
                    },
                },
                region: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'Region',
                            id: args?.id,
                        });
                    },
                },
                calendarEntry: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'CalendarEntry',
                            id: args?.id,
                        });
                    },
                },
                calendarEvent: {
                    read(_, {args, toReference}) {
                        return toReference({
                            __typename: 'CalendarEvent',
                            id: args?.id,
                        });
                    },
                },
                blogArticleList: {
                    keyArgs: ['locale', 'orderBy', 'tagSlugs', 'textSearch'],
                    merge(
                        existing: Partial<BlogArticleList> | null | undefined,
                        incoming: Partial<BlogArticleList> | null | undefined,
                        {args},
                    ) {
                        const offset = args?.offset ? asNumber(args.offset) : 0;
                        const merged = existing?.list?.slice() ?? [];
                        if (incoming?.list) {
                            for (let i = 0; i < incoming.list.length; ++i) {
                                merged[offset + i] = incoming.list[i];
                            }
                        }

                        return {
                            totalCount: incoming?.totalCount ?? existing?.totalCount ?? null,
                            list: merged,
                        };
                    },
                },
            },
        },
        BlogAuthor: {
            fields: {
                articles: {
                    keyArgs: ['locale', 'orderBy'],
                    merge(
                        existing: Partial<BlogArticleList> | null | undefined,
                        incoming: Partial<BlogArticleList> | null | undefined,
                        {args},
                    ) {
                        const offset = args?.offset ? asNumber(args.offset) : 0;
                        const merged = existing?.list?.slice() ?? [];
                        if (incoming?.list) {
                            for (let i = 0; i < incoming.list.length; ++i) {
                                merged[offset + i] = incoming.list[i];
                            }
                        }

                        return {
                            totalCount: incoming?.totalCount ?? existing?.totalCount ?? null,
                            list: merged,
                        };
                    },
                },
            },
        },
        SimpleImage: {
            keyFields: ['url'],
        },
        LocaleSlug: {
            keyFields: ['locale', 'slug'],
        },
        User: {
            fields: {
                tipInfo: {
                    merge(existing: Record<string, unknown>, incoming: Record<string, unknown>) {
                        return {...existing, ...incoming};
                    },
                },
            },
        },
    };
}
