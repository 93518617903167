import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconStar: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="star">
			<path d="M8.564 8.205l-6.173.909-.105.023a.75.75 0 00-.311 1.254l4.469 4.387-1.054 6.2-.011.1a.75.75 0 001.101.689L12 18.84l5.52 2.926.092.041a.75.75 0 00.998-.83l-1.055-6.2 4.47-4.387.071-.08a.75.75 0 00-.487-1.197l-6.175-.909-2.76-5.639a.75.75 0 00-1.348 0L8.564 8.205zM11.999 4.6l2.262 4.619.053.09a.75.75 0 00.512.322l5.071.746-3.672 3.606-.07.078a.75.75 0 00-.144.583l.864 5.083-4.524-2.397-.096-.042a.75.75 0 00-.606.042l-4.525 2.397.865-5.083.01-.104a.75.75 0 00-.224-.557l-3.673-3.606 5.071-.746a.75.75 0 00.565-.412L11.999 4.6z" />
		</Icon>
	);
};
