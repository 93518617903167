import React, {FC} from 'react';
import {FormCheck} from 'react-bootstrap';
import {FormCheckLabelProps} from 'react-bootstrap/FormCheckLabel';
import styles from './Tag.module.scss';
import classNames from 'classnames';

export type FormTagLabelProps = FormCheckLabelProps & {small: boolean};

export const FormTagLabel: FC<React.PropsWithChildren<FormTagLabelProps>> = ({small, ...props}) => {
    return (
        <FormCheck.Label {...props} className={classNames(styles.tagLabel, {[styles.tagLabelSmall]: small})} bsPrefix="form-tag-label" />
    );
};
