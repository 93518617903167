import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconMessageText: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="message-text">
			<path d="M17.333 3.25a3.417 3.417 0 013.412 3.23l.005.187v8a3.417 3.417 0 01-3.23 3.411l-.187.005h-5.071l-2.99 2.393a1.244 1.244 0 01-1.05.243l-.135-.039-.132-.054a1.244 1.244 0 01-.697-.98l-.008-.141v-1.422h-.583A3.417 3.417 0 013.27 15.04l-.015-.185-.005-.187v-8a3.417 3.417 0 013.23-3.412l.187-.005h10.666zm0 1.5H6.667a1.917 1.917 0 00-1.911 1.767l-.006.15v8c0 1.008.778 1.834 1.767 1.91l.15.006H8a.75.75 0 01.743.649l.007.101v1.639l2.781-2.224a.75.75 0 01.269-.137l.099-.02.101-.008h5.333a1.917 1.917 0 001.911-1.767l.006-.15v-8a1.917 1.917 0 00-1.767-1.91l-.15-.006zm-1.333 7a.75.75 0 01.102 1.493L16 13.25H8a.75.75 0 01-.102-1.493L8 11.75h8zm0-4a.75.75 0 01.102 1.493L16 9.25H8a.75.75 0 01-.102-1.493L8 7.75h8z" />
		</Icon>
	);
};
