import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconHomeGenerate: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="home-generate">
            <path d="M16.6752 4.04629V5.48642L18.6678 7.19441V4.04629H16.6752ZM20.7678 8.99445V3.7466C20.7678 2.75232 19.9617 1.94629 18.9675 1.94629H16.3755C15.4011 1.94629 14.6075 2.72042 14.5761 3.68721L13.9855 3.18099C12.843 2.20167 11.1571 2.20167 10.0146 3.18099L3.60419 8.67572C3.60096 8.67846 3.59775 8.68121 3.59456 8.68398L2.31297 9.78251C1.87268 10.1599 1.8217 10.8228 2.1991 11.2631C2.46216 11.57 2.86394 11.6877 3.23234 11.6031V18.9534H2.99631C2.41641 18.9534 1.94631 19.4235 1.94631 20.0034C1.94631 20.5833 2.41641 21.0534 2.99631 21.0534L4.28234 21.0534L12.0001 21.0534C12.58 21.0534 13.0501 20.5833 13.0501 20.0034C13.0501 19.4235 12.58 18.9534 12.0001 18.9534H11.0492V15.0509H13.0005C13.5804 15.0509 14.0505 14.5808 14.0505 14.0009C14.0505 13.421 13.5804 12.9509 13.0005 12.9509H10.9996C9.86723 12.9509 8.94922 13.8689 8.94922 15.0013V18.9534H5.33234V9.9603L11.3813 4.77541C11.7373 4.47019 12.2628 4.47019 12.6189 4.77541L14.9374 6.76274C14.9406 6.76552 14.9438 6.76827 14.947 6.77101L19.0299 10.2707C19.0332 10.2735 19.0364 10.2763 19.0397 10.2791L20.3205 11.3769C20.7608 11.7543 21.4236 11.7033 21.801 11.2631C22.1784 10.8228 22.1274 10.1599 21.6871 9.78251L20.7678 8.99445ZM18.5028 12.9509C19.0827 12.9509 19.5528 13.421 19.5528 14.0009C19.5528 15.3548 20.6503 16.4523 22.0042 16.4523C22.5841 16.4523 23.0542 16.9224 23.0542 17.5023C23.0542 18.0822 22.5841 18.5523 22.0042 18.5523C20.6503 18.5523 19.5528 19.6499 19.5528 21.0038C19.5528 21.5837 19.0827 22.0538 18.5028 22.0538C17.9229 22.0538 17.4528 21.5837 17.4528 21.0038C17.4528 19.6499 16.3552 18.5523 15.0013 18.5523C14.4214 18.5523 13.9513 18.0822 13.9513 17.5023C13.9513 16.9224 14.4214 16.4523 15.0013 16.4523C16.3552 16.4523 17.4528 15.3548 17.4528 14.0009C17.4528 13.421 17.9229 12.9509 18.5028 12.9509ZM18.5028 16.9089C18.3236 17.1244 18.1249 17.3231 17.9093 17.5023C18.1249 17.6815 18.3236 17.8802 18.5028 18.0958C18.682 17.8802 18.8807 17.6815 19.0962 17.5023C18.8807 17.3231 18.682 17.1244 18.5028 16.9089Z" />
        </Icon>
    );
};
