import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconSchool: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="school">
			<path d="M16 1.75c.881 0 1.61.652 1.732 1.5H19a1.75 1.75 0 011.744 1.607L20.75 5v8.419c.546.26.936.793.993 1.422l.007.159v1a1.75 1.75 0 01-1.607 1.744L20 17.75h-4.04l.768 3.068a.75.75 0 01-1.424.461l-.032-.097-.858-3.432H9.585l-.857 3.432a.75.75 0 01-1.474-.264l.018-.1.767-3.068H4a1.75 1.75 0 01-1.744-1.607L2.25 16v-1c0-.698.409-1.3 1-1.582V5c0-.966.784-1.75 1.75-1.75h1.268a1.751 1.751 0 011.578-1.493L8 1.75h8zm4 13H4a.25.25 0 00-.243.193L3.75 15v1a.25.25 0 00.193.243L4 16.25h16a.25.25 0 00.243-.193L20.25 16v-1a.25.25 0 00-.193-.243L20 14.75zM6.268 4.751L5 4.75a.25.25 0 00-.243.193L4.75 5v8.25h14.5V5a.25.25 0 00-.193-.243L19 4.75l-1.268.001a1.751 1.751 0 01-1.578 1.492L16 6.25H8a1.75 1.75 0 01-1.732-1.499zM16 3.25H8a.25.25 0 00-.243.193L7.75 3.5v1a.25.25 0 00.193.243L8 4.75h8a.25.25 0 00.243-.193l.007-.057v-1a.25.25 0 00-.193-.243L16 3.25z" />
		</Icon>
	);
};
