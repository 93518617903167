import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconRotateRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="rotate-right">
			<path d="M20 9.75a.75.75 0 00.743-.648L20.75 9V5a.75.75 0 00-1.493-.102L19.25 5l.002 2.108A8.74 8.74 0 0012 3.25 8.75 8.75 0 003.25 12a.75.75 0 101.5 0A7.25 7.25 0 0112 4.75a7.242 7.242 0 016.201 3.5H16a.75.75 0 00-.743.648L15.25 9c0 .38.282.693.648.743L16 9.75h4zm-.29 4.495a.75.75 0 00-.973.421 7.247 7.247 0 01-13.092.826.75.75 0 00-1.314.724 8.747 8.747 0 0015.8-.998.75.75 0 00-.421-.973z" />
		</Icon>
	);
};
