import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconKey: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="key">
			<path d="M10.25 2.25a3.5 3.5 0 013.475 3.08c.74.088 1.458.397 2.05.925l.154.147 4.513 4.513a4.465 4.465 0 01.159 6.148l-.159.166-.22.221a4.465 4.465 0 01-6.149.159l-.166-.159-2.988-2.986c-.15.129-.309.25-.474.362l-.196.126v4.055a1.748 1.748 0 01-.397 1.103l-.116.127-1.002 1.003a1.747 1.747 0 01-2.345.111l-.125-.113-1.002-1.005a1.748 1.748 0 01-.503-1.063l-.009-.172.002-4.045-.195-.126a5.223 5.223 0 01-2.302-4.087l-.005-.24a5.252 5.252 0 014.53-5.2 3.5 3.5 0 013.47-3.05zm-6.5 8.25a3.73 3.73 0 001.9 3.242l.23.122.08.053.073.064.063.072.054.08.043.086.032.093.02.098.006.054-.001 4.534c0 .044.012.086.033.124l.04.051 1.002 1.005c.08.08.202.094.296.04l.053-.04 1.003-1.003a.245.245 0 00.063-.109l.009-.062.001-4.494.008-.12c.04-.305.232-.5.467-.583l.011-.004.116-.062a3.733 3.733 0 001.892-3.028l.006-.213c0-.999-.39-1.906-1.027-2.578l-.03-.027-.029-.034A3.743 3.743 0 008.25 6.825l.002 3.016a1 1 0 11-1.502-.003V6.825c-1.711.347-3 1.86-3 3.675zm9.523-3.697l-.193.01a2.231 2.231 0 00-1.264.53l-.072.066a5.226 5.226 0 01.167 5.923l3.056 3.058a2.965 2.965 0 004.051.133l.143-.133.22-.221a2.965 2.965 0 00.134-4.051l-.133-.143-4.513-4.513a2.243 2.243 0 00-1.596-.659zM10.25 3.75a2 2 0 00-1.952 1.56 5.227 5.227 0 012.398 1.025l-.07.067a3.734 3.734 0 011.603-.95A2.001 2.001 0 0010.25 3.75z" />
		</Icon>
	);
};
