import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconChat: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="chat">
            <path
                fill="none"
                stroke="#807878"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m12 17.333-3.197 2.558A.494.494 0 0 1 8 19.505v-2.172H6.667A2.667 2.667 0 0 1 4 14.667v-8A2.667 2.667 0 0 1 6.667 4h10.666A2.667 2.667 0 0 1 20 6.667v8a2.667 2.667 0 0 1-2.667 2.666ZM8 8.5h8M8 12.5h8"
            />
        </Icon>
    );
};
