import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconCalendarViewWeek: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="calendar-view-week">
            <path d="M20 4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h16Zm-4.5 2H13v12h2.5V6ZM11 6H8.5v12H11V6ZM6.5 6H4v12h2.5V6ZM20 6h-2.5v12H20V6Z" />
        </Icon>
    );
};
