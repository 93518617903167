import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconParking: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="parking">
			<path d="M17.825 12.25a2.25 2.25 0 012.145 1.57l.046.167.244 1.044.403-.201a.75.75 0 01.762 1.288l-.088.052-.424.213c.467.416.777 1.005.83 1.667l.007.2V20a1.75 1.75 0 01-1.606 1.744L20 21.75h-1a1.75 1.75 0 01-1.697-1.322l-.03-.144-.005-.034h-2.537l-.004.034c-.12.736-.7 1.318-1.434 1.442l-.15.018-.143.006h-1a1.75 1.75 0 01-1.744-1.606L10.25 20v-1.75c0-.742.324-1.41.838-1.867l-.425-.213a.75.75 0 01.58-1.38l.094.04.403.201.244-1.044a2.25 2.25 0 012.018-1.73l.173-.007h3.65zm1.425 5h-6.5a1 1 0 00-.297.045.557.557 0 01-.104.041.995.995 0 00-.592.793l-.007.121V20a.25.25 0 00.193.243l.057.007h1a.25.25 0 00.243-.193L13.25 20v-.5a.75.75 0 01.648-.743L14 18.75h4a.75.75 0 01.743.648l.007.102v.5a.25.25 0 00.193.243l.057.007h1a.25.25 0 00.243-.193L20.25 20v-1.75a1 1 0 00-.607-.92.41.41 0 01-.089-.03.876.876 0 00-.199-.044l-.105-.006zM14.256 3.312l.225.215a7.768 7.768 0 012.269 5.497.75.75 0 01-1.5-.002A6.268 6.268 0 0013.62 4.8l-.22-.231a6.262 6.262 0 00-8.997 8.707l.178.185 3 3.01a.75.75 0 01-.978 1.132l-.084-.073-2.998-3.008A7.762 7.762 0 0114.256 3.312zm3.57 10.438h-3.651a.75.75 0 00-.7.48l-.03.1-.333 1.42h5.775l-.331-1.42a.75.75 0 00-.628-.573l-.103-.007zm-8.251-7.5a2.175 2.175 0 01.155 4.345l-.155.005H8.75v.9a.75.75 0 01-1.493.102L7.25 11.5V7A.75.75 0 018 6.25h1.575zm0 1.5H8.75V9.1h.825a.675.675 0 00.106-1.342l-.106-.008z" />
		</Icon>
	);
};
