import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconOffice: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="office">
			<path d="M9.98 2.23l.06.002.058.007.046.011h3.84a2.74 2.74 0 012.735 2.401l.016.172.005.167-.001 5.26H22a.75.75 0 01.102 1.493L22 11.75h-1.25V21a.75.75 0 01-1.493.102L19.25 21v-9.25H4.75V21a.75.75 0 01-1.493.102L3.25 21v-9.25H2a.75.75 0 01-.102-1.493L2 10.25h5.239l.001-5.253a2.744 2.744 0 012.572-2.762l.168-.005zm-2.02 11l.058.002.058.007.049.011h7.851a1.737 1.737 0 011.74 1.448l.018.148.006.144v.5a2.262 2.262 0 01-2.097 2.245l-.153.005h-2.74v2.51H15a.75.75 0 01.102 1.493L15 21.75H9a.75.75 0 01-.102-1.493L9 20.25h2.25v-2.511H8.461a2.263 2.263 0 01-2.235-2.09l-.006-.159v-.487a1.74 1.74 0 011.74-1.773zm-.071 1.511l-.036.014a.245.245 0 00-.128.173l-.005.062v.5c0 .373.284.688.649.742l.101.008h7.02c.372 0 .692-.29.743-.65l.007-.1v-.5a.24.24 0 00-.192-.234l-.058-.006H8l-.111-.009zM9.853 3.736a1.245 1.245 0 00-1.108 1.125l-.005.129-.001 5.25h6.5l.001-5.25c0-.6-.43-1.104-.999-1.217l-.124-.018-.127-.005H10l-.06-.002-.087-.012z" />
		</Icon>
	);
};
