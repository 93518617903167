import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconHouseSale: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="house-sale">
            <g stroke={'currentColor'} strokeWidth={1.3} strokeLinecap={'round'} strokeLinejoin={'round'} fill={'none'}>
                <path
                    d="M13.8,13.8v0.8h2.8c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.3l-5.9,6c-0.1,0.1-0.1,0.1-0.2,0.1
		c-0.1,0-0.2,0-0.2-0.1l-5.9-6c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.2,0.3-0.2h2.8V4"
                />
                <path
                    d="M19.8,7.7c0,0.5-0.8,0.8-0.9,1.3c-0.1,0.4,0.3,1.2,0,1.6c-0.3,0.4-1.1,0.2-1.5,0.5c-0.4,0.3-0.5,1.1-0.9,1.3
		c-0.4,0.1-1-0.5-1.5-0.5c-0.5,0-1.1,0.6-1.5,0.5c-0.4-0.1-0.5-1-0.9-1.3c-0.4-0.3-1.2-0.1-1.5-0.5c-0.3-0.4,0.1-1.1,0-1.6
		c-0.1-0.4-0.9-0.8-0.9-1.3c0-0.5,0.8-0.8,0.9-1.3c0.1-0.4-0.3-1.2,0-1.6c0.3-0.4,1.1-0.2,1.5-0.5c0.4-0.3,0.5-1.1,0.9-1.3
		C14,3,14.5,3.6,15,3.6c0.5,0,1.1-0.6,1.5-0.5c0.4,0.1,0.5,1,0.9,1.3c0.4,0.3,1.2,0.1,1.5,0.5c0.3,0.4-0.1,1.1,0,1.6
		C19.1,6.9,19.8,7.2,19.8,7.7z"
                />
            </g>
            <g stroke={'currentColor'} strokeWidth={0.75} strokeLinecap={'round'} strokeLinejoin={'round'} fill={'none'}>
                <line x1="16.2" y1="5.7" x2="13.9" y2="9.7" />
                <circle cx="16.8" cy="8.9" r="0.8" />
                <circle cx="13.4" cy="6.5" r="0.8" />
            </g>
        </Icon>
    );
};
