import {MenuSidebar} from '@components/MenuSidebar';
import {Button} from '@components/Button';
import {Icon} from '@components/Icon';
import {Nav} from '@components/Nav';
import {FormattedMessage} from 'react-intl';
import {Sidebar} from '@components/Sidebar';
import {VisuallyHidden} from '@components/VisuallyHidden';
import {Stack} from '@components/Stack';
import {ButtonGroup} from '@components/ButtonGroup';
import {ButtonToolbar} from '@components/ButtonToolbar';
import {Typography} from '@components/Typography';
import {getLocalizedRoute, isRoute, routes} from '@utils/routes';
import Link from 'next/link';
import {useLocale} from '@utils/locale';
import {useRouter} from 'next/router';
import {LanguageSwitcher} from './LanguageSwitcher';
import {LocaleEnum} from 'src/types/general';
import {Row} from '@components/Row';
import {Col} from '@components/Col';
import {Accordion} from '@components/Accordion';
import {RentMenu} from './RentMenu';
import {SellMenu} from './SellMenu';
import {EstateType, OfferType} from '.cache/__types__';
import {getEstatePath} from './utils';

export type SidebarMenuProps = {
    show: boolean;
    onHide: () => void;
};

export const SidebarMenu = ({show, onHide}: SidebarMenuProps) => {
    const locale = useLocale();
    const router = useRouter();

    const salePath = getEstatePath(locale, OfferType.Prodej, EstateType.Byt);
    const rentPath = getEstatePath(locale, OfferType.Pronajem, EstateType.Byt);

    return (
        <Sidebar specialVariant="menu" show={show} onHide={onHide}>
            <MenuSidebar>
                <Stack direction="horizontal" className="mb-4 mb-md-12 d-none d-md-flex justify-content-end">
                    <Button variant="icon" size="sm" onClick={onHide}>
                        <VisuallyHidden>Zavřít</VisuallyHidden>
                        <Icon.Close position="middle" />
                    </Button>
                </Stack>

                <ButtonGroup className="mb-10 mb-md-12 d-md-none" onClick={onHide}>
                    <Link href={getLocalizedRoute(routes.insert, locale)} passHref legacyBehavior>
                        <Button variant="secondary" size="sm">
                            <Typography variant="cta">
                                <FormattedMessage defaultMessage="Vložit inzerát" />
                            </Typography>
                            <Icon.Key position="end" />
                        </Button>
                    </Link>
                </ButtonGroup>

                <Nav as="ul" className="flex-column d-xl-none">
                    <Nav.Item as="li" onClick={onHide}>
                        <Link href={getLocalizedRoute(routes.howToRent, locale)} passHref legacyBehavior>
                            <Nav.Link>
                                <Typography fontWeight="bold" color="blue">
                                    <FormattedMessage defaultMessage="Jak pronajmout" />
                                </Typography>
                            </Nav.Link>
                        </Link>
                    </Nav.Item>

                    <Nav.Item as="li" onClick={onHide}>
                        <Link href={getLocalizedRoute(routes.howToSell, locale)} passHref legacyBehavior>
                            <Nav.Link>
                                <Typography fontWeight="bold" color="blue">
                                    <FormattedMessage defaultMessage="Jak prodat" />
                                </Typography>
                            </Nav.Link>
                        </Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                        <Accordion>
                            <Row className="gx-0">
                                <Col
                                // onClick={onHide}
                                >
                                    {/* <Link href={getLocalizedRoute(routes.list, locale, {list: ''})} passHref legacyBehavior> */}
                                    <Nav.Link
                                    // active={isRoute(router.asPath, routes.list, locale)}
                                    >
                                        <FormattedMessage defaultMessage="Nemovitosti" />
                                    </Nav.Link>
                                    {/* </Link> */}
                                </Col>
                                <Col xs="auto">
                                    <Accordion.Button as={Button} variant="icon" size="sm" className="h-100 me-4 me-md-10">
                                        <VisuallyHidden>
                                            <FormattedMessage defaultMessage="Nemovitosti" />
                                        </VisuallyHidden>
                                        <Icon.ChevronDown size="lg" position="middle" />
                                    </Accordion.Button>
                                </Col>
                            </Row>
                            <Accordion.Body>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item as="li">
                                        <Link href={getLocalizedRoute(routes.list, locale, {list: rentPath})} passHref legacyBehavior>
                                            <Nav.Link onClick={onHide}>
                                                <FormattedMessage defaultMessage="Pronájem" />
                                            </Nav.Link>
                                        </Link>
                                    </Nav.Item>
                                    <Nav.Item as="li">
                                        <Link href={getLocalizedRoute(routes.list, locale, {list: salePath})} passHref legacyBehavior>
                                            <Nav.Link onClick={onHide}>
                                                <FormattedMessage defaultMessage="Prodej" />
                                            </Nav.Link>
                                        </Link>
                                    </Nav.Item>
                                </Nav>
                            </Accordion.Body>
                        </Accordion>
                    </Nav.Item>
                    <Nav.Item as="li" onClick={onHide}>
                        <Link href={getLocalizedRoute(routes.serviceCentrePremium, locale)} passHref legacyBehavior>
                            <Nav.Link active={isRoute(router.asPath, routes.serviceCentrePremium, locale)}>
                                <FormattedMessage defaultMessage="Premium profil" />
                            </Nav.Link>
                        </Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Accordion>
                            <Row className="gx-0">
                                <Col onClick={onHide}>
                                    <Link href={getLocalizedRoute(routes.serviceCentreSale, locale)} passHref legacyBehavior>
                                        <Nav.Link active={isRoute(router.asPath, routes.serviceCentreSale, locale)}>
                                            <FormattedMessage defaultMessage="Služby" />
                                        </Nav.Link>
                                    </Link>
                                </Col>
                                {locale !== LocaleEnum.sk && (
                                    <Col xs="auto">
                                        <Accordion.Button as={Button} variant="icon" size="sm" className="h-100 me-4 me-md-10">
                                            <VisuallyHidden>
                                                <FormattedMessage defaultMessage="Nabídka Služby" />
                                            </VisuallyHidden>
                                            <Icon.ChevronDown size="lg" position="middle" />
                                        </Accordion.Button>
                                    </Col>
                                )}
                            </Row>

                            {locale !== LocaleEnum.sk && (
                                <Accordion.Body>
                                    <Nav as="ul" className="flex-column">
                                        <Nav.Item as="li">
                                            <Accordion>
                                                <Row className="gx-0">
                                                    <Col onClick={onHide}>
                                                        <Link
                                                            href={getLocalizedRoute(routes.serviceCentreRent, locale)}
                                                            passHref
                                                            legacyBehavior
                                                        >
                                                            <Nav.Link active={isRoute(router.asPath, routes.serviceCentreRent, locale)}>
                                                                <FormattedMessage defaultMessage="Pronájem" />
                                                            </Nav.Link>
                                                        </Link>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Accordion.Button as={Button} variant="icon" size="sm" className="h-100">
                                                            <VisuallyHidden>
                                                                <FormattedMessage defaultMessage="Nabídka Pronájem" />
                                                            </VisuallyHidden>
                                                            <Icon.ChevronDown size="lg" position="middle" />
                                                        </Accordion.Button>
                                                    </Col>
                                                </Row>

                                                <Accordion.Body>
                                                    <RentMenu onHide={onHide} />
                                                </Accordion.Body>
                                            </Accordion>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Accordion>
                                                <Row className="gx-0">
                                                    <Col onClick={onHide}>
                                                        <Link
                                                            href={getLocalizedRoute(routes.serviceCentreSale, locale)}
                                                            passHref
                                                            legacyBehavior
                                                        >
                                                            <Nav.Link active={isRoute(router.asPath, routes.serviceCentreSale, locale)}>
                                                                <FormattedMessage defaultMessage="Prodej a nákup" />
                                                            </Nav.Link>
                                                        </Link>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Accordion.Button as={Button} variant="icon" size="sm" className="h-100">
                                                            <VisuallyHidden>
                                                                <FormattedMessage defaultMessage="Nabídka Prodej" />
                                                            </VisuallyHidden>
                                                            <Icon.ChevronDown size="lg" position="middle" />
                                                        </Accordion.Button>
                                                    </Col>
                                                </Row>

                                                <Accordion.Body>
                                                    <SellMenu onHide={onHide} />
                                                </Accordion.Body>
                                            </Accordion>
                                        </Nav.Item>
                                    </Nav>
                                </Accordion.Body>
                            )}
                        </Accordion>
                    </Nav.Item>
                    <Nav.Item as="li" onClick={onHide}>
                        <Link href={getLocalizedRoute(routes.priceListRent, locale)} passHref legacyBehavior>
                            <Nav.Link active={isRoute(router.asPath, routes.priceListRent, locale)}>
                                <FormattedMessage defaultMessage="Ceník inzerce" />
                            </Nav.Link>
                        </Link>
                    </Nav.Item>
                    <Nav.Item as="li" onClick={onHide}>
                        {locale == LocaleEnum.cs ? (
                            <Link href={getLocalizedRoute(routes.blog, locale)} passHref legacyBehavior>
                                <Nav.Link>
                                    <FormattedMessage defaultMessage="Blog" />
                                </Nav.Link>
                            </Link>
                        ) : (
                            locale != LocaleEnum.en && (
                                <Nav.Link href={getLocalizedRoute(routes.blog, locale)}>
                                    <FormattedMessage defaultMessage="Blog" />
                                </Nav.Link>
                            )
                        )}
                    </Nav.Item>
                </Nav>

                <MenuSidebar.Footer>
                    <ButtonToolbar className="mt-10 mt-md-12">
                        <ButtonGroup>
                            <LanguageSwitcher variant="button" />
                        </ButtonGroup>
                    </ButtonToolbar>
                </MenuSidebar.Footer>
            </MenuSidebar>
        </Sidebar>
    );
};
