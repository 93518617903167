import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconInsurance: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="insurance">
            <g strokeLinecap={'round'} strokeWidth={'1.5'} fill={'none'} stroke={'currentColor'} strokeLinejoin={'round'}>
                <rect width={17} height={19} x={3.5} y={2} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} rx={3} />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8.25 17h7.5" />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M11.53 13.953v0A4.39 4.39 0 0 1 8 9.648V8.222C8 6.995 8.995 6 10.222 6h3.556C15.005 6 16 6.995 16 8.222v1.426a4.39 4.39 0 0 1-3.53 4.305h0a2.4 2.4 0 0 1-.94 0Z"
                    clipRule="evenodd"
                />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m10.75 9.65 1 1 1.5-1.5" />
            </g>
        </Icon>
    );
};
