import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconWarning: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="warning">
			<path d="M12 2.25c5.186 0 9.442 4.053 9.734 9.197l.012.277.004.276c0 5.186-4.053 9.442-9.197 9.734l-.277.012-.276.004c-5.186 0-9.442-4.053-9.734-9.197l-.012-.277L2.25 12c0-5.186 4.053-9.442 9.197-9.734l.277-.012L12 2.25zm.004 1.499l-.248.005c-4.369.126-7.872 3.643-8.002 7.984l-.004.251.004.255c.126 4.369 3.643 7.872 7.984 8.002l.251.004.255-.004c4.369-.126 7.872-3.643 8.002-7.984l.004-.251-.004-.255a8.25 8.25 0 00-7.99-8.003l-.252-.004zm-.005 11.501l.117.007a1 1 0 11-.226-.001l.109-.006zM12 6.75a.75.75 0 01.743.648l.007.102v5a.75.75 0 01-1.493.102l-.007-.102v-5a.75.75 0 01.75-.75z" />
		</Icon>
	);
};
