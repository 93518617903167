import classNames from 'classnames';
import React, {FC, ReactNode, useRef, useState} from 'react';
import {Overlay, OverlayTriggerProps, Tooltip} from 'react-bootstrap';
import {Icon, IconProps} from '../Icon';
import styles from './InfoTooltip.module.scss';

type InfoTooltipProps = {
    content: ReactNode;
    size?: 'xmd' | 'md';
    iconName?: IconProps['name'] | false;
    iconSize?: IconProps['size'];
    iconColor?: IconProps['color'];
    tabIndex?: number;
    className?: string;
    trigger?: OverlayTriggerProps['trigger'];
    fullWidth?: boolean;
    grid?: boolean;
};

export const InfoTooltip: FC<React.PropsWithChildren<InfoTooltipProps>> = ({
    content,
    size,
    iconName = 'InfoCircle',
    iconSize = undefined,
    iconColor = 'grey-dark',
    tabIndex = 0,
    className,
    children,
    trigger = ['hover', 'focus'],
    fullWidth = false,
    grid = false,
}) => {
    const target = useRef(null);
    const [hovered, setHovered] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [focused, setFocused] = useState(false);

    const canHover = trigger === 'hover' || (Array.isArray(trigger) && trigger.includes('hover'));
    const canFocus = trigger === 'focus' || (Array.isArray(trigger) && trigger.includes('focus'));
    const canClick = trigger === 'click' || (Array.isArray(trigger) && trigger.includes('click'));

    return (
        <span
            className={classNames(styles.infoTooltip, 'infoTooltip', className, {
                'w-100': fullWidth,
                [styles[`infoTooltip--grid`]]: grid,
            })}
            tabIndex={tabIndex}
        >
            {iconName ? (
                <>
                    {children && fullWidth ? <span className="w-100">{children}</span> : children}

                    <Overlay
                        placement="top"
                        flip
                        rootClose
                        onHide={() => {
                            setClicked(false);
                            setFocused(false);
                            setHovered(false);
                        }}
                        target={target.current}
                        show={hovered || clicked || focused}
                    >
                        {(props) => (
                            <Tooltip
                                {...props}
                                placement={props.popper.state?.placement || 'top'}
                                className={classNames({
                                    [`tooltip-${size}`]: size,
                                })}
                            >
                                {content}
                            </Tooltip>
                        )}
                    </Overlay>

                    <span
                        ref={target}
                        className={styles.infoTooltipIcon}
                        onMouseOver={
                            canHover
                                ? () => {
                                      setHovered(true);
                                  }
                                : undefined
                        }
                        onMouseOut={
                            canHover
                                ? () => {
                                      setHovered(false);
                                  }
                                : undefined
                        }
                        onFocus={
                            canFocus
                                ? () => {
                                      setFocused(true);
                                  }
                                : undefined
                        }
                        onBlur={
                            canFocus
                                ? () => {
                                      setFocused(false);
                                  }
                                : undefined
                        }
                        onClick={
                            canClick
                                ? (e) => {
                                      e.preventDefault();
                                      setClicked(!clicked);
                                  }
                                : undefined
                        }
                    >
                        <Icon name={iconName} size={iconSize} color={iconColor} />
                    </span>
                </>
            ) : (
                <>
                    {children && (
                        <span
                            className={fullWidth ? 'w-100' : ''}
                            ref={target}
                            onMouseOver={
                                canHover
                                    ? () => {
                                          setHovered(true);
                                      }
                                    : undefined
                            }
                            onMouseOut={
                                canHover
                                    ? () => {
                                          setHovered(false);
                                      }
                                    : undefined
                            }
                            onFocus={
                                canFocus
                                    ? () => {
                                          setFocused(true);
                                      }
                                    : undefined
                            }
                            onBlur={
                                canFocus
                                    ? () => {
                                          setFocused(false);
                                      }
                                    : undefined
                            }
                            onClick={
                                canClick
                                    ? (e) => {
                                          e.preventDefault();
                                          setClicked(!clicked);
                                      }
                                    : undefined
                            }
                        >
                            {children}
                        </span>
                    )}

                    <Overlay
                        placement="top"
                        flip
                        rootClose
                        onHide={() => {
                            setClicked(false);
                            setFocused(false);
                            setHovered(false);
                        }}
                        target={target.current}
                        show={hovered || clicked || focused}
                    >
                        {(props) => (
                            <Tooltip
                                {...props}
                                placement={props.popper.state?.placement || 'top'}
                                className={classNames({
                                    [`tooltip-${size}`]: size,
                                })}
                            >
                                {content}
                            </Tooltip>
                        )}
                    </Overlay>
                </>
            )}
        </span>
    );
};
