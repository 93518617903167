import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconBuilding: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="building">
			<path d="M1 21.75a.75.75 0 01-.102-1.493L1 20.25h.25V10a1.75 1.75 0 011.607-1.744L3 8.25h3.25V4a1.75 1.75 0 011.607-1.744L8 2.25h8a1.75 1.75 0 011.744 1.607L17.75 4v4.25H21a1.75 1.75 0 011.744 1.607l.006.143v10.25H23a.75.75 0 01.102 1.493L23 21.75H1zm5.25-12H3a.25.25 0 00-.243.193L2.75 10v10.25h3.5v-2.5H5a.75.75 0 01-.102-1.493L5 16.25h1.25v-2H5a.75.75 0 01-.102-1.493L5 12.75h1.25v-3zm9.75-6H8a.25.25 0 00-.243.193L7.75 4v16.25h2v-2.5H9.5a.75.75 0 01-.102-1.493l.102-.007h5a.75.75 0 01.102 1.493l-.102.007h-.25v2.5h2V4a.25.25 0 00-.193-.243L16 3.75zm-3.25 14h-1.5v2.5h1.5v-2.5zm8.25-8h-3.25v3H19a.75.75 0 01.102 1.493L19 14.25h-1.25v2H19a.75.75 0 01.102 1.493L19 17.75h-1.25v2.5h3.5V10a.25.25 0 00-.193-.243L21 9.75zm-7 3a.75.75 0 01.102 1.493L14 14.25h-4a.75.75 0 01-.102-1.493L10 12.75h4zm0-3a.75.75 0 01.102 1.493L14 11.25h-4a.75.75 0 01-.102-1.493L10 9.75h4zm0-3a.75.75 0 01.102 1.493L14 8.25h-4a.75.75 0 01-.102-1.493L10 6.75h4z" />
		</Icon>
	);
};
