import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconWalletAdd: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="wallet-add">
			<path d="M6.5 11.25a5.25 5.25 0 110 10.5 5.25 5.25 0 010-10.5zm12-8a3.25 3.25 0 013.245 3.066l.005.184v2.419c.546.26.936.792.993 1.422l.007.159v3c0 .698-.408 1.3-1 1.581V17.5a3.25 3.25 0 01-3.066 3.245l-.184.005H13a.75.75 0 01-.102-1.493L13 19.25h5.5a1.75 1.75 0 001.744-1.606l.006-.144v-2.25h-.75a3.253 3.253 0 01-3.227-2.878l-.017-.183L16.25 12a3.25 3.25 0 013.066-3.245l.184-.005h.75V6.5a1.75 1.75 0 00-1.606-1.744L18.5 4.75h-13a1.75 1.75 0 00-1.744 1.606L3.75 6.5V10a.75.75 0 01-1.493.102L2.25 10V6.5a3.25 3.25 0 013.066-3.245L5.5 3.25h13zm-12 9.5a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 1.5a.75.75 0 01.743.648L7.25 15v.75H8a.75.75 0 01.102 1.493L8 17.25h-.75V18a.75.75 0 01-1.493.102L5.75 18v-.75H5a.75.75 0 01-.102-1.493L5 15.75h.75V15a.75.75 0 01.75-.75zm14.5-4h-1.5c-.916 0-1.667.703-1.744 1.578l-.006.148.004.14a1.75 1.75 0 001.594 1.627l.152.007H21a.25.25 0 00.243-.193l.007-.057v-3a.25.25 0 00-.193-.243L21 10.25z" />
		</Icon>
	);
};
