import React from 'react';

export type ReducerState = {
    flow: 'login' | 'registration' | 'reset_password';
    page: 'initial' | 'start' | 'check_email' | 'email_failed';
};

export type ReducerAction =
    | {type: 'reset'}
    | {type: 'change_flow'; flow: ReducerState['flow']}
    | {type: 'change_page'; page: ReducerState['page']};

export const reducer: React.Reducer<ReducerState, ReducerAction> = (state, action) => {
    switch (action.type) {
        case 'change_flow':
            return {...state, flow: action.flow, page: 'start'};
        case 'change_page':
            return {...state, page: action.page};
        case 'reset':
            return {...state, page: 'initial'};
        default:
            throw new Error();
    }
};
