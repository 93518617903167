import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconSubway: FC<IconProps> = ({ ...props }) => {
	return (
		<Icon {...props} id="subway">
            <path
                d="M 0.04184017,-2.0864264 H 24.04184 V 21.913574 H 0.04184017 Z"
                id="path2"
                fill={"none"}
            />
            <g
                id="g197"
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                strokeWidth={1}
                fill={"none"}
                transform="matrix(0.99906712,0,0,1.0000509,0.00623833,-0.16420542)">
                <line
                    x1="14.24184"
                    x2="13.471841"
                    y1="13.913573"
                    y2="13.913573"
                    stroke="#323232"
                    id="line4" />
                <line
                    x1="9.8418407"
                    x2="10.61184"
                    y1="13.913573"
                    y2="13.913573"
                    stroke="#323232"
                    id="line6" />
                <line
                    x1="17.05184"
                    x2="7.0418401"
                    y1="11.633574"
                    y2="11.633574"
                    stroke="#323232"
                    id="line8" />
                <line
                    x1="8.0418406"
                    x2="9.1118402"
                    y1="18.913574"
                    y2="16.773575"
                    stroke="#323232"
                    id="line10" />
                <line
                    x1="16.04184"
                    x2="14.971841"
                    y1="18.913574"
                    y2="16.773575"
                    stroke="#323232"
                    id="line12"/>
                <path
                    stroke="#323232"
                    d="M 15.03984,5.9135736 H 9.0448402 c -1.105,0 -2,0.895 -2,2 v 6.0000004 c 0,1.657 1.343,3 2.9999998,3 h 4 c 1.658,0 3.001,-1.344 3,-3.002 l -0.004,-5.9990004 c -10e-4,-1.104 -0.897,-1.999 -2.001,-1.999 z"
                    id="path14" />
            </g>
		</Icon>
	);
};
