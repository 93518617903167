import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconStarSquare: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="star-square">
			<path d="M17 2.25a4.75 4.75 0 014.745 4.533L21.75 7v10a4.75 4.75 0 01-4.533 4.745L17 21.75H7a4.75 4.75 0 01-4.745-4.533L2.25 17V7a4.75 4.75 0 014.533-4.745L7 2.25h10zm0 1.5H7a3.25 3.25 0 00-3.245 3.066L3.75 7v10a3.25 3.25 0 003.066 3.245L7 20.25h10a3.25 3.25 0 003.245-3.066L20.25 17V7a3.25 3.25 0 00-3.066-3.245L17 3.75zm-3.847 3.84l.068.121.896 1.82 2.005.29c1.036.152 1.475 1.382.816 2.154l-.088.094-1.448 1.411.343 2.002c.177 1.032-.858 1.83-1.795 1.439l-.112-.053-1.798-.944-1.798.944c-.927.486-2.004-.253-1.922-1.264l.016-.123.343-2.002-1.552-1.511c-.71-.69-.364-1.873.566-2.101l.124-.024 2.148-.312.897-1.82c.461-.935 1.757-.976 2.29-.122zM12.04 8.71l-.905 1.838a.75.75 0 01-.463.388l-.102.022-2.027.294 1.465 1.428a.75.75 0 01.227.56l-.01.104-.348 2.019 1.813-.951.094-.041.126-.034.096-.01.097.001.097.015.095.028.094.041 1.813.952-.346-2.02a.75.75 0 01.146-.586l.07-.078 1.464-1.428-2.025-.294a.75.75 0 01-.512-.32l-.053-.09-.906-1.838z" />
		</Icon>
	);
};
