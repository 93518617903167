import classNames from 'classnames';
import React, { FC } from 'react';
import { VisuallyHidden } from '../VisuallyHidden';
import styles from './Header.module.scss';

export const HeaderNavNotification: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<span className={classNames(styles.headerNavNotification, 'bg-primary')}>
			<VisuallyHidden>{children}</VisuallyHidden>
		</span>
	);
};
