import classNames from 'classnames';
import React, { FC } from 'react';
import { Container } from '../Container';
import styles from './Footer.module.scss';
import { FooterBottom } from './FooterBottom';
import { FooterClaim } from './FooterClaim';
import { FooterCopyright } from './FooterCopyright';
import { FooterCopyrightItem } from './FooterCopyrightItem';
import { FooterLogo } from './FooterLogo';
import { FooterNav } from './FooterNav';
import { FooterNavTitle } from './FooterNavTitle';
import { FooterSideContent } from './FooterSideContent';
import { FooterSocials } from './FooterSocials';

export const Footer: FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<footer className={classNames(styles.footer, 'footer')}>
			<Container>
				<div className={styles.footerHolder}>{children}</div>
			</Container>
		</footer>
	);
};

export default Object.assign(Footer, {
	Bottom: FooterBottom,
	Claim: FooterClaim,
	Copyright: FooterCopyright,
	CopyrightItem: FooterCopyrightItem,
	Logo: FooterLogo,
	Nav: FooterNav,
	NavTitle: FooterNavTitle,
	SideContent: FooterSideContent,
	Socials: FooterSocials,
});
