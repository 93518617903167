import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronLeft: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-left">
			<path d="M13.47 7.47a.75.75 0 011.133.976l-.073.084L11.061 12l3.47 3.47a.75.75 0 01.072.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-4-4a.75.75 0 01-.073-.976l.073-.084 4-4z" />
		</Icon>
	);
};
