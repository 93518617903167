import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconStarSolid: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="star-solid">
			<path d="M11.326 2.566a.75.75 0 011.29-.098l.058.098 2.76 5.639 6.175.909c.541.08.796.675.544 1.112l-.057.086-.07.08-4.471 4.386 1.055 6.2a.751.751 0 01-.905.859l-.093-.029-.092-.041L12 18.84l-5.52 2.926a.751.751 0 01-1.099-.59l-.002-.098.01-.1 1.055-6.201-4.47-4.387a.751.751 0 01.216-1.22l.096-.034.105-.023 6.173-.909 2.762-5.639z" />
		</Icon>
	);
};
