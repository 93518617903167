import {ComponentProps, useMemo} from 'react';
import {Button} from '@components/Button';
import {useMaintenance} from '@utils/maintenance';
import {useIntl} from 'react-intl';
import {Spinner} from '@components/Spinner';

export type ApiActionButtonProps = {
    submitting?: boolean;
} & ComponentProps<typeof Button>;

export const ApiActionButton = ({disabled, title, children, submitting, ...rest}: ApiActionButtonProps) => {
    const {inMaintenance} = useMaintenance();
    const intl = useIntl();

    const titleMessage = useMemo(() => intl.formatMessage({defaultMessage: 'Server není dostupný'}), [intl]);

    return (
        <Button disabled={disabled || inMaintenance || submitting} title={inMaintenance ? titleMessage : title} {...rest}>
            {submitting && (
                <>
                    <Spinner animation="border" variant="light" showSymbol={false} size="sm" />{' '}
                </>
            )}
            {children}
        </Button>
    );
};
