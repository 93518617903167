import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCalendar: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="calendar">
			<path d="M16 1.25a.75.75 0 01.743.648L16.75 2v1.25H19a2.75 2.75 0 012.745 2.582L21.75 6v13A2.75 2.75 0 0119 21.75H5A2.75 2.75 0 012.25 19V6A2.75 2.75 0 015 3.25h2.25V2a.75.75 0 011.493-.102L8.75 2v1.25h6.5V2a.75.75 0 01.75-.75zm4.25 8.5H3.75V19c0 .648.491 1.18 1.122 1.244L5 20.25h14c.69 0 1.25-.56 1.25-1.25V9.75zM7.013 15.979l.117.007a1 1 0 11-.226-.001l.109-.006zm5 0l.117.007a1 1 0 11-.226-.001l.109-.006zm0-4l.117.007a1 1 0 11-.226-.001l.109-.006zm5 0l.117.007a1 1 0 11-.226-.001l.109-.006zM7.25 4.75H5c-.69 0-1.25.56-1.25 1.25v2.25h16.5V6a1.25 1.25 0 00-1.122-1.244L19 4.75h-2.25V6a.75.75 0 01-1.493.102L15.25 6V4.75h-6.5V6a.75.75 0 01-1.493.102L7.25 6V4.75z" />
		</Icon>
	);
};
