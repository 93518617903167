import React, { FC } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import { VisuallyHidden } from '../VisuallyHidden';

export type InputGroupShowPasswordProps = ButtonProps & {
	shown?: boolean;
};

export const InputGroupShowPassword: FC<React.PropsWithChildren<InputGroupShowPasswordProps>> = ({ shown, children, ...props }) => {
	return (
		<InputGroup.Text>
			<Button {...props} variant="blank">
				<VisuallyHidden>{children}</VisuallyHidden>

				{shown ? <Icon.EyeCross /> : <Icon.EyeCheck />}
			</Button>
		</InputGroup.Text>
	);
};
