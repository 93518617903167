import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconCheckSquare: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="check-square">
			<path d="M16 2.25a5.75 5.75 0 015.746 5.53l.004.22v8a5.75 5.75 0 01-5.53 5.746l-.22.004H8a5.75 5.75 0 01-5.746-5.53L2.25 16V8a5.75 5.75 0 015.53-5.746L8 2.25h8zm0 1.5H8a4.25 4.25 0 00-4.245 4.044L3.75 8v8a4.25 4.25 0 004.044 4.245L8 20.25h8a4.25 4.25 0 004.245-4.044L20.25 16V8a4.25 4.25 0 00-4.044-4.245L16 3.75zm-.265 5.833a.75.75 0 01.072.976l-.073.084-4.002 4a.75.75 0 01-.976.073l-.084-.072-2.406-2.4a.75.75 0 01.975-1.134l.084.073 1.876 1.869 3.473-3.47a.75.75 0 011.06 0z" />
		</Icon>
	);
};
