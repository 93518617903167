import React, { FC } from 'react';
import { Icon, IconProps } from '.';

export const IconChevronDoubleRight: FC<React.PropsWithChildren<IconProps>> = ({ ...props }) => {
	return (
		<Icon {...props} id="chevron-double-right">
			<path d="M6.946 6.397l.084.073 5 5a.75.75 0 01.073.976l-.073.084-5 5a.75.75 0 01-1.133-.976l.073-.084L10.439 12l-4.47-4.47a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073zm7 1l.084.073 4 4a.75.75 0 01.073.976l-.073.084-4 4a.75.75 0 01-1.133-.976l.073-.084L16.439 12l-3.47-3.47a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073z" />
		</Icon>
	);
};
