import {useEffect} from 'react';
import {Typography} from '@components/Typography';
import {Icon} from '@components/Icon';
import {Button} from '@components/Button';
import {FormattedMessage} from 'react-intl';

export type RegistrationEmailCheckProps = {
    onClose?: () => void;
    onSuccess?: () => void;
} & (
    | {
          withoutButton: true;
      }
    | {
          withoutButton?: false;
          onClose: () => void;
      }
);

export const RegistrationEmailCheck = ({onClose, onSuccess, withoutButton}: RegistrationEmailCheckProps) => {
    useEffect(() => {
        const channel = new BroadcastChannel('registration_email_check');
        channel.addEventListener('message', (event) => {
            if (event) {
                onSuccess?.();
            }
        });

        return () => {
            channel.close();
        };
    }, [onSuccess]);

    return (
        <>
            <Typography as="p" textAlign="center" color="grey-dark" className="mb-8">
                <Icon.Envelope size="xl" />
            </Typography>
            <Typography as="h2" variant="h4" className="mb-4" textAlign="center">
                <FormattedMessage defaultMessage="Ověření e-mailu" />
            </Typography>

            <Typography as="p" className="mb-8" textAlign="center">
                {!onSuccess && (
                    <FormattedMessage defaultMessage="Už máte skoro hotovo. Dále pokračujte potvrzením e-mailu, který jsme vám právě poslali na vaši adresu. Toto okno můžete nyní zavřít." />
                )}

                {onSuccess && (
                    <FormattedMessage defaultMessage="Už máte skoro hotovo. Dále pokračujte potvrzením e-mailu, který jsme vám právě poslali na vaši adresu. Poté můžete pokračovat v tomto okně." />
                )}
            </Typography>

            {!withoutButton && (
                <Button className="w-100" onClick={onClose}>
                    {!onSuccess && <FormattedMessage defaultMessage="Rozumím, zavřít" />}

                    {onSuccess && <FormattedMessage defaultMessage="Přesto zavřít" />}
                </Button>
            )}
        </>
    );
};
