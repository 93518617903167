import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './SocialsMenu.module.scss';
import { SocialsMenuLink } from './SocialsMenuLink';

export const SocialsMenu: FC<React.PropsWithChildren> = ({ children }) => {
	return <div className={classNames(styles.socialsMenu, 'socialsMenu')}>{children}</div>;
};

export default Object.assign(SocialsMenu, {
	Link: SocialsMenuLink,
});
