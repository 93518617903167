import classNames from 'classnames';
import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {MultiSelect} from 'react-multi-select-component';
import {Icon} from '../../Icon';
import {Typography} from '../../Typography';
import Form from '../Form';
import styles from './MultiSelect.module.scss';

export type MultiSelectOption = {
    value: any;
    label: string;
    key?: string;
    disabled?: boolean;
    separator?: boolean;
};
type MultiSelectItem = {
    itemRenderer: any;
    option: MultiSelectOption;
    checked?: boolean;
    tabIndex?: number;
    disabled?: boolean;
    onSelectionChanged: (checked: boolean) => void;
    onClick: any;
};
export type MultiSelectProps = {
    options: MultiSelectOption[];
    value: MultiSelectOption[];
    onChange?: (selected: MultiSelectOption[]) => void;
    ItemRenderer?: any;
    disabled?: boolean;
    disableSearch?: boolean;
    shouldToggleOnHover?: boolean;
    hasSelectAll?: boolean;
    filterOptions?: (options: MultiSelectOption[], filter: string) => Promise<MultiSelectOption[]> | MultiSelectOption[];
    labelledBy: string;
    className?: string;
    onMenuToggle?: any;
    debounceDuration?: number;
    defaultIsOpen?: boolean;
    isOpen?: boolean;
    closeOnChangedValue?: boolean;
    isValid?: boolean;
    isInvalid?: boolean;
    size?: 'sm';
    contentAlignment?: 'left' | 'right';
    contentSize?: 'lg';
    type?: 'radio' | 'checkbox';
    ClearSelectedIcon?: ReactNode;
};

export const FormMultiSelect: FC<React.PropsWithChildren<MultiSelectProps>> = ({
    value = [],
    isValid,
    isInvalid,
    size,
    contentAlignment = 'left',
    contentSize,
    type = 'checkbox',
    ClearSelectedIcon = <Icon.Cross />,
    ...props
}) => {
    const [selected, setSelected] = useState<MultiSelectOption[]>(value);

    useEffect(() => {
        setSelected(value);
    }, [value]);

    const handleChange = (selected: MultiSelectOption[]) => {
        const value = type === 'radio' ? (selected.length < 1 ? selected : [selected[selected.length - 1]]) : selected;

        setSelected(value);

        if (props.onChange) {
            props.onChange(value);
        }
    };
    const intl = useIntl();

    return (
        <MultiSelect
            {...props}
            className={classNames(styles.multiSelect, {
                [styles[`multiSelect--${size}`]]: size,
                [styles[`multiSelect--content-${contentSize}`]]: contentSize,
                [styles[`multiSelect--content-${contentAlignment}`]]: contentAlignment,
                [styles[`multiSelect--disabled`]]: props.disabled,
                ['is-valid']: isValid,
                ['is-invalid']: isInvalid,
            })}
            value={selected}
            onChange={handleChange}
            overrideStrings={{
                allItemsAreSelected: intl.formatMessage({defaultMessage: 'Vše'}),
                clearSearch: intl.formatMessage({defaultMessage: 'Zrušit výběr'}),
                clearSelected: intl.formatMessage({defaultMessage: 'Zrušit vybrané'}),
                noOptions: intl.formatMessage({defaultMessage: 'Žádné možnosti'}),
                search: intl.formatMessage({defaultMessage: 'Vyhledat'}),
                selectAll: intl.formatMessage({defaultMessage: 'Vybrat vše'}),
                selectAllFiltered: intl.formatMessage({defaultMessage: 'Vybrat filtrované'}),
                selectSomeItems: intl.formatMessage({defaultMessage: 'Vybrat nějaké'}),
                create: intl.formatMessage({defaultMessage: 'Vytvořit'}),
            }}
            ArrowRenderer={({expanded}) => (expanded ? <Icon.TriangleUp color="grey-dark" /> : <Icon.TriangleDown color="grey-dark" />)}
            ClearSelectedIcon={ClearSelectedIcon}
            ClearIcon={<Icon.Cross />}
            valueRenderer={(selected: MultiSelectOption[]) => {
                return selected.length
                    ? selected.map(({label}) => label).join(', ')
                    : intl.formatMessage({defaultMessage: 'Vybrat nějaké'});
            }}
            ItemRenderer={({checked, disabled, onClick, option}: MultiSelectItem) => {
                return option.separator ? (
                    <div className={styles.separator} />
                ) : (
                    <div className={`item-renderer ${disabled && 'disabled'}`}>
                        <Form.Check type={type} checked={checked} disabled={disabled} onChange={onClick} className="me-2" />
                        <Typography>{option.label}</Typography>
                    </div>
                );
            }}
        />
    );
};
